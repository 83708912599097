import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon, TimerIcon } from 'lucide-react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useSubscription,
} from '@apollo/client';
import { alpha } from '@material-ui/core';
import Countdown from 'react-countdown';
import { minutesToMilliseconds } from 'date-fns/esm';
import { Box, useTheme, Divider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdTypography,
  FdChip,
  FdButton,
  FdTooltip,
  useQueryRecursive,
  FdModal,
  successToastMessage,
  warningToastMessage,
  useSnapshot,
  globalStore,
  FdDelayed,
  FdSelect,
} from '@fifthdomain/fe-shared';
import {
  getLabTime,
  listLabInstancesbyExerciseId,
} from '../../../graphql/queries';
import {
  deleteLab,
  extendLabTime,
  restartLab,
} from '../../../graphql/mutations';
import { onUpdateLabInstance } from '../../../graphql/subscriptions';
import { formatMinutesToHours } from '../../../shared/utils/dateUtils';
import { getParticipantLabStatusColor } from '../../../shared/utils/getStatusColor';

const LessonExerciseLabControls = ({ selectedLesson }) => {
  const [activeLabInstance, setActiveLabInstance] = useState(undefined);
  const [activeVms, setActiveVms] = useState([]);
  const [labStatus, setLabStatus] = useState(undefined);
  const [openExtendModal, setExtendModal] = useState(false);
  const [resetLab, setResetLab] = useState(false);
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const theme = useTheme();
  const { userId } = useSnapshot(globalStore);
  const [labTime, setLabTime] = useState(0);
  const { setValue, control } = useFormContext();
  const { exerciseId, exerciseLab } = selectedLesson || {};
  const labId = exerciseLab?.id;
  const lessonIdxLabStatus = `lessons[${selectedLesson?.idx}].exerciseLabRunning`;

  const [deleteLabMutation] = useMutation(gql(deleteLab), {
    onCompleted: () => {
      setLabStatus('Not Started');
    },
  });

  const [fetchLabTime] = useLazyQuery(gql(getLabTime), {
    variables: {
      labInstanceId: activeLabInstance?.id,
      limit: 1000,
    },
    onCompleted: (_labTimeData) => {
      const _labTime = _labTimeData?.getLabTime?.timeRemaining || 0;
      // Delete lab if expired
      if (_labTime <= 0) {
        deleteLabMutation({
          variables: {
            labInstanceId: activeLabInstance?.id,
          },
        });
      }
      setLabTime(_labTime);
      setValue(lessonIdxLabStatus, _labTime > 0);
    },
    skip: !activeLabInstance,
  });

  const stopPollingProcess = () => {
    // eslint-disable-next-line no-use-before-define
    stopPolling();
    setPollingInProgress(false);
    fetchLabTime();
  };

  const {
    startPolling,
    stopPolling,
    refetch: refetchLabInstances,
  } = useQueryRecursive(gql(listLabInstancesbyExerciseId), {
    variables: {
      userId: { eq: userId },
      exerciseId,
      filter: {
        and: [
          { status: { ne: 'DELETED' } },
          { status: { ne: 'IN_DELETE_QUEUE' } },
          { status: { ne: 'DELETE_IN_PROGRESS' } },
          { status: { ne: 'DELETE_FAILED' } },
          { status: { ne: 'BUILD_FAILED' } },
        ],
      },
    },
    skip: !exerciseId,
    onCompleted: (_data) => {
      const labInstance = _data?.listLabInstancesbyExerciseId?.items?.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      )[0];
      const labVms =
        labInstance?.vms?.items?.map((vm, idx) => ({
          ...vm,
          name: idx === 0 ? `${vm?.name} (Primary VM)` : vm?.name,
        })) || [];
      setActiveVms(labVms);
      const labPrototypeStatus = labInstance?.status;
      if (!labPrototypeStatus) {
        stopPolling();
        setPollingInProgress(false);
        setLabStatus('Not Started');
        setValue(`lessons[${selectedLesson?.idx}].exerciseVmSelected`, null);
        return;
      }

      switch (labPrototypeStatus) {
        case 'IN_BUILD_QUEUE':
        case 'BUILD_IN_PROGRESS': {
          setLabStatus('Building');
          break;
        }
        case 'READY': {
          if (labInstance) {
            setActiveLabInstance(labInstance);

            if (labInstance.status === 'READY') {
              setLabStatus('Ready');
              stopPollingProcess();
              fetchLabTime();
              if (!selectedLesson?.exerciseVmSelected) {
                setValue(
                  `lessons[${selectedLesson?.idx}].exerciseVmSelected`,
                  labVms?.length > 1 && labVms[0],
                );
              }
            } else if (!pollingInProgress) {
              // eslint-disable-next-line no-use-before-define
              startPollingProcess();
            }
          } else {
            setLabStatus('Not Started');
            stopPollingProcess();
            setValue(
              `lessons[${selectedLesson?.idx}].exerciseVmSelected`,
              null,
            );
          }
          break;
        }
        default: {
          setLabStatus('Waiting...');
          refetchLabInstances();
          break;
        }
      }
    },
    staleTime: { seconds: 0 },
  });

  useEffect(() => {
    setLabStatus(null);
    if (labId) {
      refetchLabInstances();
    }
  }, [labId, refetchLabInstances]);

  const startPollingProcess = () => {
    startPolling(5000);
    setPollingInProgress(true);
  };

  const [restartLabMutation] = useMutation(gql(restartLab), {
    onCompleted: () => {
      refetchLabInstances();
      setLabStatus('Not Started');
    },
  });

  const [extendLabTimeMutation] = useMutation(gql(extendLabTime), {
    onCompleted: () => {
      setTimeout(() => {
        fetchLabTime();
      }, 5000);
    },
  });

  useSubscription(gql(onUpdateLabInstance), {
    onSubscriptionData: (_data) => {
      refetchLabInstances();
    },
    fetchPolicy: 'cache-and-network',
  });

  const timeRemaining = ['Ready'].includes(labStatus) ? labTime : 0;
  const milliSecondsToFinish =
    ['Ready'].includes(labStatus) && timeRemaining > 0
      ? minutesToMilliseconds(timeRemaining)
      : 0;
  const labStatusColor = getParticipantLabStatusColor(labStatus);
  const showTimer = [
    'Not Started',
    'Starting',
    'Building',
    'Ready',
    'Stopping',
  ].includes(labStatus);
  const disableExtend = labStatus !== 'Ready';

  return (
    <Box className="w-full">
      <Box className="flex items-center justify-between">
        <Box className="flex items-center gap-x-3 mt-3 mb-2">
          <FdTooltip title="Each lab has a default duration of 2 hours. Once you have started your lab, the ‘Extend Lab’ button allows you to reset the timer in your current lab instance back to 2 hours, retaining all progress made. The ‘Reset Lab’ button deploys a new lab instance, deleting all progress, and resetting your remaining lab time back to 2 hours. ">
            <Box
              className="flex items-center justify-center rounded p-1"
              sx={{
                backgroundColor: 'rgba(110, 132, 255, 0.1)',
                color: theme.palette.primary.main,
              }}
            >
              <InfoIcon />
            </Box>
          </FdTooltip>
          <Divider orientation="vertical" flexItem />
          <Box className="flex items-center gap-x-4">
            <FdTypography variant="subtitle2">Lab Status</FdTypography>
            <FdChip size="small" label={labStatus} color={labStatusColor} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex items-center gap-x-2">
            <TimerIcon size="20" />
            {showTimer ? (
              !['Starting', 'Stopping'].includes(labStatus) && (
                <Box className="flex items-center gap-x-2">
                  <FdTypography color="secondary" variant="body2">
                    Lab Time Remaining:
                  </FdTypography>
                  <FdTypography color="secondary" variant="body2">
                    {labStatus === 'Ready' && milliSecondsToFinish ? (
                      <Countdown
                        date={
                          new Date().getTime() +
                          // to trigger countdown, minimum 1 second
                          (milliSecondsToFinish > 1000
                            ? milliSecondsToFinish
                            : 1000)
                        }
                      />
                    ) : (
                      `${
                        timeRemaining
                          ? formatMinutesToHours(timeRemaining)
                          : 'N/A'
                      }`
                    )}
                  </FdTypography>
                </Box>
              )
            ) : (
              <FdTypography variant="body2" style={{ lineHeight: '20px' }}>
                Lab Time Remaining: N/A
              </FdTypography>
            )}
          </Box>
        </Box>
        <Box className="flex items-center flex-wrap gap-2 justify-end">
          <FdTooltip
            title={
              disableExtend ? 'You can extend your lab after starting it.' : ''
            }
          >
            <span>
              <FdButton
                onClick={() => setExtendModal(true)}
                disabled={disableExtend}
              >
                Extend Lab
              </FdButton>
            </span>
          </FdTooltip>
          <FdTooltip
            title={
              disableExtend ? 'You can reset your lab after starting it.' : ''
            }
          >
            <span>
              <FdButton
                onClick={() => {
                  setResetLab(true);
                }}
                disabled={disableExtend}
              >
                Reset Lab
              </FdButton>
            </span>
          </FdTooltip>
        </Box>
      </Box>
      {activeVms.length > 1 && !disableExtend && (
        <Box
          className="flex items-center justify-center gap-x-3 mt-3 mb-2 w-full rounded-lg px-3 py-2"
          sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.2) }}
        >
          <Box className="flex items-center gap-x-3 min-w-[130px]">
            <FdTypography variant="subtitle2">VM Display</FdTypography>
            <FdTooltip title="This lab contains multiple VMs. Select the VM you wish to display using the dropdown.">
              <Box
                className="flex items-center justify-center rounded p-1"
                sx={{
                  backgroundColor: 'rgba(110, 132, 255, 0.1)',
                  color: theme.palette.primary.main,
                }}
              >
                <InfoIcon />
              </Box>
            </FdTooltip>
          </Box>
          <Controller
            control={control}
            name={`lessons[${selectedLesson?.idx}].exerciseVmSelected`}
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FdDelayed delay={100}>
                <Box className="w-full">
                  <FdSelect
                    id={`lessons[${selectedLesson?.idx}].exerciseVmSelected`}
                    options={activeVms?.map((vm) => vm?.name) || []}
                    defaultSelected={fieldValue?.name || ''}
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                    data-cy={`lessons[${selectedLesson?.idx}].exerciseVmSelected`}
                    placeholder="Select a Lab"
                    inputRef={ref}
                    {...rest}
                    onChange={(value) => {
                      const selectedVm =
                        activeVms?.find((vm) => vm?.name === value) || {};
                      setValue(
                        `lessons[${selectedLesson?.idx}].exerciseVmSelected`,
                        selectedVm,
                      );
                    }}
                  />
                </Box>
              </FdDelayed>
            )}
          />
        </Box>
      )}
      <FdModal
        title="Extend this lab instance?"
        size="md"
        description={
          <Box>
            <FdTypography variant="body1">
              Extending this lab instance will reset your time remaining for
              this lab to 2 hours.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              You will be able to continue work on all VMs in this lab, with all
              progress made being unaffected.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              <b>Note:</b>
              there is no limit to the number of times you can extend a lab
              instance. However, once Lab Time Remaining reaches 0, your lab
              instance will be deleted (all progress will be lost) and you will
              need to start the lab again if you wish to continue working on the
              lab.
            </FdTypography>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={openExtendModal}
        onConfirm={() => {
          extendLabTimeMutation({
            variables: {
              labInstanceId: activeLabInstance?.id,
            },
          });
          successToastMessage('Lab instance extended! Timer reset to 2 hours.');
          setExtendModal(false);
        }}
        onDismiss={() => {
          warningToastMessage('Lab instance not extended');
          setExtendModal(false);
        }}
      />
      <FdModal
        title="Reset Lab?"
        size="md"
        description={
          <Box>
            <FdTypography variant="subtitle1">Reset this lab?</FdTypography>
            <Box mt={1}>
              Resetting this lab will deploy a new lab instance for your use. If
              you proceed, your current instance will be deleted, and you will
              lose all your work within the lab.
            </Box>
            <Box mt={1}>
              Please note that after resetting this lab, you will need to start
              the lab again to begin working on it.
            </Box>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={resetLab}
        onConfirm={() => {
          restartLabMutation({
            variables: {
              labInstanceId: activeLabInstance?.id,
              hardReset: true,
            },
          });
          successToastMessage('Lab reset successfully!');
          setResetLab(false);
        }}
        onDismiss={() => {
          warningToastMessage('Lab not reset');
          setResetLab(false);
        }}
      />
    </Box>
  );
};

LessonExerciseLabControls.propTypes = {
  selectedLesson: PropTypes.shape({
    exercise: PropTypes.string,
    idx: PropTypes.number,
    exerciseVmSelected: PropTypes.shape({ name: PropTypes.string }),
  }).isRequired,
};

export default LessonExerciseLabControls;
