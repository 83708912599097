import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdSelect, FdDelayed } from '@fifthdomain/fe-shared';
import FdTextView from '../../FdTextView';

const QuizPart = ({ moduleIdx, partIdx, isEdit, allQuizzes }) => {
  const { control, setValue } = useFormContext();

  return (
    <Box>
      <Controller
        control={control}
        name={`modules[${moduleIdx}].parts[${partIdx}].quiz`}
        render={({
          field: { ref, value: fieldValue, ...rest },
          fieldState: { error },
        }) => (
          <FdDelayed>
            <Box mb={2}>
              {isEdit ? (
                <FdSelect
                  id={`modules[${moduleIdx}].parts[${partIdx}].quiz`}
                  label="Quiz"
                  options={allQuizzes?.map((q) => q.name) || []}
                  defaultSelected={fieldValue}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy={`modules[${moduleIdx}].parts[${partIdx}].quiz`}
                  placeholder="Select a Quiz"
                  {...rest}
                  inputRef={ref}
                  onChange={(value) => {
                    setValue(
                      `modules[${moduleIdx}].parts[${partIdx}].quiz`,
                      value,
                    );
                    setValue(
                      `partsSummary[${moduleIdx}].parts[${partIdx}].quiz`,
                      value,
                    );
                  }}
                />
              ) : (
                <FdTextView label="Quiz" value={fieldValue} />
              )}
            </Box>
          </FdDelayed>
        )}
      />
    </Box>
  );
};

QuizPart.propTypes = {
  allQuizzes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  moduleIdx: PropTypes.string.isRequired,
  partIdx: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default QuizPart;
