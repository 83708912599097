import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import {
  FdTypography,
  FdFileAttachment,
  FdButton,
} from '@fifthdomain/fe-shared';
import LessonIcon from './LessonIcon';
import VideoPlayer from './VideoPlayer';

const LessonResource = ({ content, type }) => {
  const [openPlayer, setOpenPlayer] = useState(false);
  //create the markDown file
  const markDownFile = () => {
    const link = document.createElement('a');
    const file = new Blob([content?.markdown], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = `${content?.name}.md`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <Box className="flex mb-4 h-8">
      <Box mr={1}>
        <LessonIcon type={type} noLabel />
      </Box>
      <Box className="flex flex-col my-1">
        <FdTypography variant="body2">{content?.name}</FdTypography>
        {type === 'MARKDOWN' && (
          <Link
            onClick={() => {
              markDownFile();
            }}
            variant="captiontext1"
            className="font-bold "
          >
            {`${content?.name}.md`}
          </Link>
        )}
        {type === 'VIDEO' && (
          <>
            <Link
              onClick={() => {
                setOpenPlayer(true);
              }}
              variant="captiontext1"
              className="font-bold "
            >
              Open Video Player
            </Link>
            <VideoPlayer
              openPlayer={openPlayer}
              showCloseAction={() => setOpenPlayer(false)}
              content={content}
            />
          </>
        )}
        {type === 'PDF' && (
          <FdFileAttachment
            name={content?.file?.key}
            variant="captiontext1"
            style={{
              textDecoration: 'underline',
              fontWeight: '700',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

LessonResource.propTypes = {
  type: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default LessonResource;
