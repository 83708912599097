import { getUrl, uploadData } from 'aws-amplify/storage';
import { downloadFile } from '@fifthdomain/fe-shared';

const getFileFromURL = (name, url) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // check for google or  S3 bucket storage
      if (!url.includes('googleapis')) {
        getUrl({ path: `public/${name}`, options: { download: true } })
          .then((res) => res.Body)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        fetch(url)
          .then((response) => response.blob())
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      }
    });
  }, 2000);
};

export const readTextFile = (file, callback) => {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result);
  reader.readAsText(file);
};

export const getPreSignedUrl = async (key) => {
  const url = await getUrl({ path: `public/${key}` });
  return url.url.toString();
};

export const getS3File = async (type) => {
  if (type?.file) {
    const fileKey = type?.file?.key;
    const s3Url = await getPreSignedUrl(fileKey);
    return { name: fileKey || '', url: s3Url };
  }
  return null;
};

export const downloadMarkdown = (content, fileName) => {
  const blob = new Blob([content], { type: 'text/markdown;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  downloadFile(url, fileName);
  URL.revokeObjectURL(url);
};

export const uploadFileToS3 = ({ key, file, progressCallback = () => {} }) => {
  // Set default value for progressCallback as an empty function
  return uploadData({
    path: `public/${key}`,
    data: file,
    options: {
      contentType: file.type,
      onProgress: progressCallback,
    },
  });
};

export default getFileFromURL;
