import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  FdCard,
  FdAccordion,
  FdTypography,
  FdButton,
  FdSelect,
} from '@fifthdomain/fe-shared';
import { useFieldArray } from 'react-hook-form';
import FdBoxButton from '../FdBoxButton';

const LabNetworkVms = ({
  Controller,
  control,
  nextIndex,
  labNetworkValues,
  getValueDeleteVMNetwork,
  setValueVMNetwork,
  vmNetworkValues,
}) => {
  const {
    fields: labNetworkVmsFields,
    append: labNetworkVmsAppend,
    remove: labNetworkVmsRemove,
  } = useFieldArray({
    control,
    name: `labVms[${nextIndex}].networks.items`,
  });

  return (
    <FdCard
      variant="outlined"
      optional
      heading="Assign Networks to VMs"
      subHeading="Select networks that were created from the previous page that you’d like to add to the above Virtual Machine."
    >
      {labNetworkVmsFields.map((item, index1) => {
        return (
          <Box key={item.id} my={2}>
            <FdAccordion
              open
              startAdornment
              showToggleButton={false}
              summary={() => (
                <Box style={{ width: '100%' }}>
                  <Box display="flex">
                    <Box sx={{ flexGrow: 1 }}>
                      <FdTypography variant="h3">Network</FdTypography>
                    </Box>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        labNetworkVmsRemove(index1);

                        setValueVMNetwork(
                          'labDeleteVMNetworks',
                          getValueDeleteVMNetwork.concat(
                            vmNetworkValues?.filter(
                              (labNetw) =>
                                item.name === labNetw.name &&
                                item.labPrototypeVMId ===
                                  labNetw.labPrototypeVMId,
                            ),
                          ),
                        );
                      }}
                    >
                      DELETE
                    </FdButton>
                  </Box>
                </Box>
              )}
              content={() => (
                <Box display="flex" flexDirection="column">
                  <Controller
                    control={control}
                    name={`labVms[${nextIndex}].networks.items[${index1}].name`}
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box mt={1} mb={2}>
                        <FdSelect
                          inputTitle="Network"
                          defaultSelected={rest.value}
                          id={`labVms[${nextIndex}].networks.items[${index1}].name`}
                          placeholder="Select one option"
                          options={labNetworkValues?.map(
                            (labNetwork) => labNetwork.name,
                          )}
                          width="496px"
                          error={error}
                          helperText={error && error.message}
                          {...rest}
                          inputRef={ref}
                        />
                      </Box>
                    )}
                  />
                </Box>
              )}
            />
          </Box>
        );
      })}
      <FdBoxButton
        onClick={async (e) => {
          e.preventDefault();
          labNetworkVmsAppend({});
        }}
      >
        ASSIGN NEW NETWORK
      </FdBoxButton>
    </FdCard>
  );
};
LabNetworkVms.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  nextIndex: PropTypes.number.isRequired,
  labNetworkValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  getValueVMNetwork: PropTypes.arrayOf(
    PropTypes.shape({
      adapterType: PropTypes.string,
      id: PropTypes.string,
      labPrototypeVMId: PropTypes.string,
      ip: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  getValueDeleteVMNetwork: PropTypes.arrayOf(PropTypes.object),
  setValueVMNetwork: PropTypes.func,
  vmNetworkValues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

LabNetworkVms.defaultProps = {
  getValueVMNetwork: [],
  getValueDeleteVMNetwork: [],
  setValueVMNetwork: () => {},
};
export default LabNetworkVms;
