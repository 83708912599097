import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdCard,
  FdButton,
  FdChip,
  FdAlert,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import PdfViewer from '../PdfViewer/PdfViewer';
import { getImageUrlFromS3 } from '../../../shared/utils/getImageFromS3';
import {
  createModulePartProgress,
  updateCourseUser,
  updateModulePartProgress,
} from '../../../graphql/mutations';
import { listModulePartProgressesByCourseUserId } from '../../../graphql/queries';
import { getCourseUser } from '../../../queries/customQueries';
import { updateCourseUserStatus } from '../../../shared/utils/updateCourseUser';

function PdfPage({ file, name, description, courseId, userId }) {
  const [fileUrl, setFileUrl] = useState('');
  const [fileLoading, setFileLoading] = useState(true);
  const { courseUserId, partId } = useParams();

  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgress,
  } = useQuery(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
      filter: { modulePartId: { eq: partId } },
    },
    staleTime: { seconds: 0 },
    skip: !partId,
  });

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgress();
    },
  });

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: () => {
      refetchCourseUser();
    },
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgress();
    },
  });

  useEffect(() => {
    const fetchFileUrl = async () => {
      if (file && file.key) {
        const fileUrlKey = String(file.key);
        const fileUrlS3 = await getImageUrlFromS3(fileUrlKey);
        setFileUrl(fileUrlS3);
      }
      setFileLoading(false);
    };
    fetchFileUrl();
  }, [file]);

  const currentModulePartProgress =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items[0] || [];
  const isPdfCompleted = currentModulePartProgress?.status === 'FINISHED';
  const loading =
    createModulePartProgressLoading ||
    updateModulePartProgressLoading ||
    fileLoading ||
    modulePartProgressLoading ||
    courseUserDataLoading;

  return (
    <Box>
      <FdCard
        variant="outlined"
        heading={name}
        summary={
          <Box display="flex">
            {isPdfCompleted ? (
              <FdChip color="success" size="small" label="completed" />
            ) : (
              <FdButton
                onClick={() => {
                  const now = new Date().toISOString();

                  if (!currentModulePartProgress) {
                    createModulePartProgressMutation({
                      variables: {
                        input: {
                          courseUserId,
                          modulePartId: partId,
                          status: 'FINISHED',
                          finishedOn: new Date().toISOString(),
                          userId,
                          courseId,
                        },
                      },
                    });
                    return;
                  }

                  updateModulePartProgressMutation({
                    variables: {
                      input: {
                        id: currentModulePartProgress?.id,
                        status: 'FINISHED',
                        finishedOn: now,
                        userId,
                        courseId,
                      },
                    },
                  });
                  updateCourseUserStatus(
                    modulePartProgress,
                    updateCourseUserMutation,
                    courseUserData,
                    partId,
                  );
                }}
              >
                {loading ? 'Loading...' : 'Mark Complete'}
              </FdButton>
            )}
          </Box>
        }
        subHeading={description}
      />
      {isPdfCompleted && (
        <Box mb={2}>
          <FdAlert
            alertTitle="This part has been marked as complete!"
            variant="success"
          />
        </Box>
      )}
      <FdDelayed delay={1000}>
        <PdfViewer theme="dark" fileUrl={fileUrl} />
      </FdDelayed>
    </Box>
  );
}

PdfPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

PdfPage.defaultProps = {
  description: '',
};

export default PdfPage;
