import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const RadioOptions = ({ options, title, subTitle, error, ...props }) => {
  return (
    <Box mb={1}>
      <FdTypography variant="subtitle1" className="my-1">
        {title}
      </FdTypography>
      <FdTypography variant="captiontext1" className="my-1">
        {subTitle}
      </FdTypography>
      <RadioGroup {...props}>
        {options?.map((option) => (
          <FormControlLabel
            value={option?.value}
            control={
              <Radio
                size="small"
                sx={{ color: error ? 'rgba(198, 40, 40, 1)' : 'inherit' }}
              />
            }
            label={
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="body1" className="leading-none">
                  {option?.label}
                </FdTypography>
                <FdTypography
                  variant="captiontext1"
                  color="secondary"
                  className="leading-none mt-0.5"
                >
                  {option?.caption}
                </FdTypography>
              </Box>
            }
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText style={{ color: 'rgba(198, 40, 40, 1)' }}>
          {error?.message}
        </FormHelperText>
      )}
    </Box>
  );
};

RadioOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      caption: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }).isRequired,
};

export default RadioOptions;
