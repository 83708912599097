import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'lucide-react';
import { FdTypography } from '@fifthdomain/fe-shared';

const InfoAccordion = ({ title, message, ...props }) => {
  return (
    <Accordion
      className="mb-4 rounded-lg w-full"
      sx={{
        bgcolor: 'rgba(250, 250, 250, 1)',
        borderColor: 'transparent',
        '&::before': { display: 'none' },
      }}
      variant="outlined"
      disableGutters
      {...props}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon />}>
        <FdTypography variant="subtitle2" className="font-semibold">
          {title}
        </FdTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0, marginTop: 0, marginX: '2rem' }}>
        <FdTypography
          variant="subtitle2"
          color="rgba(0, 0, 0, 0.87)"
          sx={{ fontWeight: 400 }}
        >
          {message}
        </FdTypography>
      </AccordionDetails>
    </Accordion>
  );
};

InfoAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  noIcon: PropTypes.bool,
};

InfoAccordion.defaultProps = {
  noIcon: false,
};

export default InfoAccordion;
