import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTypography, FdButton, FdChip } from '@fifthdomain/fe-shared';

const MarkdownPdfPart = ({ moduleIdx, partIdx, setUploadOpen, isEdit }) => {
  const { control, setValue, trigger } = useFormContext();
  const watchPartFile = useWatch({
    control,
    name: `modules[${moduleIdx}].parts[${partIdx}].file`,
  });
  return (
    <Box>
      <Controller
        control={control}
        name={`modules[${moduleIdx}].parts[${partIdx}].file`}
        render={({ field: { value: fieldValue } }) => (
          <Box my={1}>
            {(watchPartFile?.name || watchPartFile?.key) && (
              <Box>
                {!isEdit && (
                  <FdTypography variant="subtitle1">File</FdTypography>
                )}
                <FdChip
                  color="default"
                  label={fieldValue?.name || fieldValue?.key}
                  onDelete={
                    isEdit
                      ? async () => {
                          setValue(
                            `modules[${moduleIdx}].parts[${partIdx}].file`,
                            undefined,
                          );
                          await trigger(
                            `modules[${moduleIdx}].parts[${partIdx}].file`,
                          );
                        }
                      : undefined
                  }
                  style={{
                    marginRight: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      />
      {isEdit && (
        <Box my={1}>
          <FdTypography variant="subtitle1">Upload File</FdTypography>
          <FdButton onClick={() => setUploadOpen(true)}>Upload</FdButton>
        </Box>
      )}
    </Box>
  );
};

MarkdownPdfPart.propTypes = {
  moduleIdx: PropTypes.string.isRequired,
  partIdx: PropTypes.string.isRequired,
  setUploadOpen: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default MarkdownPdfPart;
