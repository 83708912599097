import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'lucide-react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdTypography,
  FdSelect,
  FdTextField,
  FdButton,
  FdAlert,
  FdMarkdownEditor,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import FileUpload from './FileUpload';
import { RESOURCE_TYPES } from '../../../constants';
import ResourceTypeIcon from '../LessonView/ResourceTypeIcon';
import { extractVimeoUrl } from '../../../shared/utils/video';

const CourseResourceAccordion = ({
  resourceIdx,
  removeResource,
  addRemoveResourceIdx,
  dbId,
}) => {
  const { control, watch, setValue, trigger, getFieldState } = useFormContext();
  const watchResourceType = watch(
    `courseResources[${resourceIdx}].resourceType`,
  );

  const watchResourcePdf = watch(`courseResources[${resourceIdx}].resourcePdf`);
  const { error: resourceError } = getFieldState(
    `courseResources[${resourceIdx}]`,
  );
  const resourceType = RESOURCE_TYPES.find((r) => r.id === watchResourceType);

  return (
    <Box my={2} className="flex items-center gap-x-2 w-full">
      <Accordion
        className="rounded-lg w-full"
        variant="outlined"
        sx={{ borderColor: resourceError ? 'rgba(198, 40, 40, 1)' : 'inherit' }}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Box className="flex items-center justify-between flex-grow">
            <Box className="flex-shrink-0 max-w-full flex items-center gap-x-3 px-3">
              <Controller
                control={control}
                name={`courseResources[${resourceIdx}].resourceName`}
                render={({ field: { value: fieldValue } }) => {
                  const Content = () => (
                    <FdTypography
                      variant="h4"
                      className="font-semibold truncate overflow-hidden max-w-sm"
                    >
                      {fieldValue || 'Resource Name'}
                    </FdTypography>
                  );

                  return fieldValue?.length > 33 ? (
                    <FdTooltip
                      title={<snap className="break-words">{fieldValue}</snap>}
                    >
                      <snap>
                        <Content />
                      </snap>
                    </FdTooltip>
                  ) : (
                    <Content />
                  );
                }}
              />
              {watchResourceType && (
                <ResourceTypeIcon type={resourceType?.name} />
              )}
            </Box>
            <Box className="flex items-center gap-x-3">
              <Controller
                control={control}
                name={`courseResources[${resourceIdx}].resourceType`}
                render={({
                  field: { ref, value: fieldValue, ...rest },
                  fieldState: { error },
                }) => (
                  <FdSelect
                    id={`courseResources[${resourceIdx}].resourceType`}
                    options={RESOURCE_TYPES}
                    defaultSelected={fieldValue}
                    placeholder="Select Resource Type"
                    data-cy="resource-type"
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                  />
                )}
              />
              <FdButton
                variant="secondary"
                onClick={() => {
                  removeResource(resourceIdx);
                  if (dbId) {
                    // flag existing db entry for delete
                    addRemoveResourceIdx(dbId);
                  }
                }}
              >
                Delete
              </FdButton>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider className="mx-4 mb-2" />
        <AccordionDetails sx={{ paddingTop: 0, marginTop: 0, marginX: '2rem' }}>
          <Box className="flex flex-col my-4 gap-y-2">
            <Controller
              control={control}
              name={`courseResources[${resourceIdx}].resourceName`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`courseResources[${resourceIdx}].resourceName`}
                    label="Resource Name"
                    required
                    fullWidth
                    error={error}
                    helperText={error ? error.message : null}
                    placeholder="Enter Resource Name"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name={`courseResources[${resourceIdx}].resourceDescription`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mb={2}>
                  <FdTextField
                    id={`courseResources[${resourceIdx}].resourceDescription`}
                    label="Resource Description"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                    multiline
                    rows={2}
                    placeholder="Enter Resource Description"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            {watchResourceType === 'PDF' && (
              <Box className="flex flex-col gap-y-3">
                <FdTypography variant="subtitle1">Upload PDF</FdTypography>
                <FdTypography variant="captiontext1" color="secondary">
                  Click to upload a PDF below.
                </FdTypography>
                <Controller
                  control={control}
                  name={`courseResources[${resourceIdx}].resourcePdf`}
                  render={({ fieldState: { error } }) => (
                    <Box className="flex flex-col gap-y-2">
                      {error && (
                        <FdAlert variant="error" message={error.message} />
                      )}
                      <FileUpload
                        fileTypes=".pdf"
                        noFileMessage="No PDF uploaded"
                        onDrop={(files) => {
                          setValue(
                            `courseResources[${resourceIdx}].resourcePdf`,
                            files[0],
                            { shouldDirty: true },
                          );
                          trigger(
                            `courseResources[${resourceIdx}].resourcePdf`,
                          );
                        }}
                        onDelete={() => {
                          setValue(
                            `courseResources[${resourceIdx}].resourcePdf`,
                            null,
                            { shouldDirty: true },
                          );
                          trigger(
                            `courseResources[${resourceIdx}].resourcePdf`,
                          );
                        }}
                        file={watchResourcePdf}
                      />
                    </Box>
                  )}
                />
              </Box>
            )}
            {watchResourceType === 'MARKDOWN' && (
              <Controller
                control={control}
                name={`courseResources[${resourceIdx}].resourceMarkdown`}
                render={({ field, fieldState: { error } }) => (
                  <FdMarkdownEditor
                    id={`courseResources[${resourceIdx}].resourceMarkdown`}
                    name={`courseResources[${resourceIdx}].resourceMarkdown`}
                    markdown={field.value}
                    setMarkdown={field.onChange}
                    label="Markdown Editor"
                    placeholder="Enter Markdown"
                    required
                    error={error}
                    errorText={error && error.message}
                    {...field}
                  />
                )}
              />
            )}
            {watchResourceType === 'VIDEO' && (
              <Controller
                control={control}
                name={`courseResources[${resourceIdx}].resourceVideo`}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box my={2}>
                    <FdTextField
                      id={`courseResources[${resourceIdx}].resourceVideo`}
                      label="Add Vimeo Embed URL"
                      required
                      fullWidth
                      error={error}
                      helperText={
                        error
                          ? error.message
                          : 'Paste a Vimeo Embed URL in the input box above. Any URLs that are not a Vimeo Embed URL will not be playable.'
                      }
                      multiline
                      rows={1}
                      placeholder="Paste Vimeo Embed URL"
                      {...rest}
                      inputRef={ref}
                      onChange={(e) => {
                        // clean up url to get vimeo embed link
                        const cleanedUrl = extractVimeoUrl(e.target.value);
                        setValue(
                          `courseResources[${resourceIdx}].resourceVideo`,
                          cleanedUrl,
                        );
                      }}
                    />
                  </Box>
                )}
              />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

CourseResourceAccordion.propTypes = {
  resourceIdx: PropTypes.number.isRequired,
  removeResource: PropTypes.func.isRequired,
  addRemoveResourceIdx: PropTypes.func,
  dbId: PropTypes.string,
};

CourseResourceAccordion.defaultProps = {
  addRemoveResourceIdx: () => {},
  dbId: '',
};

export default CourseResourceAccordion;
