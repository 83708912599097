import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation, gql } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  FdProgress,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
  errorToastMessage,
  FdAlert,
  useRecentLinks,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import scrollToTop from '../shared/utils/scroll';
import {
  LabDetails,
  LabNetworks,
  LabVirtualMachines,
} from '../components/Labs';
import {
  labInitialValues,
  labValidationSchema,
} from '../validation-schemas/Labs';
import { getLabPrototype, listVMFiles } from '../graphql/queries';
import {
  createLabPrototype,
  updateLabPrototype,
  createLabPrototypeNetwork,
  updateLabPrototypeNetwork,
  deleteLabPrototypeNetwork,
  deleteLabPrototypeVM,
  deleteLabPrototypeVMNetwork,
  createLabPrototypeVM,
  createLabPrototypeVMNetwork,
  updateLabPrototypeVM,
  updateLabPrototypeVMNetwork,
} from '../graphql/mutations';
import FinalizeModal from '../components/Labs/FinalizeModal';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
  buttonBack: {
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  buttonPrimary: {
    '&:focus': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

const steps = ['Add Details', 'Add Networks', 'Add Virtual Machines'];

const CreateLab = ({ formMode }) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [buildInitiated, setBuildInitiated] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const { classes } = useStyles();
  const theme = useTheme();
  const isEditMode = formMode === 'edit';
  const [labStatus, setLabStatus] = useState('');
  const { labId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });
  const reactHookFormMethods = useForm({
    defaultValues: labInitialValues,
    resolver: yupResolver(labValidationSchema),
    mode: 'all',
  });
  const {
    formState: { isDirty },
    reset,
    getValues,
    trigger,
  } = reactHookFormMethods;

  // List VMs
  const { data: ListVMFilesData, loading: listVMFilesLoading } =
    useQueryRecursive(gql(listVMFiles), {
      variables: {
        filter: {
          orgId: { eq: globalSnap.orgId },
          and: [{ status: { eq: 'READY' } }],
        },
      },
    });

  const VMFiles = ListVMFilesData?.listVMFiles?.items ?? [];
  const VMFilesDetails = (prototype) => {
    return VMFiles?.filter((VMFile) => VMFile?.name === prototype)[0];
  };

  // Get Lab Prototype
  const { loading: getLabPrototypeLoading } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isEditMode,
    onCompleted: (data) => {
      setLabStatus(data?.getLabPrototype?.status);
      // reset form only when not dirty,
      // this is to trigger detail level mutations when update action happens
      if (!isDirty) {
        reset({
          labName: data.getLabPrototype?.name,
          labDescription: data.getLabPrototype?.description,
          labNetworks: data.getLabPrototype?.networks?.items?.map(
            (item) => item,
          ),
          labVms: data.getLabPrototype?.vms?.items?.map((item) => ({
            ...item,
            accessibilityOption: item?.vdi ? 'VDI' : 'VPN',
          })),
        });
        // add recent link
        addRecentLink({
          id: labId,
          name: data.getLabPrototype?.name,
          type: 'LAB',
          url: pathname + search,
          role: 'MANAGE',
        });
        setShowEditAlert(true);
      }
    },
  });

  // Add Networks, VMs, VMNetwork to the Lab Prototype
  const [createLabNetworkMutation] = useMutation(
    gql(createLabPrototypeNetwork),
  );
  const [updateLabNetworkMutation] = useMutation(
    gql(updateLabPrototypeNetwork),
  );

  const [createLabPrototypeVMMutation] = useMutation(gql(createLabPrototypeVM));
  const [createLabPrototypeVMNetworkMutation] = useMutation(
    gql(createLabPrototypeVMNetwork),
  );
  const [updateLabPrototypeVMMutation] = useMutation(gql(updateLabPrototypeVM));
  const [updateLabPrototypeVMNetworkMutation] = useMutation(
    gql(updateLabPrototypeVMNetwork),
  );
  const [deleteLabPrototypeNetworkMutation] = useMutation(
    gql(deleteLabPrototypeNetwork),
  );
  const [deleteLabPrototypeVMMutation] = useMutation(gql(deleteLabPrototypeVM));
  const [deleteLabPrototypeVMNetworkMutation] = useMutation(
    gql(deleteLabPrototypeVMNetwork),
  );

  const deleteMultipleLabNetworks = async (labDeleteNetworks) => {
    await Promise.all(
      labDeleteNetworks.map((object) => {
        return deleteLabPrototypeNetworkMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
        });
      }),
    );
  };
  const deleteMultipleLabVMNetworks = async (labDeleteVMNetworks) => {
    await Promise.all(
      labDeleteVMNetworks.map((object) => {
        return deleteLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
        });
      }),
    );
  };
  const deleteMultipleLabVMs = async (labDeleteVMs) => {
    await Promise.all(
      labDeleteVMs.map((object) => {
        return deleteLabPrototypeVMMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
          onCompleted: async () => {
            await deleteMultipleLabVMNetworks(object?.networks?.items);
          },
        });
      }),
    );
  };
  const createMultipleLabNetwork = async (labPrototypeId, labNetworks) => {
    await Promise.all(
      labNetworks.map((object) => {
        return createLabNetworkMutation({
          variables: {
            input: {
              labPrototypeId,
              name: object.name,
              hasInternet: object.hasInternet ?? false,
            },
          },
        });
      }),
    );
  };
  const updateMultipleLabNetwork = async (labPrototypeId, labNetworks) => {
    await Promise.all(
      labNetworks.map((object) => {
        return updateLabNetworkMutation({
          variables: {
            input: {
              id: object.id,
              labPrototypeId,
              name: object.name,
              hasInternet: object.hasInternet ?? false,
            },
          },
        });
      }),
    );
  };
  const createLabPrototypeVMNetworks = async (object, data) => {
    await Promise.all(
      object?.map((obj) => {
        return createLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              labPrototypeVMId:
                data?.createLabPrototypeVM?.id ||
                data?.updateLabPrototypeVM?.id,
              name: obj.name,
            },
          },
        });
      }),
    );
  };
  const createMultipleLabVms = async (labPrototypeId, labVms) => {
    await Promise.all(
      labVms?.map((object) => {
        return createLabPrototypeVMMutation({
          variables: {
            input: {
              labPrototypeId,
              name: object.name,
              imageId: VMFilesDetails(object.imageId)?.imageId,
              vpn: object.accessibilityOption === 'VPN',
              vdi: object.accessibilityOption === 'VDI',
              vdiUser: object.vdiUser ?? 'fifthdomain',
              instanceType: object.instanceType,
            },
          },
          onCompleted: (data) => {
            const createLabVMNetworks = object?.networks?.items;
            createLabPrototypeVMNetworks(createLabVMNetworks, data);
          },
        });
      }),
    );
  };

  const updateLabPrototypeVMNetworks = async (object, data) => {
    await Promise.all(
      object?.map((obj) => {
        return updateLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              id: obj.id,
              labPrototypeVMId:
                data?.createLabPrototypeVM?.id ||
                data?.updateLabPrototypeVM?.id,
              name: obj.name,
            },
          },
        });
      }),
    );
  };

  const updateMultipleLabVms = async (labPrototypeId, labVms) => {
    await Promise.all(
      labVms?.map((object) => {
        return updateLabPrototypeVMMutation({
          variables: {
            input: {
              id: object.id,
              labPrototypeId,
              name: object.name,
              imageId: VMFilesDetails(object.imageId)?.imageId,
              vpn: object.accessibilityOption === 'VPN',
              vdi: object.accessibilityOption === 'VDI',
              vdiUser: object.vdiUser ?? 'fifthdomain',
              instanceType: object.instanceType,
            },
          },
          onCompleted: (data) => {
            const createVmNetworkValue = object?.networks?.items?.filter(
              ({ labPrototypeVMId }) =>
                !data?.updateLabPrototypeVM?.id.includes(labPrototypeVMId),
            );
            const updateVmNetworkValue = object?.networks?.items?.filter(
              ({ labPrototypeVMId }) =>
                data?.updateLabPrototypeVM?.id.includes(labPrototypeVMId),
            );
            if (createVmNetworkValue?.length > 0) {
              createLabPrototypeVMNetworks(createVmNetworkValue, data);
            }
            if (updateVmNetworkValue?.length > 0) {
              updateLabPrototypeVMNetworks(updateVmNetworkValue, data);
            }
          },
        });
      }),
    );
  };

  //  update the Lab_Status
  const [updateLabPrototypeStatus] = useMutation(gql(updateLabPrototype));
  const updateLabStatus = (id, status) => {
    updateLabPrototypeStatus({
      variables: {
        input: {
          id,
          status,
        },
      },
    });
  };

  // Add Competency, Network and VMs Details to Lab
  const addDetailsToLab = (data) => {
    const labPrototypeId =
      data?.createLabPrototype?.id || data?.updateLabPrototype?.id;
    const networkIdd =
      data?.updateLabPrototype?.networks?.items?.map((item) => item.id) || [];
    const labVmsIdd =
      data?.updateLabPrototype?.vms?.items?.map((item) => item.id) || [];
    const {
      labNetworks,
      labVms,
      labDeleteNetworks,
      labDeleteVMs,
      labDeleteVMNetworks,
    } = getValues();
    if (labDeleteNetworks !== undefined) {
      deleteMultipleLabNetworks(labDeleteNetworks);
    }
    if (labDeleteVMs !== undefined) {
      deleteMultipleLabVMs(labDeleteVMs);
    }
    if (labDeleteVMNetworks !== undefined) {
      deleteMultipleLabVMNetworks(labDeleteVMNetworks);
    }

    if (labNetworks?.length > 0) {
      if (networkIdd?.length === 0) {
        createMultipleLabNetwork(labPrototypeId, labNetworks);
      } else {
        const createNetworkValue = labNetworks?.filter(
          ({ id }) => !networkIdd.includes(id),
        );
        const updateNetworkValue = labNetworks?.filter(({ id }) =>
          networkIdd.includes(id),
        );
        if (createNetworkValue?.length > 0) {
          createMultipleLabNetwork(labPrototypeId, createNetworkValue);
        }
        if (updateNetworkValue?.length > 0) {
          updateMultipleLabNetwork(labPrototypeId, updateNetworkValue);
        }
      }
    }
    if (labVms?.length > 0) {
      if (labVmsIdd?.length === 0) {
        createMultipleLabVms(labPrototypeId, labVms);
      } else {
        const createVmsValue = labVms?.filter(
          ({ id }) => !labVmsIdd.includes(id),
        );
        const updateVmsValue = labVms?.filter(({ id }) =>
          labVmsIdd.includes(id),
        );
        if (createVmsValue?.length > 0) {
          createMultipleLabVms(labPrototypeId, createVmsValue);
        }
        if (updateVmsValue?.length > 0) {
          updateMultipleLabVms(labPrototypeId, updateVmsValue);
        }
      }
    }
    const labProTypeStatus =
      data?.createLabPrototype?.status || data?.updateLabPrototype?.status;
    const successMessage =
      labProTypeStatus === 'DRAFT'
        ? 'Success! Lab saved as draft.'
        : labProTypeStatus === 'READY'
          ? 'Success! Lab saved.'
          : '';

    successToastMessage(
      buildInitiated ? 'Success! Lab build has started.' : successMessage,
    );
    reset();
    setTimeout(() => {
      singleSpa.navigateToUrl('/labs');
    }, 500);
  };

  // create Lab Prototype
  const [createLabPrototypeMutation, { loading: createLabPrototypeLoading }] =
    useMutation(gql(createLabPrototype), {
      onCompleted: (data) => {
        addDetailsToLab(data);
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    });
  // update Lab Prototype
  const [updateLabPrototypeMutation, { loading: updateLabPrototypeLoading }] =
    useMutation(gql(updateLabPrototype), {
      onCompleted: (data) => {
        addDetailsToLab(data);
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    });

  const onSubmit = (status, nextStatus) => {
    const values = getValues();
    const { labName, labDescription } = values;
    const labValue = isEditMode
      ? updateLabPrototypeMutation
      : createLabPrototypeMutation;

    labValue({
      variables: {
        input: {
          id: isEditMode ? labId : undefined,
          name: labName,
          description: labDescription,
          status: status || undefined,
          userId: globalSnap.userId,
          orgId: globalSnap.orgId,
          versionIteration: 0,
        },
      },
    }).then((res) => {
      if (nextStatus !== undefined) {
        const id =
          res?.data?.createLabPrototype?.id ||
          res?.data?.updateLabPrototype?.id;
        // update lab status
        updateLabStatus(id, nextStatus);
      }
    });
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['labName', 'labDescription']);
        break;
      }
      case 1: {
        result = await trigger(['labNetworks']);
        break;
      }
      case 2: {
        result = await trigger(['labVms']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep < 2) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      } else {
        setShowFinalizeModal(true);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/labs');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  const onFinalizeAction = async (action) => {
    const status =
      labStatus === 'READY' ? 'REBUILD_REQUESTED' : 'BUILD_REQUESTED';
    if (action === 'BUILD_LAB') {
      setBuildInitiated(true);
      onSubmit('DRAFT', status);
    } else {
      onSubmit('DRAFT');
    }
  };

  if (listVMFilesLoading) {
    return <FdProgress />;
  }

  const triggerAfterSubmit = (fields) => {
    trigger(fields);
  };
  const heading = isEditMode ? `Edit ${getValues().labName}` : 'Create a Lab';
  const loading = createLabPrototypeLoading || updateLabPrototypeLoading;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Manage Labs',
            path: '/labs',
            type: 'LAB',
          },
        ]}
        page={{ name: heading, type: 'LAB' }}
      />
      <BasePage heading={heading} data-cy="create-lab-base-page">
        <Box width="650px" my={2}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {isEditMode && showEditAlert && (
          <Box my={2}>
            <FdAlert
              variant="info"
              alertTitle="Lab edits require a rebuild"
              message="Changes to this lab will not take effect until the lab is rebuilt. To apply your edits, continue through the steps and select Build Lab at the end. Once rebuilt, the latest version of the lab will be reflected everywhere it is used on the platform, including in existing lessons and challenges."
              onClose={() => setShowEditAlert(false)}
            />
          </Box>
        )}
        {isEditMode && getLabPrototypeLoading ? (
          <Box display="flex" justifyContent="center">
            <FdProgress />
          </Box>
        ) : (
          <FormProvider {...reactHookFormMethods}>
            <form>
              {
                {
                  0: <LabDetails />,
                  1: <LabNetworks />,
                  2: (
                    <LabVirtualMachines
                      VMFiles={VMFiles}
                      triggerAfterSubmit={triggerAfterSubmit}
                      isEditMode={isEditMode}
                      labStatus={labStatus}
                    />
                  ),
                }[activeStep]
              }
              <Box className="flex justify-between my-4">
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    history.push('/labs');
                  }}
                  style={{
                    color: theme.fdProColors.alert.errorDark,
                    borderColor: theme.fdProColors.alert.errorDark,
                  }}
                >
                  Cancel
                </FdButton>
                <Box className="flex items-center gap-x-4">
                  {activeStep !== 0 && (
                    <FdButton
                      size="large"
                      variant="tertiary"
                      onClick={handleBack}
                      className={classes.buttonBack}
                    >
                      Back
                    </FdButton>
                  )}
                  {!isEditMode && (
                    <FdButton
                      size="large"
                      variant="secondary"
                      disabled={loading}
                      startIcon={loading && <CircularProgress size={20} />}
                      onClick={async () => {
                        const res = await trigger([
                          'labName',
                          'labDescription',
                        ]);
                        if (res) {
                          onSubmit('DRAFT');
                        }
                      }}
                    >
                      SAVE AS DRAFT
                    </FdButton>
                  )}
                  <FdButton
                    size="large"
                    onClick={handleNext}
                    className={classes.buttonPrimary}
                  >
                    {activeStep === 2 ? 'Finalise' : 'Next'}
                  </FdButton>
                </Box>
              </Box>
              <FinalizeModal
                showModal={showFinalizeModal}
                setShowModal={setShowFinalizeModal}
                onFinalizeAction={onFinalizeAction}
                buildLabel={labStatus === 'READY' ? 'Rebuild lab' : 'Build Lab'}
                isEditMode={isEditMode}
              />
            </form>
          </FormProvider>
        )}
        <NavigationPrompt
          when={isDirty}
          afterConfirm={() => {
            warningToastMessage('Lab creation cancelled');
          }}
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              size="md"
              title="Cancel Lab Creation"
              description={
                <>
                  Are you sure you want to cancel creating this lab?
                  <br />
                  <br />
                  Any unsaved changes will be lost. To keep your work, save this
                  lab as a draft, or finish creating the lab.
                </>
              }
              confirm="Proceed"
              dismiss="Cancel"
              open
              onConfirm={onConfirm}
              showConfirmInRed
              onDismiss={onCancel}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};
CreateLab.propTypes = {
  formMode: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export default CreateLab;
