import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { CheckIcon, DotIcon, MonitorIcon, XIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { FdModal, FdCard, FdTypography, FdChip } from '@fifthdomain/fe-shared';
import InfoAccordion from './InfoAccordion';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';

const LabelValue = ({ label, value }) => (
  <Box my={1}>
    <FdTypography variant="body2" className="font-bold">
      {label}
    </FdTypography>
    <FdTypography variant="body2">{value}</FdTypography>
  </Box>
);

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const FinalizeModal = ({
  showModal,
  setShowModal,
  onFinalizeAction,
  buildLabel,
  isEditMode,
}) => {
  const { getValues } = useFormContext();
  const { labName, labDescription, labNetworks, labVms } = getValues();

  return (
    <FdModal
      title="Great! This lab is almost ready."
      confirm={buildLabel}
      dismiss="Save as Draft"
      open={showModal}
      onConfirm={() => {
        setShowModal(false);
        onFinalizeAction('BUILD_LAB');
      }}
      onDismiss={() => {
        setShowModal(false);
        onFinalizeAction('DRAFT');
      }}
      setOpen={setShowModal}
      showDismiss={!isEditMode}
      showClose
      showCloseIcon={XIcon}
      showCloseAction={() => setShowModal(false)}
      showConfirmInGreen
      showConfirmAndDismiss
    >
      <Box>
        <FdTypography variant="body2" color="secondary">
          Build this lab now, or save it as a draft to build later. Once a lab
          is successfully built, it can be added into challenges and lessons.
        </FdTypography>
        <FdCard variant="outlined">
          <Box className="flex items-center gap-x-2 mb-4">
            <MonitorIcon />
            <FdTypography variant="subtitle1">{labName}</FdTypography>
          </Box>
          <InfoAccordion
            title="Lab Description"
            message={labDescription || 'None added'}
          />
        </FdCard>
        <Box className="flex flex-col gap-y-2">
          <FdCard variant="outlined">
            <Box className="mb-4">
              <FdTypography variant="subtitle1">Networks</FdTypography>
            </Box>
            <InfoAccordion
              title={`Networks (${labNetworks?.length})`}
              message={
                <Box>
                  {labNetworks?.length > 0 ? (
                    labNetworks?.map((network, index) => (
                      <>
                        <Box
                          className="flex items-center my-1"
                          key={network?.name}
                        >
                          <FdTypography
                            variant="subtitle2"
                            className="font-semibold"
                          >
                            {index + 1}
                          </FdTypography>
                          <DotIcon />
                          <FdTypography variant="body2">
                            {network?.name}
                          </FdTypography>
                        </Box>
                        <FdChip
                          size="small"
                          label={
                            <Box className="flex items-center gap-x-2">
                              {network?.hasInternet ? (
                                <CheckIcon size={16} />
                              ) : (
                                <XIcon size={16} />
                              )}
                              Enable Internet for VM
                            </Box>
                          }
                          rounded={false}
                          style={{
                            backgroundColor: 'rgba(235, 242, 255, 1)',
                            margin: '4px 0',
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <FdTypography variant="body2">None added</FdTypography>
                  )}
                </Box>
              }
            />
          </FdCard>
          <FdCard variant="outlined">
            <Box className="mb-4">
              <FdTypography variant="subtitle1">{`Virtual Machines (${labVms?.length})`}</FdTypography>
            </Box>
            {labVms?.map((labVm, index) => (
              <InfoAccordion
                title={
                  <Box className="flex items-center my-1" key={labVm?.name}>
                    <FdTypography variant="subtitle2" className="font-semibold">
                      {index + 1}
                    </FdTypography>
                    <DotIcon />
                    <FdTypography variant="subtitle2">
                      {labVm?.name}
                    </FdTypography>
                  </Box>
                }
                message={
                  <Box>
                    <LabelValue label="VM Image" value={labVm?.imageId} />
                    <LabelValue
                      label="VM Size"
                      value={upperCaseFirstLetter(labVm?.instanceType)}
                    />
                    <LabelValue
                      label="VM Access Type"
                      value={labVm?.accessibilityOption}
                    />
                    <LabelValue
                      label={`Assigned Networks (${labVm?.networks?.items?.length})`}
                      value={
                        labVm?.networks?.items?.length > 0
                          ? labVm?.networks?.items
                              ?.map((network) => network?.name)
                              .join(', ')
                          : 'None added'
                      }
                    />
                  </Box>
                }
              />
            ))}
          </FdCard>
        </Box>
      </Box>
    </FdModal>
  );
};

FinalizeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onFinalizeAction: PropTypes.func.isRequired,
  buildLabel: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default FinalizeModal;
