import { uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import { amplifyConfig } from '@fifthdomain/fe-shared';

export const uploadCourseChatImageAttachmentToS3 = async (
  files,
  userId,
  courseUserId,
) => {
  try {
    if (!files || files.length === 0) {
      return [];
    }
    const imagesForUpload =
      files?.map((image) => ({
        file: image,
        key: `course-messages/${userId}/${courseUserId}-${shortid.generate()}-${image?.name}`,
      })) || [];
    await Promise.all(
      imagesForUpload.map(
        ({ key, file }) =>
          uploadData({
            path: `public/${key}`,
            data: file,
          }).result,
      ),
    );
    const s3Url = `https://${amplifyConfig?.aws_user_files_s3_bucket}.s3.${amplifyConfig?.aws_user_files_s3_bucket_region}.amazonaws.com/public`;
    const imageUrls = imagesForUpload.map(({ key, file }) => ({
      name: file?.name,
      url: `${s3Url}/${key}`,
    }));

    return imageUrls;
  } catch (error) {
    console.error('Error uploading AI chat attachment files:', error);
    return [];
  }
};

const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

export const fileSizeValidator = (file) => {
  if (file.size > maxSizeInBytes) {
    return {
      code: 'too-large',
    };
  }
  return null;
};
