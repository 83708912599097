import LabsTable from './LabsTable';
import LabDetails from './LabDetails';
import LabNetworks from './LabNetworks';
import LabVirtualMachines from './LabVirtualMachines';
import LabSummary from './LabSummary';
import LabNetworkVms from './LabNetworkVms';

export {
  LabsTable,
  LabDetails,
  LabNetworks,
  LabVirtualMachines,
  LabSummary,
  LabNetworkVms,
};
