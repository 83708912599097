import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { MonitorIcon } from 'lucide-react';
import { FdTypography } from '@fifthdomain/fe-shared';
import { RESOURCE_TYPES } from '../../../constants';

const ResourceTypeIcon = ({ type, noLabel }) => {
  const resourceType = RESOURCE_TYPES.find(
    (r) => r.id === type || r.name === type,
  ) || { id: 'LAB', name: 'Lab', Icon: MonitorIcon };
  const Icon = resourceType?.Icon || null;

  if (!type) {
    return null;
  }

  return (
    <Box
      className="flex items-center px-2 py-1 gap-x-2 rounded-full"
      sx={{ backgroundColor: 'rgba(51, 51, 255, 1)' }}
    >
      <Icon className="text-white size-5" />
      {!noLabel && (
        <FdTypography variant="body2" className="text-white">
          {resourceType?.name}
        </FdTypography>
      )}
    </Box>
  );
};

ResourceTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
};

ResourceTypeIcon.defaultProps = {
  noLabel: false,
};

export default ResourceTypeIcon;
