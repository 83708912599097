import React from 'react';
import PropTypes from 'prop-types';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ThumbnailIcon,
} from '@react-pdf-viewer/default-layout';
import { FdProgress } from '@fifthdomain/fe-shared';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RenderThumbnailItem from './RenderThumbnailItem';
import findLinksPlugin from './FindLinksPlugin';

const useStyles = makeStyles()({
  container: {
    width: '100%',
  },
  content: {
    height: '50rem',
  },
});

function PdfViewer({ fileUrl, theme }) {
  const { classes } = useStyles();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) =>
      [
        {
          content: <Thumbnails renderThumbnailItem={RenderThumbnailItem} />,
          icon: <ThumbnailIcon />,
          title: 'Thumbnails',
        },
      ].concat(defaultTabs.slice(1)),
  });

  const findLinksPluginInstance = findLinksPlugin();
  const { thumbnailPluginInstance } = defaultLayoutPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance, findLinksPluginInstance]}
            theme={theme}
            renderLoader={() => <FdProgress />}
          />
        </Worker>
      </Box>
    </Box>
  );
}

PdfViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

PdfViewer.defaultProps = {
  theme: 'dark',
};

export default PdfViewer;
