import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, useTheme } from '@mui/material';
import { PresentationIcon, InfoIcon } from 'lucide-react';
import {
  FdCard,
  FdAlert,
  FdTypography,
  FdAccordion,
} from '@fifthdomain/fe-shared';
import LessonTable from './LessonTable';

const LessonMaterial = ({
  allLessons,
  editMode,
  actionButtons,
  selectionValue,
}) => {
  const theme = useTheme();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Box mt={1}>
      <FdAccordion
        style={{
          backgroundColor: theme.fdProColors.alert.infoLight,
          borderColor: theme.fdProColors.alert.infoLight,
        }}
        endAdornment
        showToggleButton={false}
        summary={() => (
          <>
            <InfoIcon
              className="mr-2"
              color={theme.fdProColors.alert.infoDark}
            />
            <FdTypography ontWeight="bold" variant="subtitle2">
              What are Lessons?
            </FdTypography>
          </>
        )}
        content={() => (
          <Box ml={4}>
            <FdTypography variant="body1">
              Lessons are reusable learning content pieces that can be used in
              courses. Each lesson features a core exercise for participants to
              complete and may also include optional resources and quizzes to
              support learning. Lessons are mapped to the FifthDomain Cynaptic
              Skills Adaptor for accurate learning tracking.
            </FdTypography>
          </Box>
        )}
      />
      <Box>
        <FdCard className="my-4" variant="outlined">
          <Box className="flex justify-between">
            <Box className="flex">
              <PresentationIcon className="size-6 mr-2 mt-1" />
              <FdTypography variant="h3">Lessons</FdTypography>
            </Box>
            {editMode && actionButtons}
          </Box>
          <Box mt={1.5} mb={3}>
            {editMode ? (
              <FdTypography variant="body2" color="secondary">
                All lessons that have been added to this course are displayed
                below. Click <b> Edit</b> to add or remove lessons to this
                course.
              </FdTypography>
            ) : (
              <FdTypography variant="body2" color="secondary">
                All published lessons in your organisation are displayed below.
                Click <b>View</b> for details about each lesson, and use the
                checkboxes to add lessons to this course.
              </FdTypography>
            )}
          </Box>
          {errors?.lessons?.message && (
            <Box mb={1.5}>
              <FdAlert
                style={{ marginTop: '4px' }}
                variant="error"
                message={
                  <FdTypography variant="body2">
                    Please add at least <b> one </b> lesson to proceed.
                  </FdTypography>
                }
              />
            </Box>
          )}
          <FdAlert
            style={{ marginTop: '4px' }}
            variant="info"
            message="All labs (in lessons with lab exercises) have a duration of 2 hours. Participants can extend their lab instances as many times as they require, resetting their lab timers to 2 hours and retaining progress made. Participants can also reset their labs as needed, which will deploy a new lab instance and erase all progress.
        "
          />
          <Controller
            control={control}
            name="lessonIds"
            render={({ field: { value } }) => (
              <LessonTable
                selectionValue={editMode ? selectionValue : true}
                lessonData={allLessons}
                selectionModel={value}
                onSelectionModelChange={(_values) => {
                  const data = allLessons
                    ?.filter((item) => _values?.includes(item?.id))
                    ?.map((lesson) => ({ ...lesson, lessonId: lesson?.id }));
                  setValue('lessonIds', _values);
                  setValue('lessons', data);
                }}
              />
            )}
          />
        </FdCard>
      </Box>
    </Box>
  );
};

LessonMaterial.propTypes = {
  allLessons: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionButtons: PropTypes.node,
  editMode: PropTypes.bool,
  selectionValue: PropTypes.bool,
};

LessonMaterial.defaultProps = {
  actionButtons: undefined,
  editMode: false,
  selectionValue: false,
};

export default LessonMaterial;
