import { uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { fetchAuthSession } from '@aws-amplify/auth';
import { amplifyConfig } from '@fifthdomain/fe-shared';

const getContentType = (fileType) => {
  switch (fileType) {
    case '.md':
      return 'text/markdown';
    case '.tar.gz':
      return 'application/gzip';
    case '.pdf':
      return 'application/pdf';
    case 'application/x-yaml':
      return 'application/yaml';
    default:
      return 'application/gzip';
  }
};

const getContentTypeEc2 = (fileType) => {
  switch (fileType) {
    case '.ova':
      return 'application/x-virtualbox-ova';
    default:
      return 'application/x-virtualbox-ova';
  }
};

const getFileNameExtension = (file) => {
  const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
  const fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
  return { fileName, fileExtension };
};

export const fileUploadAWS = async (
  { file, fileType },
  setUploadPercentage,
) => {
  const { fileName, fileExtension } = getFileNameExtension(file);
  const uniqueFileName = `${fileName}-${shortid.generate()}.${fileExtension}`;

  await uploadData({
    path: `public/${uniqueFileName}`,
    data: file,
    options: {
      contentType: getContentType(fileType),
      onProgress: ({ transferredBytes, totalBytes }) => {
        const percentage =
          Math.round((transferredBytes / totalBytes) * 100) / 100;
        setUploadPercentage?.(percentage);
      },
    },
  }).result;

  return {
    name: uniqueFileName,
    type: file.type,
    size: file.size,
    url: URL.createObjectURL(file),
    bucket: amplifyConfig?.aws_user_files_s3_bucket,
    region: amplifyConfig?.aws_user_files_s3_bucket_region,
  };
};

export const bytesToMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

const getCredentials = async () => {
  const session = await fetchAuthSession();
  return session.credentials;
};

export const fileUploadAWSEc2 = async (
  { file, platform, fileType },
  setUploadPercentage,
) => {
  const env = amplifyConfig?.aws_ec2_env ?? 'test';
  const { fileName, fileExtension } = getFileNameExtension(file);
  const uniqueFileName = `${fileName}${shortid.generate()}.${fileExtension}`;

  const s3Client = new S3Client({
    region: amplifyConfig?.aws_vm_upload_s3_bucket_region,
    credentials: getCredentials,
    requestChecksumCalculation: 'WHEN_REQUIRED',
  });

  try {
    let totalBytes = file.size;

    const upload = new Upload({
      client: s3Client,
      params: {
        Bucket: amplifyConfig?.aws_vm_upload_s3_bucket,
        Key: `${env}/${platform}/${uniqueFileName}`,
        Body: file,
        ContentType: getContentTypeEc2(fileType),
      },
      partSize: 10 * 1024 * 1024, // 10MB chunks
      leavePartsOnError: false,
    });

    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total } = progress;
      if (total) totalBytes = total;
      const percentage = Math.min((loaded / totalBytes) * 100, 100);
      const transferStatus = `${bytesToMB(loaded)} MB / ${bytesToMB(totalBytes)} MB`;

      setUploadPercentage?.({
        percentage: percentage.toFixed(2),
        transferStatus,
      });
    });

    await upload.done();

    return {
      name: uniqueFileName,
      type: file.type,
      size: file.size,
      url: URL.createObjectURL(file),
      bucket: amplifyConfig?.aws_vm_upload_s3_bucket,
      region: amplifyConfig?.aws_vm_upload_s3_bucket_region,
    };
  } catch (err) {
    console.error('Error uploading VM:', err);
    throw err;
  }
};
