import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const Gauge = ({ data, labels, chartId }) => {
  const options = {
    series: data,
    options: {
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 10,
            size: '60%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ['#2495fd', '#556bab'],
      labels: labels.length ? labels : data.map((d) => `${d}%`),
      legend: {
        show: true,
        fontSize: '16px',
        position: 'bottom',
        horizontalAlign: 'center',
        floating: true,
        labels: {
          useSeriesColors: true,
        },
        offsetX: 0,
        offsetY: -80,
      },
      chart: {
        id: chartId,
        redrawOnParentResize: true,
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        style: {
          fontSize: '14px',
        },
        custom: ({ seriesIndex, w }) =>
          `<div class="arrow_box" style="padding:10px;font-weight:bold">
            <span>
              <span style="height:12px;width:12px;background-color:${w.globals.colors[seriesIndex]};border-radius:50%;margin-right:5px;display:inline-block">
              </span>
              ${w.globals.labels[seriesIndex]}
            </span>
          </div>`,
      },
    },
  };

  return (
    <Box data-cy={`guage-chart-${chartId}`}>
      <Chart
        options={options.options}
        series={options.series}
        type="radialBar"
        width="500"
      />
    </Box>
  );
};

Gauge.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
  chartId: PropTypes.string,
};

Gauge.defaultProps = {
  data: [],
  labels: [],
  chartId: 'gauge-chart',
};

export default Gauge;
