import React, { useMemo, useState } from 'react';
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FdChip,
  FdTab,
  FdDelayed,
  useSnapshot,
  globalStore,
  Authorization,
  useRecentLinks,
  FdTypography,
  FdButton,
  successToastMessage,
  FdPageHeading,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import EditCourse from '../components/Courses/Edit/EditCourse';
import EditCourseUsersGroups from '../components/Courses/Edit/EditCourseUsersGroups';
import {
  getCourse,
  listExerciseProgressesByCourseId,
  listQuizAttemptsByCourseId,
} from '../graphql/queries';
import { getCourseUsersGroups } from '../queries/customQueries';
import ViewInsight from '../components/Courses/Insights/ViewInsight';
import { ChatQueue } from '../components/Chat';
import setAppMarginRightByIds from '../shared/utils/layout';
import { ChatContext } from '../contexts';
import ChatSaveModal from '../components/Chat/ChatSaveModal';
import { getCourseStatus } from '../shared/utils/courseStatus';
import { getCoursesStatusColor } from '../shared/utils/getStatusColor';
import EditLessons from '../components/Courses/Edit/EditLessons';
import EditResources from '../components/Courses/Edit/EditResources';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../validation-schemas/Course';
import { updateCourse } from '../graphql/mutations';

import { COURSE_STATUS } from '../constants';
import { getS3File } from '../shared/utils/fileUtils';

function ViewCourseAdmin() {
  const { courseId } = useParams();
  const { search, pathname } = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const tabindex = new URLSearchParams(search).get('tabindex') || 1; // defaults to details tab
  const [custIndex, setCustIndex] = useState(tabindex);
  const [isChatDirty, setIsChatDirty] = useState(false);
  const [showChatSave, setShowChatSave] = useState(false);
  const [triggerTabRefresh, setTriggerTabRefresh] = useState(false);
  const [targetTab, setTargetTab] = useState(undefined);
  const { permissions, userId } = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId });

  const validationSchema = Yup.object().shape({
    ...courseValidationSchema,
    status: Yup.string().nullable(),
    usersGroupsCount: Yup.number().nullable(),
  });

  const reactHookFormMethods = useForm({
    defaultValues: courseInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset } = reactHookFormMethods;

  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );
  const chatContextValue = useMemo(
    () => ({
      isChatDirty,
      setIsChatDirty,
    }),
    [isChatDirty, setIsChatDirty],
  );

  const getResources = async (courseResources) => {
    const resources = await Promise.all(
      courseResources?.items?.map(async (r) => ({
        dbId: r?.id,
        id: r?.id,
        resourceName: r?.name,
        resourceDescription: r?.description,
        resourceType: r?.type,
        resourcePdf: await getS3File(r),
        resourceMarkdown: r?.markdown,
        resourceVideo: r?.url,
        resourceOrder: r?.orderNumber,
      })) || [],
    );
    return resources?.sort(
      (a, b) => (a?.resourceOrder ?? 0) - (b?.resourceOrder ?? 0),
    );
  };

  const {
    data: courseData,
    loading: courseLoading,
    refetch: refetchCourse,
  } = useQuery(gql(getCourse), {
    variables: {
      id: courseId,
    },
    fetchPolicy: 'network-only',
    skip: !courseId,
    onCompleted: async (_data) => {
      const {
        availability,
        name,
        description,
        status,
        courseUsers,
        coursegroups,
        courseLessons,
        courseResources,
      } = _data.getCourse;
      reset({
        courseId,
        name,
        description,
        status,
        availability,
        courseResources: await getResources(courseResources),
        resourcesDeleted: [],
        lessons:
          courseLessons?.items
            ?.map(({ lesson, ...rest }) => ({
              ...lesson,
              lessonId: lesson?.id,
              order: rest?.orderNumber,
              lessonDbId: rest?.id,
            }))
            ?.sort((a, b) => (a?.order || 0) - (b?.order || 0)) || [],
        lessonIds: courseLessons?.items?.map((item) => item?.lessonID) || [],
        usersGroupsCount:
          (courseUsers?.items.length || 0) +
            (coursegroups?.items.length || 0) || 0,
      });
      // add recent link
      addRecentLink({
        id: courseId,
        name,
        type: 'COURSE',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });

  const { data: lessonsProgressData, loading: lessonsProgressLoading } =
    useQueryRecursive(gql(listExerciseProgressesByCourseId), {
      variables: {
        courseId,
      },
      skip: !courseId,
    });

  const { data: quizAttemptsData, loading: quizAttemptsLoading } =
    useQueryRecursive(gql(listQuizAttemptsByCourseId), {
      variables: {
        courseId,
      },
      skip: !courseId,
    });
  const lessonsProgress =
    lessonsProgressData?.listExerciseProgressesByCourseId?.items || [];

  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  if (courseLoading || lessonsProgressLoading || quizAttemptsLoading)
    return null;

  const { name, status } = courseData?.getCourse || {};
  const hasChatViewPermission = Authorization.canViewInsights(permissions);

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'COURSE' }} />
      <Box className="flex justify-between items-center mb-6">
        <Box className="flex items-center gap-x-4">
          <FdPageHeading type="COURSE">{name}</FdPageHeading>
          <FdChip
            color={getCoursesStatusColor(upperCaseFirstLetter(status))}
            size="small"
            label={getCourseStatus[status]}
          />
        </Box>

        {/* show publish button only when status is draft and users > 0 */}
        {status === 'DRAFT' && (
          <FdButton
            disabled={updateCourseLoading}
            onClick={async () => {
              await updateCourseMutation({
                variables: {
                  input: {
                    id: courseId,
                    status: COURSE_STATUS.AVAILABLE,
                  },
                },
              });
              refetchCourse();
              successToastMessage('Success! Course published.');
            }}
            style={{
              backgroundColor: theme?.fdProColors?.alert?.successDark,
            }}
          >
            Publish
          </FdButton>
        )}
      </Box>

      <Box mt="-1rem">
        <ChatContext.Provider value={chatContextValue}>
          <FormProvider {...reactHookFormMethods}>
            <form>
              <FdDelayed triggerField={triggerTabRefresh}>
                <FdTab
                  variant="fullWidth"
                  disableTabChange={isChatDirty}
                  label={[
                    // TODO hiding it temporarily - https://fifthdomain.atlassian.net/browse/SD-4101
                    // {
                    //   label: 'Insights',
                    //   tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=0`,
                    //   index: 0,
                    //   data: (
                    //     <ViewInsight
                    //       courseUsersGroupsData={courseUsersGroupsData}
                    //       courseData={courseData}
                    //     />
                    //   ),
                    // },
                    {
                      label: 'Details',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=0`,
                      index: 0,
                      data: <EditCourse refetchCourse={refetchCourse} />,
                    },
                    {
                      label: 'Lessons',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=1`,
                      index: 1,
                      data: (
                        <EditLessons
                          courseData={courseData}
                          refetchCourse={refetchCourse}
                        />
                      ),
                    },
                    {
                      label: (
                        <Box className="flex items-center gap-x-2">
                          Resources
                          <Box
                            className="flex items-center h-5 px-2 rounded"
                            sx={{ bgcolor: 'rgba(235, 242, 255, 1)' }}
                          >
                            <FdTypography
                              variant="h6"
                              className="font-normal text-xs"
                            >
                              Optional
                            </FdTypography>
                          </Box>
                        </Box>
                      ),
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=2`,
                      index: 2,
                      data: <EditResources refetchCourse={refetchCourse} />,
                    },
                    {
                      label: 'Participants',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=3`,
                      index: 3,
                      data: (
                        <EditCourseUsersGroups
                          lessonsProgress={lessonsProgress}
                          quizAttemptsData={quizAttemptsData}
                          courseId={courseId}
                          setRouteIndex={setRouteIndex}
                        />
                      ),
                    },
                    ...(hasChatViewPermission
                      ? [
                          {
                            label: 'Chat Inbox',
                            tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=4`,
                            index: 4,
                            data: (
                              <ChatQueue
                                courseId={courseId}
                                lessonsProgress={lessonsProgress}
                                quizAttemptsData={quizAttemptsData}
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                  index={parseInt(tabindex, 10)}
                  custIndex={parseInt(custIndex, 10)}
                  setCustIndex={setRouteIndex}
                  tabLinkComponent={RouterLink}
                  onTabChange={() =>
                    setAppMarginRightByIds(['topnav', 'labs-fe'], 0)
                  }
                  onBeforeTabChange={(_tab) => {
                    // user trying to switch tabs with unsaved chat
                    if (isChatDirty && _tab !== custIndex) {
                      setTargetTab(_tab); // save target tab for further nav
                      setShowChatSave(true);
                    }
                  }}
                />
              </FdDelayed>
            </form>
          </FormProvider>
          <ChatSaveModal
            onConfirm={() => {
              setIsChatDirty(false);
              setShowChatSave(false);
              setRouteIndex(targetTab);
              setTriggerTabRefresh(!triggerTabRefresh); // refresh tabs to switch to target tab
            }}
            onCancel={() => {
              setShowChatSave(false);
            }}
            open={showChatSave}
          />
        </ChatContext.Provider>
      </Box>
    </Box>
  );
}

export default ViewCourseAdmin;
