import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useWatch, useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdCard, FdAlert } from '@fifthdomain/fe-shared';
import CourseModule from './CourseModule';
import FdBoxButton from '../../FdBoxButton';
import SummaryTitles from './SummaryTitles';

const CourseMaterial = ({ allQuizzes, allLabs, actionButtons, isEdit }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const watchCourse = useWatch({
    control,
    name: 'name',
  });
  const {
    fields: modules,
    append: appendModule,
    remove: deleteModule,
  } = useFieldArray({
    control,
    name: 'modules',
  });
  const watchSummaryModule = useWatch({
    control,
    name: 'partsSummary',
  });
  const { append: appendPartSummary, remove: deletePartSummary } =
    useFieldArray({
      control,
      name: 'partsSummary',
    });

  const addModuleRef = useRef();

  const modulesError =
    errors?.modules?.message ||
    (errors?.modules?.length > 0 && 'There are errors in modules/parts');

  return (
    <FdCard
      variant="outlined"
      heading={watchCourse}
      data-cy="material"
      summary={
        <Box display="flex" alignItems="center">
          <SummaryTitles
            parts={watchSummaryModule?.map((m) => m.parts).flat() || []}
            quizzes={allQuizzes}
            duration={
              modules
                ?.map((m) => m.moduleDuration)
                .reduce((acc, v) => acc + Number(v), 0) || 0
            }
          />
          {actionButtons}
        </Box>
      }
    >
      {modulesError && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message={modulesError} />
        </Box>
      )}
      {modules
        .sort((a, b) => a.order - b.order)
        .map((m, moduleIdx) => (
          <CourseModule
            key={m.id}
            moduleIdx={moduleIdx}
            module={{
              moduleId: m.moduleId,
              moduleName: m.moduleName,
              moduleDuration: m.moduleDuration,
              accordionState: m.accordionState,
            }}
            deleteModule={deleteModule}
            deletePartSummary={deletePartSummary}
            allQuizzes={allQuizzes}
            allLabs={allLabs}
            isEdit={isEdit}
            addModuleRef={addModuleRef}
          />
        ))}
      {isEdit && (
        <FdBoxButton
          onClick={(e) => {
            e.preventDefault();
            // set all accordions to collapsed
            modules.forEach((m, mIdx) =>
              setValue(`modules[${mIdx}].accordionState`, false),
            );
            appendModule({ order: modules.length + 1, accordionState: true });
            appendPartSummary({});
            addModuleRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          ADD MODULE
        </FdBoxButton>
      )}
      <div ref={addModuleRef} data-cy="scroll-to-view" />
    </FdCard>
  );
};

CourseMaterial.propTypes = {
  allQuizzes: PropTypes.arrayOf(PropTypes.object).isRequired,
  allLabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionButtons: PropTypes.node,
  isEdit: PropTypes.bool,
};

CourseMaterial.defaultProps = {
  actionButtons: undefined,
  isEdit: false,
};

export default CourseMaterial;
