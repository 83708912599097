/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import { FdDelayed, FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import MessageInput from './Chat/MessageInput';
import ChatLoader from './Chat/ChatLoader';
import BlueFillerAniImage from '../LessonMaterial/BlueFillerIAniImage';
import MessageStream from './Chat/MessageStream';
import ChatIndicator from './Chat/ChatIndicator';

const CourseChat = ({
  messages,
  inputPlaceholder,
  inputCaptionText,
  noMessageText,
  onAddMessage,
  onDeleteMessage,
  onEditMessage,
  loading,
  setChatEditInProgress,
  showNewMessagesLink,
  onShowNewMessagesLink,
}) => {
  const [allActions, setAllActions] = useState([]); // record all actions by message id

  useEffect(() => {
    const isActiveAction = allActions.some((a) => a?.active);
    setChatEditInProgress(isActiveAction);
  }, [allActions, setChatEditInProgress]);

  const onAction = (id) => {
    const actionId =
      typeof id === 'object' && id !== null && 'id' in id ? id.id : id;
    // record all actions true - active action, false - inactive
    setAllActions((prevActions) => {
      const existing = prevActions.find((a) => a && a.id === actionId);
      if (existing) {
        return prevActions.map((item) =>
          item && item.id === actionId
            ? { ...item, active: !item.active }
            : item,
        );
      }
      return [...prevActions, { id: actionId, active: true }];
    });
  };

  return (
    <Box className="flex flex-col items-center gap-x-2 h-full justify-between w-full px-6">
      {loading ? (
        <Box className="flex items-center justify-center w-full">
          <ChatLoader />
        </Box>
      ) : (
        <>
          {messages?.length > 0 ? (
            <MessageStream
              messages={messages}
              onDeleteMessage={onDeleteMessage}
              onEditMessage={onEditMessage}
              onAction={onAction}
            />
          ) : (
            <Box className="flex flex-col items-center justify-center w-full h-full">
              <Box height="200px" width="200px">
                <BlueFillerAniImage />
              </Box>
              <FdDelayed delay={1000} height="50px">
                <Box className="text-center mt-6">
                  <FdTypography variant="body2" color="secondary">
                    No messages yet.
                    <br />
                    <br />
                    {noMessageText}
                  </FdTypography>
                </Box>
              </FdDelayed>
            </Box>
          )}
          {showNewMessagesLink && (
            <Box className="flex items-center justify-center relative my-2">
              <ChatIndicator />
              <FdTooltip title="Click to show new messages">
                <span>
                  <Link
                    component="button"
                    variant="body2"
                    underline="hover"
                    onClick={onShowNewMessagesLink}
                    className="leading-7"
                  >
                    Show new messages
                  </Link>
                </span>
              </FdTooltip>
            </Box>
          )}
          <Box className="w-full">
            <MessageInput
              onSubmitMessage={onAddMessage}
              placeholder={inputPlaceholder}
              captionText={inputCaptionText}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

CourseChat.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  inputCaptionText: PropTypes.string.isRequired,
  noMessageText: PropTypes.string.isRequired,
  onAddMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  setChatEditInProgress: PropTypes.func,
  showNewMessagesLink: PropTypes.bool,
  onShowNewMessagesLink: PropTypes.bool,
};

CourseChat.defaultProps = {
  showNewMessagesLink: undefined,
  setChatEditInProgress: () => {},
  onShowNewMessagesLink: undefined,
};

export default CourseChat;
