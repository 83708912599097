import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  AccordionSummary,
  Accordion,
  useTheme,
  AccordionDetails,
  alpha,
} from '@mui/material';
import { ChevronDownIcon } from 'lucide-react';

const ContentAccordion = ({ summary, details, noBorder, ...props }) => {
  const theme = useTheme();

  return (
    <Box my={2} className="flex items-center gap-x-2 w-full my-0">
      <Accordion
        className={`${noBorder ? '' : 'rounded-lg'} w-full overflow-hidden`}
        variant="outlined"
        sx={{
          borderColor: noBorder ? 'transparent' : theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
        square
        disableGutters
        {...props}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          sx={{
            backgroundColor: alpha(theme.palette.background.paper, 0.5),
          }}
        >
          <Box className="flex items-center justify-between flex-grow">
            {summary}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

ContentAccordion.propTypes = {
  summary: PropTypes.node.isRequired,
  details: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
};

ContentAccordion.defaultProps = {
  noBorder: false,
};

export default ContentAccordion;
