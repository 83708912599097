import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  FdButton,
  amplifyConfig,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  updateCourseResource,
  deleteCourseResource,
  createCourseResource,
} from '../../../graphql/mutations';
import CourseResources from '../CourseResources/CourseResources';
import { uploadFileToS3 } from '../../../shared/utils/fileUtils';

const EditResources = ({ refetchCourse }) => {
  const { getValues, reset, trigger } = useFormContext();
  const [selectionValue, setSelectionValue] = useState(false);

  const [
    createCourseResourceMutation,
    { loading: createCourseResourceLoading },
  ] = useMutation(gql(createCourseResource));

  const [
    updateCourseResourceMutation,
    { loading: updateCourseResourceLoading },
  ] = useMutation(gql(updateCourseResource));
  const [
    deleteCourseResourceMutation,
    { loading: deleteCourseResourceLoading },
  ] = useMutation(gql(deleteCourseResource));

  const updateCourseResourcesAction = async () => {
    const { courseResources, resourcesDeleted, courseId } = getValues();
    await Promise.all(
      resourcesDeleted?.map((id) =>
        deleteCourseResourceMutation({ variables: { input: { id } } }),
      ),
    );
    await Promise.all(
      courseResources?.map((r, idx) => {
        const resourceMutation = r?.dbId
          ? updateCourseResourceMutation
          : createCourseResourceMutation;
        return resourceMutation({
          variables: {
            input: {
              ...(r?.dbId ? { id: r?.dbId } : { courseID: courseId }),
              name: r?.resourceName,
              description: r?.resourceDescription,
              type: r?.resourceType,
              ...(r?.resourceType === 'PDF' && {
                file: {
                  bucket: amplifyConfig.aws_user_files_s3_bucket,
                  key: r?.resourcePdf?.name,
                  region: amplifyConfig.aws_user_files_s3_bucket_region,
                },
              }),
              ...(r?.resourceType === 'MARKDOWN' && {
                markdown: r?.resourceMarkdown,
              }),
              ...(r?.resourceType === 'VIDEO' && {
                url: r?.resourceVideo,
              }),
              orderNumber: idx,
            },
          },
        });
      }),
    );
    // upload pdf file to s3
    await Promise.all(
      courseResources
        ?.filter(
          (r) => r?.resourceType === 'PDF' && r?.resourcePdf instanceof File,
        )
        ?.map((r) => {
          return uploadFileToS3({
            key: r?.resourcePdf?.name,
            file: r?.resourcePdf,
          });
        }),
    );
    refetchCourse();
    setTimeout(() => {
      setSelectionValue(false);
      successToastMessage('Success! Course edits saved.');
    }, 500);
  };

  const ActionButtons = () => (
    <Box display="flex">
      {!selectionValue ? (
        <FdButton onClick={() => setSelectionValue(true)} data-cy="edit-button">
          Edit
        </FdButton>
      ) : (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setSelectionValue(false);
              warningToastMessage('Course edits not saved.');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                const result = await trigger(['courseResources']);
                if (result) {
                  updateCourseResourcesAction();
                }
              }}
              disabled={
                createCourseResourceLoading ||
                updateCourseResourceLoading ||
                deleteCourseResourceLoading
              }
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <CourseResources
      actionButtons={<ActionButtons />}
      isEdit={selectionValue}
      editMode
    />
  );
};

EditResources.propTypes = {
  refetchCourse: PropTypes.func.isRequired,
};

export default EditResources;
