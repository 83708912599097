import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as singleSpa from 'single-spa';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  BasePage,
  FdButton,
  FdProgress,
  FdAlert,
  FdModal,
  amplifyConfig,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import scrollToTop from '../shared/utils/scroll';
import CourseDetails from '../components/Courses/Create/CourseDetails';
import LessonMaterial from '../components/Courses/LessonMaterial/LessonMaterial';
import CourseUsers from '../components/Courses/Create/CourseUsers';
import CourseGroups from '../components/Courses/Create/CourseGroups';
import { listUsersByOrgId } from '../queries/customQueries';
import {
  createCourse,
  createCourseLesson,
  createCourseUser,
  deleteCourseUser,
  assignCourseToGroups,
  removeCourseFromGroups,
} from '../graphql/mutations';
import {
  listGroupsByOrgId,
  listCourseCategories,
  listLessonsByOrgId,
} from '../graphql/queries';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../validation-schemas/Course';
import {
  COURSE_CATEGORIES_IMAGE,
  PROFICIENCY_LEVELS,
  COURSE_STATUS,
} from '../constants';
import FinaliseModal from '../components/Courses/LessonMaterial/FinaliseModal';

const useStyles = makeStyles()(() => ({
  stepper: {
    background: 'none',
    paddingLeft: '5px',
    height: '60px',
  },
}));

const CreateCourse = () => {
  const history = useHistory();
  const { orgId, userId } = useSnapshot(globalStore);
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Add Details', 'Add Lessons', 'Add Users and Groups'];
  const [finalStep, setFinalStep] = useState(false);

  const { data: usersData, loading: usersLoading } = useQueryRecursive(
    gql(listUsersByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );
  const { data: groupsData, loading: groupsLoading } = useQueryRecursive(
    gql(listGroupsByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );
  // List Course Categories
  const { data: courseCategories, loading: courseCategoriesLoading } =
    useQueryRecursive(gql(listCourseCategories));

  const { data: lessonData, loading: lessonLoading } = useQueryRecursive(
    gql(listLessonsByOrgId),
    {
      variables: {
        orgId,
        filter: { status: { eq: 'PUBLISHED' } },
      },
      skip: !orgId,
    },
  );

  const allLessons =
    lessonData?.listLessonsByOrgId?.items?.map((lesson) => ({
      ...lesson,
      proficiency: PROFICIENCY_LEVELS[lesson?.difficulty],
      specialty: lesson?.specialty?.name,
      resources: lesson.lessonResources?.items || [],
      skills: lesson?.skills?.items?.map((skill) => skill?.skill) || [],
      quiz: lesson?.lessonQuizzes?.items[0]?.questions?.items || [],
      exercise: lesson?.lessonExercises?.items[0] || {},
      techniques:
        lesson?.skills?.items
          ?.map((skill) =>
            skill?.techniqueTags?.items.map((techniqueTag) => ({
              label: techniqueTag.techniqueTag.name,
            })),
          )
          .flat() || [],
      technologies:
        lesson?.technologyTags?.items?.map((tag) => ({
          label: tag?.technologyTag?.name,
        })) || [],
    })) || [];

  const [createCourseMutation, { loading: createCourseLoading }] = useMutation(
    gql(createCourse),
  );
  const [createCourseLessonMutation, { loading: createCourseLessonLoading }] =
    useMutation(gql(createCourseLesson));

  const [createCourseUserMutation, { loading: createCourseUserLoading }] =
    useMutation(gql(createCourseUser));

  const [deleteCourseUserMutation, { loading: deleteCourseUserLoading }] =
    useMutation(gql(deleteCourseUser));

  const [
    assignCourseToGroupsMutation,
    { loading: assignCourseToGroupsLoading },
  ] = useMutation(gql(assignCourseToGroups));

  const [
    removeCourseFromGroupsMutation,
    { loading: removeCourseFromGroupsLoading },
  ] = useMutation(gql(removeCourseFromGroups));

  const validationSchema = Yup.object().shape(
    {
      ...courseValidationSchema,
      userIds: Yup.array().when(['groupIds'], {
        is: (groupIds) => groupIds?.length === 0,
        then: Yup.array().of(Yup.string()).min(1),
        otherwise: Yup.array().of(Yup.string()),
      }),
      groupIds: Yup.array().when(['userIds'], {
        is: (userIds) => userIds?.length === 0,
        then: Yup.array().of(Yup.string()).min(1),
        otherwise: Yup.array().of(Yup.string()),
      }),
      dbUserIds: Yup.array().of(Yup.string()),
      dbGroupIds: Yup.array().of(Yup.string()),
    },
    [['userIds', 'groupIds']],
  );

  const reactHookFormMethods = useForm({
    defaultValues: courseInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors, isDirty },
  } = reactHookFormMethods;

  if (usersLoading || groupsLoading) return <FdProgress />;

  const users = usersData?.listUsersByOrgId?.items || [];
  const groups =
    groupsData?.listGroupsByOrgId?.items.map((g) => ({
      id: g.id,
      groupName: g.name,
      description: g.description,
      usersCount: g.users?.items.length || 0,
    })) || [];

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['name', 'description']);
        break;
      }
      case 1:
        result = await trigger(['lessons']);
        if (result) {
          successToastMessage('Success! Lessons added successfully');
        }
        break;
      case 2:
        result = await trigger(['userIds', 'groupIds']);
        break;
      default:
        break;
    }
    return result;
  };

  const createUsersGroups = async (courseId) => {
    // create users & groups
    const { userIds, groupIds, dbUserIds, dbGroupIds } = getValues();
    // delete users if any
    if (dbUserIds.length > 0) {
      await Promise.all(
        dbUserIds?.map(async (u) => {
          await deleteCourseUserMutation({
            variables: {
              input: {
                id: u,
              },
            },
          });
        }),
      );
      setValue('dbUserIds', []);
    }
    // delete groups if any
    if (dbGroupIds.length > 0) {
      await removeCourseFromGroupsMutation({
        variables: {
          courseId,
          groupIds: dbGroupIds,
        },
      });
      setValue('dbGroupIds', []);
    }
    // add users
    if (userIds.length > 0) {
      await Promise.all(
        userIds?.map(async (u) => {
          await createCourseUserMutation({
            variables: {
              input: {
                courseId,
                orgId,
                status: 'NOT_STARTED',
                userId: u,
              },
            },
          });
        }),
      );
    }
    // add groups
    if (groupIds.length > 0) {
      await assignCourseToGroupsMutation({
        variables: {
          courseId,
          groupIds,
        },
      });
    }
  };

  const onSubmit = async (status) => {
    const { name, description, category, availability, lessons } = getValues();
    const allCategories = courseCategories?.listCourseCategories?.items?.map(
      (c) => c.name,
    );
    // random category value initially
    const categoryValue =
      category ||
      allCategories[Math.floor(Math.random() * allCategories.length)];
    // create  course
    const categoryInput = categoryValue
      ? {
          categoryId: courseCategories?.listCourseCategories?.items?.find(
            (courseCategory) => courseCategory.name === categoryValue,
          ).id,
          image: {
            key: COURSE_CATEGORIES_IMAGE[categoryValue],
            bucket: amplifyConfig.aws_user_files_s3_bucket,
            region: amplifyConfig.aws_user_files_s3_bucket_region,
          },
        }
      : {};
    await createCourseMutation({
      variables: {
        input: {
          ...categoryInput,
          description,
          name,
          orgId,
          ownerId: userId,
          status,
          availability,
        },
      },
      onCompleted: async (_data) => {
        const courseId = _data?.createCourse?.id;
        await Promise.all(
          lessons?.map(async (item) => {
            await createCourseLessonMutation({
              variables: {
                input: {
                  courseID: courseId,
                  lessonID: item?.id,
                },
              },
            });
          }),
        );
        await createUsersGroups(courseId);
        reset();
        const msg =
          status === COURSE_STATUS.AVAILABLE
            ? 'Success! Course published'
            : 'Success! Course saved as draft.';
        successToastMessage(msg);
        setTimeout(() => {
          singleSpa.navigateToUrl('/landing');
        }, 500);
      },
    });
  };

  const saveDraft = async () => {
    if (await validatePage()) {
      onSubmit(COURSE_STATUS.DRAFT);
    }
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 2) {
        setFinalStep(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const loadStatus =
    createCourseLoading ||
    createCourseUserLoading ||
    removeCourseFromGroupsLoading ||
    deleteCourseUserLoading ||
    assignCourseToGroupsLoading ||
    courseCategoriesLoading;

  const loading = createCourseLoading || createCourseLessonLoading;

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'Create Course', type: 'COURSE' }} />
      <BasePage heading="Create a Course" data-cy="create-course-base-page">
        <Box width="734px">
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            data-cy="stepper"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel data-cy="stepper-label">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form>
            {
              {
                0: <CourseDetails heading="Add Course Details" isEdit />,
                1: <LessonMaterial allLessons={allLessons} />,
                2: (
                  <Box data-cy="add-users-courses">
                    {(errors?.groupIds || errors?.userIds) && (
                      <Box my={2}>
                        <FdAlert
                          variant="error"
                          message="Please select a user or group"
                        />
                      </Box>
                    )}
                    <CourseUsers rows={users} />
                    <CourseGroups rows={groups} />
                  </Box>
                ),
              }[activeStep]
            }
            <Box display="flex" justifyContent="space-between" width="100%">
              <FdButton
                size="large"
                variant="secondary"
                onClick={() => {
                  history.push('/landing');
                }}
                data-cy="cancel-button"
              >
                Cancel
              </FdButton>
              <Box>
                {activeStep !== 0 && (
                  <FdButton
                    size="large"
                    variant="secondary"
                    onClick={handleBack}
                    style={{ marginRight: '1rem' }}
                  >
                    Back
                  </FdButton>
                )}
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={saveDraft}
                  style={{ marginRight: '1rem' }}
                  disabled={loading}
                >
                  Save Draft
                </FdButton>
                <FdButton
                  size="large"
                  onClick={handleNext}
                  disabled={loadStatus}
                >
                  {loadStatus ? 'Loading...' : 'Next'}
                </FdButton>
              </Box>
            </Box>
            <FinaliseModal
              finalStep={finalStep}
              loading={loading}
              showCloseAction={() => setFinalStep(false)}
              onConfirm={() => {
                const status = COURSE_STATUS.AVAILABLE;
                onSubmit(status);
              }}
              onDismiss={() => {
                const status = COURSE_STATUS.DRAFT;
                onSubmit(status);
              }}
            />
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterConfirm={() => {
            warningToastMessage('Course creation cancelled');
          }}
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Cancel Course Creation"
              description={
                <>
                  Are you sure you want to cancel course creation?
                  <br />
                  <br />
                  Any unsaved changes will be lost. To keep your work, save this
                  course as a draft, or finish creating the course.
                </>
              }
              confirm="Proceed"
              dismiss="Back"
              open
              onConfirm={onConfirm}
              showConfirmInRed
              onDismiss={onCancel}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};

export default CreateCourse;
