import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdCard, FdTypography, FdAccordion } from '@fifthdomain/fe-shared';

const ViewVirtualMachines = ({ heading, subHeading, values }) => {
  return (
    <FdCard heading={heading} subHeading={subHeading} variant="outlined">
      <Box display="flex" flexDirection="column">
        {values?.labVms?.map((labVm) => (
          <Box mt={2} key={labVm.id}>
            <FdAccordion
              open
              startAdornment
              showToggleButton={false}
              summary={() => (
                <Box display="flex" flexDirection="row">
                  <FdTypography variant="h3">Virtual Machine</FdTypography>
                </Box>
              )}
              content={() => (
                <>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">VM Name</FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.name}
                    </FdTypography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">VM Image</FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.imageId}
                    </FdTypography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">VM Size</FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.instanceType}
                    </FdTypography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">
                      VM Access Type
                    </FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.accessibilityOption}
                    </FdTypography>
                  </Box>
                  <FdCard
                    optional
                    heading="Assign Networks to VMs"
                    variant="outlined"
                  >
                    <Box display="flex" flexDirection="column">
                      {labVm?.networks?.items?.map((labNetworkVm) => (
                        <Box mt={2} key={labNetworkVm.id}>
                          <FdAccordion
                            open
                            startAdornment
                            showToggleButton={false}
                            summary={() => (
                              <Box display="flex" flexDirection="row">
                                <FdTypography variant="h3">
                                  Network
                                </FdTypography>
                              </Box>
                            )}
                            content={() => (
                              <Box mt={2} mb={2}>
                                <FdTypography variant="subtitle1">
                                  Network
                                </FdTypography>
                                <FdTypography variant="body1" color="secondary">
                                  {labNetworkVm?.name}
                                </FdTypography>
                              </Box>
                            )}
                          />
                        </Box>
                      ))}
                    </Box>
                  </FdCard>
                </>
              )}
            />
          </Box>
        ))}
      </Box>
    </FdCard>
  );
};

ViewVirtualMachines.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subHeading: PropTypes.string,
  values: PropTypes.shape({
    labVms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        template: PropTypes.string,
        hasInternet: PropTypes.bool,
        hasVdi: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

ViewVirtualMachines.defaultProps = {
  heading: '',
  subHeading: '',
};

export default ViewVirtualMachines;
