import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdButton,
  BasePage,
  FdProgress,
  FdModal,
  FdTypography,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import scrollToTop from '../shared/utils/scroll';
import {
  LabDetails,
  LabNetworks,
  LabVirtualMachines,
  LabSummary,
} from '../components/Labs';
import {
  labInitialValues,
  labValidationSchema,
} from '../validation-schemas/Labs';
import { getLabPrototype, listVMFiles } from '../graphql/queries';
import {
  createLabPrototype,
  updateLabPrototype,
  createLabPrototypeNetwork,
  updateLabPrototypeNetwork,
  deleteLabPrototypeNetwork,
  deleteLabPrototypeVM,
  deleteLabPrototypeVMNetwork,
  createLabPrototypeVM,
  createLabPrototypeVMNetwork,
  updateLabPrototypeVM,
  updateLabPrototypeVMNetwork,
} from '../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
  errorToastMessage,
} from '../shared/utils/toast';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
  buttonBack: {
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  buttonPrimary: {
    '&:focus': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

const steps = [
  'Add Details',
  'Add Networks',
  'Add Virtual Machines',
  'Summary',
];

const CreateLab = ({ formMode }) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { classes } = useStyles();
  const isEditMode = formMode === 'edit';
  const [labStatus, setLabStatus] = useState('');
  const { labId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const {
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues,
    trigger,
    handleSubmit,
  } = useForm({
    defaultValues: labInitialValues,
    resolver: yupResolver(labValidationSchema),
    mode: 'all',
  });

  // List VMs
  const { data: ListVMFilesData, loading: listVMFilesLoading } =
    useQueryRecursive(gql(listVMFiles), {
      variables: {
        filter: {
          orgId: { eq: globalSnap.orgId },
          and: [{ status: { eq: 'READY' } }],
        },
      },
    });

  const VMFiles = ListVMFilesData?.listVMFiles?.items ?? [];
  const VMFilesDetails = (prototype) => {
    return VMFiles?.filter((VMFile) => VMFile?.name === prototype)[0];
  };

  // Get Lab Prototype
  const { loading: getLabPrototypeLoading } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isEditMode,
    onCompleted: (data) => {
      setLabStatus(data?.getLabPrototype?.status);
      // reset form only when not dirty,
      // this is to trigger detail level mutations when update action happens
      if (!isDirty) {
        reset({
          labName: data.getLabPrototype?.name,
          labDescription: data.getLabPrototype?.description,
          labNetworks: data.getLabPrototype?.networks?.items?.map(
            (item) => item,
          ),
          labVms: data.getLabPrototype?.vms?.items?.map((item) => item),
        });
      }
    },
  });

  // Add Networks, VMs, VMNetwork to the Lab Prototype
  const [createLabNetworkMutation] = useMutation(
    gql(createLabPrototypeNetwork),
  );
  const [updateLabNetworkMutation] = useMutation(
    gql(updateLabPrototypeNetwork),
  );

  const [createLabPrototypeVMMutation] = useMutation(gql(createLabPrototypeVM));
  const [createLabPrototypeVMNetworkMutation] = useMutation(
    gql(createLabPrototypeVMNetwork),
  );
  const [updateLabPrototypeVMMutation] = useMutation(gql(updateLabPrototypeVM));
  const [updateLabPrototypeVMNetworkMutation] = useMutation(
    gql(updateLabPrototypeVMNetwork),
  );
  const [deleteLabPrototypeNetworkMutation] = useMutation(
    gql(deleteLabPrototypeNetwork),
  );
  const [deleteLabPrototypeVMMutation] = useMutation(gql(deleteLabPrototypeVM));
  const [deleteLabPrototypeVMNetworkMutation] = useMutation(
    gql(deleteLabPrototypeVMNetwork),
  );

  const deleteMultipleLabNetworks = async (labDeleteNetworks) => {
    await Promise.all(
      labDeleteNetworks.map((object) => {
        return deleteLabPrototypeNetworkMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
        });
      }),
    );
  };
  const deleteMultipleLabVMNetworks = async (labDeleteVMNetworks) => {
    await Promise.all(
      labDeleteVMNetworks.map((object) => {
        return deleteLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
        });
      }),
    );
  };
  const deleteMultipleLabVMs = async (labDeleteVMs) => {
    await Promise.all(
      labDeleteVMs.map((object) => {
        return deleteLabPrototypeVMMutation({
          variables: {
            input: {
              id: object.id,
            },
          },
          onCompleted: async () => {
            await deleteMultipleLabVMNetworks(object?.networks?.items);
          },
        });
      }),
    );
  };
  const createMultipleLabNetwork = async (labPrototypeId, labNetworks) => {
    await Promise.all(
      labNetworks.map((object) => {
        return createLabNetworkMutation({
          variables: {
            input: {
              labPrototypeId,
              name: object.name,
              hasInternet: object.hasInternet ?? false,
            },
          },
        });
      }),
    );
  };
  const updateMultipleLabNetwork = async (labPrototypeId, labNetworks) => {
    await Promise.all(
      labNetworks.map((object) => {
        return updateLabNetworkMutation({
          variables: {
            input: {
              id: object.id,
              labPrototypeId,
              name: object.name,
              hasInternet: object.hasInternet ?? false,
            },
          },
        });
      }),
    );
  };
  const createLabPrototypeVMNetworks = async (object, data) => {
    await Promise.all(
      object?.map((obj) => {
        return createLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              labPrototypeVMId:
                data?.createLabPrototypeVM?.id ||
                data?.updateLabPrototypeVM?.id,
              name: obj.name,
            },
          },
        });
      }),
    );
  };
  const createMultipleLabVms = async (labPrototypeId, labVms) => {
    await Promise.all(
      labVms?.map((object) => {
        return createLabPrototypeVMMutation({
          variables: {
            input: {
              labPrototypeId,
              name: object.name,
              imageId: VMFilesDetails(object.imageId)?.imageId,
              vpn: object.accessibilityOption === 'VPN',
              vdi: object.accessibilityOption === 'VDI',
              vdiUser: object.vdiUser ?? 'fifthdomain',
              instanceType: object.instanceType,
            },
          },
          onCompleted: (data) => {
            const createLabVMNetworks = object?.networks?.items;
            createLabPrototypeVMNetworks(createLabVMNetworks, data);
          },
        });
      }),
    );
  };

  const updateLabPrototypeVMNetworks = async (object, data) => {
    await Promise.all(
      object?.map((obj) => {
        return updateLabPrototypeVMNetworkMutation({
          variables: {
            input: {
              id: obj.id,
              labPrototypeVMId:
                data?.createLabPrototypeVM?.id ||
                data?.updateLabPrototypeVM?.id,
              name: obj.name,
            },
          },
        });
      }),
    );
  };

  const updateMultipleLabVms = async (labPrototypeId, labVms) => {
    await Promise.all(
      labVms?.map((object) => {
        return updateLabPrototypeVMMutation({
          variables: {
            input: {
              id: object.id,
              labPrototypeId,
              name: object.name,
              imageId: VMFilesDetails(object.imageId)?.imageId,
              vpn: object.accessibilityOption === 'VPN',
              vdi: object.accessibilityOption === 'VDI',
              vdiUser: object.vdiUser ?? 'fifthdomain',
              instanceType: object.instanceType,
            },
          },
          onCompleted: (data) => {
            const createVmNetworkValue = object?.networks?.items?.filter(
              ({ labPrototypeVMId }) =>
                !data?.updateLabPrototypeVM?.id.includes(labPrototypeVMId),
            );
            const updateVmNetworkValue = object?.networks?.items?.filter(
              ({ labPrototypeVMId }) =>
                data?.updateLabPrototypeVM?.id.includes(labPrototypeVMId),
            );
            if (createVmNetworkValue?.length > 0) {
              createLabPrototypeVMNetworks(createVmNetworkValue, data);
            }
            if (updateVmNetworkValue?.length > 0) {
              updateLabPrototypeVMNetworks(updateVmNetworkValue, data);
            }
          },
        });
      }),
    );
  };

  //  update the Lab_Status
  const [updateLabPrototypeStatus] = useMutation(gql(updateLabPrototype));
  const updateLabStatus = (id, status) => {
    updateLabPrototypeStatus({
      variables: {
        input: {
          id,
          status,
        },
      },
    });
  };

  // Add Competency, Network and VMs Details to Lab
  const addDetailsToLab = (data) => {
    const labPrototypeId =
      data?.createLabPrototype?.id || data?.updateLabPrototype?.id;
    const networkIdd =
      data?.updateLabPrototype?.networks?.items?.map((item) => item.id) || [];
    const labVmsIdd =
      data?.updateLabPrototype?.vms?.items?.map((item) => item.id) || [];
    const {
      labNetworks,
      labVms,
      labDeleteNetworks,
      labDeleteVMs,
      labDeleteVMNetworks,
    } = getValues();
    if (labDeleteNetworks !== undefined) {
      deleteMultipleLabNetworks(labDeleteNetworks);
    }
    if (labDeleteVMs !== undefined) {
      deleteMultipleLabVMs(labDeleteVMs);
    }
    if (labDeleteVMNetworks !== undefined) {
      deleteMultipleLabVMNetworks(labDeleteVMNetworks);
    }

    if (labNetworks?.length > 0) {
      if (networkIdd?.length === 0) {
        createMultipleLabNetwork(labPrototypeId, labNetworks);
      } else {
        const createNetworkValue = labNetworks?.filter(
          ({ id }) => !networkIdd.includes(id),
        );
        const updateNetworkValue = labNetworks?.filter(({ id }) =>
          networkIdd.includes(id),
        );
        if (createNetworkValue?.length > 0) {
          createMultipleLabNetwork(labPrototypeId, createNetworkValue);
        }
        if (updateNetworkValue?.length > 0) {
          updateMultipleLabNetwork(labPrototypeId, updateNetworkValue);
        }
      }
    }
    if (labVms?.length > 0) {
      if (labVmsIdd?.length === 0) {
        createMultipleLabVms(labPrototypeId, labVms);
      } else {
        const createVmsValue = labVms?.filter(
          ({ id }) => !labVmsIdd.includes(id),
        );
        const updateVmsValue = labVms?.filter(({ id }) =>
          labVmsIdd.includes(id),
        );
        if (createVmsValue?.length > 0) {
          createMultipleLabVms(labPrototypeId, createVmsValue);
        }
        if (updateVmsValue?.length > 0) {
          updateMultipleLabVms(labPrototypeId, updateVmsValue);
        }
      }
    }

    const successMessage = ['DRAFT', 'READY'].includes(
      data?.createLabPrototype?.status || data?.updateLabPrototype?.status,
    )
      ? 'Success! Lab Saved'
      : 'Success! Lab Created';
    successToastMessage(successMessage);
    reset();
    setTimeout(() => {
      singleSpa.navigateToUrl('/labs');
    }, 500);
  };

  // create Lab Prototype
  const [createLabPrototypeMutation] = useMutation(gql(createLabPrototype), {
    onCompleted: (data) => {
      addDetailsToLab(data);
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });
  // update Lab Prototype
  const [updateLabPrototypeMutation] = useMutation(gql(updateLabPrototype), {
    onCompleted: (data) => {
      addDetailsToLab(data);
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  const onSubmit = (status, nextStatus) => {
    const values = getValues();
    const { labName, labDescription } = values;
    const labValue = isEditMode
      ? updateLabPrototypeMutation
      : createLabPrototypeMutation;

    labValue({
      variables: {
        input: {
          id: isEditMode ? labId : undefined,
          name: labName,
          description: labDescription,
          status: status || undefined,
          userId: globalSnap.userId,
          orgId: globalSnap.orgId,
        },
      },
    }).then((res) => {
      if (nextStatus !== undefined) {
        const id =
          res?.data?.createLabPrototype?.id ||
          res?.data?.updateLabPrototype?.id;
        // update lab status
        updateLabStatus(id, nextStatus);
      }
    });
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['labName']);
        break;
      }
      case 1: {
        result = await trigger(['labNetworks']);
        break;
      }
      case 2: {
        result = await trigger(['labVms']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep < 3) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/labs');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  if (listVMFilesLoading) {
    return <FdProgress />;
  }

  const triggerAfterSubmit = (fields) => {
    trigger(fields);
  };

  return (
    <BasePage
      heading={isEditMode ? `Edit ${getValues().labName}` : 'Create a Lab'}
      data-cy="create-lab-base-page"
      breadCrumbs={[{ url: '/labs', name: 'Home' }]}
      currentPageBreadcrumbLabel=" Manage Labs / Create a Lab"
      linkComponent={RouterLink}
    >
      <Box width="650px" my={2}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {isEditMode && getLabPrototypeLoading ? (
        <Box display="flex" justifyContent="center">
          <FdProgress />
        </Box>
      ) : (
        <FormProvider>
          <form>
            {
              {
                0: (
                  <Box>
                    <LabDetails
                      Controller={Controller}
                      control={control}
                      heading="Lab Details"
                    />
                  </Box>
                ),
                1: (
                  <Box>
                    <LabNetworks
                      Controller={Controller}
                      control={control}
                      heading="Networks"
                      getValueLabNetwork={getValues().labNetworks}
                      getValueNetwork={getValues().labDeleteNetworks}
                      setValueNetwork={setValue}
                    />
                  </Box>
                ),
                2: (
                  <Box>
                    <LabVirtualMachines
                      Controller={Controller}
                      control={control}
                      heading="Virtual Machines"
                      labNetworkValues={getValues()?.labNetworks}
                      getValueLabVM={getValues().labVms}
                      getValueVM={getValues().labDeleteVMs}
                      getValueDeleteVMNetwork={getValues().labDeleteVMNetworks}
                      setValueVM={setValue}
                      VMFiles={VMFiles}
                      preHeader={errors?.labVms}
                      triggerAfterSubmit={triggerAfterSubmit}
                      isEditMode={isEditMode}
                      labStatus={labStatus}
                    />
                  </Box>
                ),
                3: (
                  <Box>
                    <LabSummary
                      values={getValues()}
                      Controller={Controller}
                      control={control}
                    />
                  </Box>
                ),
              }[activeStep]
            }
            <Box display="flex" justifyContent="space-between" pb={3}>
              <Box>
                {activeStep !== 0 && (
                  <FdButton
                    size="large"
                    variant="secondary"
                    onClick={handleBack}
                    className={classes.buttonBack}
                  >
                    Back
                  </FdButton>
                )}
              </Box>
              <Box>
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    if (!isDirty) {
                      warningToastMessage('Changes not saved');
                    }
                    singleSpa.navigateToUrl('/labs');
                  }}
                >
                  Cancel
                </FdButton>
                {labStatus === 'READY' ? (
                  activeStep === 0 && (
                    <FdButton
                      size="large"
                      variant="secondary"
                      style={{ marginLeft: '1rem' }}
                      onClick={async () => {
                        const res = await trigger(['labName']);
                        if (res) {
                          onSubmit();
                        }
                      }}
                    >
                      Save
                    </FdButton>
                  )
                ) : (
                  <FdButton
                    size="large"
                    variant="secondary"
                    style={{ marginLeft: '1rem' }}
                    onClick={async () => {
                      const res = await trigger(['labName']);
                      if (res) {
                        onSubmit('DRAFT');
                      }
                    }}
                  >
                    SAVE AS DRAFT
                  </FdButton>
                )}
                {activeStep < 3 ? (
                  <FdButton
                    size="large"
                    onClick={handleNext}
                    style={{ marginLeft: '1rem' }}
                    className={classes.buttonPrimary}
                  >
                    Next
                  </FdButton>
                ) : (
                  <FdButton
                    size="large"
                    className={classes.buttonPrimary}
                    onClick={async () => {
                      const status =
                        labStatus === 'READY'
                          ? 'REBUILD_REQUESTED'
                          : 'BUILD_REQUESTED';
                      handleSubmit(onSubmit('DRAFT', status));
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    {labStatus === 'READY' ? 'Rebuild lab' : 'Build Lab'}
                  </FdButton>
                )}
              </Box>
            </Box>
          </form>
        </FormProvider>
      )}
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (window.location.pathname !== '/labs/create') {
            history.goBack();
          }
        }}
        afterConfirm={() => {
          warningToastMessage('Changes not saved');
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            size="md"
            title={
              <Box display="flex" alignItems="center">
                <WarningIcon
                  style={{
                    fontSize: 38,
                    color: '#C62828',
                    paddingRight: '0.5rem',
                  }}
                />
                <span>Cancel Lab Creation?</span>
              </Box>
            }
            description={
              <Box>
                <FdTypography variant="subtitle1">
                  Are you sure you want to cancel creating the lab?
                </FdTypography>
                <Box mt={2}>
                  <FdTypography variant="body1" color="secondary">
                    Any information will be lost.
                  </FdTypography>
                </Box>
              </Box>
            }
            dismiss="CANCEL"
            confirm="OK"
            open
            onConfirm={onConfirm}
            onDismiss={onCancel}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};
CreateLab.propTypes = {
  formMode: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export default CreateLab;
