import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  gql,
  useMutation,
  useQuery,
  useSubscription,
  useLazyQuery,
} from '@apollo/client';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import {
  FdCard,
  FdButton,
  FdModal,
  FdChip,
  FdTypography,
  FdAlert,
  FdProgress,
  AuthContext,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { minutesToMilliseconds } from 'date-fns/esm';
import shortid from 'shortid';
import {
  createModulePartProgress,
  startLab,
  deleteLab,
  restartLab,
  extendLabTime,
  updateCourseUser,
  updateModulePartProgress,
} from '../../graphql/mutations';
import {
  getLabTime,
  getVmConsole,
  listLabInstancesByModulePartId,
  listModulePartProgressesByCourseUserId,
} from '../../graphql/queries';
import { formatSingleDigit } from '../../shared/utils/dateUtils';
import LabVmsTable from './LabVmsTable';
import { LAB_INSTANCE_STATUS } from '../../constants';
import { getCourseUser } from '../../queries/customQueries';
import { updateCourseUserStatus } from '../../shared/utils/updateCourseUser';
import { onUpdateLabInstance } from '../../graphql/subscriptions';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';

function LabStart({ lab, userId, courseId }) {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [resetLab, setResetLab] = useState(false);
  const [openExtendModal, setxtExtendModal] = useState(false);
  const [labInstance, setLabInstance] = useState(undefined);
  const { courseUserId, partId } = useParams();
  const [refreshCount, setRefreshCount] = useState(0);

  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
    refetch: refetchLabInstances,
  } = useQueryRecursive(gql(listLabInstancesByModulePartId), {
    variables: {
      userId: { eq: user?.username },
      modulePartId: lab?.modulePartId,
      and: [
        { status: { ne: 'DELETED' } },
        { status: { ne: 'IN_DELETE_QUEUE' } },
        { status: { ne: 'DELETE_IN_PROGRESS' } },
        { status: { ne: 'DELETE_FAILED' } },
        { status: { ne: 'BUILD_FAILED' } },
      ],
    },
    skip: !user,
    onCompleted: (_data) => {
      const poweredOnLabInstance =
        _data?.listLabInstancesByModulePartId?.items?.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        )[0];
      setLabInstance(poweredOnLabInstance);
    },
    staleTime: { seconds: 0 },
  });

  const {
    data: labPrototypeTime,
    loading: labPrototypeLoading,
    refetch: refetchLabTime,
  } = useQuery(gql(getLabTime), {
    variables: {
      labInstanceId: labInstance?.id,
    },
    skip: !labInstance,
  });

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgress,
  } = useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
      filter: { modulePartId: { eq: partId } },
    },
    staleTime: { seconds: 0 },
    skip: !partId,
  });

  const [fetchConsole] = useLazyQuery(gql(getVmConsole), {
    skip: labInstance?.status !== 'Ready' || !labInstance,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { url } = data.getVmConsole;
      window.open(url, '_blank');
    },
  });

  const [startLabMutation, { loading: startLabLoading }] = useMutation(
    gql(startLab),
    {
      onCompleted: (_data) => {
        refetchLabInstances();
      },
    },
  );

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgress();
    },
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgress();
    },
  });

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: () => {
      refetchCourseUser();
    },
  });

  const [restartLabMutation] = useMutation(gql(restartLab), {
    onCompleted: () => {
      refetchLabInstances();
    },
  });

  const [extendLabTimeMutation] = useMutation(gql(extendLabTime), {
    onCompleted: () => {
      setTimeout(() => {
        refetchLabTime();
      }, 5000);
    },
  });

  const [deleteLabMutation] = useMutation(gql(deleteLab), {
    onCompleted: () => {
      refetchLabInstances();
    },
  });

  useSubscription(gql(onUpdateLabInstance), {
    onSubscriptionData: (_data) => {
      refetchLabInstances();
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (labInstance && labInstance.status !== 'READY') {
      startPolling(10000);
    }
    if (
      labInstance &&
      (labInstance.status === 'READY' || labInstance.status === 'DELETED')
    ) {
      stopPolling();
    }
  }, [labInstance, startPolling, stopPolling]);

  useEffect(() => {
    return () => {
      setLabInstance(undefined);
      refetchLabInstances();
    };
  }, [lab, refetchLabInstances]);

  useEffect(() => {
    if (
      labInstancesLoading === false &&
      labPrototypeLoading === false &&
      modulePartProgressLoading === false &&
      courseUserDataLoading === false
    ) {
      const timeRemainingLeft =
        labPrototypeTime?.getLabTime?.timeRemaining || 0;
      if (timeRemainingLeft > 0) {
        refetchLabTime();
        refetchLabInstances();
        setTimeout(setRefreshCount(refreshCount + 1), 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  if (
    labPrototypeLoading ||
    modulePartProgressLoading ||
    courseUserDataLoading ||
    (labInstancesLoading &&
      !['IN_BUILD_QUEUE', 'BUILD_IN_PROGRESS'].includes(labInstance?.status))
  )
    return <FdProgress />;

  const getVmsActions = (status, hasVdi) => {
    switch (status) {
      case LAB_INSTANCE_STATUS.READY:
        return [
          {
            label: 'connect',
            show: () => hasVdi,
            onClick: (_, rowData) => {
              fetchConsole({
                variables: {
                  vmId: rowData.id,
                  type: 'LAB',
                },
              });
            },
          },
        ];
      default:
        return [];
    }
  };

  const currentModulePartProgress =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items[0] || [];

  const handleUpdateModulePartProgress = (status) => {
    const now = new Date().toISOString();
    const params =
      status === 'STARTED' ? { startedOn: now } : { finishedOn: now };
    updateModulePartProgressMutation({
      variables: {
        input: {
          id: currentModulePartProgress?.id,
          status,
          userId,
          courseId,
          ...params,
        },
      },
    });
  };

  const labVmsData = labInstance?.vms?.items?.map(
    ({ id, name, labInstanceId, hasVdi }) => ({
      id,
      hasVdi,
      labInstanceId,
      name,
      status: LAB_INSTANCE_STATUS[labInstance?.status] || labInstance?.status,
      actions: getVmsActions(LAB_INSTANCE_STATUS[labInstance?.status], hasVdi),
    }),
  );

  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;

        return (
          <Box display="flex">
            {rowData?.actions.map(({ label, show, onClick }) => (
              <Box key={shortid.generate()}>
                {show && (
                  <FdButton
                    variant="tertiary"
                    size="small"
                    onClick={(e) => onClick(e, rowData)}
                    key={label}
                  >
                    {label}
                  </FdButton>
                )}
              </Box>
            ))}
          </Box>
        );
      },
    },
  ];

  const timeRemaining = labPrototypeTime?.getLabTime?.timeRemaining || 0;
  const milliSecondsToFinish = minutesToMilliseconds(timeRemaining);

  const isLabCompleted = currentModulePartProgress?.status === 'FINISHED';
  const isLabReady = labInstance?.status === 'READY';
  const isLabInstanceLoading =
    labInstance?.status === 'IN_BUILD_QUEUE' ||
    labInstance?.status === 'BUILD_IN_PROGRESS';

  const showStartLab =
    (!labInstance && !startLabLoading && !isLabInstanceLoading) ||
    ((labInstance?.status === 'DELETED' ||
      labInstance?.status === 'DELETE_IN_PROGRESS' ||
      labInstance?.status === 'IN_DELETE_QUEUE') &&
      !startLabLoading);

  // Delete lab if time remaining is less than 0
  if (isLabReady && timeRemaining < 0) {
    deleteLabMutation({
      variables: {
        labInstanceId: labInstance?.id,
      },
    });
  }

  return (
    <Box>
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Start Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to start the lab?
            </FdTypography>
            <Box mt={1}>
              A two-hour countdown timer will begin once you start this lab. You
              may extend your remaining lab time as many times as you require.
              You may also reset your lab to deploy a new lab instance as many
              times as you require.
            </Box>
          </Box>
        }
        confirm="Start Lab"
        dismiss="Cancel"
        open={open}
        onConfirm={() => {
          startLabMutation({
            variables: {
              labPrototypeId: lab?.labId,
              modulePartId: partId,
            },
          });
          if (!currentModulePartProgress) {
            createModulePartProgressMutation({
              variables: {
                input: {
                  courseUserId,
                  modulePartId: partId,
                  status: 'STARTED',
                  startedOn: new Date().toISOString(),
                  userId,
                  courseId,
                },
              },
            });
          } else if (
            currentModulePartProgress &&
            currentModulePartProgress.status !== 'FINISHED'
          ) {
            handleUpdateModulePartProgress('STARTED');
            updateCourseUserStatus(
              modulePartProgress,
              updateCourseUserMutation,
              courseUserData,
              partId,
            );
          }
          setOpen(false);
        }}
        onDismiss={() => setOpen(false)}
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Extend this lab instance?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="body1">
              Extending this lab instance will reset your time remaining for
              this lab to 2 hours.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              You will be able to continue work on all VMs in this lab, with all
              progress made being unaffected.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              <b>Note:</b>
              there is no limit to the number of times you can extend a lab
              instance. However, once Lab Time Remaining reaches 0, your lab
              instance will be deleted (all progress will be lost) and you will
              need to start the lab again if you wish to continue working on the
              lab.
            </FdTypography>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={openExtendModal}
        onConfirm={() => {
          extendLabTimeMutation({
            variables: {
              labInstanceId: labInstance?.id,
            },
          });
          successToastMessage('Lab instance extended! Timer reset to 2 hours.');
          setxtExtendModal(false);
          refetchLabTime();
        }}
        onDismiss={() => {
          warningToastMessage('Lab instance not extended');
          setxtExtendModal(false);
        }}
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Reset Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">Reset this lab?</FdTypography>
            <Box mt={1}>
              Resetting this lab will deploy a new lab instance for your use. If
              you proceed, your current instance will be deleted, and you will
              lose all your work within the lab. Please note that after
              resetting this lab, you will need to start the lab again to begin
              working on it.
            </Box>
          </Box>
        }
        confirm="Reset lab"
        dismiss="Cancel"
        open={resetLab}
        onConfirm={() => {
          restartLabMutation({
            variables: {
              labInstanceId: labInstance?.id,
              hardReset: true,
            },
          });
          successToastMessage('Lab reset successfully!');
          setResetLab(false);
        }}
        onDismiss={() => {
          warningToastMessage('Lab not reset');
          setResetLab(false);
        }}
      />

      <FdCard
        variant="outlined"
        heading={lab?.name}
        summary={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  ml={2}
                  whiteSpace="nowrap"
                >
                  {isLabReady && (
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <AvTimerIcon />
                      </Box>
                      <Countdown
                        date={Date.now() + milliSecondsToFinish}
                        onComplete={() => {
                          // refresh refetchLabTime() until timeRemainingLeft is 0 or less than
                          setRefreshCount(1);
                        }}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) =>
                          !completed && (
                            <FdTypography variant="body1">
                              {`Lab Time Remaining: ${formatSingleDigit(
                                days * 24 + hours,
                              )}:${formatSingleDigit(
                                minutes,
                              )}:${formatSingleDigit(seconds)}`}
                            </FdTypography>
                          )
                        }
                      />
                    </Box>
                  )}
                </Box>
                <Box ml={2} display="flex">
                  {isLabReady && (
                    <FdButton
                      variant="secondary"
                      size="small"
                      style={{ marginRight: 8 }}
                      disabled={startLabLoading || isLabInstanceLoading}
                      onClick={() => {
                        setResetLab(true);
                      }}
                    >
                      Reset Lab
                    </FdButton>
                  )}
                  {isLabReady && (
                    <FdButton
                      variant="secondary"
                      size="small"
                      style={{ marginRight: 8 }}
                      disabled={startLabLoading || isLabInstanceLoading}
                      onClick={() => setxtExtendModal(true)}
                    >
                      EXTEND LAB
                    </FdButton>
                  )}
                  {(labInstance || isLabCompleted) &&
                    (isLabCompleted ? (
                      <FdChip color="success" size="small" label="completed" />
                    ) : (
                      <FdButton
                        size="small"
                        disabled={startLabLoading || isLabInstanceLoading}
                        onClick={() => {
                          if (!currentModulePartProgress) {
                            createModulePartProgressMutation({
                              variables: {
                                input: {
                                  courseUserId,
                                  modulePartId: partId,
                                  status: 'FINISHED',
                                  finishedOn: new Date().toISOString(),
                                  userId,
                                  courseId,
                                },
                              },
                            });
                            return;
                          }

                          handleUpdateModulePartProgress('FINISHED');
                          updateCourseUserStatus(
                            modulePartProgress,
                            updateCourseUserMutation,
                            courseUserData,
                            partId,
                          );

                          if (isLabReady) {
                            deleteLabMutation({
                              variables: {
                                labInstanceId: labInstance?.id,
                              },
                            });
                          }
                        }}
                      >
                        {updateModulePartProgressLoading ||
                        createModulePartProgressLoading
                          ? 'Loading...'
                          : 'Mark Complete'}
                      </FdButton>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        }
        subHeading={lab?.description}
      >
        <Box mt={3}>
          {!showStartLab &&
            (startLabLoading ||
              (isLabInstanceLoading && (
                <Box mt={2}>
                  <FdAlert
                    alertTitle="Your lab is being built. This may take several minutes."
                    variant="info"
                    message="Building lab..."
                  />
                  <FdProgress size="small" />
                </Box>
              )))}
          {showStartLab && !startLabLoading && !isLabInstanceLoading && (
            <FdButton size="medium" onClick={() => setOpen(true)}>
              Start Lab
            </FdButton>
          )}
        </Box>
        {!startLabLoading && !isLabInstanceLoading && (
          <Box mt={2}>
            <FdAlert
              alertTitle=""
              variant="info"
              customIcon={<InfoOutlinedIcon />}
              message="Your lab instance will be deleted once Lab Time Remaining reaches 0. You'll be able to start a new lab instance, but all progress will be lost. 
                    Regularly check Lab Time Remaining and extend your lab instance as required before the timer reaches 0."
            />
          </Box>
        )}
        {isLabReady && !startLabLoading && !isLabInstanceLoading && (
          <Box mt={2}>
            <FdAlert
              alertTitle=""
              variant="info"
              customIcon={<InfoOutlinedIcon />}
              message="Each lab has a default duration of 2 hours. Once you have started your lab, the 'Extend Lab' button allows you to reset 
                  the timer in your current lab instance back to 2 hours, retaining all progress made. The 'Reset Lab' button deploys a new lab instance, 
                  deleting all progress, and resetting your remaining lab time back to 2 hours. "
            />
          </Box>
        )}
      </FdCard>
      {isLabCompleted && (
        <Box mb={2}>
          <FdAlert
            alertTitle="This lab has been marked as complete!"
            variant="success"
          />
        </Box>
      )}
      {isLabReady && (
        <LabVmsTable rows={labVmsData} actions={actions} headerActions={[]} />
      )}
    </Box>
  );
}

LabStart.propTypes = {
  lab: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    expiry: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    labId: PropTypes.string,
    modulePartId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default LabStart;
