import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import {
  FdTypography,
  FdButton,
  useQueryRecursive,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import ContentAccordion from './ContentAccordion';
import QuizOptions from '../../Quiz/Attempt/QuizOptions';
import { attemptQuiz } from '../../../graphql/mutations';
import { listQuizAttemptsByCourseUserId } from '../../../graphql/queries';
import { sortedByDate } from '../../../shared/utils/dateUtils';
import { getAttemptClearedQuiz } from './utils';

const QuizDrawerContent = ({ lesson }) => {
  const [viewMode, setViewMode] = useState(false);
  const { setValue } = useFormContext();
  const { courseUserId } = useParams();
  const { quiz, lessonId, idx: lessonIdx } = lesson || {};

  const { data: quizAttemptsData, refetch: refetchQuizAttempts } =
    useQueryRecursive(gql(listQuizAttemptsByCourseUserId), {
      variables: {
        courseUserId,
        filter: {
          quizId: { eq: quiz?.dbId },
        },
      },
    });

  const quizAttemptsByUser = sortedByDate([
    ...(quizAttemptsData?.listQuizAttemptsByCourseUserId?.items || []),
  ]);

  const top3QuizAttempts = quizAttemptsByUser.reduce((acc, item) => {
    if (!acc.some((o) => o?.questionId === item?.questionId)) {
      acc.push(item);
    }
    return acc;
  }, []);

  // Attempt Quiz Mutation
  const [attemptQuizMutation, { loading: attemptQuizLoading }] = useMutation(
    gql(attemptQuiz),
  );

  const onSubmitQuiz = async () => {
    await attemptQuizMutation({
      variables: {
        courseUserId,
        lessonId,
        quizId: quiz?.dbId,
        questionAnswers: quiz?.questions?.map((q) => {
          switch (q.questionType) {
            case 'FREE_TEXT':
              return {
                questionId: q.dbId,
                answer: q.inputAnswer || '',
              };
            case 'SINGLE_CHOICE':
              return {
                questionId: q.dbId,
                answer:
                  q.answersSingleText.find((c) => c.inputAnswer)?.dbId || null,
              };
            case 'MULTIPLE_CHOICE':
              return {
                questionId: q.dbId,
                answers:
                  q.answersMultipleText
                    .filter((c) => c.inputAnswer)
                    .map((o) => o.dbId) || null,
              };
            default:
              return {};
          }
        }),
      },
    });
    refetchQuizAttempts();
    setViewMode(true);
    setValue(`lessons[${lesson?.idx}].quizAttemptInProgress`, false);
    successToastMessage('Success! Quiz submitted');
  };

  return quiz?.questions?.length > 0 ? (
    <Box className="flex flex-col gap-y-6">
      {quiz?.quizDescription && (
        <ContentAccordion
          summary={
            <FdTypography variant="subtitle2">Quiz Description</FdTypography>
          }
          details={
            <FdTypography variant="body2">{quiz?.quizDescription}</FdTypography>
          }
        />
      )}
      {quiz?.questions?.map((item, idx) => (
        <ContentAccordion
          defaultExpanded
          summary={
            <FdTypography variant="subtitle2">{`Question ${idx + 1}`}</FdTypography>
          }
          details={
            <Box mb={2}>
              <FdTypography color="secondary" variant="body2">
                {item?.question}
              </FdTypography>
              <QuizOptions
                question={item}
                idx={idx}
                viewMode={viewMode}
                top3QuizAttempts={quiz?.answersReturned ? top3QuizAttempts : []}
              />
            </Box>
          }
        />
      ))}
      {quiz?.questions?.length > 0 && (
        <Box>
          {viewMode ? (
            <FdButton
              size="large"
              variant="secondary"
              onClick={() => {
                setViewMode(false);
                const clearedQuiz = getAttemptClearedQuiz(quiz);
                // reset quiz submission
                setValue(`lessons[${lessonIdx}].quiz.questions`, clearedQuiz);
              }}
              style={{
                width: '100%',
              }}
            >
              re-attempt
            </FdButton>
          ) : (
            <FdButton
              size="large"
              variant="secondary"
              onClick={onSubmitQuiz}
              disabled={attemptQuizLoading}
              style={{
                width: '100%',
              }}
            >
              Submit
            </FdButton>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.primary.main}`,
      })}
      className="flex flex-col rounded-lg overflow-hidden"
    >
      <FdTypography variant="body2" color="secondary" className="italic p-2">
        No quiz found
      </FdTypography>
    </Box>
  );
};

QuizDrawerContent.propTypes = {
  lesson: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default QuizDrawerContent;
