import { useState, useEffect, useCallback } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { getLabTime } from '../graphql/queries';
import { getUnreadMessageCount } from '../components/Courses/Create/courseUtils';
import { getUserQuizData } from '../shared/utils/Insights/coursePerformance';

function useFetchCourseUsersWithLabTime(
  courseData,
  courseLoading,
  lessonsProgress,
  quizAttemptsData,
) {
  const [courseUsersDataWithLabTime, setCourseUsersDataWithLabTime] = useState(
    [],
  );
  const [loading, setLoading] = useState(true);

  const [getTimeInLab] = useLazyQuery(gql(getLabTime));

  const fetchLabTimes = useCallback(
    async (instanceIds) => {
      return Promise.all(
        instanceIds.map((instanceId) =>
          getTimeInLab({
            variables: { labInstanceId: instanceId },
          }).then((response) => response?.data?.getLabTime?.timeInLab || 0),
        ),
      );
    },
    [getTimeInLab],
  );

  const getUserCourseProgress = useCallback(
    (userCourseId) => {
      if (courseData?.getCourse && lessonsProgress) {
        // lessons progress
        const lessonsProgressItems = lessonsProgress?.filter(
          (item) => userCourseId === item?.courseUserId,
        );
        // total lessons
        const totalLessons =
          courseData?.getCourse?.courseLessons?.items?.length || 0;
        // completed lessons
        const completedLessons =
          lessonsProgressItems?.filter((item) => item?.status === 'FINISHED')
            ?.length || 0;
        const individualCourseProgress =
          totalLessons > 0
            ? Math.round((completedLessons / totalLessons) * 100)
            : 0;

        return {
          individualCourseProgress,
          caption: `${completedLessons}/${totalLessons} Lessons Complete`,
        };
      }
      return 0;
    },
    [courseData.getCourse, lessonsProgress],
  );

  useEffect(() => {
    const labInstancesData =
      courseData?.getCourse?.courseModules?.items
        ?.map((courseDataItem) => courseDataItem?.parts?.items)
        .flat()
        ?.map((modulePart) => modulePart.labInstances.items)
        .flatMap((labInstances) => labInstances) || [];

    const registeredCourseUsers =
      courseData?.getCourse?.courseUsers?.items.map((u) => {
        const { individualCourseProgress, caption } = getUserCourseProgress(
          u.id,
        );

        return {
          id: u.user?.id,
          courseUserId: u.id,
          name: u.user?.name,
          email: u.user?.email,
          courseProgress: {
            progress: individualCourseProgress,
            progressCaption: caption,
          },
          quizSuccessRate: getUserQuizData(
            quizAttemptsData?.listQuizAttemptsByCourseId?.items,
            u.id,
          )?.individualQuizSuccessRate,
          unReadMessageCount: getUnreadMessageCount(
            u.messages?.items,
            u.user?.id,
          ),
          registrationStatus: 'registered',
          updatedAt: u.updatedAt,
        };
      }) || [];

    async function fetchLabTimesAndMergeWithUsers() {
      // Create a map of userId to their instanceIds
      const userToInstanceMap = labInstancesData.reduce((acc, instance) => {
        if (!acc[instance.userId]) {
          acc[instance.userId] = [];
        }
        acc[instance.userId].push(instance);
        return acc;
      }, {});

      const labTimePromises = registeredCourseUsers.map(async (user) => {
        const instanceIds =
          userToInstanceMap[user.id]?.map((instance) => instance.id) || [];
        const labTimes = await fetchLabTimes(instanceIds);
        return {
          ...user,
          totalLabTime: labTimes.reduce((sum, time) => sum + time, 0),
        };
      });

      try {
        const mergedUsers = await Promise.all(labTimePromises);
        setCourseUsersDataWithLabTime(mergedUsers);
      } catch (err) {
        console.error('Error fetching lab times: ', err);
      } finally {
        setLoading(false);
      }
    }

    if (!courseLoading && registeredCourseUsers && labInstancesData) {
      fetchLabTimesAndMergeWithUsers(registeredCourseUsers, labInstancesData);
    }

    // Set the loading state to false after the check
    if (courseLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    courseData,
    courseLoading,
    fetchLabTimes,
    getUserCourseProgress,
    quizAttemptsData.listQuizAttemptsByCourseId,
  ]);

  return { courseUsersDataWithLabTime, loading };
}

export default useFetchCourseUsersWithLabTime;
