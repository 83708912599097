import React from 'react';
import PropTypes from 'prop-types';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ThumbnailIcon,
} from '@react-pdf-viewer/default-layout';
import { FdProgress } from '@fifthdomain/fe-shared';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import RenderThumbnailItem from './RenderThumbnailItem';

function PdfViewer({ fileUrl, theme }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) =>
      [
        {
          content: <Thumbnails renderThumbnailItem={RenderThumbnailItem} />,
          icon: <ThumbnailIcon />,
          title: 'Thumbnails',
        },
      ].concat(defaultTabs.slice(1)),
  });
  const { thumbnailPluginInstance } = defaultLayoutPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[defaultLayoutPluginInstance]}
        theme={theme}
        renderLoader={() => <FdProgress />}
      />
    </Worker>
  );
}

PdfViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

PdfViewer.defaultProps = {
  theme: 'dark',
};

export default PdfViewer;
