import React, { useState } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTypography,
  FdTab,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import LabsTable from '../components/Labs/LabsTable';
import { LABS_STATUS } from '../constants';
import {
  listLabPrototypes,
  listModuleParts,
  getCourseModule,
} from '../graphql/queries';
import {
  updateLabPrototype,
  deleteCourseModule,
  deleteModulePart,
  stopPrototype,
  rollbackPrototype,
} from '../graphql/mutations';
import { getLabStatus } from '../shared/utils/getLabStatus';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import LabDuplicate from '../components/Labs/LabDuplicate';

const ListLabs = () => {
  const globalSnap = useSnapshot(globalStore);
  const [deleteLabPrototype, setDeleteLabPrototype] = useState(false);
  const [exitTestMode, setExitTestMode] = useState(false);
  const [copyLabPrototype, setCopyLabPrototype] = useState(false);
  const [copyLatestLabPrototype, setCopyLatestLabPrototype] = useState(false);
  const [rollbackLabPrototype, setRollbackLabPrototype] = useState(false);
  const [selectedLabPrototype, setSelectedLabPrototype] = useState();
  const [labPrototypeId, setLabPrototypeId] = useState(undefined);
  const [labPrototypesData, setLabPrototypesData] = useState([]);

  // List parts used by lab instance
  const [getModuleParts, { loading: listModulePartsLoading }] = useLazyQuery(
    gql(listModuleParts),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  // get module and parts
  const [getModule, { loading: getCourseModuleLoading }] = useLazyQuery(
    gql(getCourseModule),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  // List Lab Prototypes
  const {
    loading: listLabPrototypesLoading,
    refetch: refetchLabPrototypes,
    startPolling: startPollingLabPrototypes,
  } = useQueryRecursive(gql(listLabPrototypes), {
    variables: {
      filter: {
        orgId: {
          eq: globalSnap.orgId,
        },
        and: [{ status: { ne: 'DELETED' } }],
      },
    },
    staleTime: {
      seconds: 0,
    },
    onCompleted: async (_data) => {
      const labPrototypes = _data?.listLabPrototypes?.items.map((lab) => {
        return {
          id: lab?.id,
          name: lab?.name,
          description: lab?.description,
          status: getLabStatus(lab.status),
          expiryDate:
            lab?.expiry && getDateTimeZoneFormatted(lab?.expiry, true),
          labVersion: lab?.versionIteration,
          labDuration: lab?.duration,
          labNetworks: lab?.networks?.items,
          labVms: lab?.vms?.items,
          orgId: lab?.orgId,
          userId: lab?.userId,
          vms: lab?.vms?.items.length,
          instances: lab?.instances?.items?.length,
          noOfDependentChallenges: lab?.tasks?.items,
          noOfDependentLessons: lab?.exercises?.items,
          hasLabInstanesWithAssessment:
            lab?.instances?.items?.filter(
              (labInstance) => labInstance.assessmentId,
            )?.length > 0,
        };
      });
      setLabPrototypesData(labPrototypes);
      startPollingLabPrototypes(5000); // poll every 5 seconds for lab status updates
    },
  });

  const [stopPrototypeMutation] = useMutation(gql(stopPrototype), {
    onCompleted: () => {
      refetchLabPrototypes();
    },
  });

  const [rollbackPrototypeMutation] = useMutation(gql(rollbackPrototype), {
    onCompleted: () => {
      refetchLabPrototypes();
    },
  });

  // Delete Lab Prototype
  const [updateLabPrototypeMutation, { loading: updateLabPrototypeLoading }] =
    useMutation(gql(updateLabPrototype), {
      refetchQueries: ['listLabPrototypes'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        refetchLabPrototypes();
        successToastMessage('Success! Lab deleted');
      },
      onError: () => {
        errorToastMessage('Lab not deleted');
      },
    });

  const rows = labPrototypesData || [];

  const actions = [
    {
      label: 'Edit',
      show: (row) => {
        return (
          [LABS_STATUS.DRAFT, LABS_STATUS.ERROR].includes(row.status) ||
          ([LABS_STATUS.AVAILABLE, LABS_STATUS.EXPIRED].includes(row.status) &&
            !row.hasLabInstanesWithAssessment)
        );
      },
      onClick: ({ id }) => singleSpa.navigateToUrl(`/labs/editDraft/${id}`),
    },
    {
      label: 'Enter Test Mode',
      show: (row) =>
        [LABS_STATUS.AVAILABLE, LABS_STATUS.EXPIRED].includes(row.status),
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/labs/view/${id}`);
      },
    },
    {
      label: 'Delete',
      show: (row) => {
        return (
          [LABS_STATUS.DRAFT, LABS_STATUS.ERROR, LABS_STATUS.EXPIRED].includes(
            row.status,
          ) && !row.hasLabInstanesWithAssessment
        );
      },
      onClick: ({ id }) => {
        setDeleteLabPrototype(true);
        setLabPrototypeId(id);
      },
    },
    {
      label: 'Exit Edit Mode',
      show: (row) => [LABS_STATUS.EDIT_IN_PROGRESS].includes(row.status),
      onClick: ({ id }) => {
        setExitTestMode(true);
        setLabPrototypeId(id);
      },
    },

    {
      label: 'View edit mode',
      show: (row) => [LABS_STATUS.EDIT_IN_PROGRESS].includes(row.status),
      onClick: ({ id }) => {
        window.open(`/labs/test-lab/${id}`, '_blank');
      },
    },
    {
      label: 'Duplicate Original',
      show: (row) => [LABS_STATUS.AVAILABLE].includes(row.status),
      onClick: (row) => {
        setCopyLabPrototype(true);
        setCopyLatestLabPrototype(false);
        setSelectedLabPrototype(row);
      },
    },
    {
      label: 'Duplicate Latest',
      show: (row) =>
        [LABS_STATUS.AVAILABLE].includes(row.status) && row?.labVersion > 0,
      onClick: (row) => {
        setCopyLabPrototype(true);
        setCopyLatestLabPrototype(true);
        setSelectedLabPrototype(row);
      },
    },
    {
      label: 'Rollback Version',
      show: (row) =>
        [LABS_STATUS.EDIT_IN_PROGRESS].includes(row.status) &&
        row?.labVersion > 1,
      onClick: (row) => {
        setRollbackLabPrototype(true);
        setSelectedLabPrototype(row);
        setLabPrototypeId(row?.id);
      },
    },
  ];

  const loading =
    (listLabPrototypesLoading && labPrototypesData?.length === 0) ||
    updateLabPrototypeLoading ||
    listModulePartsLoading ||
    getCourseModuleLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading;

  const viewLabs = (status) => {
    let labsByStatus = [];
    if (status === 'All') {
      labsByStatus = rows;
    } else {
      labsByStatus = rows?.filter((lab) => status === lab.status);
    }

    const isOnlyAdmin = globalSnap.userType === 'ADMIN';
    const selection =
      isOnlyAdmin && (status === 'All' || status === 'Available');

    return (
      <LabsTable
        rows={labsByStatus}
        actions={actions}
        selection={selection}
        refetchLabs={refetchLabPrototypes}
        loading={loading}
      />
    );
  };

  const deleteLab = async () => {
    // fetch all parts with matching labId
    getModuleParts({
      variables: {
        limit: 100000,
        filter: { labId: { eq: labPrototypeId } },
      },
      onCompleted: async (listModulePartsData) => {
        const allLabUsedParts =
          listModulePartsData?.listModuleParts?.items || [];
        // fetch modules for the parts
        await Promise.all(
          allLabUsedParts?.map(async (p) => {
            // delete part
            await deleteModulePartMutation({
              variables: {
                input: {
                  id: p.id,
                },
              },
            });
            getModule({
              variables: {
                id: p.courseModuleId,
              },
              onCompleted: async (courseModuleData) => {
                const partCount =
                  courseModuleData?.getCourseModule?.parts?.items.length || 0;

                // if module contains only no parts then delete module as well
                if (partCount === 0) {
                  await deleteCourseModuleMutation({
                    variables: {
                      input: {
                        id: p.courseModuleId,
                      },
                    },
                  });
                }
              },
            });
          }),
        );
      },
    });
    await updateLabPrototypeMutation({
      variables: {
        input: {
          id: labPrototypeId,
          status: 'DELETED',
        },
      },
    });
  };
  const onStopLab = (save) => {
    stopPrototypeMutation({
      variables: {
        labPrototypeId,
        savePrototype: save,
      },
    });
  };
  const rollbackLab = () => {
    rollbackPrototypeMutation({
      variables: {
        labPrototypeId,
      },
    });
  };

  return (
    <Box className="w-full">
      <FdBreadcrumbHeader page={{ name: 'Manage Labs', type: 'LAB' }} />
      <FdPageHeading type="LAB">Manage Labs</FdPageHeading>
      <FdTab
        variant="fullWidth"
        label={[
          {
            label: `All (${rows?.length})`,
            path: '/labs/',
            index: 0,
            data: viewLabs('All'),
          },
          {
            label: `${LABS_STATUS.AVAILABLE} (${rows?.filter((lab) => LABS_STATUS.AVAILABLE === lab.status)?.length}) `,
            path: '/labs/',
            index: 1,
            data: viewLabs(LABS_STATUS.AVAILABLE),
          },
          {
            label: `${LABS_STATUS.DRAFT} (${rows?.filter((lab) => LABS_STATUS.DRAFT === lab.status)?.length}) `,
            path: '/labs/',
            index: 2,
            data: viewLabs(LABS_STATUS.DRAFT),
          },
          {
            label: `${LABS_STATUS.BUILDING} (${rows?.filter((lab) => LABS_STATUS.BUILDING === lab.status)?.length}) `,
            path: '/labs/',
            index: 3,
            data: viewLabs(LABS_STATUS.BUILDING),
          },
          {
            label: `${LABS_STATUS.ERROR} (${rows?.filter((lab) => LABS_STATUS.ERROR === lab.status)?.length}) `,
            path: '/labs/',
            index: 3,
            data: viewLabs(LABS_STATUS.ERROR),
          },
        ]}
      />

      {copyLabPrototype && (
        <LabDuplicate
          copyLabPrototype={copyLabPrototype}
          copyLabPrototypeCallBack={setCopyLabPrototype}
          labDetails={selectedLabPrototype}
          refetchLabPrototypes={refetchLabPrototypes}
          copyLatest={copyLatestLabPrototype}
        />
      )}
      {rollbackLabPrototype && (
        <FdModal
          size="md"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Rollback Lab Version</span>
            </Box>
          }
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to rollback the lab version?
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="body1" color="secondary">
                  This will revert the prototype to the state prior to last
                  save.
                </FdTypography>
              </Box>
            </Box>
          }
          confirm="Rollback lab version"
          dismiss="CANCEL"
          open={rollbackLabPrototype}
          disableConfirm={listModulePartsLoading || getCourseModuleLoading}
          onDismiss={async () => {
            setRollbackLabPrototype(false);
            warningToastMessage('Lab version not rolled-back');
          }}
          onConfirm={() => {
            setRollbackLabPrototype(false);
            rollbackLab();
          }}
        />
      )}
      {deleteLabPrototype && (
        <FdModal
          size="md"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Delete Lab</span>
            </Box>
          }
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to delete the lab?
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="body1" color="secondary">
                  This will permanently delete the lab, and remove it from all
                  courses.
                </FdTypography>
              </Box>
            </Box>
          }
          confirm="CANCEL"
          dismiss="Delete lab"
          open={deleteLabPrototype}
          disableConfirm={listModulePartsLoading || getCourseModuleLoading}
          onDismiss={async () => {
            setDeleteLabPrototype(false);
            await deleteLab();
          }}
          onConfirm={() => {
            setDeleteLabPrototype(false);
            warningToastMessage('Lab not deleted');
          }}
        />
      )}
      {exitTestMode && (
        <FdModal
          size="md"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Exit Edit Mode</span>
            </Box>
          }
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to exit edit mode?
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="body1" color="secondary">
                  This will delete your edit lab. Any changes made to the lab
                  will be saved.
                </FdTypography>
              </Box>
            </Box>
          }
          confirm="CANCEL"
          dismiss="Exit edit mode"
          open={exitTestMode}
          onDismiss={() => {
            setExitTestMode(false);
            onStopLab(false);
          }}
          onConfirm={() => {
            setExitTestMode(false);
          }}
        />
      )}
    </Box>
  );
};
export default ListLabs;
