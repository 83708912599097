import { getUrl } from 'aws-amplify/storage';

export const getImageUrlFromS3 = async (name) => {
  try {
    const image = await getUrl({ path: `public/${name}` });
    return image.url.toString();
  } catch (error) {
    return error.message;
  }
};
