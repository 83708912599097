import React from 'react';
import PropTypes from 'prop-types';
import { FileTextIcon } from 'lucide-react';
import { Box } from '@mui/material';
import { downloadFile } from '@fifthdomain/fe-shared';
import { downloadMarkdown } from '../../../shared/utils/fileUtils';

const DownloadLink = ({ name, url, markdown }) => (
  <Box className="flex items-center gap-x-2 py-2">
    <FileTextIcon />
    <Box
      component="button"
      onClick={(e) => {
        e.preventDefault();
        if (markdown) {
          downloadMarkdown(markdown, name);
        } else {
          downloadFile(url, name);
        }
      }}
      className="text-sm font-bold underline"
      sx={(theme) => ({
        color: theme.palette.primary.main,
      })}
    >
      {name}
    </Box>
  </Box>
);

DownloadLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  markdown: PropTypes.string,
};

DownloadLink.defaultProps = {
  markdown: '',
  url: '',
};

export default DownloadLink;
