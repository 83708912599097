export const updateChatMessagesAsRead = async ({
  updateMessageMutation,
  messageIds,
}) => {
  // update message as read
  await Promise.all(
    messageIds.map(async (id) => {
      await updateMessageMutation({
        variables: {
          input: {
            id,
            seen: 'TRUE',
          },
        },
      });
    }),
  );
};
