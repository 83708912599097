import React from 'react';
import { Box, Divider, FormControlLabel, Switch } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
} from '@fifthdomain/fe-shared';
import FdBoxButton from '../FdBoxButton';

const LabNetworks = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();
  const { labNetworks, labDeleteNetworks } = getValues();
  const {
    fields: labNetworkFields,
    remove: labNetworkRemove,
    append: labNetworkAppend,
  } = useFieldArray({
    control,
    name: 'labNetworks',
  });

  return (
    <FdCard variant="outlined" optional>
      <Box className="mb-6">
        <Box className="flex items-end gap-x-2">
          <FdTypography variant="h3">Networks</FdTypography>
          <FdTypography variant="body1" color="secondary" className="mb-1">
            optional
          </FdTypography>
        </Box>
        <FdTypography variant="body2" color="secondary" className="my-2">
          Specify the network(s) that you would like to add to this lab.
        </FdTypography>
      </Box>
      {labNetworkFields.map((item, index) => {
        const watchName = watch(`labNetworks[${index}].name`);
        return (
          <Box my={2} key={item.id}>
            <FdAccordion
              open
              startAdornment
              disableGutters
              showToggleButton={false}
              summary={() => (
                <Box className="flex items-center justify-between w-full">
                  <FdTypography variant="h4">
                    {watchName || 'Network'}
                  </FdTypography>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      labNetworkRemove(index);
                      // Delete Mutation
                      setValue(
                        'labDeleteNetworks',
                        labDeleteNetworks?.concat(
                          labNetworks?.filter(
                            (labNetw) => labNetw.name === item.name,
                          ),
                        ),
                      );
                    }}
                  >
                    DELETE
                  </FdButton>
                </Box>
              )}
              style={{
                borderColor: errors?.labNetworks?.[index]
                  ? 'rgba(198, 40, 40, 1)'
                  : 'inherit',
              }}
              content={() => (
                <>
                  <Divider />
                  <Box className="flex flex-col gap-y-6 my-6">
                    <Controller
                      control={control}
                      name={`labNetworks[${index}].name`}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <FdTextField
                          id={`labNetworks[${index}].name`}
                          label="Network Name"
                          placeholder="Enter Network Name"
                          required
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          {...rest}
                          inputRef={ref}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`labNetworks[${index}].hasInternet`}
                      render={({
                        field: { ref, value: fieldValue, ...rest },
                      }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={fieldValue}
                              {...rest}
                              inputRef={ref}
                            />
                          }
                          label={
                            <FdTypography variant="body1">
                              Enable internet for VM
                            </FdTypography>
                          }
                        />
                      )}
                    />
                  </Box>
                </>
              )}
            />
          </Box>
        );
      })}
      <FdBoxButton
        onClick={async (e) => {
          e.preventDefault();
          labNetworkAppend({
            name: '',
            hasInternet: false,
          });
        }}
      >
        ADD NETWORK
      </FdBoxButton>
    </FdCard>
  );
};

export default LabNetworks;
