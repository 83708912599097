import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdChip } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()((theme) => ({
  cellHeading: {
    lineHeight: '28px',
  },
  condensedChip: {
    fontSize: '10px !important',
    height: '16px !important',
    borderRadius: '4px !important',
    marginBottom: '3px',
    marginRight: '0.5rem',
    backgroundColor: `${theme?.fdProColors?.alert?.infoLight} !important`,
    padding: '9px 0',
    '& [class*="MuiChip-label"]': {
      padding: '3px 5px',
    },
  },
}));

const LessonInfoCell = ({ values }) => {
  const { classes } = useStyles();
  const { estimatedSolveTime, name } = values;

  return (
    <Box>
      <Box className={classes.cellHeading}>{name}</Box>
      <FdChip
        size="small"
        label={`Est. Time : ${estimatedSolveTime}min`}
        className={classes.condensedChip}
      />
    </Box>
  );
};

LessonInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default LessonInfoCell;
