import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SummaryTitle from '../../Summary/SummaryTitle';
import { formatMinutes } from '../../../shared/utils/dateUtils';

const getTileData = (_parts = [], _quizzes = [], _duration) => {
  return [
    {
      icon: TimelapseIcon,
      value: _duration ? formatMinutes(_duration) : 'Not Specified',
    },
    {
      icon: InsertDriveFileIcon,
      value:
        _parts?.filter((d) => ['PDF', 'Markdown'].includes(d?.partType))
          .length || 0,
    },
    {
      icon: OndemandVideoIcon,
      value:
        _parts?.filter((d) => d?.partType === 'Lab' && d.lab !== '').length ||
        _parts?.filter((d) => d?.partType === 'Video').length ||
        0,
    },
    {
      icon: ExtensionIcon,
      value: _parts?.filter((d) => d?.partType === 'Quiz').length || 0,
    },
  ];
};

const SummaryTitles = ({ parts, quizzes, duration }) => {
  const partTypes = parts.map((p) => p.partType);
  const labDurations = parts.map((p) => p.labDuration);
  const quizTypes = parts.map((p) => p.quiz);
  const tileData = useMemo(
    () => getTileData(parts, quizzes, duration),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partTypes, labDurations, quizTypes, quizzes, parts],
  );

  return (
    <Box display="flex">
      {tileData?.map((d) => (
        <SummaryTitle
          data={{
            value: String(d.value),
            Icon: () => {
              const Icon = d.icon || React.Fragment;
              return (
                <Icon
                  style={{
                    fontSize: 18,
                  }}
                />
              );
            },
          }}
          titleVariant="body2"
        />
      ))}
    </Box>
  );
};

SummaryTitles.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  quizzes: PropTypes.arrayOf(PropTypes.object).isRequired,
  duration: PropTypes.number,
};

SummaryTitles.defaultProps = {
  duration: 0,
};

export default SummaryTitles;
