import { useDropzone } from 'react-dropzone';
import { warningToastMessage } from '@fifthdomain/fe-shared';
import { fileSizeValidator } from '../shared/utils/chatUtils';

const useImagesDropzone = ({ setImages }) => {
  return useDropzone({
    onDrop: (files) => {
      if (files.length > 3) {
        warningToastMessage('Only 3 files are accepted for each message');
      }
      setImages((allFiles) => [...allFiles, ...files].slice(0, 3));
    },
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: true,
    validator: fileSizeValidator,
    onDropRejected: (files) => {
      if (files?.length > 0) {
        warningToastMessage('Only image files smaller than 5MB are accepted.');
      }
    },
  });
};

export default useImagesDropzone;
