import React, { useState } from 'react';
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Box } from '@mui/material';
import { BasePage, FdTab, FdProgress } from '@fifthdomain/fe-shared';
import ViewQuizDetails from '../components/Quiz/View/ViewQuizDetails';
import { getQuiz } from '../graphql/queries';
import ViewQuizQuestions from '../components/Quiz/View/ViewQuizQuestions';

const ViewQuiz = () => {
  const [viewMode, setViewMode] = useState(true);
  const { quizId } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const tabindex = new URLSearchParams(search).get('tabindex') || 0; // defaults to details tab
  const [custIndex, setCustIndex] = useState(tabindex);
  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  // Get Quiz details
  const { data: quizApiData, loading: quizDataLoading } = useQuery(
    gql(getQuiz),
    {
      variables: {
        id: quizId,
      },
      skip: !quizId,
    },
  );

  if (quizDataLoading) return <FdProgress />;

  const quizData = quizApiData?.getQuiz;
  const mode = viewMode ? 'View' : 'Edit';

  return (
    <BasePage
      heading={`${mode} ${quizData?.name}`}
      data-cy="view-course"
      breadCrumbs={[
        { url: '/landing', name: 'Home' },
        { url: '/labs/quizzes', name: 'Manage Quizzes' },
      ]}
      currentPageBreadcrumbLabel="View Quiz"
      linkComponent={RouterLink}
    >
      <Box>
        <FdTab
          label={[
            {
              label: 'Details',
              tabRoute: `/labs/quizzes/view/${quizId}?tabindex=0`,
              index: 0,
              data: (
                <ViewQuizDetails
                  quizData={quizData}
                  onModeChange={setViewMode}
                />
              ),
            },
            {
              label: 'Questions',
              tabRoute: `/labs/quizzes/view/${quizId}?tabindex=1`,
              index: 1,
              data: (
                <ViewQuizQuestions
                  quizData={quizData}
                  onModeChange={setViewMode}
                />
              ),
            },
          ]}
          index={parseInt(tabindex, 10)}
          custIndex={parseInt(custIndex, 10)}
          setCustIndex={(value) => {
            setViewMode(true);
            setRouteIndex(value);
          }}
          tabLinkComponent={RouterLink}
        />
      </Box>
    </BasePage>
  );
};

export default ViewQuiz;
