import Yup from '../Common';

export const courseInitialValues = {
  courseId: '',
  name: '',
  description: '',
  category: '',
  availability: false,
  status: '',
  lessons: [],
  lessonIds: [],
  userData: [],
  userIds: [],
  groupIds: [],
  groupData: [],
  dbUserIds: [],
  dbGroupIds: [],
  courseResources: [],
  resourcesDeleted: [],
};

export const courseValidationSchema = {
  courseId: Yup.string(),
  name: Yup.string()
    .required('Please enter a Course Name.')
    .max(150, 'Course Name must be 150 characters or less. ')
    .noWhitespace('Please enter a Course Name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a Course Name.',
      (value) => value && value.length === value.trim().length,
    ),
  description: Yup.string()
    .required('Please enter a Course Introduction.')
    .max(1000, 'Course Introduction must be 1000 characters or less. ')
    .noWhitespace('Please enter a Course Introduction.'),
  category: Yup.string().nullable(),
  availability: Yup.bool(),
  lessons: Yup.array().min(1),
  courseResources: Yup.array().of(
    Yup.object().shape({
      dbId: Yup.string().nullable(),
      id: Yup.string(),
      resourceName: Yup.string()
        .required('Please enter a Resource Name')
        .max(150, 'Resource Name must be 150 characters or less'),
      resourceDescription: Yup.string()
        .nullable()
        .max(300, 'Resource Description must be 300 characters or less'),
      resourceType: Yup.string()
        .required('Please select a Resource Type')
        .oneOf(['PDF', 'MARKDOWN', 'VIDEO'], 'Please select a Resource Type'),
      resourcePdf: Yup.mixed().when('resourceType', {
        is: 'PDF',
        then: Yup.mixed()
          .required('Please upload a PDF')
          .test('fileType', 'Only PDF files are accepted', (value) => {
            return value && (value.type === 'application/pdf' || value.url);
          }),
        otherwise: Yup.mixed().nullable(),
      }),
      resourceMarkdown: Yup.string().when('resourceType', {
        is: 'MARKDOWN',
        then: Yup.string().required('Markdown cannot be 0 characters'),
        otherwise: Yup.string().nullable(),
      }),
      resourceVideo: Yup.string().when('resourceType', {
        is: 'VIDEO',
        then: Yup.string()
          .required('Please add a video URL before proceeding')
          .url('Please add a video URL before proceeding'),
        otherwise: Yup.string().nullable(),
      }),
      resourceOrder: Yup.number(),
    }),
  ),
  resourcesDeleted: Yup.array().of(Yup.string()),
};
