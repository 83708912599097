import React from 'react';
import { Box } from '@mui/material';
import { FdTypography, FdCard } from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import MultiTileInfo from './MultiTileInfo';
import { Gauge } from '../../Charts';
import {
  getQuizPerformance,
  getLabPerformance,
  getCourseProgress,
} from '../../../shared/utils/Insights/coursePerformance';

const PerformanceInsight = ({
  courseModulePartProgress,
  modulePartData,
  totalPoolUsers,
  courseData,
}) => {
  const BoxCentered = ({ title, children }) => (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box>{title}</Box>
      {children}
    </Box>
  );
  BoxCentered.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  const coursePartData = courseData
    ?.map((courseDataItem) => courseDataItem?.parts?.items)
    .flat();

  const labPerformance = getLabPerformance(
    courseModulePartProgress,
    modulePartData,
    totalPoolUsers,
    coursePartData,
  );

  const quizPerformance = getQuizPerformance(
    courseModulePartProgress,
    modulePartData,
    totalPoolUsers,
    coursePartData,
  );

  const courseProgress = getCourseProgress(
    courseModulePartProgress,
    modulePartData,
    totalPoolUsers,
    coursePartData,
  );

  // Lab Completion Rate
  const LabCompletionGauge = (props) => (
    <Gauge
      data={[
        labPerformance.userPoolLabCompletionRate,
        labPerformance.individualLabCompletionRate,
      ]}
      {...props}
    />
  );
  const QuizCompletionGauge = (props) => (
    <Gauge
      data={[
        quizPerformance.userPoolQuizCompletionRate,
        quizPerformance.individualQuizCompletionRate,
      ]}
      {...props}
    />
  );
  const CourseProgressGauge = (props) => (
    <Gauge
      data={[
        courseProgress.userPoolCourseProgressRate,
        courseProgress.individualCourseProgressRate,
      ]}
      {...props}
    />
  );

  return (
    <Box>
      <FdCard data-cy="performance-insights-card" variant="outlined">
        <MultiTileInfo
          title="Performance Insights"
          subTitle="How did they compare to the wider pool?"
          preMessage="See how they performed compared to other users who have been assigned the course."
          tiles={[
            {
              value: `${labPerformance.individualLabCompletionRate}%`,
              description: (
                <BoxCentered title="Lab Completion Rate">
                  <LabCompletionGauge />
                </BoxCentered>
              ),
            },
            {
              value: `${quizPerformance.individualQuizCompletionRate}%`,
              description: (
                <BoxCentered title="Quiz Completion Rate">
                  <QuizCompletionGauge />
                </BoxCentered>
              ),
            },
            {
              value: `${courseProgress.individualCourseProgressRate}%`,
              description: (
                <BoxCentered title="Course Progress">
                  <CourseProgressGauge />
                </BoxCentered>
              ),
            },
          ]}
          valueVariant="h3"
        />
        <Box>
          <FdTypography
            variant="caption"
            color="secondary"
            data-cy="radial-graph-caption"
          >
            <i>
              Radial gauges showing the comparison of the lab completion rate
              (left), the quiz completion rate, and the overall course
              completion rate (right)
            </i>
          </FdTypography>
        </Box>
      </FdCard>
    </Box>
  );
};
PerformanceInsight.propTypes = {
  courseModulePartProgress: PropTypes.arrayOf(PropTypes.object),
  modulePartData: PropTypes.arrayOf(PropTypes.object),
  totalPoolUsers: PropTypes.string,
  courseData: PropTypes.arrayOf(PropTypes.object),
};
PerformanceInsight.defaultProps = {
  courseModulePartProgress: [],
  modulePartData: [],
  totalPoolUsers: 0,
  courseData: [],
};
export default PerformanceInsight;
