export const getAttemptClearedQuiz = (quiz) =>
  quiz?.questions?.map((q) => ({
    ...q,
    inputAnswer: undefined,
    answersSingleText: q?.answersSingleText?.map((o) => ({
      ...o,
      inputAnswer: false,
    })),
    answersMultipleText: q?.answersMultipleText?.map((o) => ({
      ...o,
      inputAnswer: undefined,
    })),
  }));
