import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import {
  FdButton,
  FdTypography,
  FdModal,
  FdAutocomplete,
  FdSelect,
  useQueryRecursive,
  FdDelayed,
  FdChip,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { listRoleCompetencies } from '../../../graphql/queries';
import { truncateTextByLength } from '../../../shared/utils/stringUtils';

const CompetenciesSelector = ({
  onCompetencySelection,
  onCompetencyDeletion,
  existingCompetencies,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [specialtyArea, setSpecialtyArea] = useState(undefined);

  const {
    data: listRoleCompetenciesDataFetch,
    loading: listRoleCompetenciesLoading,
  } = useQueryRecursive(gql(listRoleCompetencies), {
    variables: {
      limit: 1000,
    },
    staleTime: { hours: 24 },
  });

  const existingCompetenciesIds =
    existingCompetencies?.map((c) => c.competencyId || c.id) || [];

  const roleCompetencies = useMemo(() => {
    const listRoleCompetenciesData =
      listRoleCompetenciesDataFetch?.listRoleCompetencies?.items || [];
    return (
      listRoleCompetenciesData?.filter(
        (r) =>
          r?.competencyWorkRole?.area &&
          !existingCompetenciesIds.includes(r?.competencyKSA?.id),
      ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRoleCompetenciesDataFetch, existingCompetencies]);

  // all categories
  const categories = useMemo(() => {
    return (
      _.uniqBy(roleCompetencies, 'competencyWorkRole.category.categoryName')
        .map((item) => item?.competencyWorkRole?.category?.categoryName)
        ?.sort((a, b) => a.localeCompare(b)) || []
    );
  }, [roleCompetencies]);

  // all specialty areas
  const allSpecialtyAreas = useMemo(() => {
    return (
      _.uniqWith(
        roleCompetencies,
        (a, b) =>
          a?.competencyWorkRole?.category?.categoryName ===
            b?.competencyWorkRole?.category?.categoryName &&
          a?.competencyWorkRole?.area?.areaName ===
            b?.competencyWorkRole?.area?.areaName,
      ).map((item) => ({
        category: item?.competencyWorkRole?.category?.categoryName,
        specialtyArea: item?.competencyWorkRole?.area?.areaName,
      })) || []
    );
  }, [roleCompetencies]);

  // specialty areas based on user selection
  const specialtyAreas =
    allSpecialtyAreas
      ?.filter((t) => (category ? t.category === category : true))
      .map((t) => t.specialtyArea)
      .sort((a, b) => a.localeCompare(b)) || [];

  // filter competencies based on category and specialty area
  const allCompetencies =
    _.uniqBy(roleCompetencies, 'competencyKSA.ksaCode')
      .map((item) => ({
        id: item?.competencyKSA?.id,
        competency: truncateTextByLength(item?.competencyKSA?.description, 70),
        category: item?.competencyWorkRole?.category?.categoryName,
        specialtyArea: item?.competencyWorkRole?.area?.areaName,
        categoryId: item?.competencyWorkRole?.categoryId,
        areaId: item?.competencyWorkRole?.areaId,
        workRoleId: item?.competencyWorkRoleID,
      }))
      ?.filter(
        (item) =>
          item?.competency &&
          item?.category &&
          item?.specialtyArea &&
          (specialtyArea ? item?.specialtyArea === specialtyArea : true) &&
          (category ? item?.category === category : true),
      ) || [];

  return (
    <FdSkeleton loading={listRoleCompetenciesLoading}>
      <FdButton onClick={() => setShowModal(true)}>Add Competency</FdButton>
      <FdModal
        title="Select Competencies"
        description="You can search for specific competencies, using the search below. For a targeted search, you can apply filters by Category and Specialty Areas."
        onConfirm={() => {
          setShowModal(false);
          onCompetencySelection(competencies);
        }}
        onDismiss={() => {
          setShowModal(false);
        }}
        onEnter={() => {
          setCategory(undefined);
          setSpecialtyArea(undefined);
          setCompetencies([]);
        }}
        disableConfirm={!competencies?.length}
        open={showModal}
        confirm="Save"
        dismiss="Cancel"
      >
        <Box mt={2}>
          <Box>
            <FdTypography variant="subtitle1">
              Competencies selected to be added to the challenge.
            </FdTypography>
            <Box mt={1} mb={2} height="60px">
              <Box display="flex">
                <FdSelect
                  options={categories}
                  placeholder="Filter by Category"
                  onChange={(v) => {
                    setCategory(v);
                    setSpecialtyArea(undefined);
                  }}
                />
                <Box ml={2}>
                  <FdDelayed triggerField={category}>
                    <FdSelect
                      options={specialtyAreas}
                      placeholder="Filter by Specialty Area"
                      onChange={setSpecialtyArea}
                    />
                  </FdDelayed>
                </Box>
              </Box>
            </Box>
            <FdAutocomplete
              label=""
              placeholder="Type to search for a competency"
              options={allCompetencies}
              multiple
              fullWidth
              selectAllLabel="Select all"
              optionLabel="competency"
              optionValue="id"
              defaultSelected={[]}
              ListItemTextRenderer={({ option }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box display="flex" flexDirection="column">
                      <FdTypography variant="body1">
                        {option?.competency}
                      </FdTypography>
                      <FdTypography variant="caption">
                        {option.specialtyArea &&
                          `Specialty Area: ${option?.specialtyArea}`}
                      </FdTypography>
                    </Box>
                    <Box mr={1}>
                      <FdTypography variant="caption">
                        {option?.category}
                      </FdTypography>
                    </Box>
                  </Box>
                );
              }}
              onChange={(currentSelection) => {
                const selectedCompetencies = currentSelection?.map((c) => ({
                  competencyId: c.id,
                  competency: c.competency,
                  areaId: c?.areaId,
                  categoryId: c?.categoryId,
                  workRoleId: c?.workRoleId,
                }));
                setCompetencies(selectedCompetencies);
              }}
              renderTagsFromMUI
            />
          </Box>
        </Box>
      </FdModal>
      <Box mt={2}>
        {existingCompetencies?.map((c) => (
          <FdChip
            label={c.competency}
            style={{ marginRight: '10px', marginBottom: '10px' }}
            icon={ClearIcon}
            onDelete={() => onCompetencyDeletion(c)}
          />
        ))}
      </Box>
    </FdSkeleton>
  );
};

CompetenciesSelector.propTypes = {
  existingCompetencies: PropTypes.arrayOf(
    PropTypes.shape({
      competencyId: PropTypes.number,
      id: PropTypes.number,
      competency: PropTypes.string,
    }),
  ).isRequired,
  onCompetencySelection: PropTypes.func.isRequired,
  onCompetencyDeletion: PropTypes.func.isRequired,
};

export default CompetenciesSelector;
