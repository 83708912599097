import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  FdProgress,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { loading } = useSnapshot(globalStore);

  if (loading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        // authenticated user
        if (user) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AuthRoute;
