import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { VideoIcon } from 'lucide-react';
import ShowMoreText from 'react-show-more-text';
import { Box, useTheme } from '@mui/material';
import { FdTypography, FdMarkdownRender } from '@fifthdomain/fe-shared';
import ContentAccordion from './ContentAccordion';
import ResourceTypeIcon from './ResourceTypeIcon';
import DownloadLink from './DownloadLink';
import VideoPopup from '../LessonMaterial/VideoPopup';

const ResourcesDrawerContent = ({ lesson }) => {
  const [showVideoPopup, setShowVideoPopup] = useState(null);
  const theme = useTheme();
  const { courseUserId } = useParams();
  const { resources } = lesson || {};

  return (
    <Box
      className="flex flex-col rounded-lg overflow-hidden"
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      {resources?.length > 0 ? (
        resources?.map(
          ({
            resourceType,
            resourceName,
            resourceVideo,
            resourcePdf,
            resourceMarkdown,
            resourceDescription,
            dbId,
          }) => (
            <ContentAccordion
              noBorder
              summary={
                <Box className="flex items-center flex-wrap gap-2 py-1">
                  <ResourceTypeIcon type={resourceType} />
                  <FdTypography variant="subtitle2">
                    {resourceName}
                  </FdTypography>
                </Box>
              }
              details={
                <Box>
                  <FdTypography
                    variant="body2"
                    color="secondary"
                    className="my-2 break-words whitespace-pre-wrap"
                  >
                    {resourceDescription || <em>No description added</em>}
                  </FdTypography>
                  {
                    {
                      VIDEO: (
                        <Box className="flex items-center gap-x-2 my-3">
                          <VideoIcon />
                          <FdTypography
                            variant="body2"
                            className="font-bold underline cursor-pointer"
                            style={{
                              color: theme.palette.primary.main,
                            }}
                            onClick={() =>
                              setShowVideoPopup({
                                url: resourceVideo,
                                title: resourceName,
                                newTabUrl: `/labs/courses/video-view/${courseUserId}/${lesson?.lessonId}/${dbId}`,
                              })
                            }
                          >
                            Open Video Player
                          </FdTypography>
                        </Box>
                      ),
                      PDF: (
                        <DownloadLink
                          name={resourcePdf?.name}
                          url={resourcePdf?.url}
                        />
                      ),
                      MARKDOWN: (
                        <Box>
                          <DownloadLink
                            name={`${resourceName}.md`}
                            markdown={resourceMarkdown}
                          />
                          <Box className="w-full px-4 mx-auto my-0 max-h-[450px] overflow-y-auto">
                            <ShowMoreText
                              lines={3}
                              more="See more"
                              less="See less"
                              className="w-full font-mono text-xs"
                              expanded={false}
                              anchorClass="text-blue-600 font-bold"
                              truncatedEndingComponent="... "
                            >
                              <FdMarkdownRender markdown={resourceMarkdown} />
                            </ShowMoreText>
                          </Box>
                        </Box>
                      ),
                    }[resourceType]
                  }
                </Box>
              }
            />
          ),
        )
      ) : (
        <FdTypography variant="body2" color="secondary" className="italic p-2">
          No resources found
        </FdTypography>
      )}
      <VideoPopup
        open={showVideoPopup}
        setOpen={setShowVideoPopup}
        url={showVideoPopup?.url}
        title={showVideoPopup?.title}
        newTabUrl={showVideoPopup?.newTabUrl}
        videoIcon={
          <Box
            className="flex items-center rounded-xl px-2 py-1"
            sx={{ bgcolor: theme.palette.primary.dark }}
          >
            <VideoIcon size={18} />
          </Box>
        }
      />
    </Box>
  );
};

ResourcesDrawerContent.propTypes = {
  lesson: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default ResourcesDrawerContent;
