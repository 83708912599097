import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  FdButton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  createCourseLesson,
  deleteCourseLesson,
} from '../../../graphql/mutations';
import { listLessonsByOrgId } from '../../../graphql/queries';
import { PROFICIENCY_LEVELS } from '../../../constants';
import LessonMaterial from '../LessonMaterial/LessonMaterial';

const EditLessons = ({ courseData, refetchCourse }) => {
  const { courseId } = useParams();
  const { orgId } = useSnapshot(globalStore);
  const { getValues, reset, trigger } = useFormContext();
  const [selectionValue, setSelectionValue] = useState(false);

  const [createCourseLessonMutation, { loading: createCourseLessonLoading }] =
    useMutation(gql(createCourseLesson));
  const [deleteCourseLessonMutation, { loading: deleteCourseLessonLoading }] =
    useMutation(gql(deleteCourseLesson));

  // ALl lesson published by this org
  const { data: lessonData, loading: lessonLoading } = useQueryRecursive(
    gql(listLessonsByOrgId),
    {
      variables: {
        orgId,
        filter: { status: { eq: 'PUBLISHED' } },
      },
      skip: !orgId,
    },
  );

  const courseLessons = courseData?.getCourse?.courseLessons?.items?.map(
    (item) => ({
      courseLessonId: item?.id,
      lessonId: item?.lessonID,
    }),
  );

  const lessonTableData = !selectionValue
    ? getValues()?.lessons
    : lessonData?.listLessonsByOrgId?.items;

  const allLessons =
    lessonTableData?.map((lesson) => ({
      ...lesson,
      id: lesson?.id,
      proficiency: PROFICIENCY_LEVELS[lesson?.difficulty],
      specialty: lesson?.specialty?.name,
      resources: lesson.lessonResources?.items || [],
      skills: lesson?.skills?.items?.map((skill) => skill?.skill) || [],
      quiz: lesson?.lessonQuizzes?.items[0]?.questions?.items || [],
      exercise: lesson?.lessonExercises?.items[0] || {},
      techniques:
        lesson?.skills?.items
          ?.map((skill) =>
            skill?.techniqueTags?.items.map((techniqueTag) => ({
              label: techniqueTag.techniqueTag.name,
            })),
          )
          .flat() || [],
      technologies:
        lesson?.technologyTags?.items?.map((tag) => ({
          label: tag?.technologyTag?.name,
        })) || [],
    })) || [];

  const updateCourseLessonsAction = async () => {
    const { lessonIds } = getValues();

    const deleteItems = courseLessons?.filter(
      (item) => !lessonIds?.includes(item?.lessonId),
    );
    const addItems = lessonIds?.filter(
      (item) => !courseLessons?.map((i) => i?.lessonId)?.includes(item),
    );

    if (addItems?.length > 0) {
      await Promise.all(
        addItems?.map((i) => {
          return createCourseLessonMutation({
            variables: {
              input: {
                courseID: courseId,
                lessonID: i,
              },
            },
          });
        }),
      );
    }
    if (deleteItems?.length > 0) {
      await Promise.all(
        deleteItems?.map((i) => {
          return deleteCourseLessonMutation({
            variables: {
              input: {
                id: i?.courseLessonId,
              },
            },
          });
        }),
      );
    }
    refetchCourse();
    setSelectionValue(false);
    successToastMessage(`Success! Course edits saved.`);
  };

  const ActionButtons = () => (
    <Box display="flex">
      {!selectionValue ? (
        <FdButton onClick={() => setSelectionValue(true)} data-cy="edit-button">
          Edit
        </FdButton>
      ) : (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setSelectionValue(false);
              warningToastMessage('Course edits not saved.');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                const result = await trigger(['lessons']);
                if (result) {
                  updateCourseLessonsAction();
                }
              }}
              disabled={createCourseLessonLoading || deleteCourseLessonLoading}
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
      <LessonMaterial
        allLessons={allLessons}
        actionButtons={<ActionButtons />}
        selectionValue={selectionValue}
        editMode
      />
    </Box>
  );
};

EditLessons.propTypes = {
  courseData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchCourse: PropTypes.func.isRequired,
};

export default EditLessons;
