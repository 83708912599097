import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()((theme) => ({
  button: {
    border: `1px dashed ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    width: '100%',
    background: 'transparent',
  },
}));

const FdBoxButton = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <Box component="button" className={classes.button} py={3} {...rest}>
      <FdTypography variant="subtitle1" color="rgba(51, 51, 255, 1)">{children}</FdTypography>
    </Box>
  );
};

FdBoxButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FdBoxButton;
