import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';

const ViewDetails = ({ heading, subHeading, values }) => (
  <FdCard heading={heading} subHeading={subHeading} variant="outlined">
    <Box display="flex" flexDirection="column">
      <Box mt={2}>
        <FdTypography variant="subtitle1">Lab Name</FdTypography>
        <FdTypography variant="body1" color="secondary">
          {values?.labName}
        </FdTypography>
      </Box>
      <Box mt={2}>
        <FdTypography variant="subtitle1">Lab Description</FdTypography>
        <FdTypography variant="body1" color="secondary">
          {values?.labDescription}
        </FdTypography>
      </Box>
    </Box>
  </FdCard>
);

ViewDetails.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subHeading: PropTypes.string,
  values: PropTypes.shape({
    labName: PropTypes.string,
    labDescription: PropTypes.string,
  }).isRequired,
};

ViewDetails.defaultProps = {
  heading: '',
  subHeading: '',
};

export default ViewDetails;
