import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdLoadingSpinner } from '@fifthdomain/fe-shared';

const VideoPlayer = ({ url, ...props }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, [url]);

  return (
    <Box className="w-full relative">
      {loading && <FdLoadingSpinner />}
      <iframe
        title="Vimeo Player"
        src={url}
        className="w-full"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ height: 'calc(100vh - 250px)' }}
        onLoad={() => setLoading(false)}
        {...props}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
