import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { VideoIcon } from 'lucide-react';
import { FdTypography } from '@fifthdomain/fe-shared';
import ContentAccordion from './ContentAccordion';
import InfoAccordion from './InfoAccordion';
import VideoPopup from '../LessonMaterial/VideoPopup';

const InstructionsDrawerContent = ({ lesson }) => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const theme = useTheme();
  const { courseUserId } = useParams();
  const {
    exerciseWrittenInstructions,
    exerciseVideoInstructions,
    exercise,
    lessonId,
  } = lesson || {};

  return (
    <Box className="flex flex-col gap-y-2">
      <ContentAccordion
        summary={<FdTypography variant="subtitle2">Instructions</FdTypography>}
        details={
          <Box>
            {exerciseVideoInstructions && (
              <Box className="flex items-center gap-x-2 my-3">
                <VideoIcon />
                <FdTypography
                  variant="body2"
                  className="font-bold underline cursor-pointer"
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => setShowVideoPopup(true)}
                >
                  Open Video Instructions
                </FdTypography>
                <VideoPopup
                  open={showVideoPopup}
                  url={exerciseVideoInstructions}
                  title="Video Instructions"
                  setOpen={setShowVideoPopup}
                  newTabUrl={`/labs/courses/video-view/${courseUserId}/${lessonId}`}
                />
              </Box>
            )}
            <FdTypography
              variant="body2"
              className="break-words whitespace-pre-wrap"
            >
              {exerciseWrittenInstructions || <em>No instructions added</em>}
            </FdTypography>
          </Box>
        }
        defaultExpanded
      />
      {exercise === 'LAB' && (
        <Box mt={1}>
          <InfoAccordion
            title="Accessing Labs in Courses"
            message="Labs in Courses are only accessible via the VDI provided. Labs in Courses cannot be accessed via VPN."
            defaultExpanded
          />
          <InfoAccordion
            title="About Lab Times"
            message="Your lab instance will be deleted once Lab Time Remaining reaches 0. You’ll be able to start a new lab instance, but all progress will be lost. Regularly check Lab Time Remaining and extend your lab instance as required before the timer reaches 0."
            defaultExpanded
          />
        </Box>
      )}
    </Box>
  );
};

InstructionsDrawerContent.propTypes = {
  lesson: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default InstructionsDrawerContent;
