/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateLabInstance = /* GraphQL */ `
  subscription OnUpdateLabInstance {
    onUpdateLabInstance {
      labPrototypeId
      modulePartId
    }
  }
`;
export const onCreateCourseMessage = /* GraphQL */ `
  subscription OnCreateCourseMessage(
    $filter: ModelSubscriptionCourseMessageFilterInput
  ) {
    onCreateCourseMessage(filter: $filter) {
      userCourseId
      courseId
      senderId
      receiverId
      seen
      messages {
        type
        content
      }
      createdAt
      updatedAt
      orgId
      id
    }
  }
`;
