import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from 'react-hook-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
  FdAlert,
} from '@fifthdomain/fe-shared';
import SummaryTitles from './SummaryTitles';
import FdBoxButton from '../../FdBoxButton';
import CoursePart from './CoursePart';
import FdTextView from '../../FdTextView';

const CourseModule = ({
  moduleIdx,
  module,
  deleteModule,
  deletePartSummary,
  allQuizzes,
  allLabs,
  isEdit,
}) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const watchModuleName = useWatch({
    control,
    name: `modules[${moduleIdx}].moduleName`,
  });
  const watchModuleAccordionState = useWatch({
    control,
    name: `modules[${moduleIdx}].accordionState`,
  });
  const {
    fields: parts,
    append: appendPart,
    remove: deletePart,
    swap: swapParts,
  } = useFieldArray({
    control,
    name: `modules[${moduleIdx}].parts`,
  });
  const { append: appendPartSummaryItem, remove: deletePartSummaryItem } =
    useFieldArray({
      control,
      name: `partsSummary[${moduleIdx}].parts`,
    });
  const watchSummaryModule = useWatch({
    control,
    name: `partsSummary[${moduleIdx}]`,
  });
  const { append: appendDeleteModule } = useFieldArray({
    control,
    name: 'modulesToDelete',
  });

  const partError = errors?.modules?.[moduleIdx]?.parts?.message;

  return (
    <FdAccordion
      data-cy="module"
      summary={() => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <FdTypography variant="h3">
            {String(watchModuleName || 'Module 1').substring(0, 100)}
          </FdTypography>
          <Box display="flex" alignItems="center">
            <SummaryTitles
              duration={module.moduleDuration}
              parts={watchSummaryModule?.parts || []}
              quizzes={allQuizzes}
            />
          </Box>
        </Box>
      )}
      TransitionProps={{ unmountOnExit: true }}
      expanded={watchModuleAccordionState}
      onExpandCollapse={() =>
        setValue(
          `modules[${moduleIdx}].accordionState`,
          !watchModuleAccordionState,
        )
      }
      actions={
        isEdit ? (
          <FdButton
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              const actionId = module?.moduleId;
              if (actionId) {
                // delete from db if present on save
                appendDeleteModule(actionId);
              }
              deleteModule(moduleIdx);
              deletePartSummary(moduleIdx);
            }}
          >
            Delete
          </FdButton>
        ) : (
          []
        )
      }
      content={() => (
        <Box display="flex" flexDirection="column">
          {partError && (
            <Box mt={2} mb={2}>
              <FdAlert variant="error" message="Please add a part" />
            </Box>
          )}
          <Controller
            control={control}
            name={`modules[${moduleIdx}].moduleName`}
            render={({
              field: { id, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <Box mt={1} mb={2}>
                {isEdit ? (
                  <FdTextField
                    key={id}
                    id={`moduleName-${moduleIdx}`}
                    label="Module Name"
                    value={value}
                    required
                    fullWidth
                    placeholder="Module name must be 120 characters or less"
                    error={error}
                    helperText={error && error.message}
                    data-cy={`moduleName-${moduleIdx}`}
                    {...rest}
                    inputRef={ref}
                  />
                ) : (
                  <FdTextView label="Module Name" value={value} />
                )}
              </Box>
            )}
          />
          <Box mt={0} mb={0}>
            <Controller
              control={control}
              name={`modules[${moduleIdx}].moduleDescription`}
              render={({
                field: { id, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <Box mb={2}>
                  {isEdit ? (
                    <FdTextField
                      key={id}
                      id={`moduleDesc-${moduleIdx}`}
                      label="Module Description"
                      value={value}
                      fullWidth
                      multiline
                      required
                      rows={3}
                      placeholder="Enter a module description here"
                      error={error}
                      helperText={error && error.message}
                      data-cy={`moduleDesc-${moduleIdx}`}
                      {...rest}
                      inputRef={ref}
                    />
                  ) : (
                    <FdTextView label="Module Description" value={value} />
                  )}
                </Box>
              )}
            />
          </Box>
          <Box mt={0} mb={0}>
            <Controller
              control={control}
              name={`modules[${moduleIdx}].moduleDuration`}
              render={({
                field: { id, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <Box mb={2}>
                  {isEdit ? (
                    <FdTextField
                      key={id}
                      id={`moduleDuration-${moduleIdx}`}
                      label="What is the estimated learning time of this module? (mins)"
                      value={value}
                      type="number"
                      width="420px"
                      required
                      error={error}
                      helperText={error && error.message}
                      data-cy={`moduleDura-${moduleIdx}`}
                      {...rest}
                      inputRef={ref}
                    />
                  ) : (
                    <FdTextView
                      label="Estimated Learning Time (mins)"
                      value={value || 'Not Specified'}
                    />
                  )}
                </Box>
              )}
            />
          </Box>
          <DndProvider backend={HTML5Backend}>
            {parts
              .sort((a, b) => a.order - b.order)
              .map((p, partIdx) =>
                p?.partType === 'Lab' && p?.lab === '' ? null : (
                  <CoursePart
                    key={p.id}
                    moduleIdx={moduleIdx}
                    partIdx={partIdx}
                    part={{
                      partType: p.partType,
                      partId: p.partId,
                      file: p.file,
                    }}
                    deletePart={deletePart}
                    deletePartSummaryItem={deletePartSummaryItem}
                    allQuizzes={allQuizzes}
                    allLabs={allLabs}
                    isEdit={isEdit}
                    swapParts={swapParts}
                  />
                ),
              )}
            {isEdit && (
              <FdBoxButton
                onClick={(e) => {
                  e.preventDefault();
                  const allModules = getValues('modules') || [];
                  // set all accordions to collapsed except the current one
                  allModules.forEach((m, mIdx) => {
                    if (mIdx !== moduleIdx) {
                      setValue(`modules[${mIdx}].accordionState`, false);
                    }
                    // collapse all part accordions
                    m.parts.forEach((p, pIdx) =>
                      setValue(
                        `modules[${mIdx}].parts[${pIdx}].accordionState`,
                        false,
                      ),
                    );
                  });
                  appendPart({
                    order: parts.length + 1,
                    labAbandonment: 14,
                    deletionMode: true,
                    labDuration: 10,
                    initialLabPool: 0,
                    minimumLabPool: 0,
                    accordionState: true,
                  });
                  appendPartSummaryItem({ labDuration: 10 });
                }}
              >
                ADD PART
              </FdBoxButton>
            )}
          </DndProvider>
        </Box>
      )}
      startAdornment
      showToggleButton={false}
    />
  );
};

CourseModule.propTypes = {
  moduleIdx: PropTypes.number.isRequired,
  deleteModule: PropTypes.func.isRequired,
  deletePartSummary: PropTypes.func.isRequired,
  allQuizzes: PropTypes.arrayOf(PropTypes.object).isRequired,
  allLabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEdit: PropTypes.bool,
  module: PropTypes.shape({
    moduleId: PropTypes.string,
    moduleName: PropTypes.string,
    moduleDuration: PropTypes.number,
    accordionState: PropTypes.bool,
  }).isRequired,
  accordionState: PropTypes.bool,
};

CourseModule.defaultProps = {
  isEdit: false,
  accordionState: false,
};

export default CourseModule;
