import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, Badge } from '@mui/material';
import * as singleSpa from 'single-spa';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  FdTypography,
  BasePage,
  FdProgress,
  FdChip,
  FdCard,
  useQueryRecursive,
  FdButton,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { getCoursesStatusColor } from '../shared/utils/getStatusColor';
import CourseSummary from '../components/Courses/CourseSummary';
import Modules from '../components/Courses/Modules/Modules';
import SummaryTitles from '../components/Courses/Create/SummaryTitles';
import {
  listCourseMessagesByUserCourseId,
  listModulePartProgressesByCourseUserId,
} from '../graphql/queries';
import { getCourseUser, listQuizzesByOrgId } from '../queries/customQueries';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import { getCourseProgressStatus } from '../components/Courses/Create/courseUtils';
import { CourseChat } from '../components/Chat';
import { invalidateMessageQueries } from '../queries/invalidateQueries';
import { readChatMessages } from '../graphql/mutations';
import { ChatContext } from '../contexts';

function ViewCourse() {
  const { courseUserId } = useParams();
  const [openChat, setOpenChat] = useState(false);
  const [isChatDirty, setIsChatDirty] = useState(false);
  const { orgId } = useSnapshot(globalStore);
  5;

  const { data: courseUserData, loading: courseUserDataLoading } = useQuery(
    gql(getCourseUser),
    {
      variables: {
        userCourseId: courseUserId,
      },
      onCompleted: (_cData) => {
        if (!_cData?.getCourseUser) {
          singleSpa.navigateToUrl('/labs/courses');
        }
      },
      skip: !courseUserId,
    },
  );

  const { data: messagesData, refetch: refetchMessages } = useQueryRecursive(
    gql(listCourseMessagesByUserCourseId),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
    },
  );

  // List Quizzes
  const { data: quizData, loading: quizDataLoading } = useQueryRecursive(
    gql(listQuizzesByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  const [markAsReadChatMessagesMutation] = useMutation(gql(readChatMessages));

  // List Progress
  const { data: modulePartProgressData, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
      variables: {
        courseUserId,
      },
      staleTime: { seconds: 0 },
    });

  const courseModulePartIds =
    courseUserData?.getCourseUser?.course?.courseModules?.items
      .map((m) => m?.parts?.items.map((mp) => mp?.id))
      .flat();
  const partsProgress =
    modulePartProgressData?.listModulePartProgressesByCourseUserId?.items?.filter(
      (mp) => courseModulePartIds?.includes(mp?.modulePartId),
    ) || [];

  const { status, courseProgress } = getCourseProgressStatus({
    partsProgress,
    courseModulePartIds,
  });

  const allQuizzes = quizData?.listQuizzes?.items || [];

  if (courseUserDataLoading || quizDataLoading || modulePartProgressLoading)
    return <FdProgress />;

  const { course, user } = courseUserData?.getCourseUser || {};
  const sortByOrder = (a, b) => a.orderNumber - b.orderNumber;
  const courseModules =
    [...course?.courseModules?.items]
      .map((m) => ({
        ...m,
        parts: {
          items: [
            ...m?.parts?.items.filter((p) => p?.status !== 'DELETED'),
          ].sort(sortByOrder),
        },
      }))
      .sort(sortByOrder) || [];

  // unread messages from admin
  const unReadMessages =
    messagesData?.listCourseMessagesByUserCourseId?.items.filter(
      (m) =>
        m?.sender?.permissions?.includes('VIEW_INSIGHTS') && m.seen === 'FALSE',
    )?.length || 0;

  const ChatButtonWrapper = ({ children }) =>
    unReadMessages > 0 ? (
      <Badge badgeContent={unReadMessages} color="error">
        {children}
      </Badge>
    ) : (
      <>{children}</>
    );
  ChatButtonWrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <BasePage
      breadCrumbs={[
        { url: '/landing', name: 'Home' },
        { url: '/landing', name: 'Courses' },
      ]}
      currentPageBreadcrumbLabel={course?.name}
      renderBreadCrumbAsButton
      data-cy="view-course"
      pageAdornment={
        !openChat && (
          <ChatButtonWrapper>
            <FdButton
              startIcon={<ChatBubbleOutlineIcon />}
              onClick={() => {
                invalidateMessageQueries();
                setOpenChat(true);
                // mark all admin messages as read
                markAsReadChatMessagesMutation({
                  variables: { userCourseId: courseUserId },
                });
              }}
            >
              Ask Your Mentors
            </FdButton>
          </ChatButtonWrapper>
        )
      }
    >
      <ChatContext.Provider
        value={{
          isChatDirty,
          setIsChatDirty,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <FdTypography variant="h2" data-cy="courses-base-page">
            {course?.name}
          </FdTypography>
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={2} alignItems="center">
              <Box mr={1}>
                <FdProgress type="linear" value={courseProgress} />
              </Box>
              <FdTypography variant="subtitle1">
                {`${courseProgress || 0}%`}
              </FdTypography>
            </Box>
            <Box mr={2}>
              <FdChip
                color={getCoursesStatusColor(`${status}`)}
                size="small"
                label={`${status}`}
              />
            </Box>
          </Box>
        </Box>
        <FdCard variant="outlined">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <FdTypography variant="h3">Course Details</FdTypography>
            <Box display="flex">
              <SummaryTitles
                parts={
                  course?.courseModules.items
                    ?.map((m) =>
                      m.parts.items
                        .filter(
                          (p) =>
                            p.status !== 'DELETED' &&
                            ((p.lab?.status === 'READY' && p.type === 'LAB') ||
                              p.type !== 'LAB'),
                        )
                        .map((p) => ({
                          ...p,
                          partType:
                            p.type === 'PDF'
                              ? p.type
                              : upperCaseFirstLetter(p.type),
                          quiz: p.type === 'QUIZ' ? p.quiz?.id : '',
                          lab: p.type === 'LAB' ? p.name : '',
                        })),
                    )
                    .flat() || []
                }
                quizzes={allQuizzes}
                duration={
                  course?.courseModules?.items
                    ?.map((m) => m.duration)
                    .reduce((acc, v) => acc + Number(v), 0) || 0
                }
              />
              <CourseSummary
                data={[
                  {
                    icon: (
                      <FdChip
                        style={{
                          fontSize: 12,
                          color: '#000000',
                          backgroundColor: '#BDBDBD',
                          borderRadius: 4,
                          padding: '0 8px',
                        }}
                        size="small"
                        label={`${course?.courseModules?.items.length} Modules`}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          </Box>
          <Box mb={2}>
            <FdTypography variant="body1" color="secondary">
              {course?.description}
            </FdTypography>
          </Box>
          <Modules
            modules={courseModules}
            courseId={course?.id}
            userId={user?.id}
            allQuizzes={allQuizzes}
          />
        </FdCard>
        <CourseChat
          openChat={openChat}
          onCloseChat={() => {
            refetchMessages();
            setOpenChat(false);
          }}
          chatTitle="Ask your Mentors"
          receiverId=""
          courseId={course.id}
          userCourseId={courseUserId}
        />
      </ChatContext.Provider>
    </BasePage>
  );
}

export default ViewCourse;
