import React, { useState } from 'react';
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import {
  FdChip,
  FdTab,
  useQueryRecursive,
  FdDelayed,
  useSnapshot,
  globalStore,
  Authorization,
  useRecentLinks,
  FdTypography,
  FdButton,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import EditCourse from '../components/Courses/Edit/EditCourse';
import EditCourseUsersGroups from '../components/Courses/Edit/EditCourseUsersGroups';
import { getCourse } from '../graphql/queries';
import { getCourseUsersGroups } from '../queries/customQueries';
import ViewInsight from '../components/Courses/Insights/ViewInsight';
import { ChatQueue } from '../components/Chat';
import setAppMarginRightByIds from '../shared/utils/layout';
import { ChatContext } from '../contexts';
import ChatSaveModal from '../components/Chat/ChatSaveModal';
import { getCourseStatus } from '../shared/utils/courseStatus';
import { getCoursesStatusColor } from '../shared/utils/getStatusColor';
import EditLessons from '../components/Courses/Edit/EditLessons';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../validation-schemas/Course';
import { updateCourse } from '../graphql/mutations';
import { COURSE_STATUS } from '../constants';

function ViewCourseAdmin() {
  const { courseId } = useParams();
  const { search, pathname } = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const tabindex = new URLSearchParams(search).get('tabindex') || 1; // defaults to details tab
  const [custIndex, setCustIndex] = useState(tabindex);
  const [isChatDirty, setIsChatDirty] = useState(false);
  const [showChatSave, setShowChatSave] = useState(false);
  const [triggerTabRefresh, setTriggerTabRefresh] = useState(false);
  const [targetTab, setTargetTab] = useState(undefined);
  const { permissions } = useSnapshot(globalStore);

  const validationSchema = Yup.object().shape({
    ...courseValidationSchema,
    status: Yup.string().nullable(),
    usersGroupsCount: Yup.number().nullable(),
  });

  const reactHookFormMethods = useForm({
    defaultValues: courseInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, control } = reactHookFormMethods;

  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );

  const {
    data: courseData,
    loading: courseLoading,
    refetch: refetchCourse,
  } = useQuery(gql(getCourse), {
    variables: {
      id: courseId,
    },
    fetchPolicy: 'network-only',
    skip: !courseId,
    onCompleted: (_data) => {
      const {
        availability,
        name,
        description,
        status,
        courseUsers,
        coursegroups,
        courseLessons,
      } = _data.getCourse;
      reset({
        courseId,
        name,
        description,
        status,
        availability,
        lessons: courseLessons?.items?.map(({ lesson }) => lesson) || [],
        lessonIds: courseLessons?.items?.map((item) => item?.lessonID) || [],
        usersGroupsCount:
          (courseUsers?.items.length || 0) +
            (coursegroups?.items.length || 0) || 0,
      });
    },
  });

  const { data: courseUsersGroupsData, loading: courseUsersGroupsLoading } =
    useQuery(gql(getCourseUsersGroups), {
      variables: {
        id: courseId,
      },
      skip: !courseId,
    });

  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  if (courseLoading || courseUsersGroupsLoading) return null;

  const { name, status } = courseData?.getCourse;
  const hasChatViewPermission = Authorization.canViewInsights(permissions);

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'COURSE' }} />
      <Box className="flex justify-between items-center mb-6">
        <Box className="flex">
          <FdTypography variant="h2">{name}</FdTypography>
          <Box className="flex ml-4 mt-2">
            <FdChip
              color={getCoursesStatusColor(upperCaseFirstLetter(status))}
              size="small"
              label={getCourseStatus[status]}
            />
          </Box>
        </Box>

        {/* show publish button only when status is draft and users > 0 */}
        {status === 'DRAFT' && (
          <FdButton
            disabled={updateCourseLoading}
            onClick={async () => {
              await updateCourseMutation({
                variables: {
                  input: {
                    id: courseId,
                    status: COURSE_STATUS.AVAILABLE,
                  },
                },
              });
              refetchCourse();
              successToastMessage(`Success! Course published.`);
            }}
            style={{
              backgroundColor: theme?.fdProColors?.alert?.successDark,
            }}
          >
            Publish
          </FdButton>
        )}
      </Box>

      <Box mt="-1rem">
        <ChatContext.Provider
          value={{
            isChatDirty,
            setIsChatDirty,
          }}
        >
          <FormProvider {...reactHookFormMethods}>
            <form>
              <FdDelayed triggerField={triggerTabRefresh}>
                <FdTab
                  disableTabChange={isChatDirty}
                  label={[
                    {
                      label: 'Insights',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=0`,
                      index: 0,
                      data: (
                        <ViewInsight
                          courseUsersGroupsData={courseUsersGroupsData}
                          courseData={courseData}
                        />
                      ),
                    },
                    {
                      label: 'Details',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=1`,
                      index: 1,
                      data: <EditCourse refetchCourse={refetchCourse} />,
                    },
                    {
                      label: 'Lessons',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=2`,
                      index: 2,
                      data: (
                        <EditLessons
                          courseData={courseData}
                          refetchCourse={refetchCourse}
                        />
                      ),
                    },
                    {
                      label: 'Users',
                      tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=1`,
                      index: 3,
                      data: (
                        <EditCourseUsersGroups
                          courseId={courseId}
                          setRouteIndex={setRouteIndex}
                        />
                      ),
                    },
                    ...(hasChatViewPermission
                      ? [
                          {
                            label: 'Chat Queue',
                            tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=3`,
                            index: 3,
                            data: <ChatQueue courseId={courseId} />,
                          },
                        ]
                      : []),
                  ]}
                  index={parseInt(tabindex, 10)}
                  custIndex={parseInt(custIndex, 10)}
                  setCustIndex={setRouteIndex}
                  tabLinkComponent={RouterLink}
                  onTabChange={() =>
                    setAppMarginRightByIds(['topnav', 'labs-fe'], 0)
                  }
                  onBeforeTabChange={(_tab) => {
                    // user trying to switch tabs with unsaved chat
                    if (isChatDirty && _tab !== custIndex) {
                      setTargetTab(_tab); // save target tab for further nav
                      setShowChatSave(true);
                    }
                  }}
                />
              </FdDelayed>
            </form>
          </FormProvider>
          <ChatSaveModal
            onConfirm={() => {
              setIsChatDirty(false);
              setShowChatSave(false);
              setRouteIndex(targetTab);
              setTriggerTabRefresh(!triggerTabRefresh); // refresh tabs to switch to target tab
            }}
            onCancel={() => {
              setShowChatSave(false);
            }}
            open={showChatSave}
          />
        </ChatContext.Provider>
      </Box>
    </Box>
  );
}

export default ViewCourseAdmin;
