import React, { useEffect } from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '@fifthdomain/auth';
import {
  setShowNav,
  AppBasePageProvider,
  ReactQueryProvider,
} from '@fifthdomain/fe-shared';
import GraphQlClient from './shared/GraphQlClient';
import Error from './pages/Error';
import ListVMs from './pages/ListVMs';
import ListLabs from './pages/ListLabs';
import CreateLab from './pages/CreateLab';
import ViewLab from './pages/ViewLab';
import TestLabVDI from './pages/TestLabVDI';
import LabVDI from './pages/LabVDI';
import AuthRoute from './routes/AuthRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import ViewCourse from './pages/ViewCourse';
import ViewModule from './pages/ViewModule';
import CreateCourse from './pages/CreateCourse';
import ViewCourseAdmin from './pages/ViewCourseAdmin';
import ListQuizzes from './pages/ListQuizzes';
import ViewQuiz from './pages/ViewQuiz';
import CreateQuiz from './pages/CreateQuiz';
import TestLab from './pages/TestLab';
import ViewCourseLab from './pages/ViewCourseLab';
import DuplicateCourse from './pages/DuplicateCourse';
import ProtectedManageEventsViewInsightsRoute from './routes/ProtectedManageEventsViewInsightsRoute';
import ProtectedManageEventsRoute from './routes/ProtectedManageEventsRoute';
import ProtectedManageContentRoute from './routes/ProtectedManageContentRoute';
import './labs-fe.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'labs-fe',
  seed: 'labs-fe',
});

const App = () => {
  useEffect(() => {
    setShowNav(true);
  });

  return (
    <GraphQlClient>
      <AuthProvider>
        <ReactQueryProvider>
          <AppThemeProvider>
            <StylesProvider generateClassName={generateClassName}>
              <>
                <ToastContainer />
                <AppBasePageProvider>
                  <BrowserRouter>
                    <Switch>
                      <ProtectedManageContentRoute
                        path="/labs"
                        component={ListLabs}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/create"
                        component={CreateLab}
                        formMode="create"
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/editDraft/:labId"
                        component={CreateLab}
                        formMode="edit"
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/view/:labId"
                        component={ViewLab}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/test-lab/:labId"
                        component={TestLab}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/connect-test-lab/:labId/vdi/:vmId"
                        component={TestLabVDI}
                        exact
                      />
                      <AuthRoute
                        path="/labs/connect/:labId/vdi/:vmId"
                        component={LabVDI}
                        exact
                      />
                      <ProtectedManageEventsRoute
                        path="/labs/view-course-lab/:partId/:courseUserId"
                        component={ViewCourseLab}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/vms"
                        component={ListVMs}
                        exact
                      />
                      <AuthRoute
                        path="/labs/courses/view/:courseUserId/"
                        component={ViewCourse}
                        exact
                      />
                      <ProtectedManageEventsViewInsightsRoute
                        path="/labs/courses/view-admin/:courseId"
                        component={ViewCourseAdmin}
                        exact
                      />
                      <ProtectedManageEventsRoute
                        path="/labs/courses/duplicate/:courseIdToDuplicate"
                        component={DuplicateCourse}
                        exact
                      />
                      <AuthRoute
                        path="/labs/courses/view/:courseUserId/module/:moduleId/part/:partId"
                        component={ViewModule}
                        exact
                      />
                      <ProtectedManageEventsRoute
                        path="/labs/courses/create"
                        component={CreateCourse}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/quizzes"
                        component={ListQuizzes}
                        exact
                      />
                      <ProtectedManageContentRoute
                        path="/labs/quizzes/view/:quizId"
                        component={ViewQuiz}
                      />
                      <ProtectedManageContentRoute
                        path="/labs/quizzes/create"
                        component={CreateQuiz}
                      />
                      <Route path="/labs/error" component={Error} />
                      <Redirect to="/landing" />
                    </Switch>
                  </BrowserRouter>
                </AppBasePageProvider>
              </>
            </StylesProvider>
          </AppThemeProvider>
        </ReactQueryProvider>
      </AuthProvider>
    </GraphQlClient>
  );
};

export default App;
