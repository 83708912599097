import { FileCode2Icon, FileTextIcon, VideoIcon } from 'lucide-react';
export const USER_PERMISSION_TYPE = Object.freeze({
  CREATE: 'CREATE',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  MANAGE_TRAINING: 'MANAGE_TRAINING',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
});

export const LABS_STATUS = Object.freeze({
  AVAILABLE: 'Available',
  DRAFT: 'Draft',
  EXPIRED: 'Expired',
  BUILDING: 'Building',
  BUILD_FAILED: 'Error',
  ERROR: 'Error',
  EDIT_IN_PROGRESS: 'In Editing',
});

export const LAB_INSTANCE_STATUS = Object.freeze({
  IN_BUILD_QUEUE: 'BUILDING',
  BUILD_IN_PROGRESS: 'BUILDING',
  IN_DELETE_QUEUE: 'DELETING',
  DELETE_IN_PROGRESS: 'DELETING',
  READY: 'ACTIVE',
});

export const LAB_INSTANCE_IN_USE_STATUS = Object.freeze(['READY']);

export const VM_STATUS = Object.freeze({
  READY: 'READY',
  BUILDING: 'BUILDING',
  UPLOADED: 'UPLOADED',
});

export const VM_PLATFORMS = Object.freeze({
  LINUX: 'Linux',
  WINDOWS: 'Windows',
});

export const COURSE_PARTS = Object.freeze([
  'PDF',
  'Markdown',
  'Lab',
  'Quiz',
  'Video',
]);

export const COURSE_CATEGORIES_IMAGE = Object.freeze({
  'SOC Analyst 1 - Analysis': 'Analysis.jpg',
  'SOC Analyst 1 - Intrusion Detection': 'Intrusion Detection.jpg',
  'SOC Analyst 1 - Incident Management': 'Incident Management.jpg',
  'SOC Analyst 1 - Vulnerability': 'Vulnerability.jpg',
  Custom: 'Custom.jpg',
});

export const CONTENT_IMAGES = [
  'Analysis.jpg',
  'Intrusion Detection.jpg',
  'Incident Management.jpg',
  'Vulnerability.jpg',
  'Custom.jpg',
];

export const QUESTION_MAPPING = {
  'Free Text': 'FREE_TEXT',
  'Multiple Choice': 'MULTIPLE_CHOICE',
  'Single Choice': 'SINGLE_CHOICE',
  Ordering: 'ORDERING',
};

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const EDIT_LAB_STATUS = Object.freeze({
  READY: 'READY',
  IN_BUILD_QUEUE: 'IN_BUILD_QUEUE',
  BUILD_IN_PROGRESS: 'BUILD_IN_PROGRESS',
  EDIT_IN_PROGRESS: 'EDIT_IN_PROGRESS',
  IN_DELETE_QUEUE: 'IN_DELETE_QUEUE',
  DELETE_IN_PROGRESS: 'DELETE_IN_PROGRESS',
});

export const VM_CONNECTION_TYPE = ['VPN', 'VDI'];

export const VM_SIZE = ['SMALL', 'MEDIUM', 'LARGE'];

export const FILE_MIMES = {
  '.yml': { 'text/yaml': ['.yml'] },
  '.tar': { 'application/tar': ['.tar'] },
  '.tar.gz': { 'application/gzip': ['.tar.gz'] },
  '.ova': { 'application/x-virtualbox-ova': ['.ova'] },
  '.pdf': { 'application/pdf': ['.pdf'] },
  '.md': { 'text/markdown': ['.md'] },
};

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};

export const SPECIALTY_COLOR = {
  Intelligence: {
    color: 'rgba(163, 40, 237, 1)',
    backgroundColor: 'rgba(163, 40, 237, 0.25)',
  },
  Protection: {
    color: 'rgba(78, 78, 255, 1)',
    backgroundColor: 'rgba(78, 78, 255, 0.25)',
  },
  Detection: {
    color: 'rgba(200, 73, 164, 1)',
    backgroundColor: 'rgba(200, 73, 164, 0.25)',
  },
  Investigation: {
    color: 'rgba(62, 179, 214, 1)',
    backgroundColor: 'rgba(62, 179, 214, 0.25)',
  },
  Penetration: {
    color: 'rgba(151, 177, 255, 1)',
    backgroundColor: 'rgba(151, 177, 255, 0.25)',
  },
  Engineering: {
    color: 'rgba(164, 184, 209, 1)',
    backgroundColor: 'rgba(164, 184, 209, 0.25)',
  },
};

export const PROFICIENCY_COLOR = {
  Novice: {
    color: 'rgba(118, 209, 165, 1)',
    backgroundColor: 'rgba(118, 209, 165, 0.25)',
  },
  'Advanced Beginner': {
    color: 'rgba(151, 180, 255, 1)',
    backgroundColor: 'rgba(151, 180, 255, 0.25)',
  },
  Competent: {
    color: 'rgba(246, 185, 113, 1)',
    backgroundColor: 'rgba(246, 185, 113, 0.25)',
  },
  Proficient: {
    color: 'rgba(227, 128, 187, 1)',
    backgroundColor: 'rgba(227, 128, 187, 0.25)',
  },
  Expert: {
    color: 'rgba(213, 83, 83, 1)',
    backgroundColor: 'rgba(213, 83, 83, 0.25)',
  },
};

export const RESOURCE_TYPES = [
  { id: 'PDF', name: 'PDF', Icon: FileTextIcon },
  { id: 'MARKDOWN', name: 'Markdown', Icon: FileCode2Icon },
  { id: 'VIDEO', name: 'Video', Icon: VideoIcon },
];

export const COURSE_STATUS = {
  DRAFT: 'DRAFT',
  AVAILABLE: 'AVAILABLE',
  DELETED: 'DELETED',
};
