import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { FdCard, FdTypography, FdChips, FdTab } from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import LessonDrawerHeader from './LessonDrawerHeader';
import LessonIcon from './LessonIcon';
import InfoFdAccordion from './InfoFdAccordion';
import LessonResource from './LessonResource';

const drawerWidth = 600;
const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const LessonDrawer = ({ activeLessonData, openDrawer, openDrawerCallBack }) => {
  const [activeLesson, setActiveLesson] = useState(activeLessonData);
  const { classes } = useStyles();
  useEffect(() => {
    setActiveLesson(activeLessonData);
  }, [activeLessonData]);
  useEffect(() => {
    return () => {
      openDrawerCallBack(false);
    };
  }, [openDrawerCallBack]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Card
        style={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                openDrawerCallBack(false);
              }}
            >
              <CloseIcon style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={activeLesson?.name}
        />
        <CardContent>
          <LessonDrawerHeader activeLesson={activeLesson} />
          <FdTab
            variant="fullWidth"
            label={[
              {
                label: 'Details',
                path: '/tasks',
                index: 0,
                data: (
                  <Box>
                    <FdCard
                      className="m-2"
                      variant="outlined"
                      heading="Description"
                      headingVariant="subtitle1"
                    >
                      <InfoFdAccordion
                        title=" Lesson Description"
                        content={
                          <FdTypography color="secondary" variant="body2">
                            {activeLesson?.description}
                          </FdTypography>
                        }
                      />
                    </FdCard>
                    <FdCard
                      className="m-2 mt-4"
                      variant="outlined"
                      heading="Mapping"
                      headingVariant="subtitle1"
                    >
                      <InfoFdAccordion
                        title={`Skills (${activeLesson?.skills?.length})`}
                        content={
                          <FdChips
                            data={[...new Set(activeLesson?.skills)]?.map(
                              (t) => ({
                                label: `${t?.name} (${t?.alias})`,
                                color: '',
                              }),
                            )}
                          />
                        }
                      />
                      <InfoFdAccordion
                        title={`Techniques (${activeLesson?.techniques?.length})`}
                        content={
                          <FdChips
                            data={[...new Set(activeLesson?.techniques)]?.map(
                              (t) => ({
                                label: t?.label,
                                color: '',
                              }),
                            )}
                          />
                        }
                      />
                      <InfoFdAccordion
                        title={`Technologies (${activeLesson?.technologies?.length})`}
                        content={
                          <FdChips
                            data={[...new Set(activeLesson?.technologies)]?.map(
                              (t) => ({
                                label: t?.label,
                                color: '',
                              }),
                            )}
                          />
                        }
                      />
                    </FdCard>
                  </Box>
                ),
              },
              {
                label: 'Activities',
                path: '/tasks',
                index: 1,
                data: (
                  <Box>
                    <FdCard
                      className="m-2"
                      variant="outlined"
                      heading={
                        <Box className="flex">
                          <FdTypography variant="subtitle1">
                            Exercise
                          </FdTypography>
                          <Box ml={1}>
                            <LessonIcon type={activeLesson?.exercise?.type} />
                          </Box>
                        </Box>
                      }
                    >
                      <InfoFdAccordion
                        title="Exercise Instructions"
                        content={
                          <FdTypography color="secondary" variant="body2">
                            {activeLesson?.exercise?.instructions}
                          </FdTypography>
                        }
                      />
                    </FdCard>
                    <FdCard
                      className="m-2 mt-4"
                      variant="outlined"
                      heading="Resources"
                      headingVariant="subtitle1"
                    >
                      <InfoFdAccordion
                        title={`Included Resources (${activeLesson?.resources?.length})`}
                        content={
                          <FdTypography color="secondary" variant="body2">
                            {activeLesson?.resources?.length ? (
                              <Box className="flex flex-col">
                                {activeLesson?.resources?.map((item) => (
                                  <LessonResource
                                    content={item}
                                    type={item?.type}
                                  />
                                ))}
                              </Box>
                            ) : (
                              'None Added'
                            )}
                          </FdTypography>
                        }
                      />
                    </FdCard>
                    <FdCard
                      className="m-2 mt-4"
                      variant="outlined"
                      heading="Quiz"
                      headingVariant="subtitle1"
                    >
                      <InfoFdAccordion
                        title={`Quiz Questions (${activeLesson?.quiz?.length})`}
                        content={
                          <FdTypography color="secondary" variant="body2">
                            {activeLesson?.quiz?.length ? (
                              <ul
                                key={shortid.generate()}
                                data-cy="skills"
                                style={{
                                  listStyleType: 'decimal',
                                  marginLeft: '16px',
                                }}
                              >
                                {activeLesson?.quiz?.map((q) => (
                                  <li>{`${q?.name} `}</li>
                                ))}
                              </ul>
                            ) : (
                              'None Added'
                            )}
                          </FdTypography>
                        }
                      />
                    </FdCard>
                  </Box>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
    </Drawer>
  );
};

LessonDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeLessonData: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  openDrawerCallBack: PropTypes.func.isRequired,
};

export default LessonDrawer;
