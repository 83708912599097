/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const RenderThumbnailItem = ({
  pageIndex,
  currentPage,
  onJumpToPage,
  renderPageThumbnail,
  renderPageLabel,
}) => (
  <Box
    key={pageIndex}
    width="100%"
    padding="0.5rem"
    style={{
      backgroundColor:
        pageIndex === currentPage ? 'rgba(0, 0, 0, 0.3)' : '#fff',
      cursor: 'pointer',
    }}
  >
    <Box mb="0.5rem" onClick={onJumpToPage}>
      {renderPageThumbnail}
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mx="auto"
      width={100}
    >
      <Box mx="auto">{renderPageLabel}</Box>
    </Box>
  </Box>
);

RenderThumbnailItem.propTypes = {
  onJumpToPage: PropTypes.func.isRequired,
  renderPageLabel: PropTypes.node.isRequired,
  renderPageThumbnail: PropTypes.node.isRequired,
  pageIndex: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default RenderThumbnailItem;
