import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';
import Error from './pages/Error';
import ListVMs from './pages/ListVMs';
import ListLabs from './pages/ListLabs';
import CreateLab from './pages/CreateLab';
import ViewLab from './pages/ViewLab';
import AuthRoute from './routes/AuthRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import ViewCourse from './pages/ViewCourse';
import CreateCourse from './pages/CreateCourse';
import ViewCourseAdmin from './pages/ViewCourseAdmin';
import ListQuizzes from './pages/ListQuizzes';
import ViewQuiz from './pages/ViewQuiz';
import CreateQuiz from './pages/CreateQuiz';
import TestLab from './pages/TestLab';
import ViewCourseLab from './pages/ViewCourseLab';
import DuplicateCourse from './pages/DuplicateCourse';
import ProtectedManageEventsViewInsightsRoute from './routes/ProtectedManageEventsViewInsightsRoute';
import ProtectedManageEventsRoute from './routes/ProtectedManageEventsRoute';
import ProtectedManageContentRoute from './routes/ProtectedManageContentRoute';
import GraphQlClient from './shared/GraphQlClient';
import ViewLessonVideo from './pages/ViewLessonVideo';
import ViewCourseOverview from './pages/ViewCourseOverview';
import ViewCourseChat from './pages/ViewCourseChat';
import ViewCourseEntry from './pages/ViewCourseEntry';
import ViewLessonLab from './pages/ViewLessonLab';
import './labs-fe.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'labs-fe',
  seed: 'labs-fe',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <AppBasePageProvider>
            <BrowserRouter>
              <Switch>
                <ProtectedManageContentRoute
                  path="/labs"
                  component={ListLabs}
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/create"
                  component={CreateLab}
                  formMode="create"
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/editDraft/:labId"
                  component={CreateLab}
                  formMode="edit"
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/view/:labId"
                  component={ViewLab}
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/test-lab/:labId"
                  component={TestLab}
                  exact
                />
                <ProtectedManageEventsRoute
                  path="/labs/view-course-lab/:partId/:courseUserId"
                  component={ViewCourseLab}
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/vms"
                  component={ListVMs}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/view/:courseUserId/:lessonOrderId?"
                  component={ViewCourse}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/overview/:courseUserId"
                  component={ViewCourseOverview}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/chat/:courseUserId"
                  component={ViewCourseChat}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/entry/:courseUserId"
                  component={ViewCourseEntry}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/video-view/:courseUserId/:lessonId/:resourceId?"
                  component={ViewLessonVideo}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/course-resource-video-view/:courseUserId/:courseResourceId"
                  component={ViewLessonVideo}
                  exact
                />
                <AuthRoute
                  path="/labs/courses/course-lab-view/:courseUserId/:lessonId/:vmId?"
                  component={ViewLessonLab}
                  exact
                />
                <ProtectedManageEventsViewInsightsRoute
                  path="/labs/courses/view-admin/:courseId"
                  component={ViewCourseAdmin}
                  exact
                />
                <ProtectedManageEventsRoute
                  path="/labs/courses/duplicate/:courseIdToDuplicate"
                  component={DuplicateCourse}
                  exact
                />
                <ProtectedManageEventsRoute
                  path="/labs/courses/create"
                  component={CreateCourse}
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/quizzes"
                  component={ListQuizzes}
                  exact
                />
                <ProtectedManageContentRoute
                  path="/labs/quizzes/view/:quizId"
                  component={ViewQuiz}
                />
                <ProtectedManageContentRoute
                  path="/labs/quizzes/create"
                  component={CreateQuiz}
                />
                <Route path="/labs/error" component={Error} />
                <Redirect to="/landing" />
              </Switch>
            </BrowserRouter>
          </AppBasePageProvider>
        </StylesProvider>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
