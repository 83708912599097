import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import _ from 'lodash';
import { FdModal, FdTypography, FdCard } from '@fifthdomain/fe-shared';
import LessonIcon from './LessonIcon';
import VideoPlayer from '../../../components/Courses/LessonView/VideoPlayer';

const VimeoPLayer = ({ openPlayer, showCloseAction, content }) => {
  return (
    <FdModal
      size="md"
      title={
        <Box className="flex">
          <LessonIcon type={'Video'} noLabel />
          <Box ml={1}>
            <FdTypography>{content?.name}</FdTypography>
          </Box>
        </Box>
      }
      showCloseIcon
      showDismiss={false}
      showConfirm={false}
      showCloseAction={() => {
        showCloseAction();
      }}
      open={openPlayer}
    >
      <FdCard variant="outlined">
        <VideoPlayer url={content?.url} />
      </FdCard>
    </FdModal>
  );
};

VimeoPLayer.propTypes = {
  finalStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showCloseAction: PropTypes.func.isRequired,
};

export default VimeoPLayer;
