import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme, alpha, Divider } from '@mui/material';
import { CheckIcon, DotIcon, InfoIcon, PresentationIcon } from 'lucide-react';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import ResourceTypeIcon from './ResourceTypeIcon';
import {
  PROFICIENCY_COLOR,
  PROFICIENCY_LEVELS,
  SPECIALTY_COLOR,
} from '../../../constants';

const Chip = ({ label, bgColor, color }) => {
  return (
    <Box
      className="flex-shrink px-2 py-1 rounded"
      sx={{ backgroundColor: bgColor }}
    >
      <FdTypography
        variant="captiontext1"
        className="flex-shrink font-bold text-center"
        style={{ color }}
      >
        {label}
      </FdTypography>
    </Box>
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const LessonNavigationCard = ({ lesson, onClick, selected, status }) => {
  const theme = useTheme();
  const {
    orderId,
    name,
    description,
    estimatedTime,
    proficiencyLevel,
    specialtyMapping,
    exercise,
    resources,
    quiz,
  } = lesson;
  const proficiency = PROFICIENCY_LEVELS[proficiencyLevel];
  const proficiencyColor = PROFICIENCY_COLOR[proficiency]?.color || '';
  const specialty = specialtyMapping?.specialty?.specialtyName || '';
  const specialtyColor =
    SPECIALTY_COLOR[specialty]?.color || 'rgba(0, 0, 0, 0)';
  const lessonType = exercise;
  const isCompleted = status === 'FINISHED';
  const noOfResources = resources?.length || 0;
  const noOfQuizQuestions = quiz?.questions?.length || 0;

  return (
    <Box
      className="px-4 py-1 rounded-lg overflow-hidden"
      sx={{
        backgroundColor: isCompleted
          ? 'rgba(53, 195, 161, 0.05)'
          : theme.palette.background.paper,
        border: selected
          ? `1px solid ${isCompleted ? 'rgba(53, 195, 161, 1)' : theme.palette.primary.main}`
          : 'none',
      }}
    >
      <Box className="flex flex-col items-center">
        <Box className="flex items-center justify-between w-full my-1">
          <Box className="flex items-center gap-x-3 mr-1" minWidth={200}>
            <Chip
              label={proficiency}
              bgColor={alpha(proficiencyColor, 0.25)}
              color={proficiencyColor}
            />
            <Chip
              label={specialty}
              bgColor={alpha(specialtyColor, 0.25)}
              color={specialtyColor}
            />
            <Chip
              label={`${estimatedTime}m`}
              bgColor="rgba(235, 242, 255, 0.25)"
              color="white"
            />
          </Box>
          <Box className="flex items-center gap-x-2">
            <FdTooltip title={description}>
              <Box
                className="flex items-center justify-center rounded p-1"
                sx={{
                  backgroundColor: 'rgba(110, 132, 255, 0.1)',
                  color: theme.palette.primary.main,
                }}
              >
                <InfoIcon />
              </Box>
            </FdTooltip>
            {isCompleted ? (
              <Box className="flex items-center justify-center rounded-full w-5 h-5 bg-green-400 text-black">
                <CheckIcon size={17} className="ml-[1px] mt-[1px]" />
              </Box>
            ) : (
              <Box
                className="flex items-center justify-center rounded-full bg-transparent"
                style={{
                  border: `2px solid ${theme.palette.primary.main}`,
                  width: 22,
                  height: 22,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className="flex items-center gap-x-1 my-2">
        <Box className="flex items-center w-18">
          <PresentationIcon size={16} className="mr-1" />
          <FdTypography variant="subtitle2">{orderId}</FdTypography>
          <DotIcon />
        </Box>
        <Box component="button" onClick={onClick}>
          <FdTypography
            variant="subtitle2"
            className="font-bold truncate underline"
            color="primary"
            style={{ color: theme.palette.primary.main }}
          >
            {name}
          </FdTypography>
        </Box>
      </Box>
      <Divider />
      <Box className="flex items-center gap-x-4 mt-3 mb-2">
        <ResourceTypeIcon type={lessonType} />
        <FdTypography variant="captiontext1" color="secondary">
          {`${noOfResources} Resource${noOfResources === 1 ? '' : 's'} • ${noOfQuizQuestions} Quiz Question${noOfQuizQuestions === 1 ? '' : 's'}`}
        </FdTypography>
      </Box>
    </Box>
  );
};

LessonNavigationCard.propTypes = {
  lesson: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    estimatedTime: PropTypes.string,
    proficiencyLevel: PropTypes.string,
    specialtyMapping: PropTypes.shape({
      specialty: PropTypes.shape({
        specialtyName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    exercise: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.shape({})),
    quiz: PropTypes.shape({
      questions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default LessonNavigationCard;
