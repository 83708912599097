import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import _ from 'lodash';
import { FdModal, FdTypography, FdCard } from '@fifthdomain/fe-shared';
import LessonIcon from './LessonIcon';

const VideoPlayer = ({ openPlayer, showCloseAction, content }) => {
  return (
    <FdModal
      size="md"
      title={
        <Box className="flex">
          <LessonIcon type={'Video'} noLabel />
          <Box ml={1}>
            <FdTypography>{content?.name}</FdTypography>
          </Box>
        </Box>
      }
      showCloseIcon
      showDismiss={false}
      showConfirm={false}
      showCloseAction={() => {
        showCloseAction();
      }}
      open={openPlayer}
    >
      <FdCard variant="outlined">
        <div
          style={{ maxWidth: '80%', marginTop: '16px' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content?.url,
          }}
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </FdCard>
    </FdModal>
  );
};

VideoPlayer.propTypes = {
  finalStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showCloseAction: PropTypes.func.isRequired,
};

export default VideoPlayer;
