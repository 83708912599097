import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import {
  LightbulbIcon,
  CheckIcon,
  XIcon,
  PresentationIcon,
} from 'lucide-react';
import {
  FdModal,
  FdTypography,
  FdCard,
  FdChip,
  FdChips,
} from '@fifthdomain/fe-shared';
import InfoFdAccordion from './InfoFdAccordion';
import { getCommaSeparated } from '../../../shared/utils/stringUtils';

const FinaliseModal = ({
  finalStep,
  showCloseAction,
  onDismiss,
  onConfirm,
  loading,
}) => {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const { name, description, availability, lessons, groupData, userData } =
    getValues();

  const courseEstimatedTime = lessons?.reduce(
    (acc, curr) => acc + curr?.estimatedSolveTime,
    0,
  );

  return (
    <FdModal
      size="lg"
      title="Great! This course is almost ready."
      description="You can finish creating this course now or save it as a draft to complete later. If the course has been marked as Available, invited participants will be able to access it."
      confirm="publish"
      disableConfirm={loading}
      dismiss="Save as draft"
      disableDismiss={loading}
      showCloseIcon
      showCloseAction={() => {
        showCloseAction();
      }}
      open={finalStep}
      onConfirm={() => {
        onConfirm();
      }}
      onDismiss={() => {
        onDismiss();
      }}
    >
      <FdCard
        className="mt-6"
        variant="outlined"
        heading={
          <Box className="flex items-center gap-x-2 mb-2">
            <LightbulbIcon className="size-6 mr-1 mt-1" />
            <FdTypography variant="subtitle1">{name}</FdTypography>
            <FdChip
              rounded={false}
              label={`Est. Time: ${courseEstimatedTime} Mins`}
              size="small"
              style={{
                backgroundColor: theme?.fdProColors?.electricBlue[50],
              }}
            />
          </Box>
        }
      >
        <InfoFdAccordion
          title="Course Description"
          content={
            <>
              <Box mb={3}>
                <FdTypography color="secondary" variant="body2">
                  {description}
                </FdTypography>
              </Box>
              <FdTypography color="secondary" variant="subtitle2">
                Availability
              </FdTypography>
              <Box mt={1.5}>
                <FdChip
                  rounded={false}
                  label={
                    <Box display="flex">
                      {availability ? (
                        <CheckIcon className="size-5 mr-2" />
                      ) : (
                        <XIcon className="size-5 mr-2" />
                      )}
                      Available to Invited Users
                    </Box>
                  }
                  size="small"
                  style={{
                    backgroundColor: theme?.fdProColors?.electricBlue[50],
                  }}
                />
              </Box>
            </>
          }
        />
      </FdCard>
      <FdCard
        className="mt-6"
        variant="outlined"
        heading={
          <Box className="flex items-center gap-x-2 mb-2">
            <PresentationIcon className="size-6 ml-2 mt-1" />
            <FdTypography variant="subtitle1">Lessons</FdTypography>
          </Box>
        }
      >
        <InfoFdAccordion
          title={`Included Lessons (${lessons?.length}) `}
          content={
            <>
              {lessons?.map((l) => (
                <Box className="flex mb-3">
                  <PresentationIcon className="size-5 ml-2 mr-2" />
                  <FdTypography variant="body2">{l?.name}</FdTypography>
                </Box>
              ))}
            </>
          }
        />
      </FdCard>
      <FdCard
        className="mt-6"
        variant="outlined"
        heading="Invited Users"
        headingVariant="subtitle1"
      >
        <InfoFdAccordion
          title={`Invited Users (${userData?.length})`}
          content={
            userData?.length ? (
              <FdChips
                data={[...new Set(userData)]?.map((t) => ({
                  label: t?.userEmail,
                  color: '',
                }))}
              />
            ) : (
              <FdTypography variant="body2">None added</FdTypography>
            )
          }
        />
        <InfoFdAccordion
          title={`Invited Groups  (${groupData?.length})`}
          content={
            <Box className="flex">
              <FdTypography variant="body2">
                {groupData?.length
                  ? getCommaSeparated(groupData)
                  : 'None added'}
              </FdTypography>
            </Box>
          }
        />
      </FdCard>
    </FdModal>
  );
};

FinaliseModal.propTypes = {
  finalStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showCloseAction: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default FinaliseModal;
