import React from 'react';
import PropTypes from 'prop-types';
import { Box, lighten, useTheme } from '@mui/material';

const ChatIndicator = ({ noAnimate }) => {
  const theme = useTheme();
  const iterations = noAnimate ? 1 : 10;

  return (
    <Box className="absolute left-[-18px] h-2.5 w-2.5 rounded-full">
      <span
        className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 [animation-duration:1s] [animation-iteration-count:${iterations}]`}
        style={{
          backgroundColor: lighten(theme.palette.primary.light, 0.9),
        }}
      />
      <span
        className="absolute inline-flex h-full w-full rounded-full transition-colors"
        style={{ backgroundColor: theme.palette.primary.main }}
      />
    </Box>
  );
};

ChatIndicator.propTypes = {
  noAnimate: PropTypes.bool,
};

ChatIndicator.defaultProps = {
  noAnimate: false,
};

export default ChatIndicator;
