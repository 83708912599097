import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronUpIcon } from 'lucide-react';
import { Box, useTheme } from '@mui/material';
import { FdTooltip } from '@fifthdomain/fe-shared';

const NavigationDrawer = ({ parentRef, defaultOpen, children }) => {
  const [isMinimized, setIsMinimized] = useState(true);
  const [parentWidth, setParentWidth] = useState('100%');
  const [parentLeft, setParentLeft] = useState(0);
  const theme = useTheme();
  const observerRef = useRef(null);

  useEffect(() => {
    setIsMinimized(!defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (!parentRef?.current) return undefined;
    const updatePosition = () => {
      setParentWidth(`${parentRef.current.offsetWidth + 20}px`);
      setParentLeft(`${parentRef.current.getBoundingClientRect().left - 10}px`);
    };
    observerRef.current = new ResizeObserver(updatePosition);
    observerRef.current.observe(parentRef.current);
    updatePosition();
    return () => observerRef.current?.disconnect();
  }, [parentRef]);

  return (
    <Box
      className={`
      fixed bottom-0 w-full shadow-lg transition-transform duration-1000 z-50
       ${isMinimized ? 'translate-y-[calc(100%-20px)]' : 'translate-y-0'}
    `}
      style={{
        width: parentWidth,
        height: isMinimized ? '20px' : '220px',
        left: parentLeft,
      }}
      sx={{
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box
        className="absolute -top-7 left-1/2 transform -translate-x-1/2 px-3 pt-2 rounded-t-xl shadow-md"
        onClick={() => setIsMinimized(!isMinimized)}
        sx={{
          backgroundColor: theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.divider}`,
          borderLeft: `1px solid ${theme.palette.divider}`,
          borderRight: `1px solid ${theme.palette.divider}`,
        }}
      >
        <FdTooltip title="Lesson Navigation">
          <span>
            <Box
              className="flex items-center justify-center h-5 w-20 rounded hover:animate-bounce"
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                animationDelay: '1s',
              }}
            >
              <ChevronUpIcon
                size={20}
                className={`transition-transform duration-1000 ${
                  isMinimized ? 'rotate-0' : 'rotate-180'
                }`}
              />
            </Box>
          </span>
        </FdTooltip>
      </Box>
      <Box py={3} px={2}>
        {children}
      </Box>
    </Box>
  );
};

NavigationDrawer.propTypes = {
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape({}) }),
  ]).isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
};

NavigationDrawer.defaultProps = {
  defaultOpen: false,
};

export default NavigationDrawer;
