import React, { createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Guacamole from 'guacamole-common-js';
import { ExternalLinkIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { FdTooltip } from '@fifthdomain/fe-shared';
import Controls from './Controls';
import ConnectionStatus from './ConnectionStatus';

const VdiConsole = ({
  token,
  width,
  height,
  lessonId,
  hideNewTabButton,
  vmId,
}) => {
  const [clientConnected, setClientConnected] = useState(false);
  const [clientStatus, setClientStatus] = useState(0);
  const [tunnelStatus, setTunnelStatus] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [client, setClient] = useState(undefined);
  const [keyboard, setKeyboard] = useState(undefined);
  const [showControls, setShowControls] = useState(false);
  const [autoScaling, setAutoScaling] = useState(false);
  const [clipboardData, setClipboardData] = useState('');
  const { courseUserId } = useParams();
  const vdiDisplayRef = createRef();
  const containerRef = createRef();

  const destroyDisplay = useCallback(() => {
    if (vdiDisplayRef?.current?.children[0]) {
      vdiDisplayRef.current.removeChild(vdiDisplayRef.current.children[0]);
    }
  }, [vdiDisplayRef]);

  const pasteClipboard = useCallback(
    (data) => {
      if (!data || typeof data !== 'string' || !client) {
        return;
      }
      if (showControls) {
        setShowControls(false);
      }
      document.activeElement?.blur();

      try {
        const stream = client.createClipboardStream('text/plain');
        const writer = new Guacamole.StringWriter(stream);
        const CHUNK_SIZE = 4096;
        for (let i = 0; i < data.length; i += CHUNK_SIZE) {
          const chunk = data.substring(
            i,
            Math.min(i + CHUNK_SIZE, data.length),
          );
          writer.sendText(chunk);
        }
        writer.sendEnd();
      } catch {
        console.error('Failed to paste clipboard data:', error);
      }
    },
    [client, showControls, setShowControls, error],
  );

  const onError = useCallback(
    (_error) => {
      setClientConnected(false);
      setError(true);
      setErrorMessage(_error);
      console.log(_error);
      destroyDisplay();
    },
    [setClientConnected, setError, setErrorMessage, destroyDisplay],
  );

  const handleClipboardChange = (stream, mimetype) => {
    if (mimetype === 'text/plain') {
      stream.onblob = (data) => {
        setClipboardData(atob(data));
      };
    } else {
      console.log('Unknown mime type:', mimetype);
    }
  };

  const typeClipboard = (str) => {
    if (showControls) setShowControls(false);
    document.activeElement.blur();
    keyboard.type(str);
  };

  const resizeClient = useCallback(() => {
    const display = client?.getDisplay();
    if (!display) return;

    const displayElement = display.getElement();
    const displayWidth = display.getWidth();
    const displayHeight = display.getHeight();

    if (displayWidth && displayHeight) {
      // Set container and display to fixed width/height from props
      displayElement.style.width = `${width}px`;
      displayElement.style.height = `${height}px`;

      // Scale to fit the fixed dimensions
      const scaleX = width / displayWidth;
      const scaleY = height / displayHeight;
      const scale = Math.min(scaleX, scaleY);
      display.scale(scale);
    }
  }, [client, width, height]);

  const onClientStateChange = (state) => {
    setClientStatus(state);
    switch (state) {
      case 1: // Connecting
        setClientConnected(false);
        break;
      case 3: // Connected
        setClientConnected(true);
        // hack to get autoscaling to work
        setTimeout(() => {
          setAutoScaling(true);
        }, 50);
        break;
      case 5: // Disconnected
        setClientConnected(false);
        break;
      default:
        break;
    }
  };

  const onTunnelStateChange = (state) => {
    setTunnelStatus(state);
  };

  const setupVDI = (consoleDisplay) => {
    try {
      console.log('Connecting tunnel...');
      const vdiHost =
        window.location.hostname === 'app.fifthdomain.pro' ? 'prod' : 'test';
      const _tunnel = new Guacamole.WebSocketTunnel(
        `wss://${vdiHost}.vdi.fifthdomain.pro/vdi`,
      );

      const _client = new Guacamole.Client(_tunnel);
      setClient(_client);

      _client.onstatechange = (state) => onClientStateChange(state);
      _tunnel.onstatechange = (state) => onTunnelStateChange(state);
      _client.onerror = () => onError('Client Error');
      _tunnel.onerror = () => onError('Connection Failed');

      consoleDisplay.appendChild(_client.getDisplay().getElement());
      _client.getDisplay().getElement().className = 'fifthdomain-vdi';
      _client.connect(`token=${token}`);

      // On clipboard copy events
      _client.onclipboard = (stream, mimetype) =>
        handleClipboardChange(stream, mimetype);

      // Setup Mouse events
      const _mouse = new Guacamole.Mouse(_client.getDisplay().getElement());
      const handleMouse = (mouseState) => {
        const scaling = _client.getDisplay().getScale();
        const scaledMouse = {
          ...mouseState,
          x: mouseState.x / scaling,
          y: mouseState.y / scaling,
        };
        _client.sendMouseState(scaledMouse);
      };
      _mouse.onmousedown = handleMouse;
      _mouse.onmousemove = handleMouse;

      // Setup Keyboard
      const inputSink = new Guacamole.InputSink();
      const _keyboard = new Guacamole.Keyboard();
      document.body.appendChild(inputSink.getElement());
      _keyboard.listenTo(inputSink.getElement());
      _keyboard.onkeydown = (keysym) => _client.sendKeyEvent(1, keysym);
      _keyboard.onkeyup = (keysym) => _client.sendKeyEvent(0, keysym);
      setKeyboard(_keyboard);
      _client.getDisplay().scale(1);
    } catch (e) {
      onError(e);
    }
  };

  const handleKeyPress = useCallback((e) => {
    // Toggle Clipboard drawer
    if (e.shiftKey && e.altKey && e.ctrlKey) {
      setShowControls((state) => !state);
    }
  }, []);

  // Listen for key events
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    // Setup WMKS on mount
    setupVDI(vdiDisplayRef.current);
  }, []);

  useEffect(() => {
    if (clientConnected && autoScaling) {
      resizeClient();
    }
  }, [clientConnected, autoScaling, resizeClient, width, height]);

  useEffect(() => {
    // Tunnel disconnected and client is waiting
    if (tunnelStatus === 2 && clientStatus === 2) {
      onError('Unable to establish connection');
    }
    // Client disconnected
    else if (tunnelStatus === 2 && clientStatus === 5) {
      onError('Disconnected');
    }
  }, [tunnelStatus, clientStatus, onError]);

  return (
    <Box ref={containerRef} className="relative">
      {!hideNewTabButton && (
        <FdTooltip title="Open in New Tab">
          <IconButton
            className="absolute top-2 right-2 z-50 animate-ping [animation-iteration-count:1_!important] transition-all duration-300"
            onClick={() =>
              window.open(
                `/labs/courses/course-lab-view/${courseUserId}/${lessonId}/${vmId}`,
                '_blank',
              )
            }
          >
            <ExternalLinkIcon />
          </IconButton>
        </FdTooltip>
      )}
      <ConnectionStatus
        clientConnected={clientConnected}
        error={error}
        errorMessage={errorMessage}
      />
      <Box
        id="fifthdomain-vdi"
        ref={vdiDisplayRef}
        role="application"
        className="fifthdomain-vdi flex items-center justify-center"
        onClick={() => {
          if (showControls) setShowControls(false);
        }}
        sx={{ width: '100%', height: '100%' }}
      />
      <Controls
        typeClipboard={typeClipboard}
        setShowControls={setShowControls}
        pasteClipboard={pasteClipboard}
        showControls={showControls}
        clipboardData={clipboardData}
      />
    </Box>
  );
};

VdiConsole.propTypes = {
  token: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hideNewTabButton: PropTypes.bool,
  lessonId: PropTypes.string,
  vmId: PropTypes.string,
};

VdiConsole.defaultProps = {
  hideNewTabButton: false,
  lessonId: null,
  vmId: '',
};

export default VdiConsole;
