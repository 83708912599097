import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  useFormContext,
  Controller,
  useWatch,
  useFieldArray,
} from 'react-hook-form';
import { FdTextField, FdTypography } from '@fifthdomain/fe-shared';
import useDragDrop from '../../hooks/useDragDrop';

const ChoiceCard = ({ qIdx, cIdx, deleteChoice, swapChoice, userGraded }) => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const watchChoice = useWatch({
    control,
    name: `questions[${qIdx}].multipleChoices[${cIdx}]`,
  });

  const questionOptions = useWatch({
    control,
    name: `questions[${qIdx}].multipleChoices`,
  });

  const { append: appendDeletedChoice } = useFieldArray({
    control,
    name: `questions[${qIdx}].deletedChoices`,
  });

  const [showSelect, setShowSelect] = useState(null);
  const ref = useRef(null);
  const moveCard = (dragIdx, hoverIdx) => {
    const sourceOrder = getValues(
      `questions[${qIdx}].multipleChoices[${dragIdx}].order`,
    );
    const destinationOrder = getValues(
      `questions[${qIdx}].multipleChoices[${hoverIdx}].order`,
    );
    // interchange order
    if (sourceOrder && destinationOrder && sourceOrder !== destinationOrder) {
      setValue(
        `questions[${qIdx}].multipleChoices[${hoverIdx}].order`,
        sourceOrder,
      );
      setValue(
        `questions[${qIdx}].multipleChoices[${dragIdx}].order`,
        destinationOrder,
      );
      swapChoice(dragIdx, hoverIdx);
    }
  };
  const { handlerId, isDragging, drag, drop } = useDragDrop({
    moveCard,
    ref,
    dragIdx: watchChoice?.id,
    dropIdx: cIdx,
  });
  const handleOpenSelect = (e) => {
    e.stopPropagation();
    setShowSelect(e.currentTarget);
  };
  const handleCloseSelect = () => {
    setShowSelect(null);
  };

  const isSingleChoiceType =
    getValues(`questions[${qIdx}].questionType`) === 'Single Choice';

  const opacity = isDragging ? 0.5 : 1;
  const menuText = watchChoice?.correctAnswer
    ? 'Unmark as Correct'
    : isSingleChoiceType &&
        questionOptions?.some((choice) => choice?.correctAnswer)
      ? ''
      : 'Mark as Correct';

  drag(drop(ref));
  const isOrderingType =
    getValues(`questions[${qIdx}].questionType`) === 'Ordering';

  return (
    <Box
      mb={2}
      display="flex"
      alignItems="center"
      width="100%"
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Box mr={2} style={{ color: '#000', cursor: 'pointer' }}>
        <DragIndicatorIcon />
      </Box>
      {watchChoice?.correctAnswer && userGraded && (
        <Box mr={2}>
          <CheckCircleIcon />
        </Box>
      )}
      <Box mr={2} width="100%">
        <Controller
          control={control}
          name={`questions[${qIdx}].multipleChoices[${cIdx}].answer`}
          render={({
            field: { ref: refTxt, ...rest },
            fieldState: { error },
          }) => (
            <Box>
              <FdTextField
                id={`questions[${qIdx}].multipleChoices[${cIdx}].answer`}
                required
                fullWidth
                error={error}
                helperText={error && error.message}
                placeholder={`Answer option ${cIdx + 1}`}
                data-cy={`questions[${qIdx}].multipleChoices[${cIdx}].answer`}
                {...rest}
                inputRef={refTxt}
              />
            </Box>
          )}
        />
      </Box>
      <Box
        p={1}
        style={{ cursor: 'pointer', color: '#000' }}
        onClick={handleOpenSelect}
      >
        <MoreVertIcon />
      </Box>
      <Menu
        anchorEl={showSelect}
        open={Boolean(showSelect)}
        onClose={handleCloseSelect}
      >
        {!isOrderingType && userGraded && menuText ? (
          <MenuItem
            onClick={() => {
              setValue(
                `questions[${qIdx}].multipleChoices[${cIdx}].correctAnswer`,
                !watchChoice?.correctAnswer,
              );
              setShowSelect(false);
              trigger(`questions[${qIdx}].multipleChoices`);
            }}
          >
            <FdTypography>{menuText}</FdTypography>
          </MenuItem>
        ) : (
          ''
        )}
        <MenuItem
          onClick={() => {
            if (watchChoice.choiceId) {
              appendDeletedChoice(watchChoice.choiceId);
            }
            deleteChoice(cIdx);
          }}
        >
          <FdTypography>Delete</FdTypography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

ChoiceCard.propTypes = {
  qIdx: PropTypes.number.isRequired,
  cIdx: PropTypes.number.isRequired,
  deleteChoice: PropTypes.func.isRequired,
  swapChoice: PropTypes.func.isRequired,
  userGraded: PropTypes.bool,
};

ChoiceCard.defaultProps = {
  userGraded: true,
};

export default ChoiceCard;
