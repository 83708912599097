import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  FdAccordion,
  FdSelect,
  FdButton,
  FdTypography,
  FdAlert,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import FreeTextQuestion from './FreeTextQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import FdBoxButton from '../FdBoxButton';
import { successToastMessage } from '../../shared/utils/toast';
import { QUESTION_MAPPING } from '../../constants';

const AddQuizQuestions = ({ isEdit, userGraded }) => {
  const questionTypes = Object.keys(QUESTION_MAPPING);

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const {
    fields: questions,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: 'questions',
  });

  const { append: appendDeletedQuestion } = useFieldArray({
    control,
    name: 'deletedQuestions',
  });

  const noQuestionError = errors?.questions?.message;
  const questionErrors = errors?.questions?.length;

  return (
    <Box>
      {noQuestionError && questions.length === 0 && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message={noQuestionError} />
        </Box>
      )}
      {questionErrors && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message="There are errors in questions" />
        </Box>
      )}
      {questions.map((question, qIdx) => (
        <FdAccordion
          key={question.id}
          summary={() => (
            <Box display="flex" justifyContent="space-between" width="100%">
              <FdTypography variant="h3">
                {`Question #${qIdx + 1}`}
              </FdTypography>
            </Box>
          )}
          content={() => (
            <Controller
              control={control}
              name={`questions[${qIdx}].questionType`}
              render={({ field: { value: fieldValue } }) => (
                <Box>
                  {fieldValue === questionTypes[0] && (
                    <FreeTextQuestion
                      isEdit={isEdit}
                      qIdx={qIdx}
                      userGraded={userGraded}
                    />
                  )}
                  {fieldValue && fieldValue !== questionTypes[0] && (
                    <MultipleChoiceQuestion
                      isEdit={isEdit}
                      qIdx={qIdx}
                      userGraded={userGraded}
                    />
                  )}
                </Box>
              )}
            />
          )}
          actions={
            <Box display="flex" alignItems="center">
              <Controller
                control={control}
                name={`questions[${qIdx}].questionType`}
                render={({
                  field: { id, ref, value: fieldValue, ...rest },
                  fieldState: { error },
                }) => (
                  <FdDelayed>
                    <Box width="300px">
                      <FdSelect
                        key={id}
                        id={`questions[${qIdx}].questionType`}
                        label=""
                        options={questionTypes}
                        defaultSelected={fieldValue}
                        fullWidth
                        error={error}
                        helperText={error && error.message}
                        data-cy={`questions[${qIdx}].questionType`}
                        placeholder="Select Question Type"
                        {...rest}
                        inputRef={ref}
                      />
                    </Box>
                  </FdDelayed>
                )}
              />
              <Box ml={1}>
                <FdButton
                  variant="secondary"
                  onClick={async (e) => {
                    e.preventDefault();
                    const questionId = getValues(
                      `questions[${qIdx}].questionId`,
                    );
                    // mark to delete question via api
                    if (questionId) {
                      appendDeletedQuestion(questionId);
                    }
                    removeQuestion(qIdx);
                    successToastMessage('Success! Question deleted');
                  }}
                  disabled={false}
                >
                  Delete
                </FdButton>
              </Box>
            </Box>
          }
          showToggleButton={false}
          startAdornment
        />
      ))}
      <Box mb={2}>
        <FdBoxButton
          onClick={(e) => {
            e.preventDefault();
            appendQuestion({ order: questions.length + 1 });
          }}
        >
          ADD QUESTION
        </FdBoxButton>
      </Box>
    </Box>
  );
};

AddQuizQuestions.propTypes = {
  isEdit: PropTypes.bool,
  userGraded: PropTypes.bool,
};

AddQuizQuestions.defaultProps = {
  isEdit: false,
  userGraded: true,
};

export default AddQuizQuestions;
