import ReactDom from 'react-dom';

let createRoot;
let hydrateRoot;

if (process.env.NODE_ENV === 'production') {
  createRoot = ReactDom.createRoot;
  hydrateRoot = ReactDom.hydrateRoot;
} else {
  const i = ReactDom.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
  createRoot = function (c, o) {
    i.usingClientEntryPoint = true;
    try {
      return ReactDom.createRoot(c, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };
  hydrateRoot = function (c, h, o) {
    i.usingClientEntryPoint = true;
    try {
      return ReactDom.hydrateRoot(c, h, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };
}

export { createRoot, hydrateRoot };
