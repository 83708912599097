import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { FdTextField, FdCard, FdCheckbox } from '@fifthdomain/fe-shared';
import FdTextView from '../FdTextView';

const QuizDetails = ({
  viewMode,
  ActionButtons,
  summary,
  changeGradedState,
}) => {
  const { control, getValues } = useFormContext();

  const { quizName, quizDescription } = getValues();
  return (
    <Box>
      <FdCard
        variant="outlined"
        heading="Quiz Details"
        data-cy="quiz-details"
        summary={
          <Box display="flex" alignItems="center">
            {summary}
            {ActionButtons ? <ActionButtons /> : null}
          </Box>
        }
      >
        <Box mt={2}>
          <Box>
            {!viewMode ? (
              <Controller
                control={control}
                name="quizName"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdTextField
                    id="quizName"
                    label="Quiz Name (Quiz name must be 25 characters or less)"
                    placeholder="Quiz name must be 25 characters or less"
                    fullWidth
                    error={error}
                    required
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
            ) : (
              <FdTextView label="Quiz Name" value={quizName} />
            )}
          </Box>
          <Box mt={2}>
            {!viewMode ? (
              <Controller
                control={control}
                name="quizDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdTextField
                    id="quizDescription"
                    label="Quiz Description"
                    placeholder="Quiz description must be 80 characters or less"
                    fullWidth
                    multiline
                    rows={3}
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
            ) : (
              <FdTextView label="Description" value={quizDescription} />
            )}
          </Box>
          <Box mt={2} mb={2}>
            <Controller
              control={control}
              name="quizTimeLimit"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FdTextField
                  id="quizTimeLimit"
                  label="Quiz Time Limit (mins)"
                  type="number"
                  width="250px"
                  error={error}
                  helperText={error && error.message}
                  disabled={viewMode}
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="allowMultipleAttempts"
              render={({
                field: { ref, value: fieldValue, ...rest },
                fieldState: { error },
              }) => (
                <FdCheckbox
                  id="allowMultipleAttempts"
                  label="Allow multiple attempts"
                  value={fieldValue.value}
                  checked={fieldValue}
                  error={error && error.message}
                  disabled={viewMode}
                  data-cy="availability"
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="returnResultsToUsers"
              render={({
                field: { ref, value: fieldValue, ...rest },
                fieldState: { error },
              }) => (
                <FdCheckbox
                  id="returnResultsToUsers"
                  label="Return results to Users on completion"
                  value={fieldValue.value}
                  checked={fieldValue}
                  error={error && error.message}
                  disabled={viewMode}
                  data-cy="availability"
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="notGradeUsers"
              render={({
                field: {
                  ref,
                  value: fieldValue,
                  onChange: fieldOnChange,
                  ...rest
                },
                fieldState: { error },
              }) => (
                <FdCheckbox
                  id="notGradeUsers"
                  label="Make quiz non-graded. (Questions will not have points or correct answers assigned)"
                  value={fieldValue.value}
                  checked={fieldValue}
                  error={error && error.message}
                  disabled={!!ActionButtons}
                  data-cy="noGrade"
                  {...rest}
                  inputRef={ref}
                  onChange={(val) => {
                    changeGradedState(!val);
                    fieldOnChange(val);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </FdCard>
    </Box>
  );
};

QuizDetails.propTypes = {
  ActionButtons: PropTypes.node,
  summary: PropTypes.node,
  viewMode: PropTypes.bool,
  changeGradedState: PropTypes.func.isRequired,
};

QuizDetails.defaultProps = {
  ActionButtons: undefined,
  summary: null,
  viewMode: false,
};

export default QuizDetails;
