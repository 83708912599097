import React from 'react';
import PropTypes from 'prop-types';
import {
  globalStore,
  useSnapshot,
  FdLogo,
  S3Image,
} from '@fifthdomain/fe-shared';

function ChatLogo({ logo, isWhiteLabelled }) {
  const { logoS3ImageUrl } = useSnapshot(globalStore);

  if (logo && isWhiteLabelled)
    return <S3Image image={logo} s3ImageUrl={logoS3ImageUrl} />;
  return <FdLogo isIcon />;
}

ChatLogo.propTypes = {
  logo: PropTypes.string,
  isWhiteLabelled: PropTypes.bool.isRequired,
};

ChatLogo.defaultProps = {
  logo: '',
};

export default ChatLogo;
