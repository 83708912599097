import Yup from '../Common';

export const initialValues = {
  courseId: '',
  courseName: '',
  lessons: [],
  selectedLessonOrderId: 1,
};

export const validationSchema = Yup.object().shape({
  courseId: Yup.string(),
  courseName: Yup.string(),
  selectedLessonOrderId: Yup.string(),
  lessons: Yup.array().of(
    Yup.object().shape({
      idx: Yup.number(),
      orderId: Yup.number(),
      lessonId: Yup.string().nullable(),
      completedStatus: Yup.boolean().default(false),
      name: Yup.string(),
      description: Yup.string(),
      estimatedTime: Yup.number(),
      proficiencyLevel: Yup.string(),
      exerciseId: Yup.string().nullable(),
      exercise: Yup.string().oneOf(['LAB', 'PDF', 'MARKDOWN', 'VIDEO']),
      exerciseLab: Yup.object().when('exercise', {
        is: 'LAB',
        then: Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        }),
        otherwise: Yup.object().nullable(),
      }),
      exerciseLabRunning: Yup.boolean().default(false),
      exerciseVmSelected: Yup.object()
        .shape({
          id: Yup.string(),
          labPrototypeVmId: Yup.string(),
          name: Yup.string(),
          url: Yup.string(),
        })
        .nullable(),
      exercisePdf: Yup.mixed().when('exercise', {
        is: 'PDF',
        then: Yup.mixed(),
        otherwise: Yup.mixed().nullable(),
      }),
      exerciseMarkdown: Yup.string().when('exercise', {
        is: 'MARKDOWN',
        then: Yup.string(),
        otherwise: Yup.string().nullable(),
      }),
      exerciseVideo: Yup.string().when('exercise', {
        is: 'VIDEO',
        then: Yup.string(),
        otherwise: Yup.string().nullable(),
      }),
      exerciseWrittenInstructions: Yup.string(),
      exerciseVideoInstructions: Yup.string(),
      consoleUrl: Yup.string(),
      resources: Yup.array().of(
        Yup.object().shape({
          dbId: Yup.string().nullable(),
          id: Yup.string(),
          resourceName: Yup.string(),
          resourceDescription: Yup.string().nullable(),
          resourceType: Yup.string().oneOf(['PDF', 'MARKDOWN', 'VIDEO']),
          resourcePdf: Yup.mixed().when('resourceType', {
            is: 'PDF',
            then: Yup.mixed(),
            otherwise: Yup.mixed().nullable(),
          }),
          resourceMarkdown: Yup.string().when('resourceType', {
            is: 'MARKDOWN',
            then: Yup.string(),
            otherwise: Yup.string().nullable(),
          }),
          resourceVideo: Yup.string().when('resourceType', {
            is: 'VIDEO',
            then: Yup.string(),
            otherwise: Yup.string().nullable(),
          }),
          resourceOrder: Yup.number(),
        }),
      ),
      resourcesDeleted: Yup.array().of(Yup.string()),
      quizAttemptInProgress: Yup.boolean().default(false),
      quiz: Yup.object()
        .shape({
          dbId: Yup.string().nullable(),
          id: Yup.string(),
          quizDescription: Yup.string(),
          nonGraded: Yup.boolean().default(false),
          answersReturned: Yup.boolean().default(true),
          questions: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              question: Yup.string(),
              questionType: Yup.string().oneOf([
                'FREE_TEXT',
                'SINGLE_CHOICE',
                'MULTIPLE_CHOICE',
              ]),
              questionOrder: Yup.number(),
              answerFreeText: Yup.object().when('questionType', {
                is: 'FREE_TEXT',
                then: Yup.object().shape({
                  answer: Yup.string(),
                  caseSensitive: Yup.boolean().default(false),
                  whiteSpaceSensitive: Yup.boolean().default(false),
                  inputAnswer: Yup.string(),
                }),
                otherwise: Yup.object().nullable(),
              }),
              answersSingleText: Yup.array().when('questionType', {
                is: 'SINGLE_CHOICE',
                then: Yup.array().of(
                  Yup.object().shape({
                    dbId: Yup.string().nullable(),
                    id: Yup.string(),
                    answer: Yup.string(),
                    isCorrect: Yup.boolean().default(false),
                    answerOrder: Yup.number(),
                    inputAnswer: Yup.bool().default(false).nullable(),
                  }),
                ),
                otherwise: Yup.array(),
              }),
              answersMultipleText: Yup.array().when('questionType', {
                is: 'MULTIPLE_CHOICE',
                then: Yup.array().of(
                  Yup.object().shape({
                    dbId: Yup.string().nullable(),
                    id: Yup.string(),
                    answer: Yup.string(),
                    isCorrect: Yup.boolean().default(false),
                    answerOrder: Yup.number(),
                    inputAnswer: Yup.bool().default(false).nullable(),
                  }),
                ),
                otherwise: Yup.array(),
              }),
            }),
          ),
          questionsDeleted: Yup.array().of(Yup.string()),
        })
        .nullable(),
      specialtyMapping: Yup.object().shape({
        id: Yup.string(),
        proficiencyLevel: Yup.string(),
        specialty: Yup.object().shape({
          specialtyId: Yup.string(),
          specialtyName: Yup.string(),
        }),
        skills: Yup.array().of(
          Yup.object().shape({
            dbId: Yup.string().nullable(),
            taskSkillId: Yup.string().nullable(),
            skillId: Yup.string().nullable(),
            skillName: Yup.string(),
            id: Yup.string(),
            techniqueTags: Yup.array().of(
              Yup.object().shape({
                dbId: Yup.string().nullable(),
                techniqueId: Yup.string().nullable(),
                name: Yup.string(),
              }),
            ),
          }),
        ),
        technologyTags: Yup.array().of(
          Yup.object().shape({
            dbId: Yup.string().nullable(),
            technologyId: Yup.string().nullable(),
            name: Yup.string(),
          }),
        ),
      }),
    }),
  ),
});
