import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import ModulePartItem from './ModulePartItem';

function ModuleParts({ parts, module, userId, courseId }) {
  return (
    <Box>
      {parts.map((part) =>
        part.type === 'LAB' &&
        !['EXPIRED', 'READY'].includes(part.lab.status) ? null : (
          <ModulePartItem
            key={`${part.id}${shortid.generate()}`}
            part={part}
            module={module}
            userId={userId}
            courseId={courseId}
          />
        ),
      )}
    </Box>
  );
}

ModuleParts.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  module: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    parts: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ModuleParts;
