import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import { FdAccordion, FdTypography } from '@fifthdomain/fe-shared';

const InfoFdAccordion = ({ title, content }) => {
  const theme = useTheme();
  return (
    <FdAccordion
      style={{
        backgroundColor: theme.palette.inputs.background,
        borderColor: theme.palette.background.paper,
      }}
      endAdornment
      showToggleButton={false}
      summary={() => (
        <FdTypography color="secondary" variant="subtitle2">
          {title}
        </FdTypography>
      )}
      content={() => (
        <Box>
          <Divider style={{ marginBottom: '12px' }} />
          {content}
        </Box>
      )}
    />
  );
};

InfoFdAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default InfoFdAccordion;
