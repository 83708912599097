import React from 'react';
import { useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import {
  FdTypography,
  FdButton,
  FdChip,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import PartIcon from './PartIcon';
import { listModulePartProgressesByCourseUserId } from '../../../graphql/queries';
import {
  createModulePartProgress,
  updateCourseUser,
  updateModulePartProgress,
} from '../../../graphql/mutations';
import { getCourseUser } from '../../../queries/customQueries';
import { updateCourseUserStatus } from '../../../shared/utils/updateCourseUser';

const useStyles = makeStyles()((theme, { bgColor }) => ({
  part: {
    border: '1px solid',
    borderColor: bgColor,
    padding: 16,
    borderRadius: 4,
  },
  title: {
    color: theme.palette.iconography.primary,
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
  },
}));

function ModulePartItem({ part, module, userId, courseId }) {
  const { courseUserId } = useParams();
  const { classes } = useStyles({
    bgColor: '#BDBDBD',
  });
  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgresses,
  } = useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
      filter: { modulePartId: { eq: part?.id } },
    },
    staleTime: { seconds: 0 },
    skip: !part?.id,
  });

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgresses();
    },
  });

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: () => {
      refetchCourseUser();
    },
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress), {
    onCompleted: () => {
      refetchModulePartProgresses();
    },
  });

  const currentModulePartProgress =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items[0] || [];
  const isModulePartCompleted =
    currentModulePartProgress?.status === 'FINISHED';
  const isModulePartNotStarted =
    currentModulePartProgress?.status === 'NOT_STARTED';
  const loading =
    updateModulePartProgressLoading ||
    modulePartProgressLoading ||
    courseUserDataLoading ||
    createModulePartProgressLoading;

  return (
    <Box display="flex" flexDirection="column" mb={2} className={classes.part}>
      <Box
        display="flex"
        mb={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" className={classes.title}>
          <Box mr={1}>
            <PartIcon type={part.type} />
          </Box>
          <FdTypography color="primary" variant="h4">
            {part.name}
          </FdTypography>
        </Box>
        <Box className={classes.flex}>
          {isModulePartCompleted ? (
            <FdChip color="success" size="small" label="completed" />
          ) : (
            part?.type !== 'QUIZ' && (
              <FdButton
                size="small"
                variant="secondary"
                onClick={() => {
                  if (!currentModulePartProgress) {
                    createModulePartProgressMutation({
                      variables: {
                        input: {
                          courseUserId,
                          modulePartId: part?.id,
                          status: 'FINISHED',
                          finishedOn: new Date().toISOString(),
                          startedOn: new Date().toISOString(),
                          userId,
                          courseId,
                        },
                      },
                    });
                  } else {
                    updateModulePartProgressMutation({
                      variables: {
                        input: {
                          id: currentModulePartProgress?.id,
                          status: 'FINISHED',
                          finishedOn: new Date().toISOString(),
                          userId,
                          courseId,
                        },
                      },
                    });
                    updateCourseUserStatus(
                      modulePartProgress,
                      updateCourseUserMutation,
                      courseUserData,
                      part?.id,
                    );
                  }
                }}
              >
                {loading ? 'Loading..' : 'Mark Complete'}
              </FdButton>
            )
          )}
          <FdButton
            size="small"
            onClick={() => {
              const now = new Date().toISOString();

              if (part?.type !== 'LAB' && part?.type !== 'QUIZ') {
                if (!currentModulePartProgress) {
                  createModulePartProgressMutation({
                    variables: {
                      input: {
                        courseUserId,
                        modulePartId: part?.id,
                        status: 'STARTED',
                        startedOn: now,
                        userId,
                        courseId,
                      },
                    },
                  });
                } else if (!isModulePartNotStarted && !isModulePartCompleted) {
                  updateModulePartProgressMutation({
                    variables: {
                      input: {
                        id: currentModulePartProgress?.id,
                        status: 'STARTED',
                        startedOn: now,
                        userId,
                        courseId,
                      },
                    },
                  });
                }
              }
              singleSpa.navigateToUrl(`module/${module?.id}/part/${part?.id}`);
            }}
          >
            {loading ? 'Loading...' : 'Open'}
          </FdButton>
        </Box>
      </Box>
      <FdTypography color="primary">{part.description}</FdTypography>
    </Box>
  );
}

ModulePartItem.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.node,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  module: PropTypes.object.isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ModulePartItem;
