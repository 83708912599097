import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotIcon,
  PinIcon,
  InfoIcon,
  PresentationIcon,
} from 'lucide-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Drawer,
  IconButton,
  useTheme,
  LinearProgress,
} from '@mui/material';
import {
  FdTypography,
  FdProgress,
  useSnapshot,
  globalStore,
  useRecentLinks,
  FdTooltip,
  FdCarousel,
  FdMarkdownRender,
  useQueryRecursive,
  FdModal,
  GLOBAL_EVENTS,
  triggerGlobalEvent,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getCourseUser } from '../queries/customQueries';
import {
  LESSON_DRAWER_PAGES,
  LESSON_DRAWER_WIDTH,
  LESSON_DRAWER_WIDTH_SMALL_SCREEN,
} from '../constants';
import NavigationDrawer from '../components/Courses/LessonView/NavigationDrawer';
import VideoPlayer from '../components/Courses/LessonView/VideoPlayer';
import PdfViewer from '../components/Labs/PdfViewer/PdfViewer';
import LessonNavigationCard from '../components/Courses/LessonView/LessonNavigationCard';
import LessonButton from '../components/Courses/LessonView/LessonButton';
import DrawerButtons from '../components/Courses/LessonView/DrawerButtons';
import InstructionsDrawerContent from '../components/Courses/LessonView/InstructionsDrawerContent';
import ResourcesDrawerContent from '../components/Courses/LessonView/ResourcesDrawerContent';
import NotesDrawerContent from '../components/Courses/LessonView/NotesDrawerContent';
import QuizDrawerContent from '../components/Courses/LessonView/QuizDrawerContent';
import {
  validationSchema,
  initialValues,
} from '../validation-schemas/Course/view-course';
import { getS3File } from '../shared/utils/fileUtils';
import LessonExerciseAccordion from '../components/Courses/LessonView/LesssonExerciseAccordion';
import {
  listCourseMessagesByStatus,
  listExerciseProgressesByCourseUserId,
} from '../graphql/queries';
import { createExerciseProgress } from '../graphql/mutations';
import LabConsole from '../components/Courses/LessonView/LabConsole';
import useSubscriptionCourseChat from '../hooks/useSubscriptionCourseChat';
import { getAttemptClearedQuiz } from '../components/Courses/LessonView/utils';

const LessonNavButton = ({ orderId, lessons, onNavigate, mode, disabled }) => {
  const nextOrder = mode === 'next' ? orderId + 1 : orderId - 1;
  const name =
    !disabled && lessons?.find((l) => l?.orderId === nextOrder)?.name;
  const NavButton = () => (
    <LessonButton
      size="small"
      className="rounded"
      onClick={() => onNavigate(mode)}
      disabled={disabled}
    >
      {mode === 'next' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </LessonButton>
  );

  return disabled ? (
    <NavButton />
  ) : (
    <FdTooltip
      title={
        <Box className="flex items-center gap-x-2">
          <PresentationIcon />
          {`${nextOrder} . ${name}`}
        </Box>
      }
    >
      <span>
        <NavButton />
      </span>
    </FdTooltip>
  );
};

LessonNavButton.propTypes = {
  orderId: PropTypes.number.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onNavigate: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const ViewCourse = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isDocked, setIsDocked] = useState(false);
  const [drawerId, setDrawerId] = useState('');
  const [openQuizNavigateModal, setOpenQuizNavigateModal] = useState(null);
  const [openLabNavigateModal, setOpenLabNavigateModal] = useState(null);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { courseUserId, lessonOrderId } = useParams();
  const { userId, isSmallScreen } = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId });
  const drawerRef = useRef(null);
  const basePageRef = useRef(null);
  const theme = useTheme();
  const drawerWidth = isSmallScreen
    ? LESSON_DRAWER_WIDTH_SMALL_SCREEN
    : LESSON_DRAWER_WIDTH;

  const [createExerciseProgressMutation] = useMutation(
    gql(createExerciseProgress),
  );

  useSubscriptionCourseChat({
    courseUserId,
    userId,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !isDocked &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target)
      ) {
        setOpenDrawer(false);
        setDrawerId('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openDrawer, isDocked]);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues, setValue, watch } = reactHookFormMethods;

  const getResources = async (lessonResources) => {
    const resources = await Promise.all(
      lessonResources?.items?.map(async (r) => ({
        dbId: r?.id,
        id: r?.id,
        resourceName: r?.name,
        resourceDescription: r?.description,
        resourceType: r?.type,
        resourcePdf: await getS3File(r),
        resourceMarkdown: r?.markdown,
        resourceVideo: r?.url,
        resourceOrder: r?.orderNumber,
      })) || [],
    );

    return resources?.sort(
      (a, b) => (a?.resourceOrder ?? 0) - (b?.resourceOrder ?? 0),
    );
  };

  const { loading: courseUserDataLoading } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    staleTime: { seconds: 0 },
    onCompleted: async (_cData) => {
      const { course } = _cData?.getCourseUser || {};
      const { name: courseName, id: courseId, courseLessons } = course || {};
      const lessons = courseLessons?.items || [];
      const sortedLessons = [...lessons]?.sort(
        (a, b) => (a?.orderNumber || 0) - (b?.orderNumber || 0),
      );
      const lessonsWithResources = await Promise.all(
        sortedLessons.map(async ({ lesson }, index) => {
          const {
            lessonExercises,
            estimatedSolveTime,
            difficulty,
            name,
            id,
            lessonQuizzes,
            status,
            specialtyId,
            specialty,
            skills,
            description,
            technologyTags,
            lessonResources,
          } = lesson || {};

          const exercise = lessonExercises?.items?.[0] || {};
          const quiz = lessonQuizzes?.items?.[0] || {};
          return {
            idx: index,
            orderId: index + 1,
            lessonId: id,
            name,
            description,
            estimatedTime: estimatedSolveTime,
            proficiencyLevel: difficulty,
            exerciseId: exercise?.id,
            exercise: exercise?.type,
            exerciseWrittenInstructions: exercise?.instructions,
            exerciseVideoInstructions: exercise?.videoInstructions,
            exercisePdf: await getS3File(exercise),
            exerciseMarkdown: exercise?.markdown,
            exerciseVideo: exercise?.url,
            exerciseLab: { id: exercise?.labId, name: exercise?.lab?.name },
            resources: await getResources(lessonResources),
            consoleUrl: '',
            exerciseLabRunning: false,
            quizAttemptInProgress: false,
            quiz: {
              dbId: quiz?.id,
              quizDescription: quiz?.description,
              instructions: quiz?.instructions,
              nonGraded: quiz?.graded,
              answersReturned: quiz?.showResults,
              questions: quiz?.questions?.items?.map((q) => ({
                dbId: q?.id,
                question: q?.name,
                questionType: q?.type,
                answerFreeText: {
                  answer: q?.answer,
                  caseSensitive: Boolean(q?.caseSensitive),
                  whiteSpaceSensitive: Boolean(q?.whiteSpaceSensitive),
                },
                answersSingleText:
                  q?.type === 'SINGLE_CHOICE'
                    ? q?.options?.items
                        ?.map((o) => ({
                          dbId: o?.id,
                          answer: o?.optionName,
                          isCorrect: Boolean(o?.correctAnswer),
                          answerOrder: o?.orderNumber,
                        }))
                        ?.sort(
                          (a, b) =>
                            (a?.answerOrder ?? 0) - (b?.answerOrder ?? 0),
                        )
                    : [],
                answersMultipleText:
                  q?.type === 'MULTIPLE_CHOICE'
                    ? q?.options?.items
                        ?.map((o) => ({
                          dbId: o?.id,
                          answer: o?.optionName,
                          isCorrect: Boolean(o?.correctAnswer),
                          answerOrder: o?.orderNumber,
                        }))
                        ?.sort(
                          (a, b) =>
                            (a?.answerOrder ?? 0) - (b?.answerOrder ?? 0),
                        )
                    : [],
              })),
              questionsDeleted: [],
            },
            status,
            specialtyMapping: {
              proficiencyLevel: difficulty,
              specialty: { specialtyId, specialtyName: specialty?.name },
              skills:
                skills?.items?.map((s) => ({
                  dbId: s?.id,
                  skillId: s?.skill?.id,
                  skillName: s?.skill?.name,
                  taskSkillId: s?.id,
                  techniqueTags:
                    s?.techniqueTags?.items?.map((t) => ({
                      dbId: t?.id,
                      techniqueId: t?.techniqueTagID,
                      name: t?.techniqueTag?.name,
                    })) || [],
                })) || [],
              technologyTags: technologyTags?.items?.map((t) => ({
                dbId: t?.id,
                technologyId: t?.technologyTagID,
                name: t?.technologyTag?.name,
              })),
            },
          };
        }),
      );
      const lessonOrder = Number(lessonOrderId) || 1;
      reset({
        courseId,
        courseName,
        lessons: lessonsWithResources,
        selectedLessonOrderId:
          lessonOrder > 0 && lessonOrder <= lessonsWithResources?.length
            ? lessonOrder
            : 1,
      });

      // add recent link
      addRecentLink({
        id: courseUserId,
        name: _cData.getCourseUser?.course?.name,
        type: 'COURSE',
        url: pathname + search,
        role: 'PARTICIPATE',
      });
    },
    skip: !courseUserId,
  });

  const selectedLessonOrderId = watch('selectedLessonOrderId');

  const allLessons = getValues('lessons') || [];
  const courseId = getValues('courseId');
  const selectedLesson =
    allLessons?.find((item) => item.orderId === selectedLessonOrderId) || {};

  const {
    data: progressData,
    refetch: refetchProgress,
    loading: progressDataLoading,
  } = useQueryRecursive(gql(listExerciseProgressesByCourseUserId), {
    variables: {
      courseUserId,
    },
    skip: !courseId || !courseUserId,
    staleTime: { seconds: 0 },
    onCompleted: async (_data) => {
      const status =
        _data?.listExerciseProgressesByCourseUserId?.items?.find(
          (p) => p?.lessonId === selectedLesson?.lessonId,
        ) || 'NOT_STARTED';
      if (status === 'NOT_STARTED' && courseId) {
        // create progress as STARTED
        await createExerciseProgressMutation({
          variables: {
            input: {
              courseId,
              courseUserId,
              exerciseId: selectedLesson?.exerciseId,
              lessonId: selectedLesson?.lessonId,
              status: 'STARTED',
              userId,
              startedOn: new Date().toISOString(),
            },
          },
          onCompleted: () => refetchProgress(),
        });
      }
    },
  });

  useQueryRecursive(gql(listCourseMessagesByStatus), {
    variables: {
      seen: 'FALSE',
      filter: {
        receiverId: { eq: userId },
      },
      userCourseId: { eq: courseUserId },
    },
    skip: !userId || !courseUserId,
    staleTime: { seconds: 0 },
    onCompleted: async (_data) => {
      const msgCount = _data?.listCourseMessagesByStatus?.items?.length || 0;
      if (msgCount > 0) {
        triggerGlobalEvent(GLOBAL_EVENTS.SHOW_CHAT_UNREAD);
      }
    },
  });

  useEffect(() => {
    if (selectedLessonOrderId) {
      refetchProgress();
    }
  }, [refetchProgress, selectedLessonOrderId]);

  const selectedDrawer =
    LESSON_DRAWER_PAGES?.find((item) => item.name === drawerId) || {};
  const courseName = getValues('courseName');
  const lessonProgress =
    progressData?.listExerciseProgressesByCourseUserId?.items || [];
  const selectedLessonProgress = lessonProgress?.find(
    (p) => p?.lessonId === selectedLesson?.lessonId,
  );
  if (courseUserDataLoading) return <FdProgress />;

  const lessonsCompleted =
    lessonProgress?.filter((p) => p?.status === 'FINISHED')?.length || 0;
  const percentageCompleted =
    Math.round((lessonsCompleted / allLessons.length) * 100) || 0;

  const toggleDock = () => {
    setIsDocked((prev) => !prev);
    if (isDocked) {
      setOpenDrawer(false);
      setDrawerId('');
    }
  };

  const onDrawerOpen = (name) => {
    setOpenDrawer(true);
    setDrawerId(name);
  };

  const {
    idx,
    name: lessonName,
    orderId,
    exercise,
    exerciseMarkdown,
    exercisePdf,
    exerciseVideo,
    quiz,
  } = selectedLesson || {};
  const watchLabRunningStatus = watch(`lessons[${idx}].exerciseLabRunning`);
  const watchQuizUnfinishedStatus = watch(
    `lessons[${idx}].quizAttemptInProgress`,
  );
  const watchVmSelectedId = watch(`lessons[${idx}].exerciseVmSelected.id`);

  const hasPrevious = allLessons?.some(
    (l) => l?.orderId === selectedLessonOrderId - 1,
  );
  const hasNext = allLessons?.some(
    (l) => l?.orderId === selectedLessonOrderId + 1,
  );
  const onNavigate = (direction) => {
    if (
      (direction === 'next' && !hasNext) ||
      (direction === 'prev' && !hasPrevious)
    )
      return;
    const nextOrderId = selectedLessonOrderId + (direction === 'next' ? 1 : -1);
    const nextNavLesson = allLessons?.find((l) => l?.orderId === nextOrderId);
    if (nextNavLesson) {
      setValue('selectedLessonOrderId', nextNavLesson.orderId);
      history.push(
        `/labs/courses/view/${courseUserId}/${nextNavLesson.orderId}`,
      );
    }
  };
  const hasRunningLabAndNotCompleted =
    exercise === 'LAB' &&
    watchLabRunningStatus &&
    selectedLessonProgress?.status !== 'FINISHED';
  const hasUnfinishedQuizAndNotCompleted =
    watchQuizUnfinishedStatus && selectedLessonProgress?.status !== 'FINISHED';

  const handleNavigationWithChecks = (action) => {
    if (hasRunningLabAndNotCompleted) {
      setOpenLabNavigateModal({
        state: true,
        action,
      });
    } else if (hasUnfinishedQuizAndNotCompleted) {
      setOpenQuizNavigateModal({
        state: true,
        action,
      });
    } else {
      action();
    }
  };
  const clearQuizSubmission = () => {
    setValue(`lessons[${idx}].quizAttemptInProgress`, false);
    const clearedQuiz = getAttemptClearedQuiz(quiz);
    // reset quiz submission
    setValue(`lessons[${idx}].quiz.questions`, clearedQuiz);
  };

  const onNavigatePreCheck = (direction) => {
    handleNavigationWithChecks(() => {
      onNavigate(direction);
      clearQuizSubmission();
    });
  };

  const onDrawerNavigation = (_orderId) => {
    handleNavigationWithChecks(() => {
      setValue('selectedLessonOrderId', _orderId);
      history.push(`/labs/courses/view/${courseUserId}/${_orderId}`);
      clearQuizSubmission();
    });
  };

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <FdBreadcrumbHeader page={{ name: courseName, type: 'COURSE' }} />
        <Box className="overflow-x-hidden" data-cy="view-course">
          <Box className="flex justify-between items-center w-full">
            <Box className="flex-1 flex flex-col items-center">
              <FdTypography
                variant="caption"
                className="font-medium"
                color="secondary"
              >
                {courseName}
              </FdTypography>
              <Box className="flex items-center gap-x-4">
                <LessonNavButton
                  onNavigate={onNavigatePreCheck}
                  mode="previous"
                  orderId={orderId}
                  lessons={allLessons}
                  disabled={!hasPrevious}
                />
                <Box className="flex items-center justify-center gap-x-2 min-w-96">
                  <PresentationIcon size={20} />
                  <FdTypography
                    variant="h4"
                    className="inline-flex items-center font-medium gap-x-1"
                  >
                    {`${orderId}`} <DotIcon /> {`${lessonName}`}
                  </FdTypography>
                </Box>
                <LessonNavButton
                  onNavigate={onNavigatePreCheck}
                  mode="next"
                  orderId={orderId}
                  lessons={allLessons}
                  disabled={!hasNext}
                />
              </Box>
            </Box>
            <Box className="flex justify-end">
              <DrawerButtons
                onClick={onDrawerOpen}
                selected={drawerId}
                selectedLesson={selectedLesson}
              />
            </Box>
          </Box>
          <Box className="grid grid-cols-[1fr_auto] mt-7 w-full transition-all">
            <Box ref={basePageRef} data-cy="page-content" className="h-full">
              <LessonExerciseAccordion
                selectedLesson={selectedLesson}
                selectedLessonProgress={selectedLessonProgress}
                courseUserId={courseUserId}
                refetchProgress={refetchProgress}
                progressDataLoading={progressDataLoading}
              />
              <Box mt={2}>
                <Card
                  variant="outlined"
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '8px',
                  }}
                >
                  <CardContent className="p-0 last:pb-0 overflow-y-auto max-h-[calc(100vh-250px)] h-[calc(100vh-250px)] max-w-screen overflow-hidden">
                    {
                      {
                        VIDEO: <VideoPlayer url={exerciseVideo} />,
                        PDF: <PdfViewer fileUrl={exercisePdf?.url} />,
                        MARKDOWN: (
                          <FdMarkdownRender markdown={exerciseMarkdown} />
                        ),
                        LAB: (
                          <FdDelayed triggerField={watchVmSelectedId}>
                            <LabConsole selectedLesson={selectedLesson} />
                          </FdDelayed>
                        ),
                      }[exercise]
                    }
                  </CardContent>
                </Card>
              </Box>
            </Box>
            <Drawer
              anchor="right"
              open={openDrawer || isDocked}
              variant={isDocked ? 'permanent' : 'temporary'}
              sx={{
                width: drawerWidth,
                maxWidth: drawerWidth,
                flexShrink: 0,
              }}
              hideBackdrop
              PaperProps={{
                ref: drawerRef,
                style: {
                  marginTop: isDocked ? 0 : '100px',
                  width: drawerWidth,
                  maxWidth: drawerWidth,
                  padding: 0,
                  position: isDocked ? 'relative' : 'fixed',
                  borderRadius: '8px',
                  border: 'transparent',
                  marginLeft: isDocked ? '10px' : 'auto',
                  height: isDocked
                    ? 'calc(100vh - 144px)'
                    : 'calc(100vh - 108px)',
                  overflowY: 'auto',
                  background: theme.palette.background.paper,
                  marginBottom: 0,
                },
              }}
            >
              <Box className="p-4 h-full pb-0">
                <Box>
                  <Box className="flex items-center justify-between">
                    <Box className="flex items-center">
                      <FdTypography variant="subtitle1" className="font-medium">
                        {selectedDrawer?.name === 'Resources'
                          ? `Resources (${selectedLesson?.resources?.length})`
                          : selectedDrawer?.name}
                      </FdTypography>
                      {selectedDrawer?.name === 'Quiz' && (
                        <FdTooltip title="Quizzes are optional and don’t affect lesson completion. However, they are highly recommended for learning. You can attempt quizzes as many times as you like, but only your most recent submission is saved.">
                          <IconButton
                            size="small"
                            style={{ marginLeft: '5px' }}
                          >
                            <InfoIcon size={18} />
                          </IconButton>
                        </FdTooltip>
                      )}
                    </Box>
                    <IconButton onClick={toggleDock}>
                      <PinIcon
                        color={theme.palette.primary.main}
                        fill={isDocked ? theme.palette.primary.main : 'none'}
                      />
                    </IconButton>
                  </Box>
                  <Box my={2}>
                    {
                      {
                        Instructions: (
                          <InstructionsDrawerContent lesson={selectedLesson} />
                        ),
                        Resources: (
                          <ResourcesDrawerContent lesson={selectedLesson} />
                        ),
                        Notes: (
                          <NotesDrawerContent
                            courseId={courseId}
                            lessonId={selectedLesson?.lessonId}
                            courseUserId={courseUserId}
                          />
                        ),
                        Quiz: <QuizDrawerContent lesson={selectedLesson} />,
                      }[selectedDrawer?.name]
                    }
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Box>
          <NavigationDrawer parentRef={basePageRef} defaultOpen>
            <Box className="flex flex-col items-center justify-center">
              <Box
                className="flex items-center gap-x-2 w-full px-2 py-2 rounded-lg"
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <LinearProgress
                  variant="determinate"
                  value={percentageCompleted}
                  className="h-2 rounded w-full"
                />
                <FdTypography variant="body2" className="font-bold">
                  {`${lessonsCompleted}/${allLessons?.length}`}
                </FdTypography>
                <FdTypography
                  variant="captiontext1"
                  className="whitespace-nowrap"
                >
                  lessons complete
                </FdTypography>
              </Box>
              <Box my={1} className="w-full">
                <FdCarousel
                  currentSlide={selectedLessonOrderId}
                  slides={allLessons?.map((lesson) => {
                    const lessonStatus =
                      lessonProgress?.find(
                        (item) => item.lessonId === lesson.lessonId,
                      )?.status || 'NOT_STARTED';

                    return {
                      id: lesson?.orderId,
                      content: (
                        <LessonNavigationCard
                          lesson={lesson}
                          onClick={() => onDrawerNavigation(lesson.orderId)}
                          className="cursor-default"
                          selected={lesson.orderId === selectedLessonOrderId}
                          status={lessonStatus}
                        />
                      ),
                    };
                  })}
                />
              </Box>
            </Box>
          </NavigationDrawer>
          <FdModal
            title="Navigate to Lesson?"
            size="md"
            description={
              <Box className="flex flex-col gap-y-4">
                <FdTypography variant="body1">
                  Are you sure you want to leave this lesson?
                </FdTypography>
                <FdTypography variant="body1">
                  This lesson is incomplete, and you have a running lab
                  instance.
                </FdTypography>
                <FdTypography variant="body1">
                  Lab progress resets automatically after 2 hours. To keep your
                  progress, return to this lesson before the reset
                </FdTypography>
              </Box>
            }
            confirm="Confirm"
            dismiss="Cancel"
            open={openLabNavigateModal?.state}
            onConfirm={() => {
              openLabNavigateModal?.action(); // call as action
              setOpenLabNavigateModal(null);
            }}
            onDismiss={() => {
              setOpenLabNavigateModal(null);
            }}
          />
          <FdModal
            title="Navigate to Lesson?"
            size="md"
            description={
              <Box className="flex flex-col gap-y-4">
                <FdTypography variant="body1">
                  Are you sure you want to navigate to another lesson?
                </FdTypography>
                <FdTypography variant="body1">
                  Any unsubmitted quiz answers will be lost if you leave. To
                  keep your answers, please submit before navigating.
                </FdTypography>
              </Box>
            }
            confirm="Confirm"
            dismiss="Cancel"
            open={openQuizNavigateModal?.state}
            onConfirm={() => {
              openQuizNavigateModal?.action(); // call as action
              setOpenQuizNavigateModal(null);
            }}
            onDismiss={() => {
              setOpenQuizNavigateModal(null);
            }}
          />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ViewCourse;
