import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  useSnapshot,
  globalStore,
  useQueryRecursive,
  errorToastMessage,
  FdTypography,
  successToastMessage,
  FdAvatar,
  getAvatarName,
  getAvatarColor,
} from '@fifthdomain/fe-shared';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Course/chat';
import {
  createCourseMessage,
  readChatMessages,
  deleteCourseMessage,
  updateCourseMessage,
} from '../../graphql/mutations';
import { listCourseMessagesByUserCourseId } from '../../graphql/queries';
import CourseChat from '../Courses/LessonView/CourseChat';
import CloseIcon from '@mui/icons-material/Close';
import { uploadCourseChatImageAttachmentToS3 } from '../../shared/utils/chatUtils';

const drawerWidth = 900;
const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const AdminCourseChat = ({
  openChat,
  onCloseChat,
  chatTitle,
  receiverId,
  courseId,
  courseUserId,
}) => {
  const { classes } = useStyles();
  const [chatEditInProgress, setChatEditInProgress] = useState(false);
  const [showNewMessagesLink, setShowMessagesLink] = useState(false);
  const { userId, orgId } = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, control, watch, setValue } = reactHookFormMethods;
  const { append: appendMessage, remove: removeMessage } = useFieldArray({
    control,
    name: 'messages',
  });
  const watchMessages = watch('messages');
  const [deleteCourseMessageMutation] = useMutation(gql(deleteCourseMessage));
  const [updateCourseMessageMutation] = useMutation(gql(updateCourseMessage));
  const { refetch: refetchMessages } = useQueryRecursive(
    gql(listCourseMessagesByUserCourseId),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
      onCompleted: (_data) => {
        const chatHistory =
          _data?.listCourseMessagesByUserCourseId?.items
            .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
            ?.map((m, i) => {
              const text =
                m?.messages?.find((msg) => msg?.type === 'TEXT')?.content || '';
              const imageUrls =
                m?.messages
                  ?.filter((msg) => msg?.type === 'IMAGE')
                  ?.map((msg) => msg?.content) || [];
              return {
                message: {
                  idx: i,
                  dbId: m?.id,
                  text,
                  images: imageUrls,
                  self: m?.sender?.id === userId,
                  timeStamp:
                    m?.createdAt &&
                    format(new Date(m?.createdAt), 'dd MMM yyyy, h:mm a'),
                  MessengerIcon: (
                    <FdAvatar
                      size="small"
                      content={getAvatarName(chatTitle)}
                      color={getAvatarColor(chatTitle)}
                    />
                  ),
                  messengerName: m?.sender?.name,
                  isAdmin: m?.sender?.permissions?.includes('VIEW_INSIGHTS'),
                  isRead: m?.seen,
                  isEdited: m?.isEdited,
                },
              };
            }) || [];

        reset({
          messages: [...chatHistory],
        });
      },
    },
  );

  const [markAsReadChatMessagesMutation] = useMutation(gql(readChatMessages));
  const [createCourseMessageMutation] = useMutation(gql(createCourseMessage), {
    onCompleted: (_data) => {
      const newId = watchMessages.length;
      const updatedMessages = watchMessages.map((msg, idx) =>
        idx !== newId
          ? {
              message: {
                ...msg.message,
                dbId: _data?.createCourseMessage?.id,
              },
            }
          : msg,
      );
      setValue('messages', updatedMessages);
      markAsReadChatMessagesMutation({
        variables: { userCourseId: courseUserId, senderId: receiverId },
      });
    },
    onError: () => {
      errorToastMessage('Error: cannot submit message');
    },
  });

  const addChatMessage = async ({ text, images }) => {
    const dateSubmitted = new Date().toISOString();
    const newId = watchMessages.length;
    // append message locally
    appendMessage({
      message: {
        idx: newId,
        text,
        self: true,
        timeStamp: format(new Date(dateSubmitted), 'dd MMM yyyy, h:mm a'),
        isAdmin: false,
        isRead: false,
        images: images?.map((image) => URL.createObjectURL(image)) || [],
      },
    });
    const imageUrls = await uploadCourseChatImageAttachmentToS3(
      images,
      userId,
      courseUserId,
    );
    // create a chat message in db
    createCourseMessageMutation({
      variables: {
        input: {
          createdAt: dateSubmitted,
          updatedAt: dateSubmitted,
          messages: [
            { type: 'TEXT', content: text },
            ...(imageUrls?.map((image) => ({
              type: 'IMAGE',
              content: image?.url,
            })) || []),
          ],
          seen: 'TRUE',
          senderId: userId,
          userCourseId: courseUserId,
          courseId,
          orgId,
        },
      },
    });
  };

  const editChatMessage = async ({ text, images, dbId }) => {
    const dateSubmitted = new Date().toISOString();
    const imagesForUpload =
      images?.filter((image) => image instanceof File) || [];
    const imageUrls = await uploadCourseChatImageAttachmentToS3(
      imagesForUpload,
      userId,
      courseUserId,
    );
    const allImages = [
      ...(images
        ?.filter((image) => !(image instanceof File))
        ?.map((i) => ({ url: i })) || []),
      ...(imageUrls || []),
    ];

    // update chat message in db
    updateCourseMessageMutation({
      variables: {
        input: {
          id: dbId,
          messages: [
            { type: 'TEXT', content: text },
            ...(allImages?.map((image) => ({
              type: 'IMAGE',
              content: image?.url,
            })) || []),
          ],
          isEdited: true,
          updatedAt: dateSubmitted,
        },
      },
    });
  };

  const deleteChatMessage = ({ dbId, idx }) => {
    removeMessage(idx);
    if (!dbId) return;
    // delete from db if exists
    deleteCourseMessageMutation({
      variables: {
        input: {
          id: dbId,
        },
      },
      onCompleted: () => {
        successToastMessage('Success! Message deleted');
      },
    });
  };

  const onShowNewMessagesLink = () => {
    setShowMessagesLink(false);
    setChatEditInProgress(false);
    refetchMessages();
  };

  return (
    <Box>
      <Drawer
        variant="persistent"
        anchor="right"
        open={openChat}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Card
          style={{
            overflow: 'auto',
            height: '100%',
          }}
        >
          <CardHeader
            action={
              <IconButton
                onClick={() => {
                  onCloseChat();
                }}
              >
                <CloseIcon style={{ fontSize: 28 }} />
              </IconButton>
            }
            title={
              <Box className="flex items-center gap-x-4 overflow-hidden">
                <FdAvatar
                  size="small"
                  content={getAvatarName(chatTitle)}
                  color={getAvatarColor(chatTitle)}
                />
                <FdTypography variant="subtitle1">{chatTitle}</FdTypography>
              </Box>
            }
          />
          <CardContent className="p-0 last:pb-0 overflow-y-auto max-h-[calc(100vh-250px)] h-[calc(100vh-250px)] max-w-screen overflow-hidden">
            <Divider />
            <CourseChat
              messages={watchMessages?.map((m) => m.message) || []}
              inputPlaceholder="Message Participant"
              inputCaptionText=" "
              noMessageText="Start a conversation with your course mentors now!"
              onAddMessage={addChatMessage}
              onEditMessage={editChatMessage}
              onDeleteMessage={deleteChatMessage}
              loading={false}
              setChatEditInProgress={setChatEditInProgress}
              showNewMessagesLink={showNewMessagesLink}
              onShowNewMessagesLink={onShowNewMessagesLink}
            />
          </CardContent>
        </Card>
      </Drawer>
    </Box>
  );
};

AdminCourseChat.propTypes = {
  openChat: PropTypes.bool.isRequired,
  onCloseChat: PropTypes.func.isRequired,
  chatTitle: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  receiverId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  userCourseId: PropTypes.string.isRequired,
};

AdminCourseChat.defaultProps = {
  isAdmin: false,
};

export default AdminCourseChat;
