import React from 'react';
import PropTypes from 'prop-types';
import { ScrollTextIcon, SquareArrowOutUpRightIcon, XIcon } from 'lucide-react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import VideoPlayer from '../LessonView/VideoPlayer';

const VideoPopup = ({ open, setOpen, url, title, newTabUrl, videoIcon }) => {
  return (
    <Dialog
      maxWidth={false}
      open={open}
      fullWidth
      sx={(theme) => ({
        '& .MuiDialog-paper': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
        },
      })}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <DialogActions
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Box className="flex justify-between w-full px-2">
          <Box className="flex items-center gap-x-2">
            {videoIcon}
            <FdTypography variant="subtitle1">{title}</FdTypography>
          </Box>
          <Box>
            <FdTooltip title="Open in New Tab">
              <IconButton
                onClick={() => {
                  setOpen(false);
                  window.open(newTabUrl, '_blank');
                }}
              >
                <SquareArrowOutUpRightIcon />
              </IconButton>
            </FdTooltip>
            <FdTooltip title="Close">
              <IconButton onClick={() => setOpen(false)}>
                <XIcon />
              </IconButton>
            </FdTooltip>
          </Box>
        </Box>
      </DialogActions>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
        })}
      >
        <VideoPlayer url={url} />
      </DialogContent>
    </Dialog>
  );
};

VideoPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  newTabUrl: PropTypes.string.isRequired,
  videoIcon: PropTypes.node,
};

VideoPopup.defaultProps = {
  videoIcon: <ScrollTextIcon />,
};

export default VideoPopup;
