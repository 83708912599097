import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import {
  FdModal,
  FdTypography,
  FdTooltip,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Message from './Message';
import { scrollToBottomOfContainer } from '../../../../shared/utils/scroll';

const debouncedScrollToBottom = debounce(scrollToBottomOfContainer, 150);

const MessageStream = ({
  messages,
  onDeleteMessage,
  onEditMessage,
  onAction,
}) => {
  const streamRef = useRef(null);
  const theme = useTheme();
  const [showScrollTo, setShowScrollTo] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const reversedMessages = [...messages].reverse();
  const handleScroll = () => {
    const container = streamRef.current;
    if (!container) return;
    const isScrolledToBottom = container.scrollTop >= 0;
    setShowScrollTo(!isScrolledToBottom);
  };

  // // Scroll to bottom when messages update
  useEffect(() => {
    if (streamRef.current) {
      debouncedScrollToBottom(streamRef);
    }
    return () => debouncedScrollToBottom.cancel();
  }, [messages.length]);

  const getBgColor = (_theme) =>
    _theme?.palette?.type === 'dark' ? '#111010' : 'rgba(0, 0, 0, 0.03)';

  return (
    <>
      <Box
        ref={streamRef}
        className="relative flex flex-col-reverse w-full px-2 mb-1 h-screen overflow-x-hidden"
        sx={{
          overflowY: 'auto',
          position: 'relative',
        }}
        onScroll={handleScroll}
      >
        {reversedMessages.map((message) => (
          <Message
            key={message?.id}
            message={message}
            onDelete={(_message) => {
              setShowDeleteModal(_message);
            }}
            onEdit={(_message) =>
              onEditMessage({
                dbId: _message?.dbId,
                text: _message?.text,
                images: _message?.images,
              })
            }
            onAction={onAction}
          />
        ))}
      </Box>
      {showScrollTo && (
        <FdTooltip title="Jump to latest message">
          <IconButton
            onClick={() => scrollToBottomOfContainer(streamRef)}
            color="primary"
            aria-label="scroll to bottom"
            sx={{
              position: 'absolute',
              color: theme.palette?.primary?.main,
              top: `${(streamRef?.current?.offsetHeight || 0) + 150}px`,
              width: '32px',
              height: '32px',
              backgroundColor: getBgColor(theme),
              border: `2px solid ${theme.palette?.primary?.main}`,
              boxShadow: '0 0 0 1px #000',
              padding: '2px',
              left: '50%',
              transform: 'translateX(-50%)',
              '&:hover': {
                backgroundColor: getBgColor(theme),
              },
              '&:focus': {
                backgroundColor: getBgColor(theme),
              },
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </FdTooltip>
      )}
      <FdModal
        title="Delete Message"
        size="md"
        description={
          <Box className="flex flex-col gap-y-4">
            <FdTypography variant="body1">
              Are you sure you want to delete this message?
            </FdTypography>
            <FdTypography variant="body1">
              Once deleted, this message will be permanently removed and will no
              longer be accessible to any users. This action cannot be undone.
            </FdTypography>
            <Box
              className="rounded-xl my-2 max-h-[300px] flex flex-col gap-y-2 overflow-y-auto p-4"
              sx={{
                backgroundColor: alpha(theme.palette.inputs.selectBorder, 0.2),
                borderColor: theme.palette.table.border,
                borderWidth: '1px',
              }}
            >
              <FdTypography variant="captiontext2" color="secondary">
                {showDeleteModal?.message?.timeStamp}
              </FdTypography>
              <FdTypography
                variant="body2"
                color="secondary"
                className="whitespace-pre-wrap break-words"
              >
                {showDeleteModal?.message?.text}
              </FdTypography>
            </Box>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={showDeleteModal}
        onConfirm={() => {
          onDeleteMessage({
            dbId: showDeleteModal?.message?.dbId,
            idx: showDeleteModal?.message?.idx,
          });
          setShowDeleteModal(false);
          onAction({ id: showDeleteModal?.message?.idx, active: false });
        }}
        onDismiss={() => {
          setShowDeleteModal(false);
          onAction({ id: showDeleteModal?.message?.idx, active: false });
          warningToastMessage('Message deletion cancelled');
        }}
      />
    </>
  );
};

MessageStream.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  liveMessage: PropTypes.shape({
    text: PropTypes.string,
    live: PropTypes.bool,
  }),
  onDeleteMessage: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

MessageStream.defaultProps = {
  liveMessage: null,
};

export default MessageStream;
