import { triggerGlobalEvent, GLOBAL_EVENTS } from '@fifthdomain/fe-shared';
import { gql, useSubscription } from '@apollo/client';
import { onCreateCourseMessage } from '../graphql/subscriptions';

const useSubscriptionCourseChat = ({ userId, courseUserId, onDataAction }) => {
  useSubscription(gql(onCreateCourseMessage), {
    variables: {
      filter: {
        receiverId: { eq: userId },
        userCourseId: { eq: courseUserId },
      },
    },
    onData: (_data) => {
      triggerGlobalEvent(GLOBAL_EVENTS.SHOW_CHAT_UNREAD);
      onDataAction?.(_data);
    },
    fetchPolicy: 'network-only',
  });
};

export default useSubscriptionCourseChat;
