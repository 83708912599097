import { uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import { amplifyConfig } from '@fifthdomain/fe-shared';

const getContentType = (fileType) => {
  switch (fileType) {
    case '.md':
      return 'text/markdown';
    case '.tar.gz':
      return 'application/gzip';
    case '.pdf':
      return 'application/pdf';
    case 'application/x-yaml':
      return 'application/yaml';
    default:
      return 'application/gzip';
  }
};

const getContentTypeEc2 = (fileType) => {
  switch (fileType) {
    case '.ova':
      return 'application/x-virtualbox-ova';
    default:
      return 'application/x-virtualbox-ova';
  }
};

const getFileNameExtension = (file) => {
  const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
  const fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
  return { fileName, fileExtension };
};

export const fileUploadAWS = async (
  { file, fileType },
  setUploadPercentage,
) => {
  const { fileName, fileExtension } = getFileNameExtension(file);
  const uniqueFileName = `${fileName}-${shortid.generate()}.${fileExtension}`;

  await uploadData({
    path: `public/${uniqueFileName}`,
    data: file,
    options: {
      contentType: getContentType(fileType),
      onProgress: ({ transferredBytes, totalBytes }) => {
        const percentage =
          Math.round((transferredBytes / totalBytes) * 100) / 100;
        setUploadPercentage?.(percentage);
      },
    },
  }).result;

  return {
    name: uniqueFileName,
    type: file.type,
    size: file.size,
    url: URL.createObjectURL(file),
    bucket: amplifyConfig?.aws_user_files_s3_bucket,
    region: amplifyConfig?.aws_user_files_s3_bucket_region,
  };
};

export const fileUploadAWSEc2 = async (
  { file, platform, fileType },
  setUploadPercentage,
) => {
  const env = amplifyConfig?.aws_ec2_env ?? 'test';
  const { fileName, fileExtension } = getFileNameExtension(file);
  const uniqueFileName = `${fileName}${shortid.generate()}.${fileExtension}`;

  try {
    await uploadData({
      path: `${env}/${platform}/${uniqueFileName}`,
      data: file,
      options: {
        bucket: {
          bucketName: amplifyConfig?.aws_vm_upload_s3_bucket,
          region: amplifyConfig?.aws_vm_upload_s3_bucket_region,
        },
        contentType: getContentTypeEc2(fileType),
        onProgress: ({ transferredBytes, totalBytes }) => {
          const percentage = Math.round((transferredBytes / totalBytes) * 100) / 100;
          setUploadPercentage?.(percentage);
        },
      },
    }).result;
  } catch (err) {
    console.error('Error uploading VM: ', err);
  }

  return {
    name: uniqueFileName,
    type: file.type,
    size: file.size,
    url: URL.createObjectURL(file),
    bucket: amplifyConfig?.aws_vm_upload_s3_bucket,
    region: amplifyConfig?.aws_vm_upload_s3_bucket_region,
  };
};
