import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTable, FdTypography, FdExternalLink } from '@fifthdomain/fe-shared';

const CourseGroups = ({ rows, actions, ...rest }) => {
  const { control, setValue } = useFormContext();
  const columns = [
    { field: 'groupName', width: 300, headerName: 'Group Name' },
    {
      field: 'description',
      width: 250,
      headerName: 'Description',
    },
    { field: 'usersCount', width: 200, headerName: 'No. of Users' },
  ];

  return (
    <Box height="450px" my={2}>
      <Controller
        control={control}
        name="groupIds"
        render={({ field: { value } }) => (
          <FdTable
            toolbarSettings={{
              title: (
                <Box>
                  <FdTypography variant="h3">Add Groups</FdTypography>
                  <FdTypography variant="body1" color="secondary">
                    To invite unregistered participants to a course, Groups can
                    be very useful! You can create and add a Group to this
                    course, to which you can add list of unregistered
                    participants. You can manage Groups from
                    <FdExternalLink href="/user-management/groups">
                      here
                    </FdExternalLink>
                    .
                  </FdTypography>
                </Box>
              ),
              filterButton: true,
              searchBox: true,
            }}
            selection
            rows={rows}
            columns={columns}
            tablePageSize={5}
            actions={actions}
            selectionModel={value}
            onSelectionModelChange={(_values) => {
              const data = rows
                ?.filter((item) => _values?.includes(item?.id))
                ?.map((item) => item?.groupName);
              setValue('groupIds', _values);
              setValue('groupData', data);
            }}
            gridId="labs-course-groups"
            {...rest}
          />
        )}
      />
    </Box>
  );
};

CourseGroups.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

CourseGroups.defaultProps = {
  actions: [],
  headerActions: [],
};

export default CourseGroups;
