import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { useQuery, gql } from '@apollo/client';
import { NotepadTextIcon, VideoIcon } from 'lucide-react';
import { Box, useTheme } from '@mui/material';
import {
  FdTypography,
  FdProgress,
  useSnapshot,
  globalStore,
  FdMarkdownRender,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getCourseUser } from '../queries/customQueries';
import ContentAccordion from '../components/Courses/LessonView/ContentAccordion';
import ResourceTypeIcon from '../components/Courses/LessonView/ResourceTypeIcon';
import DownloadLink from '../components/Courses/LessonView/DownloadLink';
import VideoPopup from '../components/Courses/LessonMaterial/VideoPopup';
import { getS3File } from '../shared/utils/fileUtils';
import useSubscriptionCourseChat from '../hooks/useSubscriptionCourseChat';

const CardHeader = ({ heading }) => (
  <Box
    height={64}
    sx={(theme) => ({
      backgroundColor: theme.palette.background.paper,
    })}
    className="flex items-center"
  >
    <FdTypography variant="subtitle1" className="font-medium ml-6">
      {heading}
    </FdTypography>
  </Box>
);

CardHeader.propTypes = {
  heading: PropTypes.string.isRequired,
};

const getResources = async (_courseResources) => {
  const resources = await Promise.all(
    _courseResources?.items?.map(async (r) => ({
      dbId: r?.id,
      id: r?.id,
      resourceName: r?.name,
      resourceDescription: r?.description,
      resourceType: r?.type,
      resourcePdf: await getS3File(r),
      resourceMarkdown: r?.markdown,
      resourceVideo: r?.url,
      resourceOrder: r?.orderNumber,
    })) || [],
  );

  return resources?.sort(
    (a, b) => (a?.resourceOrder ?? 0) - (b?.resourceOrder ?? 0),
  );
};

const ViewCourseOverview = () => {
  const { courseUserId } = useParams();
  const [showVideoPopup, setShowVideoPopup] = useState(null);
  const [resources, setResources] = useState([]);
  const theme = useTheme();
  const { userId } = useSnapshot(globalStore);

  useSubscriptionCourseChat({
    courseUserId,
    userId,
  });

  const { data: courseUserData, loading: courseUserDataLoading } = useQuery(
    gql(getCourseUser),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
      onCompleted: async (data) => {
        const { courseResources } = data?.getCourseUser?.course || {};
        const resourcesFormatted = await getResources(courseResources);
        setResources(resourcesFormatted);
      },
    },
  );

  if (courseUserDataLoading) return <FdProgress />;

  const { name: courseName, description } =
    courseUserData?.getCourseUser?.course || {};

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: courseName,
            path: `/labs/courses/view/${courseUserId}`,
            type: 'COURSE',
          },
        ]}
        page={{ name: 'Overview', type: 'COURSE' }}
      />
      <Box className="overflow-x-hidden" data-cy="view-course-resources">
        <Box className="flex-1 flex flex-col items-center">
          <FdTypography
            variant="caption"
            className="font-medium"
            color="secondary"
          >
            {courseName}
          </FdTypography>
          <Box className="flex items-center gap-x-4">
            <Box className="flex items-center justify-center gap-x-2 min-w-96">
              <NotepadTextIcon size={20} />
              <FdTypography variant="h4" className="font-medium">
                Course Overview
              </FdTypography>
            </Box>
          </Box>
        </Box>
        <Box
          className={`grid grid-cols-${resources?.length > 0 ? '2' : '1'} gap-x-4 mt-7 w-full min-h-[calc(100vh-160px)]`}
        >
          <Box
            className="flex flex-col rounded-lg overflow-hidden"
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <Box className="flex flex-col">
              <CardHeader heading="Course Introduction" />
              <Box className="px-6 py-5">
                <FdTypography
                  variant="body1"
                  className="break-words whitespace-pre-wrap"
                >
                  {description}
                </FdTypography>
              </Box>
            </Box>
          </Box>
          {resources?.length > 0 && (
            <Box
              className="flex flex-col rounded-lg overflow-hidden"
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Box className="flex flex-col">
                <CardHeader
                  heading={`Course Resources (${resources?.length})`}
                />
                {resources?.map(
                  ({
                    resourceType,
                    resourceName,
                    resourceVideo,
                    resourcePdf,
                    resourceMarkdown,
                    resourceDescription,
                    dbId,
                  }) => (
                    <ContentAccordion
                      noBorder
                      defaultExpanded={resources?.length === 1}
                      summary={
                        <Box className="flex items-center flex-wrap gap-x-2 py-1">
                          <ResourceTypeIcon type={resourceType} />
                          <FdTypography variant="subtitle2">
                            {resourceName}
                          </FdTypography>
                        </Box>
                      }
                      details={
                        <Box>
                          <FdTypography
                            variant="body2"
                            color="secondary"
                            className="my-2 break-words whitespace-pre-wrap"
                          >
                            {resourceDescription || (
                              <em>No description added</em>
                            )}
                          </FdTypography>
                          {
                            {
                              VIDEO: (
                                <Box className="flex items-center gap-x-2 my-3">
                                  <VideoIcon />
                                  <FdTypography
                                    variant="body2"
                                    className="font-bold underline cursor-pointer"
                                    style={{
                                      color: theme.palette.primary.main,
                                    }}
                                    onClick={() =>
                                      setShowVideoPopup({
                                        url: resourceVideo,
                                        title: resourceName,
                                        newTabUrl: `/labs/courses/course-resource-video-view/${courseUserId}/${dbId}`,
                                      })
                                    }
                                  >
                                    Open Video Player
                                  </FdTypography>
                                </Box>
                              ),
                              PDF: (
                                <DownloadLink
                                  name={resourcePdf?.name}
                                  url={resourcePdf?.url}
                                />
                              ),
                              MARKDOWN: (
                                <Box>
                                  <DownloadLink
                                    name={`${resourceName}.md`}
                                    markdown={resourceMarkdown}
                                  />
                                  <Box className="w-full px-4 mx-auto my-0 max-h-[450px] overflow-y-auto">
                                    <ShowMoreText
                                      lines={3}
                                      more="See more"
                                      less="See less"
                                      className="w-full font-mono text-xs"
                                      expanded={false}
                                      anchorClass="text-blue-600 font-bold"
                                      truncatedEndingComponent="... "
                                    >
                                      <FdMarkdownRender
                                        markdown={resourceMarkdown}
                                      />
                                    </ShowMoreText>
                                  </Box>
                                </Box>
                              ),
                            }[resourceType]
                          }
                        </Box>
                      }
                      style={{
                        border: '1px solid rgba(39, 39, 42, 1)',
                      }}
                    />
                  ),
                )}
              </Box>
              <VideoPopup
                open={showVideoPopup}
                setOpen={setShowVideoPopup}
                url={showVideoPopup?.url}
                title={showVideoPopup?.title}
                newTabUrl={showVideoPopup?.newTabUrl}
                videoIcon={
                  <Box
                    className="flex items-center rounded-xl px-2 py-1"
                    sx={{ bgcolor: theme.palette.primary.dark }}
                  >
                    <VideoIcon size={18} />
                  </Box>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ViewCourseOverview;
