import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button, LinearProgress } from '@mui/material';

const ConnectionStatus = ({ clientConnected, error, errorMessage }) => {
  if (!clientConnected && !error) {
    return (
      <Box className="flex flex-col items-center justify-center w-full h-96">
        <Typography variant="h4" color="text.primary">
          Connecting to VM...
        </Typography>
        <Box sx={{ width: { xs: '75%', sm: '50%', md: '25%' } }}>
          <LinearProgress />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="flex flex-col items-center justify-center w-full h-96">
        <Typography variant="h4" color="error">
          {errorMessage}
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => window.location.reload()}
          sx={{ px: 4, py: 1.5 }}
        >
          Reconnect
        </Button>
      </Box>
    );
  }

  return null;
};

ConnectionStatus.propTypes = {
  clientConnected: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ConnectionStatus;
