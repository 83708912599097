import React, { useState } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTypography,
  FdTab,
  FdBreadcrumbs,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import LabsTable from '../components/Labs/LabsTable';
import { LABS_STATUS } from '../constants';
import {
  listLabPrototypes,
  listModuleParts,
  getCourseModule,
} from '../graphql/queries';
import {
  updateLabPrototype,
  deleteCourseModule,
  deleteModulePart,
  stopPrototype,
} from '../graphql/mutations';
import { getLabStatus } from '../shared/utils/getLabStatus';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import {
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
} from '../shared/utils/toast';
import { isAdminOrManager } from '../shared/utils/getUsersPermissions';
import LabDuplicate from '../components/Labs/LabDuplicate';

const ListLabs = () => {
  const globalSnap = useSnapshot(globalStore);
  const [deleteLabPrototype, setDeleteLabPrototype] = useState(false);
  const [exitTestMode, setExitTestMode] = useState(false);
  const [copyLabPrototype, setCopyLabPrototype] = useState(false);
  const [selectedLabPrototype, setSelectedLabPrototype] = useState();
  const [labPrototypeId, setLabPrototypeId] = useState(undefined);
  const [labPrototypesData, setLabPrototypesData] = useState([]);
  const isAdmin = isAdminOrManager(globalSnap.userType);

  // List parts used by lab instance
  const [getModuleParts, { loading: listModulePartsLoading }] = useLazyQuery(
    gql(listModuleParts),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  // get module and parts
  const [getModule, { loading: getCourseModuleLoading }] = useLazyQuery(
    gql(getCourseModule),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  // List Lab Prototypes
  const { loading: listLabPrototypesLoading, refetch: refetchLabPrototypes } =
    useQueryRecursive(gql(listLabPrototypes), {
      variables: {
        filter: {
          orgId: {
            eq: globalSnap.orgId,
          },
          and: [
            { status: { ne: 'DELETED' } },
            { status: { ne: 'DELETE_REQUESTED' } },
          ],
        },
      },
      staleTime: {
        seconds: 0,
      },
      onCompleted: async (_data) => {
        const labPrototypes = _data?.listLabPrototypes?.items.map((lab) => {
          return {
            id: lab?.id,
            name: lab?.name,
            description: lab?.description,
            status: getLabStatus(lab.status),
            expiryDate:
              lab?.expiry && getDateTimeZoneFormatted(lab?.expiry, true),
            labDuration: lab?.duration,
            labNetworks: lab?.networks?.items,
            labVms: lab?.vms?.items,
            orgId: lab?.orgId,
            userId: lab?.userId,
            vms: lab?.vms?.items.length,
            instances: lab?.instances?.items?.length,
            noOfDependentChallenges: lab?.tasks?.items?.length,
            hasLabInstanesWithAssessment:
              lab?.instances?.items?.filter(
                (labInstance) => labInstance.assessmentId,
              )?.length > 0,
          };
        });

        setLabPrototypesData(labPrototypes);
      },
    });

  const [stopPrototypeMutation] = useMutation(gql(stopPrototype), {
    onCompleted: () => {
      refetchLabPrototypes();
    },
  });

  // Delete Lab Prototype
  const [updateLabPrototypeMutation, { loading: updateLabPrototypeLoading }] =
    useMutation(gql(updateLabPrototype), {
      refetchQueries: ['listLabPrototypes'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        refetchLabPrototypes();
        successToastMessage('Success! Lab deleted');
      },
      onError: () => {
        errorToastMessage('Lab not deleted');
      },
    });

  const rows = labPrototypesData || [];

  const actions = [
    {
      label: 'Edit',
      show: (row) => {
        return (
          [LABS_STATUS.DRAFT, LABS_STATUS.ERROR].includes(row.status) ||
          ([LABS_STATUS.AVAILABLE, LABS_STATUS.EXPIRED].includes(row.status) &&
            !row.hasLabInstanesWithAssessment)
        );
      },
      onClick: ({ id }) => singleSpa.navigateToUrl(`/labs/editDraft/${id}`),
    },
    {
      label: 'View',
      show: (row) =>
        [LABS_STATUS.AVAILABLE, LABS_STATUS.EXPIRED].includes(row.status),
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/labs/view/${id}`);
      },
    },
    {
      label: 'Delete',
      show: (row) => {
        return (
          [LABS_STATUS.DRAFT, LABS_STATUS.ERROR, LABS_STATUS.EXPIRED].includes(
            row.status,
          ) && !row.hasLabInstanesWithAssessment
        );
      },
      onClick: ({ id }) => {
        setDeleteLabPrototype(true);
        setLabPrototypeId(id);
      },
    },
    {
      label: 'Exit Edit Mode',
      show: (row) => [LABS_STATUS.TESTING].includes(row.status),
      onClick: ({ id }) => {
        setExitTestMode(true);
        setLabPrototypeId(id);
      },
    },

    {
      label: 'View edit mode',
      show: (row) => [LABS_STATUS.TESTING].includes(row.status),
      onClick: ({ id }) => {
        window.open(`/labs/test-lab/${id}`, '_blank');
      },
    },
    {
      label: 'Duplicate Original',
      show: (row) => [LABS_STATUS.AVAILABLE].includes(row.status),
      onClick: (row) => {
        setCopyLabPrototype(true);
        setSelectedLabPrototype(row);
      },
    },
  ];

  const viewLabs = (status) => {
    let labsByStatus = [];
    if (status === 'All') {
      labsByStatus = rows;
    } else {
      labsByStatus = rows?.filter((lab) => status === lab.status);
    }

    const isOnlyAdmin = globalSnap.userType === 'ADMIN';
    const selection =
      isOnlyAdmin && (status === 'All' || status === 'Available');

    return (
      <LabsTable
        rows={labsByStatus}
        actions={actions}
        headerActions={[
          {
            label: 'Create Lab',
            onClick: () => singleSpa.navigateToUrl('/labs/create'),
          },
        ]}
        selection={selection}
        refetchLabs={refetchLabPrototypes}
      />
    );
  };

  const deleteLab = async () => {
    // fetch all parts with matching labId
    getModuleParts({
      variables: {
        limit: 100000,
        filter: { labId: { eq: labPrototypeId } },
      },
      onCompleted: async (listModulePartsData) => {
        const allLabUsedParts =
          listModulePartsData?.listModuleParts?.items || [];
        // fetch modules for the parts
        await Promise.all(
          allLabUsedParts?.map(async (p) => {
            // delete part
            await deleteModulePartMutation({
              variables: {
                input: {
                  id: p.id,
                },
              },
            });
            getModule({
              variables: {
                id: p.courseModuleId,
              },
              onCompleted: async (courseModuleData) => {
                const partCount =
                  courseModuleData?.getCourseModule?.parts?.items.length || 0;

                // if module contains only no parts then delete module as well
                if (partCount === 0) {
                  await deleteCourseModuleMutation({
                    variables: {
                      input: {
                        id: p.courseModuleId,
                      },
                    },
                  });
                }
              },
            });
          }),
        );
      },
    });
    await updateLabPrototypeMutation({
      variables: {
        input: {
          id: labPrototypeId,
          status: 'DELETE_REQUESTED',
        },
      },
    });
  };
  const onStopLab = () => {
    stopPrototypeMutation({
      variables: {
        labPrototypeId,
      },
    });
  };

  if (
    listLabPrototypesLoading ||
    updateLabPrototypeLoading ||
    listModulePartsLoading ||
    getCourseModuleLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading
  ) {
    return null;
  }

  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[
          {
            url: isAdmin ? '/landing' : '/landing/landing-homepage',
            name: 'Home',
          },
        ]}
        currentPage="Manage Labs"
        renderAsButton
      />
      <FdTypography variant="h2">Manage Labs</FdTypography>
      <FdTab
        label={[
          {
            label: 'All',
            path: '/labs/',
            index: 0,
            data: viewLabs('All'),
          },
          {
            label: LABS_STATUS.AVAILABLE,
            path: '/labs/',
            index: 1,
            data: viewLabs(LABS_STATUS.AVAILABLE),
          },
          {
            label: LABS_STATUS.DRAFT,
            path: '/labs/',
            index: 2,
            data: viewLabs(LABS_STATUS.DRAFT),
          },
          {
            label: LABS_STATUS.BUILDING,
            path: '/labs/',
            index: 3,
            data: viewLabs(LABS_STATUS.BUILDING),
          },
          {
            label: LABS_STATUS.ERROR,
            path: '/labs/',
            index: 3,
            data: viewLabs(LABS_STATUS.ERROR),
          },
        ]}
      />

      {copyLabPrototype && (
        <LabDuplicate
          copyLabPrototype={copyLabPrototype}
          copyLabPrototypeCallBack={setCopyLabPrototype}
          labDetails={selectedLabPrototype}
          refetchLabPrototypes={refetchLabPrototypes}
        />
      )}

      {deleteLabPrototype && (
        <FdModal
          size="md"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Delete Lab</span>
            </Box>
          }
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to delete the lab?
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="body1" color="secondary">
                  This will permanently delete the lab, and remove it from all
                  courses.
                </FdTypography>
              </Box>
            </Box>
          }
          confirm="CANCEL"
          dismiss="Delete lab"
          open={deleteLabPrototype}
          disableConfirm={listModulePartsLoading || getCourseModuleLoading}
          onDismiss={async () => {
            setDeleteLabPrototype(false);
            await deleteLab();
          }}
          onConfirm={() => {
            setDeleteLabPrototype(false);
            warningToastMessage('Lab not deleted');
          }}
        />
      )}
      {exitTestMode && (
        <FdModal
          size="md"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Exit Test Mode</span>
            </Box>
          }
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to exit test mode?
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="body1" color="secondary">
                  This will delete your test lab. Any changes made to the lab
                  will be saved.
                </FdTypography>
              </Box>
            </Box>
          }
          confirm="CANCEL"
          dismiss="Exit Test mode"
          open={exitTestMode}
          onDismiss={() => {
            setExitTestMode(false);
            onStopLab();
          }}
          onConfirm={() => {
            setExitTestMode(false);
          }}
        />
      )}
    </Box>
  );
};
export default ListLabs;
