import React from 'react';
import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTextField, FdSelect } from '@fifthdomain/fe-shared';
import { VM_PLATFORMS } from '../constants';

const VMDetails = () => {
  const { control } = useFormContext();

  return (
    <Box>
      <Controller
        control={control}
        name="vmName"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="vmName"
              label="VM Name"
              required
              fullWidth
              error={error}
              helperText={error?.message}
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />
      <Box mt={0} mb={0}>
        <Controller
          control={control}
          name="vmNotes"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mb={2}>
              <FdTextField
                id="vmNotes"
                label="VM Notes"
                fullWidth
                multiline
                rows={3}
                error={error}
                helperText={error?.message}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
      <Box mt={0} mb={0}>
        <Controller
          control={control}
          name="vmPlatform"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mb={2}>
              <FdSelect
                id="vmPlatform"
                label="VM Platform"
                defaultSelected={VM_PLATFORMS.LINUX}
                options={[VM_PLATFORMS.LINUX, VM_PLATFORMS.WINDOWS]}
                fullWidth
                error={error}
                helperText={error?.message}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default VMDetails;
