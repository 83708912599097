import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTextField, FdTypography } from '@fifthdomain/fe-shared';
import FdTextView from '../../FdTextView';

const VideoPart = ({ moduleIdx, partIdx, isEdit }) => {
  const { control } = useFormContext();

  return (
    <Box>
      <Controller
        control={control}
        name={`modules[${moduleIdx}].parts[${partIdx}].video`}
        render={({
          field: { id, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <Box mt={1} mb={2}>
            {isEdit ? (
              <FdTextField
                key={id}
                id={`modules[${moduleIdx}].parts[${partIdx}].video`}
                label={
                  <>
                    <FdTypography variant="subtitle1">
                      Paste in Video URL
                    </FdTypography>
                    <Box mt={1}>
                      <FdTypography variant="body2">
                        The platform supports Vimeo embed URL link.
                      </FdTypography>
                    </Box>
                  </>
                }
                value={value}
                required
                fullWidth
                placeholder="Embed URL must be less than 1500 characters."
                error={error}
                helperText={error && error.message}
                data-cy={`modules[${moduleIdx}].parts[${partIdx}].video`}
                {...rest}
                inputRef={ref}
              />
            ) : (
              <FdTextView label="Paste in Video URL" value={value} />
            )}
          </Box>
        )}
      />
    </Box>
  );
};

VideoPart.propTypes = {
  moduleIdx: PropTypes.string.isRequired,
  partIdx: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default VideoPart;
