import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { PresentationIcon } from 'lucide-react';
import { FdCard, FdAlert, FdTypography } from '@fifthdomain/fe-shared';
import LessonTable from './LessonTable';
import { formatLesson } from '../Edit/utils';
import BlueFillerAniImage from './BlueFillerIAniImage';
import InfoAccordion from '../LessonView/InfoAccordion';

const LessonMaterial = ({
  allLessons,
  editMode,
  actionButtons,
  selectionValue,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields: lessons, move: moveLesson } = useFieldArray({
    control,
    name: 'lessons',
  });

  return (
    <Box mt={1}>
      <InfoAccordion
        title="What are Lessons?"
        message="Lessons are reusable learning content pieces that can be used in
              courses. Each lesson features a core exercise for participants to
              complete and may also include optional resources and quizzes to
              support learning. Lessons are mapped to the FifthDomain Cynaptic
              Skills Adaptor for accurate learning tracking."
        noBorder
        defaultExpanded
      />
      <Box>
        <FdCard className="my-4" variant="outlined">
          <Box className="flex justify-between">
            <Box className="flex items-center gap-x-2">
              <PresentationIcon />
              <FdTypography variant="h3">Lesson Selection</FdTypography>
            </Box>
            {editMode && actionButtons}
          </Box>
          <Box mt={1.5} mb={3}>
            {editMode ? (
              <FdTypography variant="body2" color="secondary">
                All lessons that have been added to this course are displayed
                below. Click <b> Edit</b> to add or remove lessons to this
                course.
              </FdTypography>
            ) : (
              <FdTypography variant="body2" color="secondary">
                All published lessons in your organisation are displayed below.
                Click <b>View</b> for details about each lesson, and use the
                checkboxes to add lessons to this course.
              </FdTypography>
            )}
          </Box>
          {errors?.lessons?.message && (
            <Box mb={1.5}>
              <FdAlert
                style={{ marginTop: '4px' }}
                variant="error"
                message={
                  <FdTypography variant="body2">
                    Please add at least <b> one </b> lesson to proceed.
                  </FdTypography>
                }
              />
            </Box>
          )}
          <FdAlert
            style={{ marginTop: '4px' }}
            variant="info"
            message="All labs (in lessons with lab exercises) have a duration of 2 hours. Participants can extend their lab instances as many times as they require, resetting their lab timers to 2 hours and retaining progress made. Participants can also reset their labs as needed, which will deploy a new lab instance and erase all progress.
        "
          />
          <Controller
            control={control}
            name="lessonIds"
            render={({ field: { value } }) => (
              <LessonTable
                selectionValue={editMode ? selectionValue : true}
                showOrder={selectionValue}
                lessonData={allLessons}
                selectionModel={value}
                onSelectionModelChange={(_values) => {
                  const data = allLessons
                    ?.filter((item) => _values?.includes(item?.id))
                    ?.map((lesson) => ({ ...lesson, lessonId: lesson?.id }));
                  setValue('lessonIds', _values);
                  setValue('lessons', data);
                }}
              />
            )}
          />
          {!editMode && (
            <FdCard variant="outlined" className="h-full mt-4">
              <Box className="flex items-center gap-x-2 my-2">
                <PresentationIcon />
                <FdTypography variant="h3">Lesson Order</FdTypography>
                <FdTypography
                  variant="body1"
                  color="secondary"
                  className="mt-1"
                >
                  optional
                </FdTypography>
              </Box>
              <FdTypography variant="body2" color="secondary">
                This table displays the lesson order that participants will see.
                Reorder lessons by dragging or using the arrows. Lessons will
                appear here as you select them from the Lesson Selection table.
              </FdTypography>
              {lessons?.length > 0 ? (
                <LessonTable
                  lessonData={lessons?.map(formatLesson) || []}
                  rowReordering
                  onRowOrderChange={({ oldIndex, targetIndex }) => {
                    moveLesson(oldIndex, targetIndex);
                  }}
                  onUpArrow={(rowIndex) => moveLesson(rowIndex, rowIndex - 1)}
                  onDownArrow={(rowIndex) => moveLesson(rowIndex, rowIndex + 1)}
                  showUpDown
                  isOrderTable
                />
              ) : (
                <FdCard variant="outlined" className="mt-4">
                  <Box
                    height="350px"
                    className="flex flex-col items-center justify-center w-full gap-y-2 rounded"
                    style={{ backgroundColor: 'rgba(51, 51, 255, 0.03)' }}
                  >
                    <Box height="120px" width="120px">
                      <BlueFillerAniImage />
                    </Box>
                    <FdTypography variant="body2" color="secondary">
                      No lessons added yet.
                    </FdTypography>
                  </Box>
                </FdCard>
              )}
            </FdCard>
          )}
        </FdCard>
      </Box>
    </Box>
  );
};

LessonMaterial.propTypes = {
  allLessons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actionButtons: PropTypes.node,
  editMode: PropTypes.bool,
  selectionValue: PropTypes.bool,
};

LessonMaterial.defaultProps = {
  actionButtons: undefined,
  editMode: false,
  selectionValue: false,
};

export default LessonMaterial;
