import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckIcon, SquarePenIcon, XIcon } from 'lucide-react';
import { gql, useMutation } from '@apollo/client';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import {
  FdMarkdownEditor,
  FdTypography,
  FdMarkdownRender,
  FdLoadingSpinner,
  globalStore,
  useSnapshot,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { createLessonNote, updateLessonNote } from '../../../graphql/mutations';
import { listLessonNotesByLessonId } from '../../../queries/customQueries';

const NotesDrawerContent = ({ lessonId, courseUserId, courseId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState({ noteId: '', content: '' });
  const theme = useTheme();
  const { userId } = useSnapshot(globalStore);

  const { loading: courseUserDataLoading, refetch: refetchNotes } =
    useQueryRecursive(gql(listLessonNotesByLessonId), {
      variables: {
        lessonId,
        courseUserId: {
          eq: courseUserId,
        },
      },
      staleTime: { seconds: 0 },
      onCompleted: (data) => {
        const _notes = data?.listLessonNotesByLessonId?.items[0] || {};
        setNotes({
          noteId: _notes?.id,
          content: _notes?.content,
        });
      },
    });

  const [createNoteMutation, { loading: createNoteLoading }] = useMutation(
    gql(createLessonNote),
  );

  const [updateNoteMutation, { loading: updateNoteLoading }] = useMutation(
    gql(updateLessonNote),
  );
  const loading =
    courseUserDataLoading || createNoteLoading || updateNoteLoading;

  return (
    <Box className="flex flex-col gap-y-2">
      <Box
        className="flex flex-col rounded-lg overflow-hidden"
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
      >
        <Box
          className="flex items-center justify-between gap-x-2 px-4"
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.background.paper, 0.5),
          }}
          height={48}
        >
          <FdTypography variant="subtitle2">Your Notes</FdTypography>
          <Box className="flex items-center">
            {isEditing ? (
              <>
                <IconButton
                  onClick={() => {
                    setIsEditing(false);
                    refetchNotes();
                  }}
                >
                  <XIcon style={{ color: theme?.fdProColors.alert.error }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsEditing(false);
                    if (notes?.noteId) {
                      updateNoteMutation({
                        variables: {
                          input: {
                            id: notes?.noteId,
                            content: notes?.content,
                          },
                        },
                      });
                    } else {
                      createNoteMutation({
                        variables: {
                          input: {
                            courseId,
                            courseUserId,
                            lessonId,
                            userId,
                            content: notes?.content,
                          },
                        },
                      });
                    }
                  }}
                >
                  <CheckIcon
                    style={{ color: theme?.fdProColors.alert.success }}
                  />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={() => setIsEditing(true)}>
                <SquarePenIcon style={{ color: theme?.palette.primary.main }} />
              </IconButton>
            )}
          </Box>
        </Box>
        {loading ? (
          <Box className="flex items-center justify-center w-full h-20">
            <FdLoadingSpinner size={20} />
          </Box>
        ) : (
          <Box className="p-4">
            {isEditing ? (
              <FdMarkdownEditor
                id="notes"
                markdown={notes?.content}
                setMarkdown={(val) => setNotes({ ...notes, content: val })}
                placeholder="Enter notes"
                required
              />
            ) : (
              <FdTypography variant="body2" color="secondary">
                <FdMarkdownRender
                  markdown={notes?.content || '*No notes added*'}
                />
              </FdTypography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

NotesDrawerContent.propTypes = {
  lessonId: PropTypes.number.isRequired,
  courseUserId: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
};

export default NotesDrawerContent;
