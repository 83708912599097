import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ModuleItem from './ModuleItem';

function Modules({ modules, allQuizzes, userId, courseId }) {
  return (
    <Box>
      {modules.map((module) => (
        <ModuleItem
          key={module.id}
          module={module}
          allQuizzes={allQuizzes}
          userId={userId}
          courseId={courseId}
        />
      ))}
    </Box>
  );
}

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allQuizzes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};
export default Modules;
