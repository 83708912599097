export const USER_PERMISSION_TYPE = Object.freeze({
  CREATE: 'CREATE',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  MANAGE_TRAINING: 'MANAGE_TRAINING',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
});

export const LABS_STATUS = Object.freeze({
  AVAILABLE: 'Available',
  DRAFT: 'Draft',
  EXPIRED: 'Expired',
  BUILDING: 'Building',
  ERROR: 'Error',
  TESTING: 'In Editing',
});

export const LAB_INSTANCE_STATUS = Object.freeze({
  IN_BUILD_QUEUE: 'BUILDING',
  BUILD_IN_PROGRESS: 'BUILDING',
  IN_DELETE_QUEUE: 'DELETING',
  DELETE_IN_PROGRESS: 'DELETING',
  READY: 'ACTIVE',
});

export const LAB_INSTANCE_IN_USE_STATUS = Object.freeze(['READY']);

export const VM_STATUS = Object.freeze({
  READY: 'READY',
  BUILDING: 'BUILDING',
  UPLOADED: 'UPLOADED',
});

export const VM_PLATFORMS = Object.freeze({
  LINUX: 'Linux',
  WINDOWS: 'Windows',
});

export const COURSE_PARTS = Object.freeze([
  'PDF',
  'Markdown',
  'Lab',
  'Quiz',
  'Video',
]);

export const COURSE_CATEGORIES_IMAGE = Object.freeze({
  'SOC Analyst 1 - Analysis': 'Analysis.jpg',
  'SOC Analyst 1 - Intrusion Detection': 'Intrusion Detection.jpg',
  'SOC Analyst 1 - Incident Management': 'Incident Management.jpg',
  'SOC Analyst 1 - Vulnerability': 'Vulnerability.jpg',
  Custom: 'Custom.jpg',
});

export const CONTENT_IMAGES = [
  'Analysis.jpg',
  'Intrusion Detection.jpg',
  'Incident Management.jpg',
  'Vulnerability.jpg',
  'Custom.jpg',
];

export const QUESTION_MAPPING = {
  'Free Text': 'FREE_TEXT',
  'Multiple Choice': 'MULTIPLE_CHOICE',
  'Single Choice': 'SINGLE_CHOICE',
  Ordering: 'ORDERING',
};

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const TEST_LAB_STATUS = Object.freeze({
  READY: 'READY',
  POWERING_ON: 'POWERING_ON',
  POWERING_OFF: 'POWERING_OFF',
  TESTING: 'TESTING',
  STOPPING: 'STOPPING',
  NOT_STARTED: 'NOT_STARTED',
  OFF: 'OFF',
  DELETED: 'DELETED',
});

export const VM_CONNECTION_TYPE = [
  'VPN',
  'VDI',
];

export const VM_SIZE = [
  'SMALL',
  'MEDIUM',
  'LARGE',
];

export const FILE_MIMES = {
  '.yml': { 'text/yaml': ['.yml'] },
  '.tar': { 'application/tar': ['.tar'] },
  '.tar.gz': { 'application/gzip': ['.tar.gz'] },
  '.ova': { 'application/x-virtualbox-ova': ['.ova'] },
  '.pdf': { 'application/pdf': ['.pdf'] },
  '.md': { 'text/markdown': ['.md'] },
};
