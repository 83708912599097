export const upperCaseFirstLetter = (str) =>
  str && str[0].toUpperCase() + str.substring(1).toLowerCase();

export const getCommaSeparated = (arr, includeQuotes = false) => {
  const arrQ = includeQuotes ? arr.map((a) => `"${a}"`) : arr;
  if (arrQ.length === 1) return arrQ[0];
  const firsts = arrQ.slice(0, arrQ.length - 1);
  const last = arrQ[arrQ.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const getSearchResults = (data, text) =>
  text === ''
    ? data
    : data?.filter((_content) =>
        Object.values(_content)
          .filter((item) => item !== null && item !== undefined && item !== '')
          .some((val) =>
            val.toString().toLowerCase().startsWith(text.toLowerCase()),
          ),
      );

export const truncateTextByLength = (text, maxLength = text?.length) => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }
  const truncatedText = `${text.substring(0, maxLength - 3)}...`;
  return truncatedText;
};

export const sortDataAlphabetically = (data, key, order = 'asc') => {
  return (
    data?.sort((a, b) => {
      const dataA = a[key]?.toUpperCase(); // ignore upper and lowercase
      const dataB = b[key]?.toUpperCase(); // ignore upper and lowercase

      if (order === 'asc') {
        if (dataA < dataB) {
          return -1;
        }
        if (dataA > dataB) {
          return 1;
        }
        return 0;
      }
      if (dataA > dataB) {
        return -1;
      }
      if (dataA < dataB) {
        return 1;
      }
      return 0;
    }) || []
  );
};
