import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as singleSpa from 'single-spa';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  BasePage,
  FdButton,
  FdProgress,
  FdModal,
  amplifyConfig,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import scrollToTop from '../shared/utils/scroll';
import CourseDetails from '../components/Courses/Create/CourseDetails';
import CourseMaterial from '../components/Courses/Create/CourseMaterial';
import { getCourseForEdit, listQuizzesByOrgId } from '../queries/customQueries';
import {
  createCourse,
  createCourseModule,
  updateCourseModule,
  createModulePart,
  updateModulePart,
  updateCourse,
  deleteCourseModule,
  deleteModulePart,
} from '../graphql/mutations';
import { listLabPrototypes, listCourseCategories } from '../graphql/queries';
import {
  successToastMessage,
  warningToastMessage,
} from '../shared/utils/toast';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../validation-schemas/Course';
import {
  deleteModulesParts,
  createModules,
} from '../components/Courses/Create/courseUtils';
import { COURSE_CATEGORIES_IMAGE } from '../constants';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';

const useStyles = makeStyles()(() => ({
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const DuplicateCourse = () => {
  const { courseIdToDuplicate } = useParams();
  const history = useHistory();
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Add Details', 'Add Modules'];
  const initialValues = {
    ...courseInitialValues,
  };
  const validationSchema = Yup.object().shape({
    ...courseValidationSchema,
  });

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    getValues,
    setValue,
    trigger,
    reset,
    formState: { isDirty },
  } = reactHookFormMethods;

  const { orgId, userId } = useSnapshot(globalStore);

  // List Quizzes
  const { data: quizData, loading: quizDataLoading } = useQueryRecursive(
    gql(listQuizzesByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          status: { eq: 'READY' },
        },
      },
    },
  );

  const allQuizzes = quizData?.listQuizzes?.items || [];
  const allLabs = labsData?.listLabPrototypes?.items || [];

  const { loading: courseLoading } = useQuery(gql(getCourseForEdit), {
    variables: {
      id: courseIdToDuplicate,
    },
    skip: !courseIdToDuplicate,
    fetchPolicy: 'cache-and-network',
    onCompleted: (_data) => {
      const {
        name,
        availability,
        description,
        status,
        courseModules,
        category,
      } = _data?.getCourse;
      reset({
        ...initialValues,
        courseId: undefined,
        description,
        availability,
        status,
        category: category?.name,
        modules: courseModules.items
          .map((m, mIdx) => ({
            moduleId: undefined,
            moduleName: m.name,
            moduleDescription: m.description,
            moduleDuration: m.duration,
            order: m.orderNumber || mIdx + 1,
            accordionState: false,
            createdAt: m.createdAt,
            parts: m.parts?.items
              .map((p, pIdx) => ({
                partId: undefined,
                partType:
                  p.type !== 'PDF' ? upperCaseFirstLetter(p.type) : p.type,
                partTitle: p.name,
                partDescription: p.description,
                order: p.orderNumber || pIdx + 1,
                accordionState: false,
                lab: p.labId ? p.lab?.name : '',
                isLabTransferred: false,
                quiz: p.quizId ? p.quiz?.name : '',
                file: {
                  bucket: p.file?.bucket,
                  key: p.file?.key,
                  region: p.file?.region,
                },
                labDuration: p.type === 'LAB' ? p.duration : undefined,
                labExpiryDateTime: undefined,
                labAbandonment:
                  p.type === 'LAB' ? p.inactivityExpiry : undefined,
                initialLabPool:
                  p.type === 'LAB' ? p.initialLabCount : undefined,
                minimumLabPool: p.type === 'LAB' ? p.minLabCount : undefined,
                deletionMode: p.type === 'LAB' ? p.deletionMode : undefined,
                video: p.type === 'VIDEO' ? p.url : undefined,
              }))
              .sort((a, b) => a.order - b.order),
          }))
          .sort((a, b) => a.order - b.order),
        usersGroupsCount: 0,
        partsSummary: courseModules.items
          .map((m, mIdx) => ({
            moduleId: undefined,
            order: m.orderNumber || mIdx + 1,
            parts: m.parts?.items
              .map((p, pIdx) => ({
                order: p.orderNumber || pIdx + 1,
                partType:
                  p.type !== 'PDF' ? upperCaseFirstLetter(p.type) : p.type,
                quiz:
                  allQuizzes.filter((l) => l.id === p.quizId)[0]?.name || '',
                labDuration: p.type === 'LAB' ? p.duration : undefined,
              }))
              .sort((a, b) => a.order - b.order),
          }))
          .sort((a, b) => a.order - b.order),
      });
      setValue('name', `Copy of ${name}`, { shouldDirty: true }); // this is also to set the form dirty
    },
  });

  // List Course Categories
  const { data: courseCategories, loading: courseCategoriesLoading } =
    useQueryRecursive(gql(listCourseCategories));

  const [createCourseMutation, { loading: createCourseLoading }] = useMutation(
    gql(createCourse),
  );

  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );

  const [createCourseModuleMutation, { loading: createCourseModuleLoading }] =
    useMutation(gql(createCourseModule));

  const [updateCourseModuleMutation, { loading: updateCourseModuleLoading }] =
    useMutation(gql(updateCourseModule));

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  const [updateModulePartMutation, { loading: updateModulePartLoading }] =
    useMutation(gql(updateModulePart));

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  if (
    courseLoading ||
    courseCategoriesLoading ||
    quizDataLoading ||
    labsDataLoading
  )
    return <FdProgress />;

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['name', 'description', 'category']);
        break;
      }
      case 1:
        result = await trigger(['modules']);
        break;
      default:
        break;
    }
    return result;
  };

  const createCourseByStatus = async ({ status, navigate }) => {
    const { courseId, name, description, category, availability } = getValues();
    const allCategories = courseCategories?.listCourseCategories?.items?.map(
      (c) => c.name,
    );
    // random category value initially
    const categoryValue =
      category ||
      allCategories[Math.floor(Math.random() * allCategories.length)];

    const actionId = courseId ? { id: courseId } : undefined;
    const actionMutation = courseId
      ? updateCourseMutation
      : createCourseMutation;
    // create/edit course
    const categoryInput = categoryValue
      ? {
          categoryId: courseCategories?.listCourseCategories?.items?.find(
            (courseCategory) => courseCategory.name === categoryValue,
          ).id,
          image: {
            key: COURSE_CATEGORIES_IMAGE[categoryValue],
            bucket: amplifyConfig.aws_user_files_s3_bucket,
            region: amplifyConfig.aws_user_files_s3_bucket_region,
          },
        }
      : {};

    await actionMutation({
      variables: {
        input: {
          ...categoryInput,
          description,
          name,
          orgId,
          ownerId: userId,
          status,
          availability,
          ...actionId,
        },
      },
      onCompleted: async (_data) => {
        const actionCourse = courseId ? 'updateCourse' : 'createCourse';
        const draftCourseId = _data?.[actionCourse]?.id;
        if (!courseId) {
          setValue('courseId', draftCourseId);
        }
        await createModules({
          updateCourseModuleMutation,
          createCourseModuleMutation,
          values: getValues(),
          courseId: courseId || draftCourseId,
          setValue,
          updateModulePartMutation,
          createModulePartMutation,
          allQuizzes,
          allLabs,
        });
        await deleteModulesParts({
          updateModulePartMutation,
          deleteModulePartMutation,
          deleteCourseModuleMutation,
          values: getValues(),
        });
        if (navigate) {
          reset();
          // navigate & show success toast
          const msg =
            status === 'AVAILABLE'
              ? 'Success! Duplicate Course created'
              : 'Success! Draft Saved';
          successToastMessage(msg);
          setTimeout(() => {
            singleSpa.navigateToUrl(
              `/labs/courses/view-admin/${_data?.createCourse?.id}/?tabindex=1`,
            );
          }, 500);
        }
      },
    });
  };

  const saveDraft = async () => {
    const { modules: draftModules } = getValues();
    if (draftModules.length > 0) {
      const noModuleErrors = await trigger(['modules']);
      if (!noModuleErrors) {
        setActiveStep(1);
        return;
      }
    }
    if (await trigger(['name'])) {
      await createCourseByStatus({
        status: 'DRAFT',
        navigate: true,
      });
    }
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
      if (activeStep === 1) {
        // save & publish
        await createCourseByStatus({
          status: 'AVAILABLE',
          navigate: true,
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const loadStatus =
    createCourseLoading ||
    updateCourseLoading ||
    createCourseModuleLoading ||
    updateCourseModuleLoading ||
    createModulePartLoading ||
    updateModulePartLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading;

  return (
    <BasePage
      heading="Duplicate Course"
      data-cy="duplicate-course-base-page"
      breadCrumbs={[{ url: '/landing', name: 'Home' }]}
      currentPageBreadcrumbLabel="Duplicate Course"
      renderBreadCrumbAsButton
    >
      <Box width="534px">
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          data-cy="stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel data-cy="stepper-label">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...reactHookFormMethods}>
        <form>
          {
            {
              0: (
                <Box>
                  <CourseDetails heading="Add Course Details" isEdit />
                </Box>
              ),
              1: (
                <Box>
                  <CourseMaterial
                    allQuizzes={allQuizzes}
                    allLabs={allLabs}
                    isEdit
                  />
                </Box>
              ),
            }[activeStep]
          }
          <Box display="flex" justifyContent="space-between" width="100%">
            {activeStep === 0 ? (
              <FdButton
                size="large"
                variant="secondary"
                onClick={() => singleSpa.navigateToUrl('/labs/courses')}
                data-cy="cancel-button"
              >
                Cancel
              </FdButton>
            ) : (
              <FdButton
                size="large"
                variant="secondary"
                onClick={handleBack}
                data-cy="back-button"
              >
                Back
              </FdButton>
            )}
            <Box>
              <FdButton
                size="large"
                variant="secondary"
                onClick={saveDraft}
                style={{ marginRight: '1rem' }}
                disabled={createCourseLoading}
              >
                Save Draft
              </FdButton>
              <FdButton size="large" onClick={handleNext} disabled={loadStatus}>
                {loadStatus
                  ? 'Loading...'
                  : activeStep === steps.length - 1
                    ? 'PUBLISH'
                    : 'Next'}
              </FdButton>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (
            window.location.pathname !==
            `/labs/courses/duplicate/${courseIdToDuplicate}`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Abandon Duplicate Creation?"
            description="Are you sure that you want to abandon creating this duplicate course? Any inputs made during the process will be lost."
            confirm="Confirm"
            dismiss="Cancel"
            open
            onConfirm={() => {
              warningToastMessage('Duplicate course not created');
              onConfirm();
            }}
            onDismiss={onCancel}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default DuplicateCourse;
