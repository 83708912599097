import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, IconButton, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { CircleXIcon } from 'lucide-react';
import { FdButton, FdTypography } from '@fifthdomain/fe-shared';
import { FILE_MIMES } from '../../../constants';

const FileUpload = ({ onDrop, fileTypes, file, noFileMessage, onDelete }) => {
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: FILE_MIMES[fileTypes],
    multiple: false,
  });
  const fileUrl = file instanceof File && file ? URL.createObjectURL(file) : '';

  return (
    <Box>
      <Box className="flex items-center gap-x-2" {...getRootProps()}>
        <input {...getInputProps()} />
        <FdButton
          onClick={open}
          data-cy="upload-btn"
          disabled={file}
          aria-label="add-artifact"
          size="medium"
        >
          Upload
        </FdButton>
        {!file && <FdTypography variant="body2">{noFileMessage}</FdTypography>}
        {file && (
          <Box className="flex items-center gap-x-1 pl-2 bg-gray-300 rounded-full">
            <Link
              href={fileUrl}
              download={file?.name}
              variant="captiontext1"
              className="font-bold"
            >
              {file?.name}
            </Link>
            <IconButton size="small" onClick={onDelete}>
              <CircleXIcon className="text-black" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  fileTypes: PropTypes.string,
  file: PropTypes.objectOf(PropTypes.shape({})),
  noFileMessage: PropTypes.string,
};

FileUpload.defaultProps = {
  inProgress: false,
  fileTypes: '',
  file: undefined,
  noFileMessage: 'No file uploaded',
};

export default FileUpload;
