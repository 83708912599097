import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';
import SquareButton from './SquareButton';
import DragReorderContainer from './DragReorderContainer';
import CourseResourceAccordion from './CourseResourceAccordion';
import ResourceTypeIcon from '../LessonView/ResourceTypeIcon';
import FieldView from './FieldView';
import VideoPlayer from '../LessonView/VideoPlayer';
import InfoAccordion from '../LessonView/InfoAccordion';
import DownloadLink from '../LessonView/DownloadLink';

const CourseResources = ({ editMode, isEdit, actionButtons }) => {
  const { control, watch } = useFormContext();
  const {
    fields: courseResources,
    append: appendCourseResource,
    move: moveCourseResource,
    remove: removeCourseResource,
  } = useFieldArray({
    control,
    name: 'courseResources',
  });

  const watchResource = watch('courseResources');

  const { append: addRemoveCourseResourceIdx } = useFieldArray({
    control,
    name: 'resourcesDeleted',
  });

  const courseResourcesLength = courseResources?.length;
  const courseResourcesAccordions = courseResources?.map((r, index) => ({
    id: r?.id,
    content: (
      <CourseResourceAccordion
        resourceIdx={index}
        removeResource={removeCourseResource}
        addRemoveResourceIdx={addRemoveCourseResourceIdx}
        dbId={r?.dbId}
      />
    ),
  }));

  const onDragComplete = (source, destination) => {
    moveCourseResource(source, destination);
  };

  return (
    <Box mt={1}>
      <InfoAccordion
        title="What are Course Resources?"
        message="Course resources are supplementary materials available to participants as they complete a course (e.g., recommended readings or prerequisite materials). You can add PDFs, markdown files, and videos. Adding course resources is optional but recommended for additional support. Note that individual lessons may also have their own lesson-specific resources."
        noBorder
        defaultExpanded
      />
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex justify-between">
          <Box className="flex flex-col gap-y-2">
            <Box className="flex items-end gap-x-2 mb-1">
              <FdTypography variant="h3">Course Resources</FdTypography>
              {!editMode ? (
                <FdTypography
                  variant="body1"
                  color="secondary"
                  style={{ lineHeight: '32px' }}
                >
                  optional
                </FdTypography>
              ) : (
                <FdTypography variant="h3">
                  ({courseResourcesLength})
                </FdTypography>
              )}
            </Box>
            {!editMode && (
              <FdTypography variant="body2" color="secondary">
                Click <strong>Add Resource</strong> to include materials at the
                course level. After selecting a resource type, provide the
                required details or click Delete to remove the resource.
              </FdTypography>
            )}
          </Box>
          {editMode && actionButtons}
        </Box>
        {editMode && !isEdit ? (
          watchResource?.length > 0 ? (
            watchResource?.map((r) => (
              <FdCard variant="outlined" className="mb-4">
                <Box key={r?.id} className="flex flex-col gap-y-2">
                  <Box className="flex items-center flex-wrap gap-x-2 gap-y-1">
                    <ResourceTypeIcon type={r?.resourceType} />
                    <FdTypography variant="subtitle1">
                      {r?.resourceName}
                    </FdTypography>
                  </Box>
                  {r?.resourceType === 'PDF' && (
                    <FieldView
                      value={
                        <Box className="flex items-center justify-start gap-x-2">
                          <DownloadLink
                            name={r?.resourcePdf?.name}
                            url={
                              r?.resourcePdf instanceof File && r?.resourcePdf
                                ? URL.createObjectURL(r?.resourcePdf || {})
                                : r?.resourcePdf?.url
                            }
                          />
                        </Box>
                      }
                      highlight
                    />
                  )}
                  {r?.resourceType === 'MARKDOWN' && (
                    <InfoAccordion
                      title="Markdown"
                      message={r?.resourceMarkdown}
                      noBorder
                    />
                  )}
                  {r?.resourceType === 'VIDEO' && (
                    <FieldView
                      value={<VideoPlayer url={r?.resourceVideo} />}
                      highlight
                    />
                  )}
                  <InfoAccordion
                    title="Resource Description"
                    message={
                      r?.resourceDescription || <em>No description added</em>
                    }
                    noBorder
                  />
                </Box>
              </FdCard>
            ))
          ) : (
            <FieldView highlight />
          )
        ) : (
          <>
            <DragReorderContainer
              dragItems={courseResourcesAccordions}
              onDragComplete={onDragComplete}
            />
            <Box my={3}>
              <SquareButton
                label="Add Resource"
                onClick={() =>
                  appendCourseResource({
                    resourceOrder: courseResourcesLength,
                  })
                }
              />
            </Box>
          </>
        )}
      </FdCard>
    </Box>
  );
};

CourseResources.propTypes = {
  editMode: PropTypes.bool,
  isEdit: PropTypes.bool,
  actionButtons: PropTypes.node,
};

CourseResources.defaultProps = {
  editMode: false,
  isEdit: false,
  actionButtons: undefined,
};

export default CourseResources;
