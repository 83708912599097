import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import shortid from 'shortid';
import { Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { FdCheckbox, FdTextField } from '@fifthdomain/fe-shared';
import QuizAnswerCard from './QuizAnswerCard';

const QuizOptions = ({ question, idx, viewMode, top3QuizAttempts }) => {
  const { control, setValue, getValues, watch } = useFormContext();
  const { selectedLessonOrderId } = getValues();
  const answersSingleTextValue = question?.answersSingleText?.find(
    (a) => a?.inputAnswer,
  )?.answer;
  const freeTextValue = watch(
    `lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].inputAnswer`,
  );

  return (
    <Box mt={2}>
      {
        {
          FREE_TEXT: (
            <Box mt={1} mb={2}>
              {viewMode ? (
                <QuizAnswerCard
                  top3QuizAttempts={top3QuizAttempts}
                  answer={{
                    questionId: question?.dbId,
                    answer: question?.inputAnswer,
                  }}
                />
              ) : (
                <FdTextField
                  id={`lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].inputAnswer`}
                  label=""
                  value={freeTextValue}
                  required
                  fullWidth
                  placeholder="Enter Answer"
                  data-cy="name"
                  multiline
                  rows={3}
                  onChange={(e) => {
                    setValue(
                      `lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].inputAnswer`,
                      e.target.value,
                    );
                    setValue(
                      `lessons[${selectedLessonOrderId - 1}].quizAttemptInProgress`,
                      true,
                    );
                  }}
                />
              )}
            </Box>
          ),
          MULTIPLE_CHOICE: (
            <Box mt={2}>
              {viewMode ? (
                <QuizAnswerCard
                  top3QuizAttempts={top3QuizAttempts}
                  answer={{
                    questionId: question?.dbId,
                    answer: question?.answersMultipleText?.filter(
                      (c) => c.inputAnswer,
                    )?.length
                      ? question?.answersMultipleText
                          ?.filter((c) => c.inputAnswer)
                          ?.map((i) => `${i?.answer}, `)
                      : undefined,
                  }}
                />
              ) : (
                question?.answersMultipleText?.map((o, oIdx) => (
                  <Controller
                    control={control}
                    name={`lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].answersMultipleText[${oIdx}].inputAnswer`}
                    render={({ field: { ref, value: fieldValue } }) => (
                      <Box>
                        <FdCheckbox
                          id={`lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].answersMultipleText[${oIdx}].inputAnswer`}
                          label={o.answer}
                          key={shortid.generate()}
                          checked={fieldValue}
                          value={fieldValue?.value}
                          onChange={(e) => {
                            setValue(
                              `lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].answersMultipleText[${oIdx}].inputAnswer`,
                              e,
                              { shouldDirty: true },
                            );
                            setValue(
                              `lessons[${selectedLessonOrderId - 1}].quizAttemptInProgress`,
                              true,
                            );
                          }}
                          inputRef={ref}
                        />
                      </Box>
                    )}
                  />
                ))
              )}
            </Box>
          ),
          SINGLE_CHOICE: (
            <Box mt={2} mr={1}>
              {viewMode ? (
                <QuizAnswerCard
                  top3QuizAttempts={top3QuizAttempts}
                  answer={{
                    questionId: question?.dbId,
                    answer: question?.answersSingleText.find(
                      (c) => c.inputAnswer,
                    )?.answer,
                  }}
                />
              ) : (
                <RadioGroup
                  name="radio-buttons-group"
                  value={answersSingleTextValue}
                  onChange={(e) => {
                    const selectedValue = e.target.defaultValue;
                    question?.answersSingleText?.forEach((mc, mcIdx) => {
                      const checkValue = selectedValue === mc.answer;
                      setValue(
                        `lessons[${selectedLessonOrderId - 1}].quiz.questions[${idx}].answersSingleText[${mcIdx}].inputAnswer`,
                        checkValue,
                        { shouldDirty: true },
                      );
                      setValue(
                        `lessons[${selectedLessonOrderId - 1}].quizAttemptInProgress`,
                        true,
                      );
                    });
                  }}
                >
                  {question?.answersSingleText?.map((ro) => (
                    <FormControlLabel
                      control={<Radio />}
                      value={ro.answer}
                      label={ro.answer}
                      key={ro.id}
                    />
                  ))}
                </RadioGroup>
              )}
            </Box>
          ),
        }[question?.questionType]
      }
    </Box>
  );
};

QuizOptions.propTypes = {
  question: PropTypes.shape({
    dbId: PropTypes.string,
    answersMultipleText: PropTypes.arrayOf(
      PropTypes.shape({
        inputAnswer: PropTypes.bool,
        answer: PropTypes.string,
      }),
    ),
    answersSingleText: PropTypes.arrayOf(PropTypes.shape({})),
    inputAnswer: PropTypes.bool,
    questionType: PropTypes.string,
  }).isRequired,
  idx: PropTypes.number.isRequired,
  viewMode: PropTypes.bool.isRequired,
  top3QuizAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default QuizOptions;
