import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import {
  FdButton,
  FdModal,
  FdTypography,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { startLab } from '../../../graphql/mutations';
import {
  getVmConsole,
  listLabInstancesbyExerciseId,
} from '../../../graphql/queries';
import VdiConsole from '../vdi-console/VdiConsole';

const LabConsole = ({ selectedLesson }) => {
  const { userId } = useSnapshot(globalStore);
  const [activeLabInstance, setActiveLabInstance] = useState(undefined);
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [consoleUrl, setConsoleUrl] = useState('');
  const parentRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { watch } = useFormContext();
  const { exerciseId, exerciseLab, lessonId, exerciseVmSelected, idx } =
    selectedLesson || {};
  const watchVmSelectedId = watch(`lessons[${idx}].exerciseVmSelected.id`);

  const [fetchConsoleUrl, { loading: fetchConsoleUrlLoading }] = useLazyQuery(
    gql(getVmConsole),
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const { url } = data.getVmConsole || {};
        setConsoleUrl(url);
        if (url) {
          // eslint-disable-next-line no-use-before-define
          stopPolling();
          setPollingInProgress(false);
        }
      },
    },
  );

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
    refetch: refetchLabInstances,
  } = useQueryRecursive(gql(listLabInstancesbyExerciseId), {
    variables: {
      filter: {
        and: [
          { status: { ne: 'DELETED' } },
          { status: { ne: 'IN_DELETE_QUEUE' } },
          { status: { ne: 'DELETE_IN_PROGRESS' } },
          { status: { ne: 'DELETE_FAILED' } },
          { status: { ne: 'BUILD_FAILED' } },
        ],
      },
      userId: { eq: userId },
      exerciseId,
    },
    skip: !exerciseId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const labInstance = _data?.listLabInstancesbyExerciseId?.items?.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      )[0];
      const vmInstances = labInstance?.vms?.items || [];
      const vm = vmInstances?.length > 1 ? exerciseVmSelected : vmInstances[0];
      setActiveLabInstance(labInstance);
      if (!labInstance?.status) {
        setConsoleUrl('');
        stopPolling();
        setPollingInProgress(false);
      }
      if (labInstance?.status === 'READY' && vm?.id) {
        fetchConsoleUrl({
          variables: {
            vmId: vm?.id,
            type: 'LAB',
          },
        });
      } else if (vmInstances.length > 0) {
        startPolling(5000);
      }
    },
  });

  const [startLabMutation, { loading: startLabLoading }] = useMutation(
    gql(startLab),
    {
      onCompleted: (_data) => {
        startPolling(5000);
        setPollingInProgress(true);
      },
    },
  );

  useEffect(() => {
    setConsoleUrl('');
    refetchLabInstances();
  }, [refetchLabInstances, watchVmSelectedId, selectedLesson]);

  useEffect(() => {
    const updateDimensions = () => {
      if (parentRef.current) {
        const { width, height } = parentRef.current.getBoundingClientRect();
        setDimensions({ width: Math.floor(width), height: Math.floor(height) });
      }
    };

    updateDimensions();
    const observer = new ResizeObserver(updateDimensions);
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(parentRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef.current]);

  const token = consoleUrl
    ? new URLSearchParams(consoleUrl.split('?')[1]).get('token')
    : '';

  if (
    startLabLoading ||
    labInstancesLoading ||
    pollingInProgress ||
    fetchConsoleUrlLoading ||
    activeLabInstance?.status === 'BUILD_IN_PROGRESS'
  ) {
    return (
      <Box className="w-full flex items-center justify-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box ref={parentRef} className="w-full h-full">
      {consoleUrl ? (
        <VdiConsole
          token={token}
          width={dimensions.width - 50}
          height={dimensions.height}
          lessonId={lessonId}
          vmId={watchVmSelectedId === null ? '' : watchVmSelectedId}
        />
      ) : (
        <Box
          className="w-full flex items-center justify-center h-full"
          style={{ height: 'calc(100vh - 250px)' }}
        >
          <FdButton onClick={() => setOpen(true)} className="w-[360px]">
            Start Lab
          </FdButton>
        </Box>
      )}
      <FdModal
        title="Start Lab"
        size="md"
        description={
          <Box>
            <FdTypography
              variant="subtitle1"
              className="font-bold"
              color="primary"
            >
              Are you sure you want to start the lab?
            </FdTypography>
            <Box mt={1}>
              A two-hour countdown timer will begin once you start this lab. You
              may extend your remaining lab time as many times as you require.
              You may also reset your lab to deploy a new lab instance as many
              times as you require.
            </Box>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={open}
        onConfirm={() => {
          startLabMutation({
            variables: {
              labPrototypeId: exerciseLab?.id,
              exerciseId,
            },
          });
          setOpen(false);
        }}
        onDismiss={() => setOpen(false)}
      />
    </Box>
  );
};

LabConsole.propTypes = {
  selectedLesson: PropTypes.shape({
    exercise: PropTypes.string,
  }).isRequired,
};

export default LabConsole;
