import React, { useState } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as singleSpa from 'single-spa';
import { MonitorIcon } from 'lucide-react';
import {
  FdCard,
  FdTypography,
  FdButton,
  FdAlert,
  FdTable,
  FdChip,
  FdTooltip,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { getLabsStatusColor } from '../../shared/utils/getStatusColor';
import TransferContentOwnership from './TransferContentOwnership';
import { transferLabOwnership } from '../../graphql/mutations';
import { listOrgByAlias } from '../../graphql/queries';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  getCommaSeparated,
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';

const LabsTable = ({ rows, actions, selection, refetchLabs }) => {
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [transferLabOwnershipModal, setTransferOwnershipModal] =
    useState(false);
  const [orgAlias, setOrgAlias] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    orgError: '',
    checkedError: '',
  });
  const [successMessage, setSuccessMessage] = useState(undefined);

  const [
    transferLabOwnershipMutation,
    { loading: transferLabOwnershipLoading },
  ] = useMutation(gql(transferLabOwnership));

  const { data: orgData } = useQuery(gql(listOrgByAlias), {
    variables: {
      alias: orgAlias,
    },
    skip: !orgAlias,
  });

  const columns = [
    { field: 'name', width: 250, headerName: 'Lab Name' },
    {
      field: 'description',
      flex: 1,
      headerName: 'Lab Description',
      renderCell: (params) => {
        return (
          <Box width={390}>
            <FdTooltip title={params.value?.length > 40 ? params.value : ''}>
              <span>
                <FdTypography
                  variant="body2"
                  fontWeight="medium"
                  className="overflow-hidden whitespace-nowrap overflow-ellipsis mb-1"
                >
                  {params.value}
                </FdTypography>
              </span>
            </FdTooltip>
          </Box>
        );
      },
    },
    {
      field: 'status',
      width: 250,
      headerName: 'Status',
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdChip
          color={getLabsStatusColor(params?.row?.status)}
          size="small"
          label={params?.row?.status}
        />
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.value.localeCompare(param2.value),
    },
    {
      field: 'vms',
      width: 250,
      headerName: 'Virtual Machines',
      renderCell: (params) => {
        const toolTipText = (
          <ul
            key={shortid.generate()}
            style={{
              listStyleType: 'decimal',
              marginLeft: '16px',
            }}
          >
            {params?.row?.labVms?.map((item) => (
              <li>
                {`${item?.name} `}
                <p>{`${item?.vdiUser} VDI, ${upperCaseFirstLetter(item?.instanceType)} VM Size,   ${item?.vpn ? 'VPN' : 'VDI'} Access, ${item?.networks?.items?.length} Assigned Network(s)`}</p>
              </li>
            ))}
          </ul>
        );
        return (
          <Box width={300}>
            <FdTooltip title={toolTipText} position="right">
              <span>{params.value}</span>
            </FdTooltip>
          </Box>
        );
      },
    },
  ];

  const onConfirmSearch = () => {
    const org = orgData?.listOrgByAlias?.items?.length > 0;
    if (!org) {
      setErrorMessage({
        orgError: 'No matching organisation found.',
      });
    }
    setSuccessMessage(org);
  };

  const onConfirmTransferLabOwnership = () => {
    transferLabOwnershipMutation({
      variables: {
        orgAlias,
        labIds: selectedLabs,
      },
      onCompleted: () => {
        setTransferOwnershipModal(false);
        successToastMessage(
          `Success! Ownership of ${selectedLabs?.length} lab(s) transferred.`,
        );
        setSelectedLabs([]);
        setSuccessMessage(undefined);
        setOrgAlias('');
        refetchLabs();
      },
      onError: (e) => setErrorMessage(e.message),
    });
  };

  const onDismissTransferLabOwnership = () => {
    setTransferOwnershipModal(false);
    setSelectedLabs([]);
    setOrgAlias('');
    setErrorMessage({ orgError: '', checkedError: '' });
    setSuccessMessage(undefined);
    warningToastMessage('Lab ownership transfer cancelled');
  };

  return (
    <FdCard
      variant="outlined"
      heading={
        <Box className="flex justify-between">
          <Box className="flex items-center gap-x-2 mb-4">
            <MonitorIcon />
            <FdTypography variant="h3">Labs</FdTypography>
          </Box>
          <Box className="flex items-center gap-x-2 mb-4">
            <FdButton onClick={() => singleSpa.navigateToUrl('/labs/create')}>
              Create
            </FdButton>
            {selectedLabs?.length > 0 && (
              <FdButton onClick={() => setTransferOwnershipModal(true)}>
                {` transfer (${selectedLabs?.length})`}
              </FdButton>
            )}
          </Box>
        </Box>
      }
      subHeading={
        <FdTypography variant="body2" color="secondary">
          All labs in your organisation are listed below. Use the Actions menu
          to <b>Edit, Test</b> or <b>Duplicate</b> a lab. Checkboxes can be used
          to <b>Transfer</b> ownership of a lab to another organisation.
        </FdTypography>
      }
    >
      <Box>
        <Box my={2}>
          <FdAlert
            variant="info"
            message={
              <FdTypography variant="body2">
                Labs with<b> Available</b> status can be used in lessons and
                challenges.
                <b> Lessons</b> only support labs that include at least one VM
                with VDI access, while
                <b> Challenges</b> support all labs, regardless of VM access
                type. All labs have a 2-hour duration. Participants can extend
                their lab instances as needed, resetting the timer to 2 hours
                while retaining progress. They can also reset their labs, which
                deploys a new instance and erases all progress.
              </FdTypography>
            }
          />
        </Box>
        <Box height={768}>
          <FdTable
            toolbarSettings={{
              filterButton: true,
              searchBox: true,
            }}
            rows={rows}
            columns={columns}
            tablePageSize={10}
            actions={actions}
            selection={selection}
            disableSelectionOnClick
            isRowSelectable={(params) => params.row.status === 'Available'}
            selectionModel={selectedLabs}
            onSelectionModelChange={(_value) => setSelectedLabs(_value)}
            gridId="labs-admin-labs"
          />
        </Box>
        <TransferContentOwnership
          rows={rows.filter((row) => selectedLabs.includes(row?.id))}
          columns={[
            {
              field: 'name',
              width: 200,
              headerName: 'Lab Name',
            },
            { field: 'vms', width: 200, headerName: 'Virtual Machines' },
            { field: 'instances', width: 100, headerName: 'Instances' },
            {
              field: 'noOfDependentChallenges',
              width: 200,
              headerName: 'Linked Challenges',
              renderCell: (params) => {
                return params?.value?.length > 0 ? (
                  <FdTooltip
                    position="right-end"
                    title={
                      <Box m={2}>
                        {getCommaSeparated(
                          params?.value?.map((i) => i?.name) || [''],
                        )}
                      </Box>
                    }
                  >
                    {` ${getCommaSeparatedPlusSuffix(
                      params?.value?.map((i) => i?.name),
                    )} More`}
                  </FdTooltip>
                ) : (
                  <Box width="100%" textAlign="center">
                    None
                  </Box>
                );
              },
              renderHeader: () => (
                <TableHeaderColumnWithTooltip
                  title="Linked Challenges"
                  tooltipText="Linked challenges are challenges that currently use this lab."
                />
              ),
            },
            {
              field: 'noOfDependentLessons',
              width: 200,
              headerName: 'Linked Lessons',
              renderCell: (params) => {
                return params?.value?.length > 0 ? (
                  <FdTooltip
                    position="right-end"
                    title={
                      <Box m={2}>
                        {getCommaSeparated(
                          params?.value?.map((i) => i?.name) || [''],
                        )}
                      </Box>
                    }
                  >
                    {` ${getCommaSeparatedPlusSuffix(
                      params?.value?.map((i) => i?.name),
                    )} More`}
                  </FdTooltip>
                ) : (
                  <Box width="100%" textAlign="center">
                    None
                  </Box>
                );
              },
              renderHeader: () => (
                <TableHeaderColumnWithTooltip
                  title="Linked Lessons"
                  tooltipText="Linked lessons are lessons that currently use this lab."
                />
              ),
            },
          ]}
          onConfirm={onConfirmTransferLabOwnership}
          onDismiss={onDismissTransferLabOwnership}
          onChange={(e) => {
            setOrgAlias(e.target.value);
            setSuccessMessage(undefined);
            setErrorMessage({ orgError: '', checkedError: '' });
          }}
          transferContentOwnershipModal={transferLabOwnershipModal}
          orgAlias={orgAlias}
          contentType="Labs"
          loading={transferLabOwnershipLoading}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          onConfirmSearch={onConfirmSearch}
        />
      </Box>
    </FdCard>
  );
};

LabsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  selection: PropTypes.bool,
  refetchLabs: PropTypes.func.isRequired,
};

LabsTable.defaultProps = {
  actions: [],
  selection: false,
};

export default LabsTable;
