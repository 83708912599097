import React from 'react';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SubjectIcon from '@mui/icons-material/Subject';
import ExtensionIcon from '@mui/icons-material/Extension';

const PartIcon = ({ type }) =>
  ({
    PDF: <InsertDriveFileIcon />,
    MARKDOWN: <SubjectIcon />,
    LAB: <OndemandVideoIcon />,
    VIDEO: <OndemandVideoIcon />,
    QUIZ: <ExtensionIcon />,
  })[type] || null;

PartIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PartIcon;
