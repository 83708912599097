import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { Box } from '@mui/material';
import {
  FdTable,
  FdTypography,
  useQueryRecursive,
  FdProgress,
  FdCard,
  FdAlert,
} from '@fifthdomain/fe-shared';
import AdminCourseChat from './AdminCourseChat';
import NoChatMessages from '../../shared/images/no-data.svg';
import {
  getCourseUsersGroups,
  listCourseUsersForMessages,
} from '../../queries/customQueries';
import { getDateTimeZoneCustom } from '../../shared/utils/dateUtils';
import { invalidateMessageQueries } from '../../queries/invalidateQueries';
import useFetchCourseUsersWithLabTime from '../../hooks/useFetchCourseUsersWithLabTime';

const ChatQueue = ({ courseId, lessonsProgress, quizAttemptsData }) => {
  const [openChat, setOpenChat] = useState(false);
  const [chatCourseUser, setChatCourseUser] = useState({});

  const { data: courseData, loading: courseLoading } = useQueryRecursive(
    gql(getCourseUsersGroups),
    {
      variables: {
        id: courseId,
      },
      skip: !courseId,
    },
  );

  const { courseUsersDataWithLabTime, loading } =
    useFetchCourseUsersWithLabTime(
      courseData,
      courseLoading,
      lessonsProgress,
      quizAttemptsData,
    );

  const {
    data: messagesData,
    loading: messagesLoading,
    refetch: refetchMessages,
  } = useQueryRecursive(gql(listCourseUsersForMessages), {
    variables: {
      filter: { courseId: { eq: courseId } },
    },
    skip: !courseId,
    staleTime: { seconds: 0 },
  });

  const columns = [
    {
      field: 'name',
      width: 350,
      headerName: 'Name',
    },
    {
      field: 'latestMessageDate',
      width: 250,
      headerName: 'Last Message Sent',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        getDateTimeZoneCustom(value, 'MMM d, yyyy h:mm a'),
    },
    {
      field: 'email',
      width: 350,
      headerName: 'Email',
    },
    {
      field: 'courseProgress',
      width: 350,
      headerName: 'Course Progress',
      searchable: false,
      filterable: false,
      renderCell: (params) => {
        const courseProgress = courseUsersDataWithLabTime?.find(
          ({ courseUserId }) => courseUserId === params?.row?.courseUserId,
        )?.courseProgress;
        return (
          <FdProgress
            type="linear"
            value={courseProgress?.progress}
            caption={courseProgress?.progressCaption}
          />
        );
      },
    },
    {
      field: 'unReadMessageCount',
      width: 150,
      headerName: '',
      valueGetter: (params) => params?.value,
      filterable: false,
      searchable: false,
      renderCell: (params) => {
        return (
          <Box
            className=" px-2 py-1 gap-x-2 rounded-full"
            sx={{ backgroundColor: 'rgba(51, 51, 255, 1)', color: '#fff' }}
          >
            {params.value}
          </Box>
        );
      },
    },
  ];

  if (messagesLoading || courseLoading || loading) return <FdProgress />;

  const unreadMessageItems = messagesData?.listCourseUsers?.items
    .map((item) => {
      const latestMessage = item?.messages?.items?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )?.[0];
      return {
        key: latestMessage?.sender?.key,
        id: latestMessage?.id,
        name: latestMessage?.sender?.name,
        email: latestMessage?.sender?.email,
        latestMessageDate: latestMessage?.createdAt,
        unReadMessageCount: item?.messages?.items?.length || 0,
        courseUserId: latestMessage?.userCourseId,
        senderId: latestMessage?.sender?.id,
      };
    })
    .filter((m) => m.email)
    .sort((a, b) => b.unReadMessageCount - a.unReadMessageCount);

  const onChatSelection = (_params) => {
    invalidateMessageQueries();
    setOpenChat(true);
    setChatCourseUser(_params);
  };

  const actionsQueue = [
    {
      label: 'View',
      show: () => true,
      onClick: (row) => {
        onChatSelection(row);
      },
    },
  ];

  return (
    <FdCard
      variant="outlined"
      heading="Chat Inbox"
      subHeading="Participants who have sent messages that have not yet been responded to are listed below. Click a participant to open the chat and clear them from the inbox."
    >
      <Box my={3}>
        <FdAlert
          variant="info"
          message={
            <FdTypography variant="body2">
              <b>Note : </b>
              As you respond to participants with pending messages, they will
              disappear from this list. To chat with a participant not listed
              below, go to the
              <b> Participants </b> tab.
            </FdTypography>
          }
        />
      </Box>
      <Box height="515px">
        {unreadMessageItems?.length ? (
          <FdTable
            toolbarSettings={{
              filterButton: true,
              searchBox: true,
              headerActions: [],
            }}
            rows={unreadMessageItems || []}
            columns={columns}
            tablePageSize={10}
            actions={actionsQueue}
            columnVisibilityModel={{
              courseUserId: false,
              senderId: false,
            }}
            gridId="labs-chat-queue"
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <img src={NoChatMessages} alt="no-data" />
            <Box my={2}>
              <FdTypography variant="captiontext1" color="secondary">
                No unanswered messages.
              </FdTypography>
            </Box>
            <FdTypography variant="captiontext1" color="secondary">
              Students who send messages will appear here until you respond to
              them.
            </FdTypography>
          </Box>
        )}
      </Box>
      <AdminCourseChat
        openChat={openChat}
        onCloseChat={() => {
          refetchMessages();
          setOpenChat(false);
          setChatCourseUser({});
        }}
        chatTitle={chatCourseUser?.name}
        courseUserId={chatCourseUser?.courseUserId}
        receiverId={chatCourseUser.senderId}
        courseId={courseId}
        isAdmin
      />
    </FdCard>
  );
};

ChatQueue.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default ChatQueue;
