import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { InfoIcon, LightbulbIcon, VideoIcon } from 'lucide-react';
import * as singleSpa from 'single-spa';
import { Box, useTheme } from '@mui/material';
import {
  FdTypography,
  FdProgress,
  useSnapshot,
  globalStore,
  FdButton,
  FdTooltip,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getCourseUser } from '../queries/customQueries';
import ContentAccordion from '../components/Courses/LessonView/ContentAccordion';
import ResourceTypeIcon from '../components/Courses/LessonView/ResourceTypeIcon';
import VideoPopup from '../components/Courses/LessonMaterial/VideoPopup';
import { getS3File } from '../shared/utils/fileUtils';
import { listExerciseProgressesByCourseUserId } from '../graphql/queries';

const CardHeader = ({ heading, action }) => (
  <Box
    height={64}
    sx={(theme) => ({
      backgroundColor: theme.palette.background.paper,
    })}
    className="flex items-center justify-between"
  >
    <FdTypography variant="subtitle1" className="font-medium ml-6">
      {heading}
    </FdTypography>
    <Box className="mr-6">{action}</Box>
  </Box>
);

CardHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  action: PropTypes.node,
};

CardHeader.defaultProps = {
  action: undefined,
};

const getResources = async (_courseResources) => {
  const resources = await Promise.all(
    _courseResources?.items?.map(async (r) => ({
      dbId: r?.id,
      id: r?.id,
      resourceName: r?.name,
      resourceDescription: r?.description,
      resourceType: r?.type,
      resourcePdf: await getS3File(r),
      resourceMarkdown: r?.markdown,
      resourceVideo: r?.url,
      resourceOrder: r?.orderNumber,
    })) || [],
  );

  return resources?.sort(
    (a, b) => (a?.resourceOrder ?? 0) - (b?.resourceOrder ?? 0),
  );
};

const ViewCourseEntry = () => {
  const { courseUserId } = useParams();
  const [showVideoPopup, setShowVideoPopup] = useState(null);
  const [resources, setResources] = useState([]);
  const theme = useTheme();
  const { userName } = useSnapshot(globalStore);

  const { loading: progressDataLoading } = useQueryRecursive(
    gql(listExerciseProgressesByCourseUserId),
    {
      variables: {
        courseUserId,
      },
      skip: !courseUserId,
      staleTime: { seconds: 0 },
      onCompleted: async (_data) => {
        const haveStatus =
          _data?.listExerciseProgressesByCourseUserId?.items?.length;
        if (haveStatus) {
          singleSpa.navigateToUrl(`/labs/courses/view/${courseUserId}`);
        }
      },
    },
  );

  const { data: courseUserData, loading: courseUserDataLoading } = useQuery(
    gql(getCourseUser),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
      onCompleted: async (data) => {
        const { courseResources } = data?.getCourseUser?.course || {};
        const resourcesFormatted = await getResources(courseResources);
        setResources(resourcesFormatted);
      },
    },
  );

  if (courseUserDataLoading || progressDataLoading) return <FdProgress />;

  const {
    name: courseName,
    description,
    courseLessons,
  } = courseUserData?.getCourseUser?.course || {};
  const noOfLessons = courseLessons?.items?.length || 0;

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: courseName, type: 'COURSE' }} />
      <Box className="overflow-x-hidden" data-cy="view-course-resources">
        <Box className="flex-1 flex flex-col items-center">
          <Box className="flex items-center gap-x-2">
            <LightbulbIcon size={20} />
            <FdTypography variant="h4" className="font-medium">
              {courseName}
            </FdTypography>
          </Box>
          <FdTypography
            variant="caption"
            className="font-medium"
            color="secondary"
          >
            Course Entry
          </FdTypography>
        </Box>
        <Box
          className="flex flex-col rounded-lg overflow-hidden mt-2"
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Box className="flex flex-col">
            <CardHeader
              heading={`Welcome, ${userName}!`}
              action={
                <FdButton
                  onClick={() =>
                    singleSpa.navigateToUrl(
                      `/labs/courses/view/${courseUserId}`,
                    )
                  }
                >
                  Enter Course
                </FdButton>
              }
            />
            <Box className="px-6 py-5">
              <FdTypography
                variant="body1"
                className="break-words"
                color="secondary"
              >
                Your course is ready to begin. This course has{' '}
                <strong>{noOfLessons} Lessons.</strong>
                <br />
                <br />
                Check the Course Introduction below for details. Course
                Resources are also be listed below (if available). Click{' '}
                <strong>Enter Course</strong> to access your{' '}
                <strong>Lessons.</strong> Once inside, you can always access the
                introduction and resources in the <strong>Overview</strong> tab.
              </FdTypography>
            </Box>
          </Box>
        </Box>
        <Box
          className={`grid grid-cols-${resources?.length > 0 ? '2' : '1'} gap-x-4 mt-7 w-full min-h-[calc(100vh-380px)]`}
        >
          <Box
            className="flex flex-col rounded-lg overflow-hidden"
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <Box className="flex flex-col">
              <CardHeader heading="Course Introduction" />
              <Box className="px-6 py-5">
                <FdTypography
                  variant="body1"
                  className="break-words whitespace-pre-wrap"
                  color="secondary"
                >
                  {description}
                </FdTypography>
              </Box>
            </Box>
          </Box>
          {resources?.length > 0 && (
            <Box
              className="flex flex-col rounded-lg overflow-hidden"
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Box className="flex flex-col">
                <CardHeader
                  heading={`Course Resources (${resources?.length})`}
                  action={
                    <FdTooltip title="You’ll be able to access course resources in the Overview tab once you enter this course.">
                      <Box
                        className="flex items-center justify-center rounded p-1"
                        sx={{
                          backgroundColor: 'rgba(110, 132, 255, 0.1)',
                          color: theme.palette.primary.main,
                        }}
                      >
                        <InfoIcon />
                      </Box>
                    </FdTooltip>
                  }
                />
                {resources?.map(
                  ({ resourceType, resourceName, resourceDescription }) => (
                    <ContentAccordion
                      noBorder
                      defaultExpanded={resources?.length === 1}
                      summary={
                        <Box className="flex items-center flex-wrap gap-x-2 py-1">
                          <ResourceTypeIcon type={resourceType} />
                          <FdTypography variant="subtitle2">
                            {resourceName}
                          </FdTypography>
                        </Box>
                      }
                      details={
                        <Box>
                          <FdTypography
                            variant="body2"
                            color="secondary"
                            className="break-words whitespace-pre-wrap"
                          >
                            {resourceDescription || (
                              <em>No description added</em>
                            )}
                          </FdTypography>
                        </Box>
                      }
                      style={{
                        border: '1px solid rgba(39, 39, 42, 1)',
                      }}
                    />
                  ),
                )}
              </Box>
              <VideoPopup
                open={showVideoPopup}
                setOpen={setShowVideoPopup}
                url={showVideoPopup?.url}
                title={showVideoPopup?.title}
                newTabUrl={showVideoPopup?.newTabUrl}
                videoIcon={
                  <Box
                    className="flex items-center rounded-xl px-2 py-1"
                    sx={{ bgcolor: theme.palette.primary.dark }}
                  >
                    <VideoIcon size={18} />
                  </Box>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ViewCourseEntry;
