import React from 'react';
import PropTypes from 'prop-types';
import { DotIcon } from 'lucide-react';
import { Box } from '@mui/material';
import {
  FdAccordion,
  FdTypography,
  FdButton,
  FdSelect,
  FdCard,
} from '@fifthdomain/fe-shared';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import FdBoxButton from '../FdBoxButton';

const LabNetworkVms = ({ nextIndex, vmNetworkValues }) => {
  const { control, setValue, getValues } = useFormContext();
  const {
    fields: labNetworkVmsFields,
    append: labNetworkVmsAppend,
    remove: labNetworkVmsRemove,
  } = useFieldArray({
    control,
    name: `labVms[${nextIndex}].networks.items`,
  });
  const { labDeleteVMNetworks, labNetworks } = getValues();

  return (
    <FdAccordion
      open
      startAdornment
      disableGutters
      showToggleButton={false}
      summary={() => (
        <Box className="ml-2">
          <Box className="flex items-end gap-x-2">
            <FdTypography variant="h4">
              Assign Networks to Virtual Machine
            </FdTypography>
            <FdTypography variant="body1" color="secondary" className="mb-1">
              optional
            </FdTypography>
          </Box>
          <FdTypography variant="body2" color="secondary" className="my-2">
            Select networks from the previous page to assign to this Virtual
            Machine.
          </FdTypography>
        </Box>
      )}
      content={() => (
        <Box className="flex flex-col gap-y-4">
          {labNetworkVmsFields.map((item, index1) => {
            return (
              <FdCard variant="outlined">
                <Box className="flex items-center justify-between gap-x-2">
                  <Box className="flex items-center">
                    <FdTypography variant="h4">{`${index1 + 1}`}</FdTypography>
                    <DotIcon />
                  </Box>
                  <Controller
                    control={control}
                    name={`labVms[${nextIndex}].networks.items[${index1}].name`}
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box className="w-full">
                        <FdSelect
                          defaultSelected={rest.value}
                          id={`labVms[${nextIndex}].networks.items[${index1}].name`}
                          placeholder="Select Network"
                          options={
                            labNetworks?.map(
                              (labNetwork) => labNetwork?.name,
                            ) || []
                          }
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          {...rest}
                          inputRef={ref}
                        />
                      </Box>
                    )}
                  />
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      labNetworkVmsRemove(index1);
                      setValue(
                        'labDeleteVMNetworks',
                        labDeleteVMNetworks?.concat(
                          vmNetworkValues?.filter(
                            (labNetw) =>
                              item.name === labNetw.name &&
                              item.labPrototypeVMId ===
                                labNetw.labPrototypeVMId,
                          ),
                        ),
                      );
                    }}
                  >
                    DELETE
                  </FdButton>
                </Box>
              </FdCard>
            );
          })}
          <FdBoxButton
            onClick={async (e) => {
              e.preventDefault();
              labNetworkVmsAppend({});
            }}
          >
            ASSIGN NETWORK
          </FdBoxButton>
        </Box>
      )}
    />
  );
};

LabNetworkVms.propTypes = {
  nextIndex: PropTypes.number.isRequired,
  vmNetworkValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LabNetworkVms;
