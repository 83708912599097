import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { CheckIcon, PenIcon, TrashIcon, XIcon, ImageIcon } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useFormContext } from 'react-hook-form';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  alpha,
  darken,
  useTheme,
} from '@mui/material';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import ChatLoader from './ChatLoader';
import ImageCarousel from './ImageCarousel';
import ImagePreview from './ImagePreview';
import useImagesDropzone from '../../../../hooks/useImagesDropzone';

const maxTextLimit = 500;

const ImagesPreview = ({ images, onClick }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Box className="flex gap-x-2 my-2 justify-end">
      {images?.map((image, index) => (
        <Box
          height="120px"
          width="120px"
          className="flex items-center justify-center relative cursor-pointer relative"
          onClick={() => onClick(index)}
        >
          {loading && <CircularProgress className="absolute" />}
          <img
            key={image}
            src={image}
            alt="attachment"
            style={{ height: '120px', width: '120px' }}
            className="rounded bg-cover bg-center"
            onLoad={() => setLoading(false)}
          />
        </Box>
      ))}
    </Box>
  );
};

ImagesPreview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
};

const SelfMessage = ({ message, onDelete, onEdit, onAction }) => {
  const [edit, setEdit] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [editImages, setEditImages] = useState([]);
  const [showImagePreview, setShowImagePreview] = useState(null);
  const { setValue } = useFormContext();
  const theme = useTheme();
  const { getRootProps, open, getInputProps } = useImagesDropzone({
    setImages: setEditImages,
  });
  const { idx, dbId, text, timeStamp, images } = message || {};

  useEffect(() => {
    setTextValue(text);
    setEditImages(images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  const textExceedsLimit = textValue.trim().length > maxTextLimit;

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const submitDisabled = textValue.trim() === '' || textExceedsLimit;

  return (
    <Box className={`flex flex-col self-end my-1 ${edit ? 'w-full' : 'w-fit'}`}>
      <Box
        className={`rounded-xl mb-2 ${edit ? 'p-0' : 'p-4'} self-end w-full`}
        sx={{
          backgroundColor: alpha(theme.palette.inputs.selectBorder, 0.2),
          maxWidth: '1090px',
          borderColor: edit
            ? theme.palette.primary.main
            : theme.palette.table.border,
          borderWidth: '1px',
        }}
      >
        {!edit && images?.length > 0 && (
          <ImagesPreview images={images} onClick={setShowImagePreview} />
        )}
        {edit ? (
          <>
            {editImages?.length > 0 && (
              <Box className="flex mt-3 bg-transparent w-full ml-10 gap-x-3 mb-1 max-w-96">
                {editImages.map((image, _ImgIdx) => {
                  const imageUrl =
                    image instanceof File ? URL.createObjectURL(image) : image;

                  return (
                    <ImagePreview
                      imageSrc={imageUrl}
                      onClose={() =>
                        setEditImages((allImages) =>
                          allImages
                            ?.map((img, _allImageIdx) => ({
                              image: img,
                              idx: _allImageIdx,
                            }))
                            .filter((img) => img?.idx !== _ImgIdx)
                            ?.map((img) => img?.image),
                        )
                      }
                      key={image?.name}
                    />
                  );
                })}
              </Box>
            )}
            <TextField
              variant="outlined"
              multiline
              fullWidth
              maxRows={3}
              value={textValue}
              onChange={handleTextChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FdTooltip title="You can upload up to 3 images (PNG, JPG, JPEG) at a time in a single message. Each image can be up to 5MB, with a total limit of 15MB for all 3 images.">
                      <IconButton
                        component="label"
                        sx={{
                          padding: '0',
                          color: theme.palette?.primary?.main,
                          '&:disabled': {
                            color: darken(theme.palette?.primary?.main, 0.5),
                          },
                        }}
                        onClick={open}
                        disabled={editImages?.length >= 3}
                      >
                        <ImageIcon size={28} />
                      </IconButton>
                    </FdTooltip>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '1080px',
                '& .MuiInputBase-input': {
                  fontSize: '13px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '16px',
                  '& fieldset': {
                    border: 'none',
                  },
                  background:
                    theme?.palette?.type === 'dark'
                      ? '#111010'
                      : theme.palette.inputs.background,
                },
              }}
              className="rounded-2xl overflow-hidden w-full"
            />
            {textExceedsLimit && (
              <Box className="my-2 text-center">
                <FdTypography
                  variant="captiontext2"
                  color="rgba(229, 115, 135, 1)"
                >
                  Message too long. Please shorten to 500 characters or less.
                </FdTypography>
              </Box>
            )}
          </>
        ) : (
          <FdTypography
            variant="body2"
            color="secondary"
            className="whitespace-pre-wrap break-words"
          >
            {textValue}
            {message?.isEdited && (
              <span
                className="pl-1"
                style={{ color: theme.palette.primary.main }}
              >
                (edited)
              </span>
            )}
          </FdTypography>
        )}
      </Box>
      <Box className="text-right">
        <FdTypography variant="captiontext1" color="secondary">
          {timeStamp}
        </FdTypography>
      </Box>
      <Box className="flex items-center gap-x-2 justify-end mb-2">
        {edit ? (
          <Box className="flex items-center gap-x-2 mt-2">
            <FdTooltip title="Save">
              <IconButton
                size="small"
                onClick={() => {
                  setEdit(false);
                  setValue(`messages[${idx}].message.text`, textValue);
                  setValue(
                    `messages[${idx}].message.images`,
                    editImages?.map((img) =>
                      img instanceof File ? URL.createObjectURL(img) : img,
                    ),
                  );
                  setValue(`messages[${idx}].message.isEdited`, true);
                  onEdit({ text: textValue, images: editImages, dbId });
                  onAction({ id: idx, active: false });
                }}
                disabled={submitDisabled}
                className="rounded w-10"
                sx={{
                  backgroundColor: 'rgba(53, 195, 161, 0.1)',
                }}
              >
                <CheckIcon
                  size={16}
                  color={submitDisabled ? 'gray' : 'rgba(53, 195, 161, 1)'}
                />
              </IconButton>
            </FdTooltip>
            <FdTooltip title="Cancel">
              <IconButton
                size="small"
                onClick={() => {
                  setTextValue(text);
                  setEditImages(images);
                  setEdit(false);
                  onAction({ id: idx, active: false });
                }}
                className="rounded w-10"
                sx={{
                  backgroundColor: 'rgba(229, 115, 135, 0.1)',
                }}
              >
                <XIcon size={16} color="rgba(229, 115, 135, 1)" />
              </IconButton>
            </FdTooltip>
          </Box>
        ) : (
          <>
            <FdTooltip title="Edit Message">
              <IconButton
                size="small"
                onClick={() => {
                  setEdit(true);
                  onAction({ id: idx, active: true });
                }}
              >
                <PenIcon size={16} color={theme.palette.primary.main} />
              </IconButton>
            </FdTooltip>
            <FdTooltip title="Delete Message">
              <IconButton
                size="small"
                onClick={() => {
                  onDelete({
                    message: { text: textValue, timeStamp, dbId, idx },
                  });
                  onAction({ id: idx, active: true });
                }}
              >
                <TrashIcon size={16} color={theme.palette.primary.main} />
              </IconButton>
            </FdTooltip>
          </>
        )}
      </Box>
      <ImageCarousel
        open={showImagePreview}
        onClose={() => setShowImagePreview(null)}
        imageUrls={images}
      />
    </Box>
  );
};

SelfMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

const ExternalMessage = ({ message }) => {
  const [showImagePreview, setShowImagePreview] = useState(null);
  const { text, messengerName, MessengerIcon, timeStamp, images } =
    message || {};
  const theme = useTheme();

  return (
    <Box my={1}>
      <Box className="flex items-center gap-x-2">
        <Avatar sx={{ width: 28, height: 28 }}>{MessengerIcon}</Avatar>
        <FdTypography variant="subtitle2">{messengerName}</FdTypography>
      </Box>
      <Box
        className="my-2 text-wrap p-4 rounded-xl w-fit"
        sx={{
          backgroundColor: alpha(theme.palette.inputs.selectBorder, 0.1),
          border: `1px solid ${theme.palette.background.border}`,
          maxWidth: '1080px',
        }}
      >
        <ImagesPreview images={images} onClick={setShowImagePreview} />
        <FdTypography variant="body2" className="flex whitespace-pre-wrap">
          <Markdown
            key={text}
            // eslint-disable-next-line react/no-children-prop
            children={text}
            components={{
              p: ({ ...props }) => <p {...props} className="pb-1" />,
              ol: ({ ...props }) => <ol {...props} className="list-decimal" />,
              li: ({ ...props }) => <li {...props} className="ml-4 pl-1" />,
              code(props) {
                // eslint-disable-next-line react/prop-types
                const { children, className, ...rest } = props;
                const match = /language-(\w+)/.exec(className || '');
                return match ? (
                  <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    // eslint-disable-next-line react/no-children-prop
                    children={String(children).replace(/\n$/, '')}
                    language={match[1]}
                    style={dracula}
                  />
                ) : (
                  <code {...rest} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          />
          {!text && (
            <Box mb={1}>
              <ChatLoader />
            </Box>
          )}
          {message?.isEdited && (
            <span
              className="pl-1"
              style={{ color: theme.palette.primary.main }}
            >
              (edited)
            </span>
          )}
        </FdTypography>
      </Box>
      <Box className="mb-2 text-left">
        <FdTypography variant="captiontext1" color="secondary">
          {timeStamp}
        </FdTypography>
      </Box>
      <ImageCarousel
        open={showImagePreview}
        onClose={() => setShowImagePreview(null)}
        imageUrls={images}
      />
    </Box>
  );
};

ExternalMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

const Message = ({ message, onDelete, onEdit, onAction }) => {
  const { self } = message || {};

  return self ? (
    <SelfMessage
      message={message}
      onDelete={onDelete}
      onEdit={onEdit}
      onAction={onAction}
    />
  ) : (
    <ExternalMessage message={message} />
  );
};

Message.propTypes = {
  message: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default Message;
