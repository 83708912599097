import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'lucide-react';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  AccordionSummary,
  Accordion,
  useTheme,
  AccordionDetails,
  alpha,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  FdTypography,
  FdChip,
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import ResourceTypeIcon from './ResourceTypeIcon';
import {
  PROFICIENCY_COLOR,
  PROFICIENCY_LEVELS,
  SPECIALTY_COLOR,
} from '../../../constants';
import { updateExerciseProgress } from '../../../graphql/mutations';
import LessonExerciseLabControls from './LessonExerciseLabControls';

const LessonExerciseAccordion = ({
  selectedLesson,
  selectedLessonProgress,
  refetchProgress,
  progressDataLoading,
}) => {
  const [disableMarkComplete, setDisableMarkComplete] = useState(false);
  const [showMarkCompleteModal, setShowMarkCompleteModal] = useState(false);
  const theme = useTheme();
  const { isSmallScreen } = useSnapshot(globalStore);
  const [updateExerciseProgressMutation] = useMutation(
    gql(updateExerciseProgress),
  );
  const { exercise, proficiencyLevel, specialtyMapping } = selectedLesson || {};
  const proficiency = PROFICIENCY_LEVELS[proficiencyLevel || 1];
  const specialty = specialtyMapping?.specialty?.specialtyName;
  const isCompleted = selectedLessonProgress?.status === 'FINISHED';

  const onMarkComplete = async () => {
    setDisableMarkComplete(true);
    await updateExerciseProgressMutation({
      variables: {
        input: {
          id: selectedLessonProgress?.id,
          status: 'FINISHED',
        },
      },
      onCompleted: () => {
        refetchProgress();
        setTimeout(() => setDisableMarkComplete(false), 2000);
      },
      onError: () => {
        setDisableMarkComplete(false);
      },
    });
  };

  const proficiencyColor = PROFICIENCY_COLOR[proficiency]?.color;
  const specialtyColor =
    SPECIALTY_COLOR[specialty]?.color || 'rgba(0, 0, 0, 0)';
  const isLab = exercise === 'LAB';
  const cardBorderColor = isCompleted
    ? 'rgba(53, 195, 161, 1)'
    : theme.palette.primary.main;

  const Summary = () => (
    <Box
      className={`flex items-center justify-between w-full ${isSmallScreen ? 'pr-0' : 'pr-4'}`}
    >
      <Box className="flex items-center flex-wrap gap-x-4 gap-y-2">
        <Box className="flex items-center gap-x-3">
          <ResourceTypeIcon type={exercise} />
          <FdTypography variant="subtitle1">Exercise</FdTypography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className="flex items-center flex-wrap gap-3">
          <FdChip
            size="small"
            label={proficiency}
            rounded={false}
            style={{
              backgroundColor: alpha(proficiencyColor, 0.25),
              color: proficiencyColor,
            }}
          />
          <FdChip
            size="small"
            label={specialty}
            rounded={false}
            style={{
              backgroundColor: alpha(specialtyColor, 0.25),
              color: specialtyColor,
            }}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <FdChip
          size="small"
          label={`Est. Time: ${selectedLesson?.estimatedTime} Mins`}
          rounded={false}
          style={{
            backgroundColor: 'rgba(235, 242, 255, 0.25)',
            fontWeight: 700,
            color: 'white',
          }}
        />
      </Box>
      {isCompleted ? (
        <Box
          className="flex items-center px-3 py-2 rounded text-center"
          bgcolor="rgba(53, 195, 161, 0.1)"
        >
          <FdTypography variant="button_med" color="rgba(53, 195, 161, 1)">
            LESSON COMPLETED
          </FdTypography>
        </Box>
      ) : (
        <FdButton
          onClick={(event) => {
            event.stopPropagation();
            setShowMarkCompleteModal(true);
          }}
          disabled={disableMarkComplete || progressDataLoading}
          startIcon={progressDataLoading && <CircularProgress size={20} />}
          style={{
            minWidth: isSmallScreen ? 80 : 200,
          }}
        >
          {!progressDataLoading && 'Mark Complete'}
        </FdButton>
      )}
    </Box>
  );

  return (
    <Box>
      {isLab ? (
        <Accordion
          sx={{
            border: `1px solid ${cardBorderColor}`,
          }}
          className="rounded-lg overflow-hidden"
          square
          disableGutters
          {...(isLab ? {} : { expanded: false })}
        >
          <AccordionSummary
            expandIcon={isLab ? <ChevronDownIcon /> : null}
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Summary />
          </AccordionSummary>
          <AccordionDetails>
            <LessonExerciseLabControls selectedLesson={selectedLesson} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box
          sx={{
            border: `1px solid ${cardBorderColor}`,
          }}
          className="rounded-lg overflow-hidden py-3 px-4"
        >
          <Summary />
        </Box>
      )}
      <FdModal
        title="Mark Lesson Complete"
        size="md"
        description={
          <Box className="flex flex-col gap-y-4">
            <FdTypography variant="body1">
              Are you sure you want to mark this lesson complete?
            </FdTypography>
            <FdTypography variant="body1">
              You can still access and interact with the lesson, but your course
              completion progress will be updated.
            </FdTypography>
            <FdTypography variant="body1">
              This action cannot be undone.
            </FdTypography>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={showMarkCompleteModal}
        onConfirm={() => {
          onMarkComplete();
          setShowMarkCompleteModal(false);
        }}
        onDismiss={() => {
          setShowMarkCompleteModal(false);
        }}
      />
    </Box>
  );
};

LessonExerciseAccordion.propTypes = {
  selectedLesson: PropTypes.shape({
    exercise: PropTypes.string,
    estimatedTime: PropTypes.string,
  }).isRequired,
  courseUserId: PropTypes.string.isRequired,
  refetchProgress: PropTypes.func.isRequired,
  progressDataLoading: PropTypes.bool.isRequired,
  selectedLessonProgress: PropTypes.string.isRequired,
};

export default LessonExerciseAccordion;
