import React from 'react';
import { Box } from '@mui/material';
import {
  BasePage,
  FdProgress,
  useQueryRecursive,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import QuizTable from '../components/Quiz/QuizTable';
import { getAllCoursesWithQuizByOrgId } from '../queries/customQueries';
import { getDateTimeZoneCustom } from '../shared/utils/dateUtils';
import { successToastMessage } from '../shared/utils/toast';
import {
  listQuizesByOrgId,
  listQuestions,
  listModuleParts,
  getCourseModule,
  listQuizesByOrgIdForParticipant,
  listQuizAttempts,
} from '../graphql/queries';
import {
  deleteCompetencyQuestion,
  deleteQuestionOption,
  deleteQuestion,
  deleteQuiz,
  deleteModulePart,
  deleteCourseModule,
} from '../graphql/mutations';
import { isAdminOrManager } from '../shared/utils/getUsersPermissions';

const ListQuizzes = () => {
  const globalSnap = useSnapshot(globalStore);

  const isAdmin = isAdminOrManager(globalSnap.userType);
  const { orgId } = globalSnap;

  const quizQuery = isAdmin
    ? listQuizesByOrgId
    : listQuizesByOrgIdForParticipant;

  const {
    data: quizData,
    loading: quizLoading,
    refetch: refetchQuizData,
  } = useQueryRecursive(gql(quizQuery), {
    variables: {
      orgId,
    },
  });

  const { data: coursesData, loading: coursesLoading } = useQueryRecursive(
    gql(getAllCoursesWithQuizByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  const { data: attemptsData, loading: attemptsLoading } = useQueryRecursive(
    gql(listQuizAttempts),
    {
      variables: {
        orgId,
      },
    },
  );

  // Get Quiz questions & options
  const [getQuestions, { loading: quizQuestionsDataLoading }] = useLazyQuery(
    gql(listQuestions),
  );
  const [deleteCompetencyQuestionsMutation] = useMutation(
    gql(deleteCompetencyQuestion),
  );
  // List parts used by lab instance
  const [getModuleParts, { loading: listModulePartsLoading }] = useLazyQuery(
    gql(listModuleParts),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  // get module and parts
  const [getModule, { loading: getCourseModuleLoading }] = useLazyQuery(
    gql(getCourseModule),
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  const [deleteQuestionOptionMutation] = useMutation(gql(deleteQuestionOption));
  const [deleteQuestionMutation] = useMutation(gql(deleteQuestion));
  const [deleteQuizMutation] = useMutation(gql(deleteQuiz));

  // delete quiz and associated course records
  const deleteQuizAndQuestions = async (quizId) => {
    // delete all parts with matching labId
    getModuleParts({
      variables: {
        limit: 100000,
        filter: { quizId: { eq: quizId } },
      },
      onCompleted: async (listModulePartsData) => {
        const allLabUsedParts =
          listModulePartsData?.listModuleParts?.items || [];
        // fetch modules for the parts
        await Promise.all(
          allLabUsedParts.map(async (p) => {
            // delete part
            await deleteModulePartMutation({
              variables: {
                input: {
                  id: p.id,
                },
              },
            });

            getModule({
              variables: {
                id: p.courseModuleId,
              },
              onCompleted: async (courseModuleData) => {
                const partCount =
                  courseModuleData?.getCourseModule?.parts?.items.length || 0;
                // if module contains no parts then delete module as well
                if (partCount === 0) {
                  await deleteCourseModuleMutation({
                    variables: {
                      input: {
                        id: p.courseModuleId,
                      },
                    },
                  });
                }
              },
            });
          }),
        );
      },
    });

    // delete questions, parts and quiz
    await getQuestions({
      variables: {
        filter: { quizId: { eq: quizId } },
      },
      onCompleted: async (_quizQuestionsData) => {
        const questions = _quizQuestionsData?.listQuestions?.items || [];
        questions.map(async (question) => {
          // delete competencies
          if (question.competencies?.items?.length > 0) {
            await Promise.all(
              question?.competencies?.items?.map((cd) =>
                deleteCompetencyQuestionsMutation({
                  variables: {
                    input: { id: cd.id },
                  },
                }),
              ),
            );
          }
          // delete choices/options
          if (question.options?.items?.length > 0) {
            await Promise.all(
              question?.options?.items?.map((dc) =>
                deleteQuestionOptionMutation({
                  variables: {
                    input: { id: dc.id },
                  },
                }),
              ),
            );
          }
          // delete questions
          deleteQuestionMutation({
            variables: {
              input: {
                id: question.id,
              },
            },
          });
        });
        // delete quiz
        await deleteQuizMutation({
          variables: {
            input: {
              id: quizId,
            },
          },
          onCompleted: () => {
            refetchQuizData();
            successToastMessage('Success! Quiz deleted');
          },
        });
      },
    });
  };

  if (
    quizLoading ||
    coursesLoading ||
    quizQuestionsDataLoading ||
    listModulePartsLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading ||
    getCourseModuleLoading ||
    attemptsLoading
  ) {
    return <FdProgress />;
  }

  const quizAttempts = attemptsData?.listQuizAttempts?.items || [];

  const courses = coursesData?.listCoursesByOrgId?.items || [];
  const coursesWithQuizzes = courses
    .filter((c) =>
      c.courseModules?.items.some((cm) => cm.parts.items.length > 0),
    )
    .map((q) => ({
      moduleId: q.id,
      quizzes: q.courseModules?.items
        .map((qm) => qm?.parts?.items.map((p) => p.quiz?.id))
        .flat(),
    }));

  const quizRows = isAdmin
    ? quizData?.listQuizesByOrgId?.items
    : quizData?.listQuizesByOrgId?.items.filter(
        (q) => q.user?.id === globalSnap.userId,
      ) || [];

  const quizzes =
    quizRows?.map((quiz) => {
      const attempts = quizAttempts.filter((a) => a.quizId === quiz.id);
      const userAttempts = _.chain(attempts)
        .groupBy('courseUserId')
        .map((items, courseUser) => ({
          courseUser,
          attempts: [...new Set(items.map((i) => i.attempt))].length,
        }))
        .value();
      const countAttempts = userAttempts.reduce(
        (acc, v) => acc + v.attempts,
        0,
      );
      return {
        id: quiz.id,
        name: quiz.name,
        creator: isAdmin ? quiz?.user?.name : '',
        createdAt: getDateTimeZoneCustom(quiz.createdAt),
        course: coursesWithQuizzes.filter((cq) =>
          cq.quizzes.some((q) => q === quiz.id),
        ).length,
        attempts: countAttempts,
      };
    }) || [];

  return (
    <BasePage
      heading="Manage Quizzes"
      breadCrumbs={[{ url: '/landing', name: 'Home' }]}
      currentPageBreadcrumbLabel="Manage Quizzes"
      renderBreadCrumbAsButton
      data-cy="quizzes-base-page"
    >
      <Box>
        <QuizTable
          rows={quizzes}
          onDeleteQuiz={deleteQuizAndQuestions}
          isAdmin={isAdmin}
        />
      </Box>
    </BasePage>
  );
};

export default ListQuizzes;
