import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useQuery, gql, useMutation } from '@apollo/client';
import { alpha } from '@material-ui/core';
import { MessageSquareDotIcon } from 'lucide-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdProgress,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  triggerGlobalEvent,
  GLOBAL_EVENTS,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getCourseUser } from '../queries/customQueries';
import CourseChat from '../components/Courses/LessonView/CourseChat';
import { listCourseMessagesByUserCourseId, listUrls } from '../graphql/queries';
import {
  createCourseMessage,
  deleteCourseMessage,
  updateCourseMessage,
} from '../graphql/mutations';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/Course/chat';
import ChatLogo from '../components/Courses/LessonView/Chat/ChatLogo';
import useSubscriptionCourseChat from '../hooks/useSubscriptionCourseChat';
import { updateChatMessagesAsRead } from '../components/Courses/LessonView/Chat/chatUtils';
import { uploadCourseChatImageAttachmentToS3 } from '../shared/utils/chatUtils';

const MessengerIcon = ({ children }) => (
  <Box
    height={32}
    width={32}
    sx={(theme) => ({
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
    })}
  >
    {children}
  </Box>
);

MessengerIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

const ViewCourseChat = () => {
  const [chatEditInProgress, setChatEditInProgress] = useState(false);
  const [showNewMessagesLink, setShowMessagesLink] = useState(false);
  const { courseUserId } = useParams();
  const { userId, orgId, theme: orgTheme } = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, control, watch, setValue } = reactHookFormMethods;
  const { append: appendMessage, remove: removeMessage } = useFieldArray({
    control,
    name: 'messages',
  });
  const watchMessages = watch('messages');

  const { data: urlData } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: orgId },
        theme: { eq: orgTheme?.toUpperCase() },
      },
    },
    skip: !orgId,
    fetchPolicy: 'cache-and-network',
  });
  const key = urlData?.listUrls?.items?.[0]?.key;
  const isWhiteLabelledOrg = key !== undefined;

  const { data: courseUserData, loading: courseUserDataLoading } = useQuery(
    gql(getCourseUser),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
    },
  );
  const { name: courseName, id: courseId } =
    courseUserData?.getCourseUser?.course || {};
  const [updateCourseMessageMutation] = useMutation(gql(updateCourseMessage));

  const { refetch: refetchMessages } = useQueryRecursive(
    gql(listCourseMessagesByUserCourseId),
    {
      variables: {
        userCourseId: courseUserId,
      },
      skip: !courseUserId,
      staleTime: { seconds: 0 },
      onCompleted: (_data) => {
        const chatHistory =
          _data?.listCourseMessagesByUserCourseId?.items
            .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
            ?.map((m, i) => {
              const text =
                m?.messages?.find((msg) => msg?.type === 'TEXT')?.content || '';
              const imageUrls =
                m?.messages
                  ?.filter((msg) => msg?.type === 'IMAGE')
                  ?.map((msg) => msg?.content) || [];
              return {
                message: {
                  idx: i,
                  dbId: m?.id,
                  text,
                  images: imageUrls,
                  self: m?.sender?.id === userId,
                  timeStamp:
                    m?.createdAt &&
                    format(new Date(m?.createdAt), 'dd MMM yyyy, h:mm a'),
                  MessengerIcon: (
                    <MessengerIcon>
                      <ChatLogo
                        logo={key}
                        isWhiteLabelled={isWhiteLabelledOrg}
                      />
                    </MessengerIcon>
                  ),
                  messengerName: 'Course Mentor',
                  isAdmin: m?.sender?.permissions?.includes('VIEW_INSIGHTS'),
                  isRead: m?.seen === 'TRUE',
                  isEdited: m?.isEdited,
                },
              };
            }) || [];

        reset({
          messages: [...chatHistory],
        });
        // mark all chat messages as read
        triggerGlobalEvent(GLOBAL_EVENTS.HIDE_CHAT_UNREAD);

        // update all admin messages as SEEN=true
        updateChatMessagesAsRead({
          updateMessageMutation: updateCourseMessageMutation,
          messageIds: chatHistory
            ?.filter(
              ({ message }) => !message?.self && message?.seen !== 'TRUE',
            )
            ?.map(({ message }) => message?.dbId),
        });
      },
    },
  );

  useSubscriptionCourseChat({
    courseUserId,
    userId,
    onDataAction: (_newMessage) => {
      // check if chat is in edit mode
      if (chatEditInProgress) {
        setShowMessagesLink(true); // show new messages link
        return;
      }
      const { message, id, createdAt } =
        _newMessage?.data?.data?.onCreateCourseMessage || {};
      const newId = watchMessages.length;
      // append message locally
      appendMessage({
        message: {
          idx: newId,
          dbId: id,
          text: message,
          self: false,
          timeStamp: format(new Date(createdAt), 'dd MMM yyyy, h:mm a'),
          isAdmin: true,
          isRead: true,
          messengerName: 'Course Mentor',
          MessengerIcon: (
            <MessengerIcon>
              <ChatLogo logo={key} isWhiteLabelled={isWhiteLabelledOrg} />
            </MessengerIcon>
          ),
          isEdited: false,
        },
      });
      triggerGlobalEvent(GLOBAL_EVENTS.HIDE_CHAT_UNREAD);
    },
  });

  const [createCourseMessageMutation] = useMutation(gql(createCourseMessage), {
    onCompleted: (_data) => {
      const newId = watchMessages.length;
      const updatedMessages = watchMessages.map((msg, idx) =>
        idx !== newId
          ? {
              message: {
                ...msg.message,
                dbId: _data?.createCourseMessage?.id,
              },
            }
          : msg,
      );
      setValue('messages', updatedMessages);
    },
  });
  const [deleteCourseMessageMutation] = useMutation(gql(deleteCourseMessage));
  const addChatMessage = async ({ text, images }) => {
    const dateSubmitted = new Date().toISOString();
    const newId = watchMessages.length;

    // append message locally
    appendMessage({
      message: {
        idx: newId,
        text,
        self: true,
        timeStamp: format(new Date(dateSubmitted), 'dd MMM yyyy, h:mm a'),
        isAdmin: false,
        isRead: false,
        images: images?.map((image) => URL.createObjectURL(image)) || [],
      },
    });
    const imageUrls = await uploadCourseChatImageAttachmentToS3(
      images,
      userId,
      courseUserId,
    );
    // create a chat message in db
    createCourseMessageMutation({
      variables: {
        input: {
          createdAt: dateSubmitted,
          updatedAt: dateSubmitted,
          messages: [
            { type: 'TEXT', content: text },
            ...(imageUrls?.map((image) => ({
              type: 'IMAGE',
              content: image?.url,
            })) || []),
          ],
          seen: 'FALSE',
          senderId: userId,
          userCourseId: courseUserId,
          courseId,
          orgId,
        },
      },
    });
  };
  const editChatMessage = async ({ text, images, dbId }) => {
    const dateSubmitted = new Date().toISOString();
    const imagesForUpload =
      images?.filter((image) => image instanceof File) || [];
    const imageUrls = await uploadCourseChatImageAttachmentToS3(
      imagesForUpload,
      userId,
      courseUserId,
    );
    const allImages = [
      ...(images
        ?.filter((image) => !(image instanceof File))
        ?.map((i) => ({ url: i })) || []),
      ...(imageUrls || []),
    ];

    // update chat message in db
    updateCourseMessageMutation({
      variables: {
        input: {
          id: dbId,
          messages: [
            { type: 'TEXT', content: text },
            ...(allImages?.map((image) => ({
              type: 'IMAGE',
              content: image?.url,
            })) || []),
          ],
          updatedAt: dateSubmitted,
          isEdited: true,
        },
      },
    });
  };
  const deleteChatMessage = ({ dbId, idx }) => {
    removeMessage(idx);
    if (!dbId) return;
    // delete from db if exists
    deleteCourseMessageMutation({
      variables: {
        input: {
          id: dbId,
        },
      },
      onCompleted: () => {
        successToastMessage('Success! Message deleted');
      },
    });
  };
  const onShowNewMessagesLink = () => {
    setShowMessagesLink(false);
    setChatEditInProgress(false);
    refetchMessages();
  };

  if (courseUserDataLoading) return <FdProgress />;

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <FdBreadcrumbHeader
          entries={[
            {
              name: courseName,
              path: `/labs/courses/view/${courseUserId}`,
              type: 'COURSE',
            },
          ]}
          page={{ name: 'Chat', type: 'COURSE' }}
        />
        <Box data-cy="view-course-chat">
          <Box className="flex-1 flex flex-col items-center">
            <FdTypography variant="caption" className="font-medium">
              {courseName}
            </FdTypography>
            <Box className="flex items-center gap-x-4">
              <Box className="flex items-center justify-center gap-x-2 min-w-96">
                <MessageSquareDotIcon size={24} />
                <FdTypography variant="h4" className="font-medium">
                  Chat
                </FdTypography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              height: 'calc(100vh - 170px)',
              border: `1px solid ${theme.palette.primary.main}`,
            })}
            className="w-full rounded-lg mt-4"
          >
            <Box
              className="flex items-center gap-x-4 h-[72px] pl-4"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
              })}
            >
              <MessengerIcon>
                <ChatLogo logo={key} isWhiteLabelled={isWhiteLabelledOrg} />
              </MessengerIcon>
              <FdTypography variant="subtitle1">Course Mentor</FdTypography>
            </Box>
            <Box
              sx={{
                height: 'calc(100vh - 260px)',
              }}
            >
              <CourseChat
                messages={watchMessages?.map((m) => m.message) || []}
                inputPlaceholder="Message Course Mentors"
                inputCaptionText="Only course mentors can see the messages in this chat."
                noMessageText="Start a conversation with your course mentors now!"
                onAddMessage={addChatMessage}
                onEditMessage={editChatMessage}
                onDeleteMessage={deleteChatMessage}
                loading={false}
                setChatEditInProgress={setChatEditInProgress}
                showNewMessagesLink={showNewMessagesLink}
                onShowNewMessagesLink={onShowNewMessagesLink}
              />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ViewCourseChat;
