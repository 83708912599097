import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import {
  DotIcon,
  NotepadTextIcon,
  PresentationIcon,
  ScrollTextIcon,
  VideoIcon,
} from 'lucide-react';
import { Box, useTheme } from '@mui/material';
import { FdTypography, FdProgress } from '@fifthdomain/fe-shared';
import { getCourseUser } from '../queries/customQueries';
import VideoPlayer from '../components/Courses/LessonView/VideoPlayer';

const ViewLessonVideo = () => {
  const { courseUserId, lessonId, resourceId, courseResourceId } = useParams();
  const theme = useTheme();

  const { data, loading } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  if (loading) return <FdProgress />;

  const course = data?.getCourseUser?.course;
  if (!course)
    return (
      <FdTypography variant="h6" className="text-center my-2">
        Course not found
      </FdTypography>
    );

  const { name: courseName, courseLessons, courseResources } = course;
  const lessonsData = [...(courseLessons?.items || [])];
  const lessons =
    lessonsData
      ?.sort((a, b) => (a?.orderNumber || 0) - (b?.orderNumber || 0))
      ?.map((item, idx) => ({
        ...item,
        orderId: idx + 1,
      })) || [];
  const selectedLesson =
    lessons.find((item) => item.lessonID === lessonId) || {};
  const {
    name: lessonName,
    lessonExercises,
    lessonResources,
  } = selectedLesson?.lesson || {};
  const selectedResource =
    resourceId && lessonResources?.items?.find((r) => r.id === resourceId);
  const selectedCourseResource = courseResources?.items?.find(
    (r) => r.id === courseResourceId,
  );
  const videoUrl =
    selectedResource?.url ||
    lessonExercises?.items?.[0]?.videoInstructions ||
    selectedCourseResource?.url;
  const videoName =
    selectedResource?.name ||
    selectedCourseResource?.name ||
    'Video Instructions';

  return (
    <Box my={2}>
      <Box className="overflow-x-hidden" data-cy="view-course">
        <Box className="flex justify-between items-center w-full">
          <Box className="flex-1 flex flex-col items-center">
            <FdTypography variant="caption" className="font-medium">
              {courseName}
            </FdTypography>
            <Box className="flex items-center gap-x-4">
              <Box className="flex items-center justify-center gap-x-2 min-w-96">
                {selectedLesson?.lesson && <PresentationIcon size={20} />}
                <FdTypography
                  variant="h4"
                  className="inline-flex items-center font-medium gap-x-1"
                >
                  {resourceId || selectedLesson?.lesson ? (
                    <>
                      {selectedLesson?.orderId}
                      <DotIcon /> {lessonName}
                    </>
                  ) : (
                    <>
                      <NotepadTextIcon /> Course Overview
                    </>
                  )}
                </FdTypography>
                <DotIcon />
                {selectedResource || courseResourceId ? (
                  <Box
                    className="flex items-center rounded-xl px-2 py-1"
                    sx={{ bgcolor: theme.palette.primary.dark }}
                  >
                    <VideoIcon size={18} />
                  </Box>
                ) : (
                  <ScrollTextIcon />
                )}
                <FdTypography variant="h4" className="font-medium">
                  {videoName}
                </FdTypography>
              </Box>
            </Box>
            <Box mt={2} className="w-full">
              <VideoPlayer url={videoUrl} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewLessonVideo;
