import { upperCaseFirstLetter } from './stringUtils';

export const getLabStatus = (status = 'Unknown') => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'READY':
      return 'Available';
    case 'DRAFT':
      return 'Draft';
    case 'IN BUILD QUEUE':
    case 'BUILD IN PROGRESS':
    case 'BUILDING':
    case 'IN_BUILD_QUEUE':
      return 'Building';
    case 'EXPIRED':
      return 'Expired';
    case 'ERROR':
      return 'Error';
    case 'BUILD FAILED':
      return 'Error';
    case 'EDIT IN PROGRESS':
      return 'In Editing';
    case 'IN DELETE QUEUE':
    case 'DELETE IN PROGRESS':
      return 'Building';
    default:
      return upperCaseFirstLetter(_status);
  }
};

export const getLabInstanceStatus = (status = 'Unknown') => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'READY':
      return 'Ready';
    case 'DRAFT':
      return 'Draft';
    case 'IN BUILD QUEUE':
    case 'BUILD IN PROGRESS':
    case 'BUILDING':
      return 'Building';
    case 'EXPIRED':
      return 'Expired';
    case 'ERROR':
      return 'Error';
    case 'BUILD FAILED':
      return 'Error';
    case 'EDIT IN PROGRESS':
      return 'In Editing';
    case 'IN DELETE QUEUE':
    case 'DELETE IN PROGRESS':
      return 'Building';
    default:
      return upperCaseFirstLetter(_status);
  }
};
