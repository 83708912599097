/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: EditUser!) {
    updateUser(input: $input)
  }
`;

//Create Lab Prototype
export const createLabPrototype = /* GraphQL */ `
  mutation CreateLabPrototype(
    $input: CreateLabPrototypeInput!
    $condition: ModelLabPrototypeConditionInput
  ) {
    createLabPrototype(input: $input, condition: $condition) {
      id
      name
      description
      provider
      status
      userId
      public
      shared
      skipBuildVms
      payload
      version
      versionIteration
    }
  }
`;

export const updateLabPrototype = /* GraphQL */ `
  mutation UpdateLabPrototype(
    $input: UpdateLabPrototypeInput!
    $condition: ModelLabPrototypeConditionInput
  ) {
    updateLabPrototype(input: $input, condition: $condition) {
      id
      name
      description
      provider
      status
      userId
      public
      shared
      version
      versionIteration
      skipBuildVms
      vms {
        items {
          id
          labPrototypeId
          name
          imageId
          vpn
          vdi
          vdiUser
          createdAt
          updatedAt
        }
        nextToken
      }
      networks {
        items {
          id
          labPrototypeId
          name
          hasInternet
          createdAt
          updatedAt
        }
        nextToken
      }
      orgId
      createdAt
      updatedAt
    }
  }
`;

// Create Lab Competency Prototype
export const createCompetencyLabPrototype = /* GraphQL */ `
  mutation CreateCompetencyLabPrototype(
    $input: CreateCompetencyLabPrototypeInput!
    $condition: ModelCompetencyLabPrototypeConditionInput
  ) {
    createCompetencyLabPrototype(input: $input, condition: $condition) {
      id
      competencyId
      labPrototypeId
      createdAt
      updatedAt
    }
  }
`;
// Update Lab Competency Prototype
export const updateCompetencyLabPrototype = /* GraphQL */ `
  mutation UpdateCompetencyLabPrototype(
    $input: UpdateCompetencyLabPrototypeInput!
    $condition: ModelCompetencyLabPrototypeConditionInput
  ) {
    updateCompetencyLabPrototype(input: $input, condition: $condition) {
      id
      competencyId
      labPrototypeId
    }
  }
`;
// Delete Lab Competency Prototype
export const deleteCompetencyLabPrototype = /* GraphQL */ `
  mutation DeleteCompetencyLabPrototype(
    $input: DeleteCompetencyLabPrototypeInput!
    $condition: ModelCompetencyLabPrototypeConditionInput
  ) {
    deleteCompetencyLabPrototype(input: $input, condition: $condition) {
      id
      competencyId
      labPrototypeId
      createdAt
      updatedAt
    }
  }
`;
// Create Lab Prototype Network
export const createLabPrototypeNetwork = /* GraphQL */ `
  mutation CreateLabPrototypeNetwork(
    $input: CreateLabPrototypeNetworkInput!
    $condition: ModelLabPrototypeNetworkConditionInput
  ) {
    createLabPrototypeNetwork(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      hasInternet
      createdAt
      updatedAt
      __typename
    }
  }
`;
// Update Lab Prototype Network
export const updateLabPrototypeNetwork = /* GraphQL */ `
  mutation UpdateLabPrototypeNetwork(
    $input: UpdateLabPrototypeNetworkInput!
    $condition: ModelLabPrototypeNetworkConditionInput
  ) {
    updateLabPrototypeNetwork(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      hasInternet
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteLabPrototypeNetwork = /* GraphQL */ `
  mutation DeleteLabPrototypeNetwork(
    $input: DeleteLabPrototypeNetworkInput!
    $condition: ModelLabPrototypeNetworkConditionInput
  ) {
    deleteLabPrototypeNetwork(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      hasInternet
      createdAt
      updatedAt
      __typename
    }
  }
`;

// Create Lab Prototype VMs
export const createLabPrototypeVM = /* GraphQL */ `
  mutation CreateLabPrototypeVM(
    $input: CreateLabPrototypeVMInput!
    $condition: ModelLabPrototypeVMConditionInput
  ) {
    createLabPrototypeVM(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      imageId
      vpn
      vdi
      vdiUser
      instanceType
      networks {
        items {
          id
          labPrototypeVMId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

//Update Lab Prototype Vms
export const updateLabPrototypeVM = /* GraphQL */ `
  mutation UpdateLabPrototypeVM(
    $input: UpdateLabPrototypeVMInput!
    $condition: ModelLabPrototypeVMConditionInput
  ) {
    updateLabPrototypeVM(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      imageId
      vpn
      vdi
      vdiUser
      instanceType
      networks {
        items {
          id
          labPrototypeVMId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

// Delete Lab Prototype Vms
export const deleteLabPrototypeVM = /* GraphQL */ `
  mutation DeleteLabPrototypeVM(
    $input: DeleteLabPrototypeVMInput!
    $condition: ModelLabPrototypeVMConditionInput
  ) {
    deleteLabPrototypeVM(input: $input, condition: $condition) {
      id
      labPrototypeId
      name
      imageId
      vpn
      vdi
      vdiUser
      instanceType
      networks {
        items {
          id
          labPrototypeVMId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

// Create Lab Prototype VM Network
export const createLabPrototypeVMNetwork = /* GraphQL */ `
  mutation CreateLabPrototypeVMNetwork(
    $input: CreateLabPrototypeVMNetworkInput!
    $condition: ModelLabPrototypeVMNetworkConditionInput
  ) {
    createLabPrototypeVMNetwork(input: $input, condition: $condition) {
      id
      labPrototypeVMId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
// Update Lab Prototype VM Network
export const updateLabPrototypeVMNetwork = /* GraphQL */ `
  mutation UpdateLabPrototypeVMNetwork(
    $input: UpdateLabPrototypeVMNetworkInput!
    $condition: ModelLabPrototypeVMNetworkConditionInput
  ) {
    updateLabPrototypeVMNetwork(input: $input, condition: $condition) {
      id
      labPrototypeVMId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
// Update Lab Prototype VM Network
export const deleteLabPrototypeVMNetwork = /* GraphQL */ `
  mutation DeleteLabPrototypeVMNetwork(
    $input: DeleteLabPrototypeVMNetworkInput!
    $condition: ModelLabPrototypeVMNetworkConditionInput
  ) {
    deleteLabPrototypeVMNetwork(input: $input, condition: $condition) {
      id
      labPrototypeVMId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const startLab = /* GraphQL */ `
  mutation StartLab(
    $labPrototypeId: ID!
    $modulePartId: ID
    $userAssessmentId: ID
  ) {
    startLab(
      labPrototypeId: $labPrototypeId
      modulePartId: $modulePartId
      userAssessmentId: $userAssessmentId
    )
  }
`;
export const restartLab = /* GraphQL */ `
  mutation RestartLab($labInstanceId: ID!, $hardReset: Boolean) {
    restartLab(labInstanceId: $labInstanceId, hardReset: $hardReset)
  }
`;
export const extendLabTime = /* GraphQL */ `
  mutation ExtendLabTime($labInstanceId: ID!) {
    extendLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
      __typename
    }
  }
`;
export const startPrototype = /* GraphQL */ `
  mutation StartPrototype($labPrototypeId: ID) {
    startPrototype(labPrototypeId: $labPrototypeId)
  }
`;
export const stopPrototype = /* GraphQL */ `
  mutation StopPrototype($labPrototypeId: ID, $savePrototype: Boolean) {
    stopPrototype(
      labPrototypeId: $labPrototypeId
      savePrototype: $savePrototype
    )
  }
`;
export const rollbackPrototype = /* GraphQL */ `
  mutation RollbackPrototype($labPrototypeId: ID) {
    rollbackPrototype(labPrototypeId: $labPrototypeId)
  }
`;
export const startParticipantLab = /* GraphQL */ `
  mutation StartParticipantLab($userId: ID, $modulePartId: ID) {
    startParticipantLab(userId: $userId, modulePartId: $modulePartId)
  }
`;
export const stopParticipantLab = /* GraphQL */ `
  mutation StopParticipantLab($userId: ID, $modulePartId: ID) {
    stopParticipantLab(userId: $userId, modulePartId: $modulePartId)
  }
`;
export const deleteLab = /* GraphQL */ `
  mutation DeleteLab($labInstanceId: ID!) {
    deleteLab(labInstanceId: $labInstanceId)
  }
`;
export const startVm = /* GraphQL */ `
  mutation StartVm($labInstanceVmId: ID) {
    startVm(labInstanceVmId: $labInstanceVmId)
  }
`;

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const createCourseLesson = /* GraphQL */ `
  mutation CreateCourseLesson(
    $input: CreateCourseLessonInput!
    $condition: ModelCourseLessonConditionInput
  ) {
    createCourseLesson(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCourseLesson = /* GraphQL */ `
  mutation DeleteCourseLesson(
    $input: DeleteCourseLessonInput!
    $condition: ModelCourseLessonConditionInput
  ) {
    deleteCourseLesson(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createCourseModule = /* GraphQL */ `
  mutation CreateCourseModule(
    $input: CreateCourseModuleInput!
    $condition: ModelCourseModuleConditionInput
  ) {
    createCourseModule(input: $input, condition: $condition) {
      name
      description
      courseId
      parts {
        items {
          name
          description
          courseModuleId
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        nextToken
      }
      id
    }
  }
`;

export const createModulePart = /* GraphQL */ `
  mutation CreateModulePart(
    $input: CreateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    createModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      deletionMode
      url
      file {
        bucket
        key
        region
      }
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;

export const createModulePartProgress = /* GraphQL */ `
  mutation CreateModulePartProgress(
    $input: CreateModulePartProgressInput!
    $condition: ModelModulePartProgressConditionInput
  ) {
    createModulePartProgress(input: $input, condition: $condition) {
      courseUserId
      courseUser {
        courseId
        userId
        orgId
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      modulePartId
      status
      startedOn
      finishedOn
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateModulePartProgress = /* GraphQL */ `
  mutation UpdateModulePartProgress(
    $input: UpdateModulePartProgressInput!
    $condition: ModelModulePartProgressConditionInput
  ) {
    updateModulePartProgress(input: $input, condition: $condition) {
      courseUserId
      modulePartId
      status
      startedOn
      finishedOn
      id
      createdAt
      updatedAt
    }
  }
`;

export const createCourseUser = /* GraphQL */ `
  mutation CreateCourseUser(
    $input: CreateCourseUserInput!
    $condition: ModelCourseUserConditionInput
  ) {
    createCourseUser(input: $input, condition: $condition) {
      courseId
      id
      userId
      orgId
      status
    }
  }
`;

export const createCourseGroup = /* GraphQL */ `
  mutation CreateCourseGroup(
    $input: CreateCourseGroupInput!
    $condition: ModelCourseGroupConditionInput
  ) {
    createCourseGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      courseCoursegroupsId
    }
  }
`;

export const deleteCourseGroup = /* GraphQL */ `
  mutation DeleteCourseGroup(
    $input: DeleteCourseGroupInput!
    $condition: ModelCourseGroupConditionInput
  ) {
    deleteCourseGroup(input: $input, condition: $condition) {
      courseId
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
      courseCoursegroupsId
    }
  }
`;

export const deleteCourseUser = /* GraphQL */ `
  mutation DeleteCourseUser(
    $input: DeleteCourseUserInput!
    $condition: ModelCourseUserConditionInput
  ) {
    deleteCourseUser(input: $input, condition: $condition) {
      courseId
      course {
        orgId
        name
        description
        categoryId
        available
        image {
          bucket
          key
          region
        }
        category {
          name
          id
          createdAt
          updatedAt
        }
        ownerId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        availability
        status
        courseModules {
          nextToken
        }
        coursegroups {
          nextToken
        }
        courseUsers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      userId
      orgId
      user {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      courseProgress
      id
      createdAt
      updatedAt
      courseCourseUsersId
    }
  }
`;

export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      name
      description
      availability
      status
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateCourseModule = /* GraphQL */ `
  mutation UpdateCourseModule(
    $input: UpdateCourseModuleInput!
    $condition: ModelCourseModuleConditionInput
  ) {
    updateCourseModule(input: $input, condition: $condition) {
      name
      description
      courseId
      parts {
        items {
          name
          description
          courseModuleId
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      courseCourseModulesId
    }
  }
`;

export const updateModulePart = /* GraphQL */ `
  mutation UpdateModulePart(
    $input: UpdateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    updateModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      deletionMode
      url
      file {
        bucket
        key
        region
      }
      id
      createdAt
      updatedAt
      courseModulePartsId
      status
    }
  }
`;

export const deleteCourseModule = /* GraphQL */ `
  mutation DeleteCourseModule(
    $input: DeleteCourseModuleInput!
    $condition: ModelCourseModuleConditionInput
  ) {
    deleteCourseModule(input: $input, condition: $condition) {
      name
      description
      courseId
      parts {
        items {
          name
          description
          courseModuleId
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      courseCourseModulesId
    }
  }
`;

export const deleteModulePart = /* GraphQL */ `
  mutation DeleteModulePart(
    $input: DeleteModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    deleteModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      url
      file {
        bucket
        key
        region
      }
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;

export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      orgId
      name
    }
  }
`;

export const createVMFile = /* GraphQL */ `
  mutation CreateVMFile(
    $input: CreateVMFileInput!
    $condition: ModelVMFileConditionInput
  ) {
    createVMFile(input: $input, condition: $condition) {
      id
      description
      name
      status
      fileSize
      file {
        bucket
        key
        region
      }
      userId
      orgId
      createdAt
      updatedAt
    }
  }
`;

export const updateVMFile = /* GraphQL */ `
  mutation UpdateVMFile(
    $input: UpdateVMFileInput!
    $condition: ModelVMFileConditionInput
  ) {
    updateVMFile(input: $input, condition: $condition) {
      id
      description
      name
      status
      file {
        bucket
        key
        region
      }
      userId
      orgId
      createdAt
      updatedAt
    }
  }
`;

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      answer
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          orderNumber
          correctAnswer
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      answer
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          orderNumber
          correctAnswer
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      answer
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          orderNumber
          correctAnswer
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionOption = /* GraphQL */ `
  mutation CreateQuestionOption(
    $input: CreateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    createQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      orderNumber
      correctAnswer
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionOption = /* GraphQL */ `
  mutation UpdateQuestionOption(
    $input: UpdateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    updateQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      orderNumber
      correctAnswer
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionOption = /* GraphQL */ `
  mutation DeleteQuestionOption(
    $input: DeleteQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    deleteQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      orderNumber
      correctAnswer
      id
      createdAt
      updatedAt
    }
  }
`;

export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createCompetencyQuestion = /* GraphQL */ `
  mutation CreateCompetencyQuestion(
    $input: CreateCompetencyQuestionInput!
    $condition: ModelCompetencyQuestionConditionInput
  ) {
    createCompetencyQuestion(input: $input, condition: $condition) {
      id
      competencyId
      questionId
      question {
        id
        quizId
        type
        name
        point
        answer
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        orderNumber
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencyQuestion = /* GraphQL */ `
  mutation UpdateCompetencyQuestion(
    $input: UpdateCompetencyQuestionInput!
    $condition: ModelCompetencyQuestionConditionInput
  ) {
    updateCompetencyQuestion(input: $input, condition: $condition) {
      id
      competencyId
      questionId
      question {
        id
        quizId
        type
        name
        point
        answer
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        orderNumber
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyQuestion = /* GraphQL */ `
  mutation DeleteCompetencyQuestion(
    $input: DeleteCompetencyQuestionInput!
    $condition: ModelCompetencyQuestionConditionInput
  ) {
    deleteCompetencyQuestion(input: $input, condition: $condition) {
      id
      competencyId
      questionId
      question {
        id
        quizId
        type
        name
        point
        answer
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        orderNumber
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateCourseUser = /* GraphQL */ `
  mutation UpdateCourseUser(
    $input: UpdateCourseUserInput!
    $condition: ModelCourseUserConditionInput
  ) {
    updateCourseUser(input: $input, condition: $condition) {
      courseId
      userId
      orgId
      status
      courseProgress
      id
      createdAt
      updatedAt
      courseCourseUsersId
    }
  }
`;

export const attemptQuiz = /* GraphQL */ `
  mutation AttemptQuiz(
    $courseUserId: ID!
    $quizId: ID!
    $questionAnswers: [QuestionAnswerInput]
  ) {
    attemptQuiz(
      courseUserId: $courseUserId
      quizId: $quizId
      questionAnswers: $questionAnswers
    )
  }
`;
export const assignCourseToGroups = /* GraphQL */ `
  mutation AssignCourseToGroups($courseId: ID!, $groupIds: [ID]!) {
    assignCourseToGroups(courseId: $courseId, groupIds: $groupIds)
  }
`;
export const removeCourseFromGroups = /* GraphQL */ `
  mutation RemoveCourseFromGroups($courseId: ID!, $groupIds: [ID]!) {
    removeCourseFromGroups(courseId: $courseId, groupIds: $groupIds)
  }
`;

export const transferVMOwnership = /* GraphQL */ `
  mutation TransferVMOwnership($orgAlias: String!, $vmIds: [ID]!) {
    transferVMOwnership(orgAlias: $orgAlias, vmIds: $vmIds)
  }
`;
export const transferLabOwnership = /* GraphQL */ `
  mutation TransferLabOwnership($orgAlias: String!, $labIds: [ID]!) {
    transferLabOwnership(orgAlias: $orgAlias, labIds: $labIds)
  }
`;

export const createCourseMessage = /* GraphQL */ `
  mutation CreateCourseMessage(
    $input: CreateCourseMessageInput!
    $condition: ModelCourseMessageConditionInput
  ) {
    createCourseMessage(input: $input, condition: $condition) {
      id
    }
  }
`;

export const readChatMessages = /* GraphQL */ `
  mutation ReadChatMessages($userCourseId: ID!, $senderId: ID) {
    readChatMessages(userCourseId: $userCourseId, senderId: $senderId)
  }
`;

export const assignCourseToUsers = /* GraphQL */ `
  mutation AssignCourseToUsers(
    $courseId: ID!
    $orgId: ID!
    $emails: [AWSEmail]!
    $groupIds: [ID]
    $action: INVITATION_TYPE!
  ) {
    assignCourseToUsers(
      courseId: $courseId
      orgId: $orgId
      emails: $emails
      groupIds: $groupIds
      action: $action
    )
  }
`;

export const removeInvitedUser = /* GraphQL */ `
  mutation RemoveInvitedUser($inviteToken: ID!) {
    removeInvitedUser(inviteToken: $inviteToken)
  }
`;
