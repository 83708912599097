import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { FdChip } from '@fifthdomain/fe-shared';
import { PROFICIENCY_COLOR, SPECIALTY_COLOR } from '../../../constants';

const LessonDrawerHeader = ({ activeLesson }) => {
  const theme = useTheme();
  return (
    <Box mb={2} className="flex gap-2">
      <FdChip
        size="small"
        rounded={false}
        style={{
          color: PROFICIENCY_COLOR[activeLesson?.proficiency]?.color,
          backgroundColor:
            PROFICIENCY_COLOR[activeLesson?.proficiency]?.backgroundColor,
        }}
        label={activeLesson?.proficiency}
      />
      <FdChip
        size="small"
        label={activeLesson?.specialty}
        rounded={false}
        style={{
          color: SPECIALTY_COLOR[activeLesson?.specialty]?.color || '',
          backgroundColor:
            SPECIALTY_COLOR[activeLesson?.specialty]?.backgroundColor,
        }}
      />
      <FdChip
        size="small"
        rounded={false}
        label={`Est. Time : ${activeLesson?.estimatedSolveTime}min`}
        style={{
          backgroundColor: theme?.fdProColors?.electricBlue[50],
        }}
      />
    </Box>
  );
};

LessonDrawerHeader.propTypes = {
  activeLesson: PropTypes.shape({
    specialty: PropTypes.string,
    proficiency: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default LessonDrawerHeader;
