import { PROFICIENCY_LEVELS } from '../../../constants';

export const formatLesson = (lesson, index) => ({
  ...lesson,
  order: index + 1,
  id: lesson?.id,
  lessonId: lesson?.id,
  proficiency: PROFICIENCY_LEVELS[lesson?.difficulty],
  specialty: lesson?.specialty?.name || lesson?.specialty,
  resources: lesson.lessonResources?.items || [],
  skills:
    lesson?.skills?.items?.map((skill) => skill?.skill) || lesson?.skills || [],
  quiz: lesson?.lessonQuizzes?.items[0]?.questions?.items || [],
  exercise: lesson?.lessonExercises?.items[0] || {},
  techniques:
    lesson?.skills?.items
      ?.map((skill) =>
        skill?.techniqueTags?.items.map((techniqueTag) => ({
          label: techniqueTag.techniqueTag.name,
        })),
      )
      .flat() ||
    lesson?.techniques ||
    [],
  technologies:
    lesson?.technologyTags?.items?.map((tag) => ({
      label: tag?.technologyTag?.name,
    })) || [],
});
