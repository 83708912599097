import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdModal, FdTextField } from '@fifthdomain/fe-shared';
import { useMutation, gql } from '@apollo/client';
import {
  createLabPrototype,
  createLabPrototypeNetwork,
  createLabPrototypeVM,
  createLabPrototypeVMNetwork,
  updateLabPrototype,
} from '../../graphql/mutations';
import { successToastMessage } from '../../shared/utils/toast';
import { duplicateLabValidationSchema } from '../../validation-schemas/Labs';

const LabDuplicate = ({
  copyLabPrototype,
  copyLabPrototypeCallBack,
  labDetails,
  refetchLabPrototypes,
}) => {
  const [error, setError] = useState(false);
  const [copyLabName, setCopyLabName] = useState(`Copy of ${labDetails?.name}`);

  const [createLabPrototypeMutation] = useMutation(gql(createLabPrototype));
  const [updateLabPrototypeStatus] = useMutation(gql(updateLabPrototype));

  const [createLabNetworkMutation] = useMutation(
    gql(createLabPrototypeNetwork),
  );
  const [createLabPrototypeVMMutation] = useMutation(gql(createLabPrototypeVM));
  const [createLabPrototypeVMNetworkMutation] = useMutation(
    gql(createLabPrototypeVMNetwork),
  );

  const validateDuplicateName = (_value) => {
    setCopyLabName(_value);
    const validateLabName = duplicateLabValidationSchema
      .validate({
        labDuplicate: _value,
      })
      .then(setError(false))
      .catch((res) => setError(res.errors));
    return validateLabName;
  };

  return (
    <FdModal
      size="md"
      title="Duplicate Lab"
      confirm="Copy"
      dismiss="CANCEL"
      open={copyLabPrototype}
      onConfirm={async () => {
        if (!error) {
          await createLabPrototypeMutation({
            variables: {
              input: {
                name: copyLabName,
                description: labDetails?.description,
                status: 'DRAFT',
                userId: labDetails?.userId,
                orgId: labDetails?.orgId,
              },
            },
            onCompleted: async (_data) => {
              const draftLabId = _data?.createLabPrototype?.id;

              // Lab Networks
              if (labDetails?.labNetworks?.length > 0) {
                await Promise.all(
                  labDetails?.labNetworks?.map((object) => {
                    return createLabNetworkMutation({
                      variables: {
                        input: {
                          labPrototypeId: draftLabId,
                          name: object.name,
                          cidr: object.cidr,
                          hasInternet: object.hasInternet,
                        },
                      },
                    });
                  }),
                );
              }
              // Lab Vms
              if (labDetails?.labVms?.length > 0) {
                await Promise.all(
                  labDetails?.labVms?.map((object) => {
                    return createLabPrototypeVMMutation({
                      variables: {
                        input: {
                          labPrototypeId: draftLabId,
                          name: object.name,
                          template: object.template,
                          catalog: object?.catalog,
                          cpus: object?.cpus,
                          cores: object?.cores,
                          memory: object?.memory,
                          hasInternet: object?.hasInternet,
                          hasVdi: object?.hasVdi,
                          captureVmUserEvents: object?.captureVmUserEvents,
                          customConfigurations: object?.customConfigurations,
                        },
                      },
                      onCompleted: async (__data) => {
                        const createLabVMNetworks = object?.networks?.items;

                        if (createLabVMNetworks?.length > 0) {
                          await Promise.all(
                            createLabVMNetworks?.map((obj) => {
                              return createLabPrototypeVMNetworkMutation({
                                variables: {
                                  input: {
                                    labPrototypeVMId:
                                      __data?.createLabPrototypeVM?.id,
                                    name: obj.name,
                                    ip: obj.ip,
                                    mac: obj.mac,
                                    adapterType: obj.adapterType || 'VMXNET3',
                                  },
                                },
                              });
                            }),
                          );
                        }
                      },
                    });
                  }),
                );
              }

              // BUILD_REQUESTED
              await updateLabPrototypeStatus({
                variables: {
                  input: {
                    id: draftLabId,
                    status: 'BUILD_REQUESTED',
                  },
                },
                onCompleted: () => {
                  refetchLabPrototypes();
                  successToastMessage('Copy of lab created successfully.');
                },
              });
            },
          });
          copyLabPrototypeCallBack(false);
        }
      }}
      onDismiss={() => {
        copyLabPrototypeCallBack(false);
      }}
      data-cy="lab-duplicate-modal"
    >
      <Box mt={0} mb={0}>
        <Box mb={2}>
          <FdTextField
            id="labDuplicate"
            label="Enter the new Lab name"
            fullWidth
            required
            value={copyLabName}
            data-cy="labDuplicate"
            onChange={(e) => {
              validateDuplicateName(e.target.value);
            }}
            error={error}
            helperText={error}
          />
        </Box>
      </Box>
    </FdModal>
  );
};

LabDuplicate.propTypes = {
  copyLabPrototype: PropTypes.bool,
  copyLabPrototypeCallBack: PropTypes.func,
  refetchLabPrototypes: PropTypes.func.isRequired,
  labDetails: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    userId: PropTypes.string,
    orgId: PropTypes.string,
    labNetworks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        cidr: PropTypes.string,
      }),
    ),
    labVms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        template: PropTypes.string,
        catalog: PropTypes.string,
        cpus: PropTypes.string,
        cores: PropTypes.string,
        memory: PropTypes.string,
      }),
    ),
  }).isRequired,
};

LabDuplicate.defaultProps = {
  copyLabPrototype: false,
  copyLabPrototypeCallBack: () => {},
};
export default LabDuplicate;
