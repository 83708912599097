import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  FdButton,
  FdCard,
  FdAlert,
  FdDelayed,
  useSnapshot,
  globalStore,
  Authorization,
  successToastMessage,
  warningToastMessage,
  FdTypography,
} from '@fifthdomain/fe-shared';
import CourseDetails from '../Create/CourseDetails';
import { updateCourse } from '../../../graphql/mutations';

const EditCourse = ({ refetchCourse }) => {
  const { courseId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const { permissions } = useSnapshot(globalStore);
  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );
  const { getValues, reset, trigger } = useFormContext();
  const isDraft = getValues()?.status === 'DRAFT';

  const updateCourseAction = async () => {
    const { name, description, availability } = getValues();
    await updateCourseMutation({
      variables: {
        input: {
          id: courseId,
          description,
          name,
          availability,
        },
      },
    });
    refetchCourse();
    setEditMode(false);
    successToastMessage('Success! Course edits saved.');
  };

  const ActionButtons = () => (
    <Box display="flex">
      {editMode ? (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setEditMode(false);
              warningToastMessage('Course edits not saved.');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                const result = await trigger(['name', 'description']);
                if (result) {
                  updateCourseAction();
                }
              }}
              disabled={updateCourseLoading}
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      ) : (
        <FdButton onClick={() => setEditMode(true)} data-cy="edit-button">
          Edit
        </FdButton>
      )}
    </Box>
  );

  return (
    <Box>
      {!isDraft && (
        <Box mb={2}>
          <FdAlert
            variant="info"
            message={
              <FdTypography variant="body2">
                This course is currently
                <b>
                  {!getValues()?.availability
                    ? ' not available '
                    : ' available '}
                </b>
                This means that invited participants
                {!getValues()?.availability ? ' cannot ' : ' can '}
                access and participate in this course. If you would like to
                change this, you can edit course availability below.
              </FdTypography>
            }
          />
        </Box>
      )}

      <FdCard
        variant="outlined"
        heading="Course Details"
        summary={
          Authorization.canManageEvents(permissions) && <ActionButtons />
        }
      >
        <FdDelayed>
          <CourseDetails heading="" isEdit={editMode} />
        </FdDelayed>
      </FdCard>
    </Box>
  );
};

EditCourse.propTypes = {
  refetchCourse: PropTypes.func.isRequired,
};

export default EditCourse;
