import Yup from '../Common';

export const courseInitialValues = {
  courseId: '',
  name: '',
  description: '',
  category: '',
  availability: false,
  status: '',
  lessons: [],
  lessonIds: [],
  userData: [],
  userIds: [],
  groupIds: [],
  groupData: [],
  dbUserIds: [],
  dbGroupIds: [],
};

export const courseValidationSchema = {
  courseId: Yup.string(),
  name: Yup.string()
    .required('Please enter a Course Name.')
    .max(150, 'Course Name must be 150 characters or less. ')
    .noWhitespace('Please enter a Course Name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a Course Name.',
      (value) => value && value.length === value.trim().length,
    ),
  description: Yup.string()
    .required('Please enter a Course Description.')
    .max(800, 'Course Description must be 800 characters or less.')
    .noWhitespace('Please enter a Course Description.'),
  category: Yup.string().nullable(),
  availability: Yup.bool(),
  lessons: Yup.array().min(1),
};
