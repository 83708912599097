import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';
import { quizAttemptIconColor } from '../../../constants';

const QuizAnswerCard = ({ answer, top3QuizAttempts }) => {
  const chipLabel = !answer?.answer
    ? 'Incomplete'
    : !top3QuizAttempts?.length
      ? 'Submitted'
      : top3QuizAttempts?.find(
            (item) => item?.questionId === answer?.questionId,
          )?.success
        ? 'Correct'
        : 'Incorrect';
  const Icon = quizAttemptIconColor[chipLabel].Icon;

  return (
    <FdCard>
      <Box className="flex justify-between">
        <FdTypography variant="subtitle2">Your Answer</FdTypography>
        <Box className="flex">
          <Icon
            className="size-5 mr-2 mt-1"
            color={quizAttemptIconColor[chipLabel].color}
          />
          <FdTypography
            variant="subtitle1"
            color={quizAttemptIconColor[chipLabel].color}
            className="font-semibold "
          >
            {chipLabel}
          </FdTypography>
        </Box>
      </Box>
      <Box mt={1.5}>
        <FdTypography variant="body2" color="secondary">
          {answer?.answer || 'None provied'}
        </FdTypography>
      </Box>
    </FdCard>
  );
};

QuizAnswerCard.propTypes = {
  answer: PropTypes.string,
};

QuizAnswerCard.defaultProps = {
  answer: 'None provided',
};
export default QuizAnswerCard;
