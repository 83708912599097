import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { FdChip, FdModal, FdAlert } from '@fifthdomain/fe-shared';
import FdFileUpload from './FdFileUpload';
import {
  errorToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';

const FdFileUploadModal = ({
  onConfirm,
  onDismiss,
  fileTypes,
  initialFiles,
  ...props
}) => {
  const [uploadFiles, setUploadFiles] = useState(initialFiles);
  const [fileStatus, setFileStatus] = useState(false);

  const onUploadDelete = () => {
    setFileStatus(false);
    setUploadFiles([]);
    warningToastMessage('No files were added');
  };

  const fileUpload = async (files) => {
    if (files.length === 0) {
      errorToastMessage(` Only ${fileTypes} file accepted`);
      return;
    }
    if (files.length > 1) {
      errorToastMessage(' Only one file is accepted ');
      return;
    }
    setUploadFiles(files);
  };

  return (
    <FdModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onConfirm={() => onConfirm(uploadFiles)}
      onDismiss={() => {
        onDismiss();
        setFileStatus(false);
      }}
      onEnter={() => {
        setUploadFiles(initialFiles);
      }}
      disableConfirm={!uploadFiles.length}
    >
      <Box mt={2}>
        <FdFileUpload
          onDrop={(files) => {
            fileUpload(files);
          }}
          fileTypes={fileTypes}
          fileStatus={(newValue) => {
            setFileStatus(newValue);
          }}
          fileCount={uploadFiles.length}
        />
      </Box>
      <Box mt={2}>
        <Box>
          {uploadFiles.length > 0 &&
            uploadFiles.map((c) => (
              <FdChip
                color={fileStatus ? 'error' : 'default'}
                key={shortid.generate()}
                label={c?.name || c?.key}
                onDelete={() => onUploadDelete(c.id)}
                style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
              />
            ))}
        </Box>
      </Box>
      {fileStatus && (
        <Box>
          <FdAlert
            variant="error"
            message="Error uploading file, please try again."
          />
        </Box>
      )}
    </FdModal>
  );
};

FdFileUploadModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fileTypes: PropTypes.string,
  errorMessage: PropTypes.string,
  fileValidator: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialFiles: PropTypes.array,
};

FdFileUploadModal.defaultProps = {
  fileTypes: '',
  errorMessage: '',
  fileValidator: null,
  initialFiles: [],
};

export default FdFileUploadModal;
