import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTooltip } from '@fifthdomain/fe-shared';
import LessonButton from './LessonButton';
import { LESSON_DRAWER_PAGES, LESSON_DRAWER_WIDTH } from '../../../constants';

const DrawerButtons = ({ onClick, selected, selectedLesson }) => {
  const hasQuiz = selectedLesson?.quiz?.questions?.length > 0;
  const hasResources = selectedLesson?.resources?.length > 0;

  const pages = LESSON_DRAWER_PAGES.filter(({ name }) => {
    if (name === 'Quiz') return hasQuiz;
    if (name === 'Resources') return hasResources;
    return true;
  });

  return (
    <Box
      className="flex items-center gap-x-4 px-4"
      sx={{ width: LESSON_DRAWER_WIDTH }}
    >
      {pages?.map(({ name, Icon }) => (
        <FdTooltip title={name}>
          <span className="flex-1">
            <LessonButton
              className="w-full"
              onClick={() => onClick(name)}
              sx={(theme) => ({
                border:
                  name === selected
                    ? `1px solid ${theme.palette.primary.main}`
                    : 'inherit',
              })}
            >
              <Icon />
            </LessonButton>
          </span>
        </FdTooltip>
      ))}
    </Box>
  );
};

DrawerButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  selectedLesson: PropTypes.shape({
    quiz: PropTypes.shape({
      questions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    resources: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default DrawerButtons;
