import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import * as singleSpa from 'single-spa';
import { Box, Badge } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTable,
  FdProgress,
  FdModal,
  FdSelect,
  FdTypography,
  FdAlert,
  useQueryRecursive,
  FdExternalLink,
  FdDelayed,
  FdChip,
  FdMultiChipTextField,
  FdAutocomplete,
  useSnapshot,
  globalStore,
  emailValidation,
  InformationBox,
  Authorization,
} from '@fifthdomain/fe-shared';
import {
  getCourseUsersGroups,
  listUsersByOrgId,
} from '../../../queries/customQueries';
import {
  assignCourseToGroups,
  deleteCourseUser,
  removeCourseFromGroups,
  assignCourseToUsers,
  removeInvitedUser,
} from '../../../graphql/mutations';
import {
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '../../../shared/utils/toast';
import {
  listGroupsByOrgId,
  listModulePartProgressesByCourseId,
  listQuizAttemptsByCourseId,
} from '../../../graphql/queries';

import { CourseChat } from '../../Chat';
import { invalidateMessageQueries } from '../../../queries/invalidateQueries';
import ChatSaveModal from '../../Chat/ChatSaveModal';
import { ChatContext } from '../../../contexts';
import { getUserStatus } from '../../../shared/utils/userStatus';
import { upperCaseFirstLetter } from '../../../shared/utils/stringUtils';
import Summary from '../../Summary/Summary';
import {
  getAvergeLabPerformance,
  getAvergeQuizPerformance,
} from '../../../shared/utils/Insights/coursePerformance';
import useFetchCourseUsersWithLabTime from '../../../hooks/useFetchCourseUsersWithLabTime';

const EditCourseUsersGroups = ({ courseId, setRouteIndex }) => {
  const [openChat, setOpenChat] = useState(false);
  const [openUsersModal, setOpenUsersModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(undefined);
  const [reinviteUser, setReinviteUser] = useState(undefined);
  const [openGroupsModal, setOpenGroupsModal] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(undefined);
  const [showLastUserError, setShowLastUserError] = useState(false);
  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showError, setShowError] = useState(false);
  const [chatCourseUser, setChatCourseUser] = useState(undefined);
  const { isChatDirty, setIsChatDirty } = useContext(ChatContext);
  const [showChatSave, setShowChatSave] = useState(false);
  const [targetRow, setTargetRow] = useState(undefined);
  const [refreshList, setRefreshList] = useState(false);
  const [error, setError] = useState('');

  const { orgId, permissions, orgPricingTier } = useSnapshot(globalStore);

  const { data: allUsersData, loading: usersLoading } = useQueryRecursive(
    gql(listUsersByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );
  const { data: allGroupsData, loading: groupsLoading } = useQueryRecursive(
    gql(listGroupsByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  const {
    refetch: refetchCourseData,
    data: courseData,
    loading: courseLoading,
  } = useQueryRecursive(gql(getCourseUsersGroups), {
    variables: {
      id: courseId,
    },
    skip: !courseId,
  });

  const { data: modulePartProgressData, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgressesByCourseId), {
      variables: {
        courseId,
      },
      skip: !courseId,
    });

  const { data: quizAttemptsData, loading: quizAttemptsLoading } =
    useQueryRecursive(gql(listQuizAttemptsByCourseId), {
      variables: {
        courseId,
      },
      skip: !courseId,
    });

  const { courseUsersDataWithLabTime, loading } =
    useFetchCourseUsersWithLabTime(
      courseData,
      courseLoading,
      modulePartProgressData,
      quizAttemptsData,
    );

  const [assignedCourseToUsersMutation, { loading: assignCourseLoading }] =
    useMutation(gql(assignCourseToUsers), {
      onCompleted: async (_data) => {
        setOpenUsersModal(false);
        successToastMessage('Success! User(s) added');
        refetchCourseData();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors?.[0]?.message);
      },
    });

  const [
    assignCourseToGroupsMutation,
    { loading: assignCourseToGroupsLoading },
  ] = useMutation(gql(assignCourseToGroups), {
    onCompleted: () => {
      successToastMessage('Success! Group(s) added');
      refetchCourseData();
    },
  });

  const [deleteCourseUserMutation, { loading: deleteCourseUserLoading }] =
    useMutation(gql(deleteCourseUser), {
      onCompleted: () => {
        successToastMessage('Success! User removed');
        refetchCourseData();
      },
    });

  const [
    removeCourseFromGroupsMutation,
    { loading: removeCourseFromGroupsLoading },
  ] = useMutation(gql(removeCourseFromGroups), {
    onCompleted: () => {
      successToastMessage('Success! Group removed');
      refetchCourseData();
    },
  });

  const [removeInvitedUserMutation, { loading: removeInvitedUserLoading }] =
    useMutation(gql(removeInvitedUser), {
      onCompleted: () => {
        successToastMessage('Success! User removed');
        refetchCourseData();
      },
    });

  if (
    loading ||
    courseLoading ||
    usersLoading ||
    groupsLoading ||
    modulePartProgressLoading ||
    quizAttemptsLoading
  )
    return <FdProgress />;

  const coursePartData = courseData?.getCourse?.courseModules?.items
    ?.map((courseDataItem) => courseDataItem?.parts?.items)
    .flat();

  const invitedCourseUsers =
    courseData?.getCourse?.invitedUsers?.items?.map((_u) => ({
      id: _u.id,
      email: _u.email,
      registrationStatus: 'invited',
      courseUserId: '',
      courseProgress: {
        progress: 0,
        progressCaption: '0% done',
      },
      totalLabTime: 0,
      quizSuccessRate: 0,
      unReadMessageCount: 0,
      updatedAt: _u.updatedAt,
    })) || [];

  const coursesRows =
    [...courseUsersDataWithLabTime, ...invitedCourseUsers]
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      ?.filter((cr) => cr?.id) || [];

  const groupsRows =
    courseData?.getCourse?.coursegroups?.items.map((u) => ({
      id: u.group?.id || Math.random(),
      courseGroupId: u.id,
      groupName: u.group?.name,
      usersCount: u.group?.users?.items?.length,
      avgCourseProgress: '',
      avgLabCompletionRate: '',
      avgQuizCompletionRate: '',
      avgQuizSuccessRate: '',
    })) || [];

  const isLastUserOrGroup =
    (coursesRows.length + groupsRows.length || 0) === 1 &&
    courseData?.getCourse?.status === 'AVAILABLE';

  const hasChatViewPermission = Authorization.canViewInsights(permissions);

  const averageLabCompletionRate = getAvergeLabPerformance(
    modulePartProgressData?.listModulePartProgressesByCourseId?.items,
    coursePartData,
  )?.avergaeLabCompletionRate;

  const averageQuizPerformance = getAvergeQuizPerformance(
    quizAttemptsData?.listQuizAttemptsByCourseId?.items,
    coursePartData,
  );

  const averageLabTime =
    courseUsersDataWithLabTime && courseUsersDataWithLabTime.length > 0
      ? Math.round(
          courseUsersDataWithLabTime.reduce(
            (acc, curr) => acc + curr.totalLabTime,
            0,
          ) / courseUsersDataWithLabTime.length,
        )
      : 0;

  const actionsUsers = [
    ...(Authorization.canViewInsights(permissions)
      ? [
          {
            label: 'View',
            show: ({ registrationStatus }) =>
              registrationStatus === 'registered',
            onClick: () => {
              setRouteIndex(0);
              // TODO - investigate why tab change is not initiating tab switch
              window.location.reload();
            },
          },
        ]
      : []),
    {
      label: 'Open Chat',
      show: ({ registrationStatus }) =>
        hasChatViewPermission && registrationStatus === 'registered',
      onClick: (params) => {
        // if chat dirty then stop user from switching rows
        if (
          isChatDirty &&
          chatCourseUser?.courseUserId !== params?.courseUserId
        ) {
          setTargetRow(params);
          setShowChatSave(true);
          return;
        }
        if (
          !chatCourseUser ||
          chatCourseUser?.courseUserId !== params?.courseUserId
        ) {
          invalidateMessageQueries();
          setOpenChat(true);
          setChatCourseUser(params);
        }
      },
    },
    ...(Authorization.canManageEvents(permissions)
      ? [
          {
            label: 'Re-invite',
            show: ({ registrationStatus }) => registrationStatus === 'invited',
            onClick: ({ email }) => {
              setReinviteUser(email);
            },
          },
        ]
      : []),
    ...(Authorization.canManageEvents(permissions)
      ? [
          {
            label: 'Remove',
            show: () => true,
            onClick: (_user) => {
              if (isLastUserOrGroup) {
                setShowLastUserError(true);
              } else {
                const id =
                  _user.registrationStatus === 'invited'
                    ? _user.id
                    : _user.courseUserId;
                setDeleteUser({
                  id,
                  registrationStatus: _user.registrationStatus,
                });
              }
            },
          },
        ]
      : []),
    {
      show: () => false, // this is to show the drop down instead of buttons
    },
  ];

  const actionsGroups = [
    ...(Authorization.canManageGroups(permissions, orgPricingTier)
      ? [
          {
            label: 'View',
            show: () => true,
            onClick: (_group) => {
              singleSpa.navigateToUrl(
                `/user-management/groups/edit/${_group.id}`,
              );
            },
          },
        ]
      : []),
    ...(Authorization.canManageEvents(permissions)
      ? [
          {
            label: 'Remove',
            show: () => true,
            onClick: (_group) => {
              if (isLastUserOrGroup) {
                setShowLastUserError(true);
              } else {
                setDeleteGroup(_group?.id);
              }
            },
          },
        ]
      : []),
    {
      show: () => false, // this is to show the drop down instead of buttons
    },
  ];

  const existingUsers = coursesRows?.map((cr) => cr.id) || [];
  const usersToAdd = allUsersData?.listUsersByOrgId?.items.filter(
    (u) => !existingUsers.includes(u.id),
  );

  const existingGroups = groupsRows?.map((gr) => gr.id);
  const groupsToAdd = allGroupsData?.listGroupsByOrgId?.items.filter(
    (g) => !existingGroups.includes(g.id),
  );

  const usersForDropdown =
    usersToAdd?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const groupsForDropdown =
    groupsToAdd?.map((g) => g.name).sort((a, b) => a.localeCompare(b)) || [];

  const courseAssignedGroups =
    courseData?.getCourse?.coursegroups?.items?.map((g) => ({
      id: g.group?.id,
      name: g.group?.name,
    })) || [];

  const userColumns = [
    { field: 'name', width: 300, headerName: 'Name' },
    {
      field: 'unReadMessageCount',
      width: 100,
      headerName: '',
      filterable: false,
      searchable: false,
      renderCell: (column) => {
        if (column.value === 0) {
          return null;
        }
        return (
          <Badge badgeContent={column.value} color="error">
            <ChatBubbleOutlineIcon />
          </Badge>
        );
      },
    },
    {
      field: 'registrationStatus',
      width: 150,
      headerName: 'Registration Status',
      valueGetter: (params) => params?.row?.registrationStatus,
      renderCell: (params) => (
        <FdChip
          color={getUserStatus(params?.row?.registrationStatus)}
          size="small"
          label={params?.row?.registrationStatus}
        />
      ),
    },
    {
      field: 'email',
      width: 300,
      headerName: 'Email',
    },
    {
      field: 'courseProgress',
      width: 325,
      headerName: 'Course Progress',
      searchable: false,
      filterable: false,
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdProgress
          type="linear"
          value={params?.value?.progress}
          caption={params?.value?.progressCaption}
        />
      ),
    },
    {
      field: 'totalLabTime',
      width: 100,
      headerName: 'Total Lab Time (min)',
      valueGetter: (params) => `${params?.row?.totalLabTime}m`,
    },
    {
      field: 'quizSuccessRate',
      width: 100,
      headerName: 'Quiz Success Rate (%) ',
      valueGetter: (params) => `${params?.row?.quizSuccessRate}%`,
    },
    // { field: 'totalLabTime', width: 200, headerName: 'Total Lab Time (min)' },
    // {
    //   field: 'quizSuccessRate',
    //   width: 200,
    //   headerName: 'Quiz Success Rate (%) ',
    // },
  ];

  const groupColumns = [
    { field: 'groupName', width: 300, headerName: 'Group Name' },
    {
      field: 'usersCount',
      width: 100,
      headerName: 'Users',
    },
    // {
    //   field: 'avgCourseProgress',
    //   width: 250,
    //   headerName: 'Avg. Course Progress (%)',
    // },
    // {
    //   field: 'avgLabCompletionRate',
    //   width: 250,
    //   headerName: 'Avg. Lab Completion Rate (%)',
    // },
    // {
    //   field: 'avgQuizCompletionRate',
    //   width: 250,
    //   headerName: 'Avg. Quiz Completion Rate (%)',
    // },
    // {
    //   field: 'avgQuizSuccessRate',
    //   width: 250,
    //   headerName: 'Avg. Quiz Success Rate (%)',
    // },
  ];

  return (
    <Box>
      {showLastUserError && (
        <Box mt={1} mb={2}>
          <FdAlert variant="error" message="At least one user is required" />
        </Box>
      )}
      <Summary
        data={[
          {
            value: `${averageLabTime}m`,
            description: 'Average Lab Time',
          },
          {
            value: `${Math.round(
              coursesRows?.length > 0
                ? averageLabCompletionRate / (coursesRows?.length || 1)
                : 0,
            )}%`,
            description: 'Average Lab Completion Rate',
          },
          {
            value: `${Math.round(
              coursesRows?.length > 0
                ? (averageQuizPerformance?.averageQuizSuccessRate || 0) /
                    (coursesRows?.length || 1)
                : 0,
            )}%`,
            description: 'Average Quiz Success Rate',
          },
          {
            value: `${Math.round(
              coursesRows?.length > 0
                ? (averageQuizPerformance?.averageQuizCompletionRate || 0) /
                    (coursesRows?.length || 1)
                : 0,
            )}%`,
            description: 'Average Quiz Completion Rate',
          },
        ]}
      />
      <Box height="450px" my={2}>
        <FdTable
          toolbarSettings={{
            title: <FdTypography variant="h3">Users</FdTypography>,
            filterButton: true,
            searchBox: true,
            headerActions: Authorization.canManageEvents(permissions)
              ? [
                  {
                    label: 'Invite Participants',
                    onClick: () => {
                      setOpenUsersModal(true);
                      setShowLastUserError(false);
                    },
                  },
                ]
              : [],
          }}
          columnVisibilityModel={{
            unReadMessageCount: hasChatViewPermission,
          }}
          rows={coursesRows}
          columns={userColumns}
          tablePageSize={10}
          actions={actionsUsers}
          gridId="labs-edit-course-users"
        />
      </Box>
      <Box height="450px" my={2}>
        <FdTable
          toolbarSettings={{
            title: (
              <Box>
                <FdTypography variant="h3">Add Groups</FdTypography>
                <FdTypography variant="body1" color="secondary">
                  To invite unregistered participants to a course, Groups can be
                  very useful! You can create and add a Group to this course, to
                  which you can add list of unregistered participants. You can
                  manage Groups from &nbsp;
                  <FdExternalLink href="/user-management/groups">
                    here
                  </FdExternalLink>
                  .
                </FdTypography>
              </Box>
            ),
            filterButton: true,
            searchBox: true,
            headerActions: Authorization.canManageEvents(permissions)
              ? [
                  {
                    label: 'Add',
                    onClick: () => {
                      setOpenGroupsModal(true);
                      setShowLastUserError(false);
                    },
                  },
                ]
              : [],
          }}
          rows={groupsRows}
          columns={groupColumns}
          tablePageSize={5}
          actions={actionsGroups}
          gridId="labs-edit-course-groups"
        />
      </Box>
      <FdModal
        open={openUsersModal}
        onConfirm={() => {
          if (emails.length < 1) {
            setError({ ...error, emailError: 'Enter at least 1 email' });
            return;
          }
          if (emails.some((email) => !emailValidation(email))) {
            setError({
              ...error,
              emailError: 'One or more of the email addresses are invalid',
            });
            return;
          }

          assignedCourseToUsersMutation({
            variables: {
              emails,
              orgId,
              action: 'NEW_INVITE',
              courseId,
              ...(groups && { groupIds: groups }),
            },
          });
        }}
        onDismiss={() => {
          setOpenUsersModal(false);
          setUsers([]);
          setEmails([]);
          setError('');
          warningToastMessage('User(s) not added');
        }}
        onEnter={() => {
          setShowError(false);
          setUsers([]);
        }}
        disableConfirm={assignCourseLoading}
        title="Invite Participants"
        confirm={assignCourseLoading ? 'Loading' : 'Add'}
        dismiss="Cancel"
        size="md"
      >
        <Box mb={2}>
          <Box className="flex flex-col gap-2">
            <FdTypography color="secondary" variant="body2">
              Paste in email addresses of users to want to invite to this
              course. Press enter after you have pasted the email address(es).
            </FdTypography>
            <FdAlert
              alertTitle="Information to Note"
              variant="info"
              message="Participants who have already been invited to this event will not receive duplicate invitations. Inviting over 50 participants in one go may cause the invitation instance to fail. "
            />
          </Box>
          <Box display="flex" alignItems="flex-end" mb={4} mt={1}>
            <FdMultiChipTextField
              id="Emails"
              width="100%"
              label="Email Addresses"
              placeholder="Emails"
              helperText="You can copy and paste a list of users to invite, or type in the email addresses."
              required
              values={emails}
              setValues={setEmails}
              onUpdateInput={() => setError('')}
              onDelete={(_email) => {
                setUsers((prevUsers) =>
                  prevUsers.filter((_prev) => _prev.email !== _email),
                );
                setRefreshList(!refreshList);
              }}
            />
          </Box>
          {error.emailError && (
            <Box display="flex">
              <InformationBox
                variant="error"
                status=""
                message={error.emailError}
              />
            </Box>
          )}
          <FdDelayed delay={0} triggerField={refreshList}>
            <FdAutocomplete
              label="Search in User Directory"
              optional
              helperText="Type names and/or email addresses of existing users to add them to the invitation. Click the filter icon to filter your search by existing tags."
              options={usersForDropdown}
              multiple
              fullWidth
              selectAllLabel="Select all"
              optionLabel="name"
              optionValue="id"
              defaultSelected={users}
              ListItemTextRenderer={({ option }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box display="flex" flexDirection="column">
                      <FdTypography variant="body1">
                        {option?.name}
                      </FdTypography>
                      <FdTypography variant="caption">
                        {option?.email}
                      </FdTypography>
                    </Box>
                    <Box mr={1}>
                      <FdTypography variant="caption">
                        {upperCaseFirstLetter(option?.type)}
                      </FdTypography>
                    </Box>
                  </Box>
                );
              }}
              onChange={(currentSelection) => {
                const emailsSelected = currentSelection?.map((c) => c.email);
                setEmails((_emails) => [
                  ...new Set([..._emails, ...emailsSelected]),
                ]);
                setUsers(currentSelection);
              }}
            />
          </FdDelayed>
          <FdSelect
            inputTitle={
              <Box className="flex flex-col gap-2 my-4">
                <Box className="flex items-center gap-1">
                  <FdTypography variant="subtitle1">
                    Assign a Group to the participant being invited
                  </FdTypography>
                  <FdTypography color="secondary" variant="captiontext1">
                    Optional
                  </FdTypography>
                </Box>
                <FdTypography variant="captiontext1" color="secondary">
                  Choose a group from the existing list of invited groups below.
                  The selected group will be allocated to all users whose email
                  addresses are provided in the &apos; Invite Email Addresses
                  &apos; section above. If you want to include a new group in
                  the course, simply click the &apos; Add &apos; button within
                  the Groups table.
                </FdTypography>
              </Box>
            }
            id="groups"
            options={courseAssignedGroups}
            width="100%"
            onChange={(group) => {
              setGroups([group]);
            }}
            helperText=""
          />
        </Box>
      </FdModal>
      <FdModal
        open={openGroupsModal}
        onConfirm={async () => {
          if (groups.length === 0) {
            setShowError(true);
          } else {
            setOpenGroupsModal(false);
            const groupIdsToAdd = groupsToAdd
              .filter((g) => groups.includes(g.name))
              .map((g) => g.id);

            // add groups
            await assignCourseToGroupsMutation({
              variables: {
                courseId,
                groupIds: groupIdsToAdd,
              },
            });
          }
        }}
        onDismiss={() => {
          setOpenGroupsModal(false);
          warningToastMessage('Group(s) not added');
        }}
        onEnter={() => {
          setShowError(false);
          setGroups([]);
        }}
        disableConfirm={assignCourseToGroupsLoading}
        title="Add Groups"
        confirm={assignCourseToGroupsLoading ? 'Loading' : 'Add'}
        dismiss="Cancel"
        size="md"
      >
        <Box mb={2}>
          <FdSelect
            id="groups"
            label="Registered Groups"
            options={groupsForDropdown}
            fullWidth
            multiple
            error={showError}
            helperText={showError && 'Please select a group'}
            data-cy="groups"
            onChange={(_groups) => {
              setGroups(_groups);
              if (_groups.length > 0) {
                setShowError(false);
              }
            }}
            placeholder="Select from the list"
          />
        </Box>
      </FdModal>
      <FdModal
        size="xs"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Remove User?</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to remove the user?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                All information about the user&apos;s engagement with the course
                will be lost, and the user will not be able to access course
                content.
              </FdTypography>
            </Box>
          </Box>
        }
        disableConfirm={deleteCourseUserLoading || removeInvitedUserLoading}
        confirm={
          deleteCourseUserLoading || removeInvitedUserLoading
            ? 'Loading...'
            : 'REMOVE'
        }
        dismiss="CANCEL"
        open={deleteUser}
        onConfirm={() => {
          if (deleteUser.registrationStatus === 'invited') {
            removeInvitedUserMutation({
              variables: {
                inviteToken: deleteUser.id,
              },
            });
          } else {
            deleteCourseUserMutation({
              variables: {
                input: {
                  id: deleteUser.id,
                },
              },
            });
          }
          setDeleteUser(undefined);
        }}
        onDismiss={() => {
          setDeleteUser(undefined);
          warningToastMessage('User not removed');
        }}
      />
      <FdModal
        size="xs"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Remove Group?</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to remove the group?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                All information about the group&apos;s engagement with the
                course will be lost, and the group members will not be able to
                access course content.
              </FdTypography>
            </Box>
          </Box>
        }
        disableConfirm={removeCourseFromGroupsLoading}
        confirm={removeCourseFromGroupsLoading ? 'Loading...' : 'REMOVE'}
        dismiss="CANCEL"
        open={deleteGroup}
        onConfirm={async () => {
          await removeCourseFromGroupsMutation({
            variables: {
              courseId,
              groupIds: [deleteGroup],
            },
          });
          setDeleteGroup(undefined);
        }}
        onDismiss={() => {
          setDeleteGroup(undefined);
          warningToastMessage('Group not removed');
        }}
      />
      {reinviteUser && (
        <FdModal
          size="md"
          title="Re-invite participant?"
          description="Are you sure you want to re-invite this participant? They will receive an email invitation to join the platform again."
          disableConfirm={assignCourseLoading}
          confirm={assignCourseLoading ? 'Loading...' : 'Re-invite Participant'}
          dismiss="cancel"
          open={reinviteUser}
          onConfirm={() => {
            assignedCourseToUsersMutation({
              variables: {
                courseId,
                emails: [reinviteUser],
                orgId,
                action: 'RE_INVITE',
              },
              onCompleted: () => {
                successToastMessage('Success! Participant re-invited.');
                setReinviteUser(false);
              },
            });
          }}
          onDismiss={() => {
            setReinviteUser(false);
            warningToastMessage('Participant not re-invited');
          }}
        />
      )}
      <CourseChat
        openChat={openChat}
        onCloseChat={() => {
          setOpenChat(false);
          setChatCourseUser(undefined);
        }}
        chatTitle={chatCourseUser?.name}
        userCourseId={chatCourseUser?.courseUserId}
        receiverId={chatCourseUser?.id}
        courseId={courseId}
        isAdmin
      />
      <ChatSaveModal
        onConfirm={() => {
          setIsChatDirty(false);
          setShowChatSave(false);
          invalidateMessageQueries();
          setOpenChat(true);
          setChatCourseUser(targetRow);
        }}
        onCancel={() => {
          setShowChatSave(false);
        }}
        open={showChatSave}
      />
    </Box>
  );
};

EditCourseUsersGroups.propTypes = {
  courseId: PropTypes.string.isRequired,
  setRouteIndex: PropTypes.func.isRequired,
};

export default EditCourseUsersGroups;
