import React, { useState } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdButton,
  BasePage,
  FdLoadingSpinner,
  FdModal,
  FdTypography,
  FdCard,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { useMutation, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import * as singleSpa from 'single-spa';
import { yupResolver } from '@hookform/resolvers/yup';
import AddQuizQuestions from '../components/Quiz/AddQuizQuestions';
import {
  initialValuesDetails,
  initialValuesQuestions,
  validationSchemaDetails,
  validationSchemaQuestions,
} from '../validation-schemas/Quizzes';
import scrollToTop from '../shared/utils/scroll';
import QuizDetails from '../components/Quiz/QuizDetails';
import {
  createQuestion,
  createQuestionOption,
  createQuiz,
  createCompetencyQuestion,
  deleteCompetencyQuestion,
} from '../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../shared/utils/toast';
import { createUpdateQuestions } from '../components/Quiz/quizUtils';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = ['Add Details', 'Add Questions'];

const CreateQuiz = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [cancelQuiz, setCancelQuiz] = useState(false);
  const [validateOnSaveOnly, setValidateOnSaveOnly] = useState(false);
  const [userGraded, setUserGraded] = useState(true);
  const { classes } = useStyles();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    ...validationSchemaDetails,
    ...validationSchemaQuestions(userGraded),
  });
  const initialValues = { ...initialValuesDetails, ...initialValuesQuestions };

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    context: { validateOnSaveOnly },
    mode: 'all',
  });
  const {
    reset,
    getValues,
    trigger,
    setValue,
    formState: { isDirty },
  } = hookFormMethods;

  const { orgId, userId } = useSnapshot(globalStore);

  const [createQuestionOptionMutation] = useMutation(gql(createQuestionOption));
  const [createQuestionMutation] = useMutation(gql(createQuestion));
  const [createCompetencyQuestionsMutation] = useMutation(
    gql(createCompetencyQuestion),
  );
  const [deleteCompetencyQuestionsMutation] = useMutation(
    gql(deleteCompetencyQuestion),
  );
  const [createQuizMutation, { loading: createQuizLoading }] = useMutation(
    gql(createQuiz),
  );

  if (createQuizLoading) {
    return <FdLoadingSpinner />;
  }

  const changeGradedState = (val) => {
    setUserGraded(val);
  };

  const onSubmit = async () => {
    setValidateOnSaveOnly(true);
    // setValue takes a while to re-render hence setTimeout
    setTimeout(async () => {
      const readyForSubmit = await trigger();
      if (activeStep === 0) {
        // show second page if there are errors
        if (
          !readyForSubmit &&
          getValues('quizName') &&
          getValues('questions').length > 0
        ) {
          setActiveStep(1);
          trigger();
        }
      }

      if (
        readyForSubmit ||
        ((await trigger(['quizName'])) && getValues('questions').length === 0)
      ) {
        const {
          quizName,
          quizDescription,
          quizTimeLimit,
          allowMultipleAttempts,
          returnResultsToUsers,
          notGradeUsers,
          questions,
        } = getValues();
        // create quiz
        await createQuizMutation({
          variables: {
            input: {
              description: quizDescription,
              duration: Number(quizTimeLimit),
              multipleAttempts: allowMultipleAttempts,
              name: quizName,
              orgId,
              ownerId: userId,
              showResults: returnResultsToUsers,
              graded: !notGradeUsers,
            },
          },
          onCompleted: async (_quizData) => {
            const quizId = _quizData?.createQuiz?.id;
            if (questions.length > 0) {
              await createUpdateQuestions({
                createQuestionMutation,
                createQuestionOptionMutation,
                createCompetencyQuestionsMutation,
                deleteCompetencyQuestionsMutation,
                values: getValues(),
                quizId,
                setValue,
              });
            }
            // show toast and navigate to list page
            successToastMessage('Success! Quiz Saved');
            reset();
            setTimeout(() => {
              singleSpa.navigateToUrl('/labs/quizzes');
            }, 500);
          },
        });
      }
    }, 1000);
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['quizName']);
        break;
      }
      default:
        result = await trigger();
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      setCancelQuiz(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  return (
    <BasePage
      heading="Create a Quiz"
      data-cy="create-quiz-base-page"
      breadCrumbs={[
        { url: '/landing', name: 'Home' },
        { url: '/labs/quizzes', name: 'Manage Quizzes' },
      ]}
      currentPageBreadcrumbLabel="Create Quiz"
      renderBreadCrumbAsButton
    >
      <Box width="350px" my={2}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...hookFormMethods}>
        <form>
          {
            {
              0: <QuizDetails changeGradedState={changeGradedState} />,
              1: (
                <FdCard
                  heading="Quiz Questions"
                  data-cy="quiz-questions"
                  variant="outlined"
                >
                  <AddQuizQuestions userGraded={userGraded} />
                </FdCard>
              ),
            }[activeStep]
          }
          <Box
            display="flex"
            justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'}
            pb={3}
          >
            <FdButton size="large" variant="secondary" onClick={handleBack}>
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </FdButton>
            <Box ml={1} display="flex">
              {activeStep === 1 && (
                <Box mr={1}>
                  <FdButton
                    size="large"
                    variant="secondary"
                    onClick={() => setCancelQuiz(true)}
                  >
                    Cancel
                  </FdButton>
                </Box>
              )}
              <FdButton
                size="large"
                variant={activeStep === 1 ? 'primary' : 'secondary'}
                onClick={onSubmit}
              >
                Save
              </FdButton>
              <Box ml={1}>
                {activeStep < 1 && (
                  <FdButton size="large" onClick={handleNext}>
                    Next
                  </FdButton>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <FdModal
        size="xs"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Cancel Quiz Creation?</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to cancel creating the quiz?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                Any information will be lost.
              </FdTypography>
            </Box>
          </Box>
        }
        confirm="OK"
        dismiss="CANCEL"
        open={cancelQuiz}
        onConfirm={() => {
          reset();
          setTimeout(() => {
            singleSpa.navigateToUrl('/labs/quizzes');
          }, 500);
          warningToastMessage('Quiz not created');
        }}
        onDismiss={() => setCancelQuiz(false)}
      />
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (window.location.pathname !== '/labs/quizzes/create') {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={() => {
              warningToastMessage('Quiz not created');
              onConfirm();
            }}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default CreateQuiz;
