import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import ResourceTypeIcon from '../../../components/Courses/LessonView/ResourceTypeIcon';
import FieldView from './FieldView';
import VideoPlayer from '../LessonView/VideoPlayer';

const ViewResources = ({ resourceData }) => {
  //create the markDown file
  const markDownFile = () => {
    const link = document.createElement('a');
    const file = new Blob([resourceData?.resourceMarkdown], {
      type: 'text/plain',
    });
    link.href = URL.createObjectURL(file);
    link.download = `${resourceData?.resourceName}.md`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <Box className="flex flex-col">
      <Box className="flex items-center justify-start gap-x-2 my-2">
        <ResourceTypeIcon
          type={resourceData?.resourceType}
          className="flex-shrink-0"
          noLabel
        />
        <FdTypography variant="body2">
          {resourceData?.resourceName}
        </FdTypography>
      </Box>
      {resourceData?.resourceType === 'PDF' && (
        <Link
          href={
            resourceData?.resourcePdf
              ? URL.createObjectURL(resourceData?.resourcePdf)
              : ''
          }
          download={resourceData?.resourcePdf?.name}
          variant="captiontext1"
          className="font-bold my-1"
        >
          {resourceData?.resourcePdf?.name}
        </Link>
      )}
      {resourceData?.resourceType === 'VIDEO' && (
        <FieldView
          value={<VideoPlayer url={resourceData?.resourceVideo} />}
          highlight
        />
      )}
      {resourceData?.resourceType === 'MARKDOWN' && (
        <Link
          onClick={() => {
            markDownFile();
          }}
          variant="captiontext1"
          className="font-bold "
        >
          {`${resourceData?.resourceName}.md`}
        </Link>
      )}
    </Box>
  );
};

ViewResources.propTypes = {
  type: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default ViewResources;
