import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { FdButton, FdTypography, FdChip } from '@fifthdomain/fe-shared';
import { FILE_MIMES } from '../../constants';

const FdFileUpload = ({
  onDrop,
  inProgress,
  fileTypes,
  fileCount,
  singleFile,
  uploadPercentage,
  onSingleFileNextAttempt,
}) => {
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: FILE_MIMES[fileTypes],
  });
  const onClickEvent = singleFile
    ? fileCount === 0
      ? open
      : onSingleFileNextAttempt
    : open;
  const { percentage, transferStatus } = uploadPercentage || {};

  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <FdButton
          variant="secondary"
          onClick={onClickEvent}
          data-cy="upload-btn"
          disabled={inProgress}
          aria-label="add-artifact"
          size="small"
        >
          {fileCount && !singleFile > 0 ? 'Replace' : 'Browse'}
        </FdButton>
      </Box>
      <Box className="flex items-center justify-between gap-x-4 my-4">
        <Box className="flex items-center gap-x-2 w-52">
          {inProgress && (
            <FdTypography variant="h3" style={{ marginRight: 6 }}>
              {percentage !== undefined ? `${percentage}%` : 'Initialising...'}
            </FdTypography>
          )}
          {inProgress && <CircularProgress size={24} />}
        </Box>
        {transferStatus && (
          <Box className="flex items-center gap-x-2">
            <FdTypography variant="captiontext1" style={{ marginRight: 6 }}>
              Transfer Status
            </FdTypography>
            <FdChip
              rounded={false}
              size="small"
              label={transferStatus}
              color="warning"
              style={{ width: '200px' }}
            />
          </Box>
        )}
      </Box>
      <Box mt={1}>
        <FdTypography variant="captiontext1" color="secondary">
          {uploadPercentage > 0 &&
            `Once the image has started uploading, the cancel button won't discard the process. 
              If you press cancel, the upload continues to be processed in the background.`}
        </FdTypography>
      </Box>
    </Box>
  );
};

FdFileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  fileTypes: PropTypes.string,
  fileCount: PropTypes.number,
  singleFile: PropTypes.bool,
  uploadPercentage: PropTypes.number,
  onSingleFileNextAttempt: PropTypes.func,
};

FdFileUpload.defaultProps = {
  inProgress: false,
  fileTypes: '',
  fileCount: 0,
  singleFile: false,
  uploadPercentage: 0.0,
  onSingleFileNextAttempt: () => null,
};

export default FdFileUpload;
