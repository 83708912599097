import Yup from '../Common';

export const initialValues = {
  messages: [],
};

export const validationSchema = Yup.object({
  messages: Yup.array().of(
    Yup.object().shape({
      message: Yup.object().shape({
        dbId: Yup.string(),
        text: Yup.string(),
        timeStamp: Yup.string(),
        self: Yup.boolean(),
        id: Yup.string(),
        messengerIcon: Yup.mixed().nullable(),
        messengerName: Yup.string(),
        isAdmin: Yup.boolean(),
        isRead: Yup.boolean(),
        isEdited: Yup.boolean(),
      }),
    }),
  ),
});
