import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { DotIcon, MonitorIcon, PresentationIcon } from 'lucide-react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import {
  FdTypography,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { getCourseUser } from '../queries/customQueries';
import { getVmConsole, listLabInstancesbyExerciseId } from '../graphql/queries';
import VdiConsole from '../components/Courses/vdi-console/VdiConsole';

const ViewLessonLab = () => {
  const { courseUserId, lessonId, vmId } = useParams();
  const { userId } = useSnapshot(globalStore);
  const theme = useTheme();
  const [activeLabInstance, setActiveLabInstance] = useState(undefined);
  const [consoleUrl, setConsoleUrl] = useState('');
  const [pollingInProgress, setPollingInProgress] = useState(false);

  const { data, loading } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const course = data?.getCourseUser?.course;

  const { name: courseName, courseLessons } = course || {};
  const lessonsData = [...(courseLessons?.items || [])];
  const lessons =
    lessonsData
      ?.sort((a, b) => (a?.orderNumber || 0) - (b?.orderNumber || 0))
      ?.map((item, idx) => ({
        ...item,
        orderId: idx + 1,
      })) || [];
  const selectedLesson =
    lessons.find((item) => item.lessonID === lessonId) || {};
  const { name: lessonName, lessonExercises } = selectedLesson?.lesson || {};
  const exerciseId = lessonExercises?.items?.[0]?.id || {};

  const [fetchConsoleUrl, { loading: fetchConsoleUrlLoading }] = useLazyQuery(
    gql(getVmConsole),
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (_data) => {
        const { url } = _data.getVmConsole || {};
        setConsoleUrl(url);
        if (url) {
          // eslint-disable-next-line no-use-before-define
          stopPolling();
          setPollingInProgress(false);
        }
      },
    },
  );

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
  } = useQueryRecursive(gql(listLabInstancesbyExerciseId), {
    variables: {
      filter: {
        and: [
          { status: { ne: 'DELETED' } },
          { status: { ne: 'IN_DELETE_QUEUE' } },
          { status: { ne: 'DELETE_IN_PROGRESS' } },
          { status: { ne: 'DELETE_FAILED' } },
          { status: { ne: 'BUILD_FAILED' } },
        ],
      },
      userId: { eq: userId },
      exerciseId,
    },
    skip: !exerciseId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const labInstance = _data?.listLabInstancesbyExerciseId?.items?.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      )[0];
      const vmInstances = labInstance?.vms?.items || [];
      const vm = vmId
        ? vmInstances.find((item) => item.id === vmId)
        : vmInstances[0];
      setActiveLabInstance(labInstance);
      if (!labInstance?.status) {
        setConsoleUrl('');
        stopPolling();
        setPollingInProgress(false);
      }
      if (labInstance?.status === 'READY' && vm?.id) {
        fetchConsoleUrl({
          variables: {
            vmId: vm?.id,
            type: 'LAB',
          },
        });
      } else if (vmInstances.length > 0) {
        startPolling(5000);
      }
    },
  });

  const token = consoleUrl
    ? new URLSearchParams(consoleUrl.split('?')[1]).get('token')
    : '';

  if (
    loading ||
    labInstancesLoading ||
    pollingInProgress ||
    fetchConsoleUrlLoading ||
    activeLabInstance?.status === 'BUILD_IN_PROGRESS'
  ) {
    return (
      <Box className="w-full flex items-center justify-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box my={2}>
      <Box className="overflow-x-hidden" data-cy="view-course">
        <Box className="flex justify-between items-center w-full">
          <Box className="flex-1 flex flex-col items-center">
            <FdTypography variant="caption" className="font-medium">
              {courseName}
            </FdTypography>
            <Box className="flex items-center gap-x-4">
              <Box className="flex items-center justify-center gap-x-2 min-w-96">
                {selectedLesson?.lesson && <PresentationIcon size={20} />}
                <FdTypography
                  variant="h4"
                  className="inline-flex items-center font-medium gap-x-1"
                >
                  {selectedLesson?.orderId}
                  <DotIcon /> {lessonName}
                </FdTypography>
                <DotIcon />
                <Box
                  className="flex items-center rounded-xl px-2 py-1"
                  sx={{ bgcolor: theme.palette.primary.dark }}
                >
                  <MonitorIcon size={18} />
                </Box>
                <FdTypography variant="h4" className="font-medium">
                  Exercise
                </FdTypography>
              </Box>
            </Box>
            <Box className="w-full h-full mt-1">
              {consoleUrl ? (
                <VdiConsole
                  token={token}
                  width={window.innerWidth}
                  height={window.innerHeight - 100}
                  hideNewTabButton
                />
              ) : (
                <FdTypography
                  variant="h4"
                  className="h-screen flex items-center justify-center"
                >
                  Lab not found
                </FdTypography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewLessonLab;
