import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronDownIcon, InfoIcon } from 'lucide-react';
import { FdTypography } from '@fifthdomain/fe-shared';

const InfoAccordion = ({ title, message, noBorder, ...props }) => {
  return (
    <Accordion
      disableGutters
      className="mb-4 rounded-lg w-full"
      variant="outlined"
      sx={(theme) => ({
        '&::before': {
          display: 'none',
        },
        border: noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: theme.fdProColors?.alert?.infoLight,
        color: 'rgba(0, 0, 0, 0.87)',
      })}
      {...props}
    >
      <AccordionSummary
        //      css-1dv112o-MuiPaper-root-MuiAccordion-root::before
        expandIcon={<ChevronDownIcon color="rgba(36, 32, 226, 1)" />}
      >
        <InfoIcon className="mr-2" color="rgba(36, 32, 226, 1)" />
        <FdTypography
          variant="subtitle2"
          className="font-semibold"
          color="rgba(0, 0, 0, 0.87)"
        >
          {title}
        </FdTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0, marginTop: 0, marginX: '2rem' }}>
        <FdTypography
          variant="subtitle2"
          color="rgba(0, 0, 0, 0.87)"
          sx={{ fontWeight: 400 }}
        >
          {message}
        </FdTypography>
      </AccordionDetails>
    </Accordion>
  );
};

InfoAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
};

InfoAccordion.defaultProps = {
  noBorder: false,
};

export default InfoAccordion;
