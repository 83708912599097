import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Divider,
  Drawer,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import { Close, Input, ContentPasteGo } from '@mui/icons-material';

const drawerWidth = 400;

const Controls = ({
  setShowControls,
  typeClipboard,
  pasteClipboard,
  clipboardData,
  showControls,
}) => {
  const [clipboard, setClipboard] = useState('');

  useEffect(() => {
    setClipboard(clipboardData);
  }, [clipboardData]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={showControls}
      PaperProps={{
        sx: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          width: drawerWidth,
          zIndex: 1500,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .see-more': {
          color: '#1976D2',
          display: 'block',
          paddingTop: '0.5rem',
          textDecoration: 'none',
        },
      }}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Typography variant="h4">Clipboard</Typography>
          <IconButton
            onClick={() => {
              setShowControls(false);
            }}
          >
            <Close style={{ fontSize: 25 }} />
          </IconButton>
        </Box>
        <Divider />
        <Box mt={1} />
        <TextField
          id="clipboard-field"
          multiline
          fullWidth
          minRows="20"
          maxRows="30"
          placeholder="Copied text will appear here."
          value={clipboard}
          onChange={(e) => {
            setClipboard(e.target.value);
          }}
        />
        <Box mt={1} mb={3} display="flex" justifyContent="space-around">
          <Button
            size="large"
            endIcon={<Input />}
            onClick={() => typeClipboard(clipboard)}
          >
            Type
          </Button>
          <Button
            size="large"
            endIcon={<ContentPasteGo />}
            onClick={() => {
              pasteClipboard(clipboard);
            }}
          >
            Paste
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

Controls.propTypes = {
  setShowControls: PropTypes.func.isRequired,
  typeClipboard: PropTypes.func.isRequired,
  pasteClipboard: PropTypes.func.isRequired,
  clipboardData: PropTypes.string.isRequired,
  showControls: PropTypes.bool.isRequired,
};

export default Controls;
