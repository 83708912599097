import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon, PresentationIcon } from 'lucide-react';
import {
  FdTable,
  FdChip,
  FdChips,
  FdTypography,
  FdDelayed,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import LessonInfoCell from './LessonInfoCell';
import { PROFICIENCY_COLOR, SPECIALTY_COLOR } from '../../../constants';
import LessonDrawer from './LessonDrawer';
import LessonIcon from './LessonIcon';

const LessonTable = ({
  lessonData,
  selectionModel,
  onSelectionModelChange,
  selectionValue,
  isOrderTable,
  showUpDown,
  onUpArrow,
  onDownArrow,
  ...props
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [lessonDetail, setLessonDetail] = useState();
  const actions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const _lessonData = lessonData?.find((item) => item.id === id);
        setLessonDetail(_lessonData);
      },
    },
  ];

  return (
    <Box mt={3} height={isOrderTable ? '728px' : '490px'} data-cy="event-table">
      <FdDelayed triggerField={selectionValue} delay={0}>
        <FdTable
          elevation={0}
          id="fd-lessons-table"
          rowHeight={60}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            filterButton: true,
            columnsButton: true,
            densityButton: true,
          }}
          toolbarSettings={{
            searchBox: true,
          }}
          columnVisibilityModel={{
            skills: false,
            techniques: false,
            technologies: false,
            order: !selectionValue,
            upDown: showUpDown,
          }}
          selection={selectionValue}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
          keepNonExistentRowsSelected
          reorderButtonTooltipText="Drag Lesson"
          rows={lessonData || []}
          actions={actions}
          columns={[
            {
              field: 'order',
              headerName: 'Order',
              width: 70,
              filterable: false,
              renderCell: (params) => (
                <Box className="flex items-center gap-x-1">
                  <PresentationIcon />
                  <FdTypography variant="subtitle2" className="font-bold">
                    {params?.row?.order}
                  </FdTypography>
                </Box>
              ),
            },
            {
              field: 'upDown',
              headerName: '',
              width: 70,
              filterable: false,
              unSort: true,
              renderCell: (params) => {
                const rowIndex = (params.row?.order || 0) - 1;

                return (
                  <Box className="flex items-center">
                    <FdTooltip title="Move Lesson Up">
                      <IconButton
                        size="small"
                        disabled={rowIndex === 0}
                        onClick={() => onUpArrow(rowIndex)}
                      >
                        <ArrowUpIcon size={16} />
                      </IconButton>
                    </FdTooltip>
                    <FdTooltip title="Move Lesson Down">
                      <IconButton
                        size="small"
                        disabled={rowIndex + 1 === lessonData.length}
                        onClick={() => onDownArrow(rowIndex)}
                      >
                        <ArrowDownIcon size={16} />
                      </IconButton>
                    </FdTooltip>
                  </Box>
                );
              },
            },
            {
              field: 'name',
              width: 310,
              headerName: 'Lesson Name',
              valueGetter: (params) => params?.row?.name,
              renderCell: (params) => <LessonInfoCell values={params.row} />,
            },
            {
              field: 'exercise',
              width: 150,
              headerName: 'Exercise',
              valueGetter: (params) => params?.value?.type,
              renderCell: (params) => <LessonIcon type={params?.value} />,
            },
            {
              field: 'resources',
              width: 100,
              headerName: 'Resources',
              valueGetter: (params) => params?.value?.length,
            },
            {
              field: 'quiz',
              width: 130,
              headerName: 'Quiz Questions',
              valueGetter: (params) => params?.value?.length,
            },
            {
              field: 'specialty',
              width: 150,
              headerName: 'Specialty',
              renderCell: (params) => (
                <FdChip
                  size="small"
                  label={params.value}
                  rounded={false}
                  style={{
                    color: SPECIALTY_COLOR[params?.value]?.color || '',
                    backgroundColor:
                      SPECIALTY_COLOR[params?.value]?.backgroundColor,
                  }}
                />
              ),
            },
            {
              field: 'proficiency',
              width: 150,
              headerName: 'Proficiency',
              renderCell: (params) => (
                <FdChip
                  size="small"
                  label={params.value}
                  rounded={false}
                  style={{
                    color: PROFICIENCY_COLOR[params?.value]?.color,
                    backgroundColor:
                      PROFICIENCY_COLOR[params?.value]?.backgroundColor,
                  }}
                />
              ),
            },
            {
              field: 'skills',
              width: 180,
              headerName: 'Skills',
              valueGetter: (params) =>
                params.row?.skills.length > 0
                  ? [...(params.row?.skills ?? [])]
                      .map((t) => t?.name)
                      ?.join(' ')
                  : '',
              renderCell: (params) => {
                const _data =
                  params.row?.skills?.map((t) => ({
                    label: t?.name,
                    color: '',
                  })) || [];
                return <FdChips data={_data} numberOfChipsDisplayed={1} />;
              },
            },
            {
              field: 'techniques',
              headerName: 'Techniques',
              width: 180,
              valueGetter: (params) =>
                params.row?.techniques.length > 0
                  ? [...(params.row?.techniques ?? [])]
                      .map((t) => t?.label)
                      ?.join(' ')
                  : '',
              renderCell: (params) => {
                const _data =
                  params.row?.techniques?.map((t) => ({
                    label: t?.label,
                    color: '',
                  })) || [];
                return <FdChips data={_data} numberOfChipsDisplayed={1} />;
              },
            },
            {
              field: 'technologies',
              headerName: 'Technologies',
              width: 180,
              valueGetter: (params) =>
                params.row?.technologies.length > 0
                  ? [...(params.row?.technologies ?? [])]
                      .map((t) => t?.label)
                      ?.join(' ')
                  : '',
              renderCell: (params) => {
                const _data =
                  params.row?.technologies?.map((t) => ({
                    label: t?.label,
                    color: '',
                  })) || [];
                return <FdChips data={_data} numberOfChipsDisplayed={1} />;
              },
            },
          ]}
          tablePageSize={isOrderTable ? 100 : 5}
          gridId="lesson-table"
          hideFooter={isOrderTable}
          {...props}
        />
      </FdDelayed>

      {lessonDetail && openDrawer && (
        <LessonDrawer
          activeLessonData={lessonDetail}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'labs-fe']}
        />
      )}
    </Box>
  );
};

LessonTable.propTypes = {
  lessonData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectionModel: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectionModelChange: PropTypes.func.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  isOrderTable: PropTypes.bool,
  editMode: PropTypes.bool,
  showUpDown: PropTypes.bool,
  onUpArrow: PropTypes.func,
  onDownArrow: PropTypes.func,
};

LessonTable.defaultProps = {
  isOrderTable: false,
  editMode: false,
  showUpDown: false,
  onUpArrow: () => {},
  onDownArrow: () => {},
};

export default LessonTable;
