import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTable, FdChip, FdChips } from '@fifthdomain/fe-shared';
import LessonInfoCell from './LessonInfoCell';
import { PROFICIENCY_COLOR, SPECIALTY_COLOR } from '../../../constants';
import LessonDrawer from './LessonDrawer';
import LessonIcon from './LessonIcon';

const LessonTable = ({
  lessonData,
  selectionModel,
  onSelectionModelChange,
  selectionValue,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [lessonDetail, setLessonDetail] = useState();
  const actions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const _lessonData = lessonData?.find((item) => item.id === id);
        setLessonDetail(_lessonData);
      },
    },
  ];

  return (
    <Box mt={3} height="450px" data-cy="event-table">
      <FdTable
        elevation={0}
        id="fd-lessons-table"
        rowHeight={60}
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          filterButton: true,
          columnsButton: true,
          densityButton: true,
        }}
        toolbarSettings={{
          searchBox: true,
        }}
        columnVisibilityModel={{
          skills: false,
          techniques: false,
          technologies: false,
        }}
        selection={selectionValue}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        keepNonExistentRowsSelected
        rows={lessonData}
        actions={actions}
        columns={[
          {
            field: 'name',
            width: 310,
            headerName: 'Lesson Name',
            valueGetter: (params) => params?.row?.name,
            renderCell: (params) => <LessonInfoCell values={params.row} />,
          },
          {
            field: 'exercise',
            width: 150,
            headerName: 'Exercise',
            valueGetter: (params) => params?.value?.type,
            renderCell: (params) => <LessonIcon type={params?.value} />,
          },
          {
            field: 'resources',
            width: 100,
            headerName: 'Resources',
            valueGetter: (params) => params?.value?.length,
          },
          {
            field: 'quiz',
            width: 130,
            headerName: 'Quiz Questions',
            valueGetter: (params) => params?.value?.length,
          },
          {
            field: 'specialty',
            width: 150,
            headerName: 'Specialty',
            renderCell: (params) => (
              <FdChip
                size="small"
                label={params.value}
                rounded={false}
                style={{
                  color: SPECIALTY_COLOR[params?.value]?.color || '',
                  backgroundColor:
                    SPECIALTY_COLOR[params?.value]?.backgroundColor,
                }}
              />
            ),
          },
          {
            field: 'proficiency',
            width: 150,
            headerName: 'Proficiency',
            renderCell: (params) => (
              <FdChip
                size="small"
                label={params.value}
                rounded={false}
                style={{
                  color: PROFICIENCY_COLOR[params?.value]?.color,
                  backgroundColor:
                    PROFICIENCY_COLOR[params?.value]?.backgroundColor,
                }}
              />
            ),
          },
          {
            field: 'skills',
            width: 180,
            headerName: 'Skills',
            valueGetter: (params) =>
              params.row?.skills.length > 0
                ? [...params.row?.skills].map((t) => t?.name)?.join(' ')
                : '',
            renderCell: (params) => {
              const _data =
                params.row?.skills?.map((t) => ({
                  label: t?.name,
                  color: '',
                })) || [];
              return <FdChips data={_data} numberOfChipsDisplayed={1} />;
            },
          },
          {
            field: 'techniques',
            headerName: 'Techniques',
            width: 180,
            valueGetter: (params) =>
              params.row?.techniques.length > 0
                ? [...params.row?.techniques].map((t) => t?.label)?.join(' ')
                : '',
            renderCell: (params) => {
              const _data =
                params.row?.techniques?.map((t) => ({
                  label: t?.label,
                  color: '',
                })) || [];
              return <FdChips data={_data} numberOfChipsDisplayed={1} />;
            },
          },
          {
            field: 'technologies',
            headerName: 'Technologies',
            width: 180,
            valueGetter: (params) =>
              params.row?.technologies.length > 0
                ? [...params.row?.technologies].map((t) => t?.label)?.join(' ')
                : '',
            renderCell: (params) => {
              const _data =
                params.row?.technologies?.map((t) => ({
                  label: t?.label,
                  color: '',
                })) || [];
              return <FdChips data={_data} numberOfChipsDisplayed={1} />;
            },
          },
        ]}
        tablePageSize={5}
        gridId="lesson-table"
      />

      {lessonDetail && openDrawer && (
        <LessonDrawer
          activeLessonData={lessonDetail}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'labs-fe']}
        />
      )}
    </Box>
  );
};

LessonTable.propTypes = {
  lessonData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectionModel: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectionModelChange: PropTypes.func.isRequired,
  selectionValue: PropTypes.bool.isRequired,
};

export default LessonTable;
