import React from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField, FdTypography } from '@fifthdomain/fe-shared';

const LabDetails = () => {
  const { control, values } = useFormContext();

  return (
    <FdCard variant="outlined">
      <FdTypography variant="h3">Lab Details</FdTypography>
      <FdTypography variant="body2" color="secondary" className="my-2">
        Enter key details for this lab.
      </FdTypography>
      <Box className="flex flex-col my-4 gap-y-6">
        <Controller
          control={control}
          name="labName"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <FdTextField
              id="labName"
              label="Lab Name"
              required
              fullWidth
              error={error}
              value={values?.labName}
              helperText={error && error.message}
              {...rest}
              inputRef={ref}
            />
          )}
        />
        <Controller
          control={control}
          name="labDescription"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <FdTextField
              id="labDescription"
              label="Lab Description"
              fullWidth
              multiline
              value={values?.labDescription}
              rows={2}
              error={error}
              helperText={error && error.message}
              {...rest}
              inputRef={ref}
            />
          )}
        />
      </Box>
    </FdCard>
  );
};

export default LabDetails;
