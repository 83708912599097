import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { FdTable, FdChip, FdSelect } from '@fifthdomain/fe-shared';
import { getLabsStatusColor } from '../../shared/utils/getStatusColor';
import TransferContentOwnership from './TransferContentOwnership';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { transferLabOwnership } from '../../graphql/mutations';

const LabsTable = ({
  rows,
  actions,
  headerActions,
  selection,
  refetchLabs,
}) => {
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [transferLabOwnershipModal, setTransferOwnershipModal] =
    useState(false);
  const [orgAlias, setOrgAlias] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [
    transferLabOwnershipMutation,
    { loading: transferLabOwnershipLoading },
  ] = useMutation(gql(transferLabOwnership));

  const columns = [
    { field: 'name', width: 250, headerName: ' Name' },
    {
      field: 'status',
      width: 250,
      headerName: 'Status',
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdChip
          color={getLabsStatusColor(params?.row?.status)}
          size="small"
          label={params?.row?.status}
        />
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.value.localeCompare(param2.value),
    },
    { field: 'vms', width: 250, headerName: 'VMs' },
  ];

  const onBulkAction = (_selection) => {
    switch (_selection) {
      case 'Transfer Ownership':
        setTransferOwnershipModal(true);
        break;
      default:
        break;
    }
  };

  const onConfirmTransferLabOwnership = () => {
    transferLabOwnershipMutation({
      variables: {
        orgAlias,
        labIds: selectedLabs,
      },
      onCompleted: () => {
        setTransferOwnershipModal(false);
        setSelectedLabs([]);
        setOrgAlias('');
        successToastMessage('Transfer of ownership initiated');
        refetchLabs();
      },
      onError: (e) => setErrorMessage(e.message),
    });
  };

  const onDismissTransferLabOwnership = () => {
    setTransferOwnershipModal(false);
    setOrgAlias('');
    setErrorMessage('');
    warningToastMessage('Transfer of ownership cancelled');
  };

  return (
    <Box>
      <FdTable
        toolbarSettings={{
          title: 'Labs',
          headerActions,
          filterButton: true,
          searchBox: true,
          headerCustomElements:
            selectedLabs.length > 0
              ? [
                  {
                    CustomElement: () => (
                      <FdSelect
                        label=""
                        options={['Transfer Ownership']}
                        width="250px"
                        placeholder="Actions"
                        customPlaceHolder
                        onChange={onBulkAction}
                      />
                    ),
                  },
                ]
              : [],
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        selection={selection}
        disableSelectionOnClick
        isRowSelectable={(params) => params.row.status === 'Available'}
        selectionModel={selectedLabs}
        onSelectionModelChange={(_value) => setSelectedLabs(_value)}
        autoHeight
        gridId="labs-admin-labs"
      />
      <TransferContentOwnership
        rows={rows.filter((row) => selectedLabs.includes(row?.id))}
        columns={[
          {
            field: 'name',
            width: 200,
            headerName: 'Labs to be transferred',
          },
          { field: 'vms', width: 200, headerName: 'VMs' },
          { field: 'instances', width: 100, headerName: 'Instances' },
          {
            field: 'noOfDependentChallenges',
            width: 200,
            headerName: 'Dependent Challenges',
          },
        ]}
        onConfirm={onConfirmTransferLabOwnership}
        onDismiss={onDismissTransferLabOwnership}
        onChange={(e) => {
          setOrgAlias(e.target.value);
          setErrorMessage('');
        }}
        transferContentOwnershipModal={transferLabOwnershipModal}
        orgAlias={orgAlias}
        contentType="Labs"
        loading={transferLabOwnershipLoading}
        error={!!errorMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </Box>
  );
};

LabsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  selection: PropTypes.bool,
  refetchLabs: PropTypes.func.isRequired,
};

LabsTable.defaultProps = {
  actions: [],
  headerActions: [],
  selection: false,
};

export default LabsTable;
