import React from 'react';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  FdCard,
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
  FdSelect,
  FdAlert,
} from '@fifthdomain/fe-shared';
import LabNetworkVms from './LabNetworkVms';
import FdBoxButton from '../FdBoxButton';
import { VM_CONNECTION_TYPE, VM_SIZE } from '../../constants';
import RadioOptions from './RadioOptions';

const LabVirtualMachines = ({ VMFiles, isEditMode, labStatus }) => {
  const { control, setValue, getValues } = useFormContext();
  const {
    fields: labVmsFields,
    remove: labVmsRemove,
    append: labVmsAppend,
  } = useFieldArray({
    control,
    name: 'labVms',
  });
  const { labVms, labDeleteVMs } = getValues();

  // Vm Network Values
  const vmNetworkValues = labVms
    ?.map((vMNetwork) => vMNetwork?.networks?.items?.map((item) => item))
    .flat();

  return (
    <FdCard variant="outlined">
      <Box className="mb-6">
        <FdTypography variant="h3">Virtual Machines</FdTypography>
        <FdTypography variant="body2" color="secondary" className="my-2">
          Add Virtual Machines (VMs) to your lab below.
        </FdTypography>
      </Box>
      <ErrorMessage
        name="labVms"
        render={() => (
          <Box my={2}>
            <FdAlert
              alertTitle="At least one VM must be added"
              variant="error"
              message="Please add a VM"
            />
          </Box>
        )}
      />
      {labVmsFields.map((item, index) => {
        const vmAccessType = item?.vdi ? 'VDI' : 'VPN';

        return (
          <Box my={2} key={item.id}>
            <FdAccordion
              open
              startAdornment
              disableGutters
              showToggleButton={false}
              summary={() => (
                <Box className="flex items-center justify-between w-full">
                  <FdTypography variant="h4">Virtual Machine</FdTypography>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      labVmsRemove(index);
                      setValue(
                        'labDeleteVMs',
                        labDeleteVMs?.concat(
                          labVms?.filter(
                            (labNetw) => labNetw.name === item.name,
                          ),
                        ),
                      );
                    }}
                  >
                    DELETE
                  </FdButton>
                </Box>
              )}
              content={() => (
                <>
                  <Divider />
                  <Box className="flex flex-col gap-y-6 my-6">
                    {isEditMode &&
                    labStatus === 'READY' &&
                    // check if vm has been transferred to another org
                    item?.id &&
                    item?.imageId &&
                    !VMFiles?.some(
                      (_VMFile) => _VMFile.imageId === item.imageId,
                    ) ? (
                      <>
                        <Box mt={2} mb={2}>
                          <FdTypography variant="subtitle1">
                            VM Name
                          </FdTypography>
                          <FdTypography variant="body1" color="secondary">
                            {item?.name}
                          </FdTypography>
                        </Box>
                        <Box mt={2} mb={2}>
                          <FdTypography variant="subtitle1">
                            VM Image
                          </FdTypography>
                          <FdTypography variant="body1" color="secondary">
                            {item?.imageId}
                          </FdTypography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Controller
                          control={control}
                          name={`labVms[${index}].name`}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <FdTextField
                              id={`labVms[${index}].name`}
                              label="VM Name"
                              placeholder="Enter VM Name"
                              required
                              fullWidth
                              error={error}
                              helperText={error && error.message}
                              {...rest}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Box mt={0} mb={3}>
                          <Controller
                            control={control}
                            name={`labVms[${index}].imageId`}
                            render={({
                              field: { ref, ...rest },
                              fieldState: { error },
                            }) => (
                              <FdSelect
                                inputTitle="VM Image"
                                id={`labVms[${index}].imageId`}
                                placeholder="Select VM Image"
                                defaultSelected={
                                  VMFiles?.find(
                                    (VMFile) => VMFile.imageId === rest.value,
                                  )?.name || rest.value
                                }
                                error={error}
                                helperText={
                                  error
                                    ? error.message
                                    : 'All successfully uploaded VM Images in your organisation will appear here for selection.'
                                }
                                options={
                                  VMFiles?.map((VMFile) => VMFile.name)?.sort(
                                    (a, b) => a.localeCompare(b),
                                  ) || []
                                }
                                fullWidth
                                {...rest}
                                inputRef={ref}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          control={control}
                          name={`labVms[${index}].instanceType`}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <RadioOptions
                              id={`labVms[${index}].instanceType`}
                              options={VM_SIZE}
                              title="VM Size"
                              subTitle="Select a VM size below based on computing power requirements."
                              error={error}
                              {...rest}
                              inputRef={ref}
                            />
                          )}
                        />
                        {isEditMode ? (
                          <Box className="flex flex-col gap-2">
                            <FdTypography variant="subtitle1">
                              VM Access Type
                            </FdTypography>
                            <Box className="flex gap-x-2">
                              <FdTypography variant="body1">
                                {vmAccessType}
                              </FdTypography>
                              <FdTypography variant="captiontext1">
                                {
                                  VM_CONNECTION_TYPE?.find(
                                    (i) => i?.value === vmAccessType,
                                  )?.caption
                                }
                              </FdTypography>
                            </Box>
                          </Box>
                        ) : (
                          <Controller
                            control={control}
                            name={`labVms[${index}].accessibilityOption`}
                            render={({
                              field: { ref, ...rest },
                              fieldState: { error },
                            }) => (
                              <RadioOptions
                                id={`labVms[${index}].accessibilityOption`}
                                options={VM_CONNECTION_TYPE}
                                title="VM Access Type"
                                subTitle="Select how participants will access this lab."
                                error={error}
                                {...rest}
                                inputRef={ref}
                              />
                            )}
                          />
                        )}
                        <FdAlert
                          alertTitle={
                            isEditMode
                              ? ''
                              : 'VM Access Type is Not Editable after Lab Creation'
                          }
                          variant="info"
                          message={
                            isEditMode ? (
                              <Box>
                                <FdTypography variant="body2">
                                  This setting was chosen when the lab was first
                                  created and cannot be edited.
                                </FdTypography>
                                <FdTypography variant="body2">
                                  <b> Challenges </b> support both VPN and VDI
                                  labs, but <b> Lessons </b> only support labs
                                  with at least one VM using VDI access.
                                </FdTypography>
                              </Box>
                            ) : (
                              <Box>
                                <FdTypography variant="body2">
                                  Once a lab is built, the VM Access Type cannot
                                  be changed, even when editing the lab later.
                                  Duplicating the lab will not make this field
                                  editable.
                                </FdTypography>
                                <FdTypography variant="body2">
                                  <b> Challenges </b> support both VPN and VDI
                                  labs, but <b> Lessons </b> only support labs
                                  with at least one VM using VDI access. Choose
                                  carefully to avoid rebuilding the lab from
                                  scratch.
                                </FdTypography>
                              </Box>
                            )
                          }
                        />
                        <Controller
                          control={control}
                          name={`labVms[${index}].vdiUser`}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <FdTextField
                              id={`labVms[${index}].vdiUser`}
                              label="VDI Username"
                              required
                              placeholder="Enter VDI Username"
                              fullWidth
                              error={error}
                              helperText={
                                error
                                  ? error.message
                                  : 'Enter the VDI username to enable automatic login. This username is preconfigured in the VM, so when VDI is enabled, users will be logged into the VM without needing to enter credentials.'
                              }
                              {...rest}
                              inputRef={ref}
                            />
                          )}
                        />
                      </>
                    )}
                    <LabNetworkVms
                      nextIndex={index}
                      vmNetworkValues={vmNetworkValues}
                    />
                  </Box>
                </>
              )}
            />
          </Box>
        );
      })}
      <FdBoxButton
        onClick={async (e) => {
          e.preventDefault();
          labVmsAppend({
            name: '',
            imageId: '',
            instanceType: '',
            accessibilityOption: '',
            vdiUser: '',
          });
        }}
      >
        ADD VIRTUAL MACHINE
      </FdBoxButton>
    </FdCard>
  );
};

LabVirtualMachines.propTypes = {
  VMFiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  triggerAfterSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  labStatus: PropTypes.string.isRequired,
};

LabVirtualMachines.defaultProps = {
  isEditMode: false,
};

export default LabVirtualMachines;
