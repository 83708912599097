import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { FdButton, FdTypography, FdChip } from '@fifthdomain/fe-shared';
import { FILE_MIMES } from '../../constants';

const FdFileUpload = ({
  onDrop,
  inProgress,
  fileTypes,
  fileCount,
  singleFile,
  uploadPercentage,
  onSingleFileNextAttempt,
  selectedFile,
  errorState,
}) => {
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: FILE_MIMES[fileTypes],
  });
  const onClickEvent = singleFile
    ? fileCount === 0
      ? open
      : onSingleFileNextAttempt
    : open;
  const { percentage, transferStatus } = uploadPercentage || {};
  const uploadInProgress = inProgress && percentage !== undefined;

  return (
    <Box my={1}>
      {inProgress && (
        <Box
          className="flex items-center justify-between gap-x-6 rounded px-4 py-4 w-full"
          style={{
            backgroundColor: 'rgba(235, 242, 255, 1)',
          }}
        >
          <Box className="flex flex-col gap-y-2">
            <Box className="flex items-center gap-x-2">
              <FdTypography variant="subtitle2">
                {uploadInProgress
                  ? `Uploading... ${percentage}% Complete`
                  : 'Initialising upload...'}
              </FdTypography>
              <CircularProgress size={16} />
            </Box>
            <FdTypography variant="captiontext1">
              {uploadInProgress
                ? 'Your VM file is currently being uploaded. You may close this window, and the upload will continue in the background. Reopen this window to check upload progress or visit the Manage VMs page once the upload is complete.'
                : 'Your VM upload is being prepared. This may take a few moments before the upload starts.'}
            </FdTypography>
          </Box>
          {transferStatus && (
            <Box className="flex flex-col items-center justify-center gap-y-1">
              <FdTypography variant="captiontext1" className="text-center">
                Transfer Status
              </FdTypography>
              <FdChip
                rounded={false}
                size="small"
                label={transferStatus}
                color="warning"
                style={{ width: '200px' }}
              />
            </Box>
          )}
        </Box>
      )}
      {errorState}
      <Box className="flex items-center gap-x-5 mt-2" {...getRootProps()}>
        <input {...getInputProps()} />
        <FdButton
          onClick={onClickEvent}
          data-cy="upload-btn"
          disabled={selectedFile}
          aria-label="add-artifact"
          size="medium"
        >
          {fileCount && !singleFile > 0 ? 'Replace' : 'Browse'}
        </FdButton>
        {selectedFile || (
          <FdTypography variant="body2" color="secondary">
            No VM file selected
          </FdTypography>
        )}
      </Box>
    </Box>
  );
};

FdFileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  fileTypes: PropTypes.string,
  fileCount: PropTypes.number,
  singleFile: PropTypes.bool,
  uploadPercentage: PropTypes.number,
  onSingleFileNextAttempt: PropTypes.func,
  selectedFile: PropTypes.node,
  errorState: PropTypes.node,
};

FdFileUpload.defaultProps = {
  inProgress: false,
  fileTypes: '',
  fileCount: 0,
  singleFile: false,
  uploadPercentage: 0.0,
  onSingleFileNextAttempt: () => null,
  selectedFile: null,
  errorState: null,
};

export default FdFileUpload;
