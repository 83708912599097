import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  FdAccordion,
  FdTypography,
  FdCard,
  FdTab,
  FdProgress,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import _ from 'lodash';
import InsightTable from './InsightTable';
import { listQuizAttemptsByCourseUserId } from '../../../graphql/queries';
import { listLabInstancesRemainingTime } from '../../../queries/customQueries';
import { QUESTION_MAPPING } from '../../../constants';
import { getKeyByValue } from '../../../shared/utils/objectUtils';

const ProgressInsight = ({
  userId,
  courseUserId,
  courseData,
  modulePartData,
}) => {
  const [refetch, setRefetch] = useState(false);
  const BoxCentered = ({ title, children }) => (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box>{title}</Box>
      {children}
    </Box>
  );
  BoxCentered.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  // get Lab Remaining time from lab Instance query
  const {
    data: listLabInstancesRemainingTimeData,
    loading: listLabInstancesRemainingTimeLoading,
  } = useQueryRecursive(gql(listLabInstancesRemainingTime), {
    variables: {
      filter: {
        userId: {
          eq: userId,
        },
      },
    },
    refetch,
    skip: !userId,
  });

  // Quiz CourseID
  const { data: quizAttemptsData, loading: quizAttemptsLoading } =
    useQueryRecursive(gql(listQuizAttemptsByCourseUserId), {
      variables: {
        courseUserId,
      },
      refetch,
      skip: !courseUserId,
    });

  useEffect(() => {
    return () => {
      setRefetch(!refetch);
    };
  }, [courseUserId, refetch]);

  if (quizAttemptsLoading || listLabInstancesRemainingTimeLoading) {
    return <FdProgress />;
  }

  const labResult = (labId) => {
    // started status
    const started = modulePartData
      ?.filter((modulePart) => modulePart?.labId === labId)
      ?.map(
        (result) => result.status === 'FINISHED' || result.status === 'STARTED',
      )?.[0];
    // completed status
    const completed = modulePartData
      ?.filter((modulePart) => modulePart?.labId === labId)
      ?.map((result) => result.status === 'FINISHED')?.[0];
    // lab time used
    const labTime =
      listLabInstancesRemainingTimeData?.listLabInstances?.items?.filter(
        (item) => item.labPrototypeId === labId,
      )?.[0]?.runningTime;

    return { started, completed, labTime };
  };

  const quizResult = (val) => {
    const quizAttempt =
      quizAttemptsData?.listQuizAttemptsByCourseUserId?.items?.filter(
        (attemptItem) => attemptItem.quizId === val,
      );
    const groupByAttempts = _.values(_.groupBy(quizAttempt, 'attempt'));
    const questionsApiData = quizAttempt?.[0]?.quiz?.questions?.items || [];
    const question = questionsApiData?.map((q) => ({
      questionId: q.id,
      questionType: getKeyByValue(QUESTION_MAPPING, q.type),
      question: q.name,
      correctAnswer: null,
      points: q.point,
      multipleChoices: q.options?.items
        .map((o) => ({
          choiceId: o.id,
          answer: o.optionName,
          correctAnswer: null,
          order: o.orderNumber,
        }))
        .sort(() => Math.random() - 0.5),
    }));
    const scorePoints = groupByAttempts
      .reverse()
      .map((a) =>
        a
          .filter((qa) => qa.success)
          .reduce(
            (ac, v) =>
              ac +
                question?.find((qns) => qns?.questionId === v.questionId)
                  ?.points || 0,
            0,
          ),
      );
    const topScore = scorePoints?.length > 0 ? Math.max(...scorePoints) : 0;
    const totalScore = question?.reduce((ac, v) => ac + v.points, 0);
    const recentScore = scorePoints?.length > 0 ? scorePoints?.[0] : 0;
    const successRate = ` ${
      totalScore > 0
        ? Math.round(
            scorePoints.length > 0 &&
              (Math.max(...scorePoints) / totalScore) * 100,
          )
        : 0
    }%`;

    return {
      attempted: quizAttempt?.length > 0,
      attempts: groupByAttempts?.length,
      topScore: `${topScore}/${totalScore}`,
      recentScore: `${recentScore}/${totalScore}`,
      successRate,
    };
  };

  const sortByOrder = (a, b) => a.orderNumber - b.orderNumber;
  const courseDataItems = [...courseData?.getCourse?.courseModules?.items].sort(
    sortByOrder,
  );

  return (
    <Box>
      <FdCard
        heading="Module Insights"
        data-cy="progress-insights-card"
        variant="outlined"
      >
        {courseDataItems?.map((item) => (
          <FdAccordion
            key={item.id}
            startAdornment
            showToggleButton={false}
            summary={() => (
              <Box style={{ width: '100%' }}>
                <Box display="flex">
                  <Box sx={{ flexGrow: 1 }}>
                    <FdTypography variant="h3">{item?.name}</FdTypography>
                  </Box>
                </Box>
              </Box>
            )}
            content={() => {
              const partDataItem = [...item?.parts?.items].sort(sortByOrder);
              const pdfRows = partDataItem
                ?.filter((itemType) => itemType.type === 'PDF')
                ?.map((pdfItem) => ({
                  id: pdfItem.id,
                  name: pdfItem.name,
                  completed: modulePartData
                    ?.filter(
                      (modulePart) =>
                        modulePart?.courseModulePartsId === pdfItem.id,
                    )
                    ?.map((result) => result.status === 'FINISHED')?.[0],
                }));

              const labRows = partDataItem
                ?.filter((itemType) => itemType.type === 'LAB')
                ?.map((labItem) => {
                  const { started, completed, labTime } = labResult(
                    labItem.lab.id,
                  );
                  return {
                    name: labItem.lab.name,
                    id: labItem.id, // partId not LabId
                    started,
                    completed,
                    type: labItem.type,
                    labTime,
                  };
                });

              const quizRows = partDataItem
                ?.filter((itemType) => itemType.type === 'QUIZ')
                ?.map((quizItem) => {
                  const {
                    attempted,
                    attempts,
                    topScore,
                    recentScore,
                    successRate,
                  } = quizResult(quizItem.quiz.id);
                  return {
                    id: quizItem.quiz.id,
                    name: quizItem.quiz.name,
                    type: quizItem.type,
                    attempted,
                    attempts,
                    topScore,
                    recentScore,
                    successRate,
                  };
                });

              const markdownRows = partDataItem
                ?.filter((itemType) => itemType.type === 'MARKDOWN')
                ?.map((markdownItem) => ({
                  id: markdownItem.id,
                  name: markdownItem.name,
                  completed: modulePartData
                    ?.filter(
                      (modulePart) =>
                        modulePart?.courseModulePartsId === markdownItem.id,
                    )
                    ?.map((result) => result.status === 'FINISHED')?.[0],
                }));

              return (
                <Box display="flex" flexDirection="column">
                  <FdTypography variant="body1">
                    The description of the module goes here. Learning outcomes,
                    labs, quizzes and instructions related to the module.
                  </FdTypography>
                  <FdTab
                    label={[
                      {
                        label: 'Labs',
                        index: 0,
                        data: (
                          <InsightTable
                            rows={labRows}
                            modulePart="Labs"
                            courseUserId={userId}
                          />
                        ),
                      },
                      {
                        label: 'Quizzes',
                        index: 1,
                        data: (
                          <InsightTable rows={quizRows} modulePart="Quizzes" />
                        ),
                      },
                      {
                        label: 'PDFs',
                        index: 3,
                        data: <InsightTable rows={pdfRows} modulePart="PDFs" />,
                      },
                      {
                        label: 'Markdowns',
                        index: 4,
                        data: (
                          <InsightTable
                            rows={markdownRows}
                            modulePart="Markdowns"
                          />
                        ),
                      },
                    ]}
                  />
                </Box>
              );
            }}
          />
        ))}
      </FdCard>
    </Box>
  );
};

ProgressInsight.propTypes = {
  courseData: PropTypes.shape({
    getCourse: PropTypes.shape({
      availability: PropTypes.bool,
      categoryId: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      courseModules: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  modulePartData: PropTypes.arrayOf(PropTypes.object),
  courseUserId: PropTypes.string,
  userId: PropTypes.string,
};

ProgressInsight.defaultProps = {
  courseData: {},
  modulePartData: [],
  courseUserId: '',
  userId: '',
};
export default ProgressInsight;
