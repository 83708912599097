import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const FieldView = ({ label, value, highlight, labelTypography }) => {
  return (
    <Box my={1}>
      {label && (
        <FdTypography variant={labelTypography} fontSize="text-xl">
          {label}
        </FdTypography>
      )}
      <Box
        className={`my-1 rounded ${highlight ? 'p-3' : ''}`}
        style={{
          backgroundColor: highlight ? 'rgba(250, 250, 250, 1)' : 'inherit',
        }}
      >
        <FdTypography variant="body1" color="secondary">
          {value || <em>None Added</em>}
        </FdTypography>
      </Box>
    </Box>
  );
};

FieldView.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  highlight: PropTypes.bool,
  labelTypography: PropTypes.string,
};

FieldView.defaultProps = {
  highlight: false,
  labelTypography: 'h4',
};

export default FieldView;
