import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  FdAlert,
  FdButton,
  FdTable,
  FdModal,
  FdTextField,
  FdTypography,
  FdCheckbox,
} from '@fifthdomain/fe-shared';

const TransferContentOwnership = ({
  rows,
  columns,
  onConfirm,
  onDismiss,
  transferContentOwnershipModal,
  contentType,
  orgAlias,
  onChange,
  loading,
  error,
  errorMessage,
  setErrorMessage,
  successMessage,
  onConfirmSearch,
}) => {
  const [checkedA, setCheckedA] = useState(false);
  const [checkedB, setCheckedB] = useState(false);

  const contentTypes = {
    Labs: 'Lab',
    VMs: 'VM',
  };

  return (
    <FdModal
      title={`Transfer ${contentTypes[contentType]} Ownership`}
      description={`${contentType} that have been selected for transfer are listed below. Once transferred, the receiving organisation will own these ${contentType} and have full access to view, edit, test, and use them in challenges and lessons. Your organisation will no longer have access to these ${contentType} from the Manage ${contentType} page.`}
      confirm={loading ? 'Loading...' : 'Transfer'}
      showConfirmInGreen
      dismiss="Cancel"
      showDismiss
      open={transferContentOwnershipModal}
      onConfirm={() => {
        if (!orgAlias) {
          setErrorMessage({
            orgError: 'Please enter an Org Alias.',
            checkedError: '',
          });
        } else if (!checkedA || !checkedB) {
          setErrorMessage({
            orgError: '',
            checkedError:
              'You need to confirm both checkboxes before proceeding.',
          });
        } else {
          onConfirm();
          setCheckedA(false);
          setCheckedB(false);
        }
      }}
      onDismiss={() => {
        setCheckedA(false);
        setCheckedB(false);
        onDismiss();
      }}
      maxWidth="md"
    >
      <Box>
        <Box className="flex items-center gap-x-2 my-6">
          <Box width={contentType === 'Labs' ? '90%' : '100%'}>
            <FdTextField
              fullWidth
              id="org_alias"
              label="Receiving Organisation"
              placeholder=""
              required
              onChange={onChange}
              error={errorMessage?.orgError !== '' || successMessage === false}
              helperText={errorMessage?.orgError}
            />
            {contentType === 'Labs' && successMessage && (
              <FdTypography
                variant="captiontext1"
                style={{
                  color: '#28957B',
                }}
              >{`Matching organisation found!`}</FdTypography>
            )}
          </Box>
          {contentType === 'Labs' && (
            <Box mt={2.5}>
              <FdButton onClick={() => onConfirmSearch()}>search</FdButton>
            </Box>
          )}
        </Box>
        {contentType === 'Labs' && (
          <Box mb={3}>
            <FdAlert
              variant="info"
              alertTitle={`How Lab Transfers Affect Challenges and Lessons  `}
              message={
                <Box className="flex flex-col">
                  <FdTypography variant="body2">
                    When a lab is transferred, any challenges or lessons that
                    use it will continue to function as normal. Participants
                    will still be able to start the lab, and no manual updates
                    are needed to replace it.
                  </FdTypography>
                  <Box my={2}>
                    <FdTypography variant="body2">
                      However, after the transfer, you won’t be able to add the
                      lab to any new challenges or lessons. If you remove the
                      lab from an existing challenge or lesson, you cannot
                      re-add it later as your organisation will no longer have
                      access.
                    </FdTypography>
                  </Box>
                </Box>
              }
            />
          </Box>
        )}
        <Box mb={2}>
          <FdTypography variant="subtitle1">{`${contentType} Selected for Transfer (${rows?.length})`}</FdTypography>
        </Box>
        <FdTable
          autoHeight
          title=""
          toolbarSettings={{
            hide: true,
          }}
          rows={rows}
          columns={columns}
          tablePageSize={5}
          gridId="labs-transfer-content-ownership"
        />
        {orgAlias && successMessage && (
          <Box mt={2}>
            <FdAlert
              variant="info"
              alertTitle={`Confirm Transfer of ${rows.length} ${contentType}  `}
              message={
                <Box className="flex flex-col">
                  <FdTypography variant="body2">
                    {`Your organisation will lose access to all ${contentType} selected for transfer. Once transferred, you won’t be able to view, edit, test, or attach these ${contentType} to challenges and lessons.`}
                  </FdTypography>
                  <Box my={2}>
                    <FdTypography color="secondary" variant="body2">
                      <b> Tip:</b>
                      {` If you need to keep a copy of a ${contentTypes[contentType]}, duplicate it before transferring.`}
                    </FdTypography>
                  </Box>
                  <Box mb={2}>
                    <FdCheckbox
                      label={`I agree to transfer ownership of the selected ${contentType} to the receiving organisation (alias: ${orgAlias}) and understand that my organisation will lose access to these ${contentType}.`}
                      checked={checkedA}
                      onChange={(e) => setCheckedA(e)}
                      error={errorMessage?.checkedError !== ''}
                    />
                  </Box>
                  <FdCheckbox
                    label={`I understand that transferred ${contentType} will still function in challenges and lessons, but if removed, they cannot be reattached.`}
                    checked={checkedB}
                    onChange={(e) => setCheckedB(e)}
                    error={errorMessage?.checkedError !== ''}
                    helperText={errorMessage?.checkedError}
                  />
                </Box>
              }
            />
          </Box>
        )}
      </Box>
    </FdModal>
  );
};

TransferContentOwnership.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  transferContentOwnershipModal: PropTypes.bool.isRequired,
  contentType: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.bool.isRequired,
};

export default TransferContentOwnership;
