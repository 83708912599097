import React from 'react';
import PropTypes from 'prop-types';
import { alpha, Button, useTheme } from '@mui/material';

const LessonButton = ({ sx, children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      sx={[
        {
          bgcolor: alpha(theme.palette.primary.main, 0.1),
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
          borderRadius: '8px',
        },
        typeof sx === 'function' ? sx(theme) : sx,
      ]}
      {...props}
    >
      {children}
    </Button>
  );
};

LessonButton.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

LessonButton.defaultProps = {
  sx: [],
};

export default LessonButton;
