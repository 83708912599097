import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PresentationIcon } from 'lucide-react';
import {
  FdButton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
  FdCard,
  FdTypography,
} from '@fifthdomain/fe-shared';
import {
  createCourseLesson,
  updateCourseLesson,
  deleteCourseLesson,
} from '../../../graphql/mutations';
import { listLessonsByOrgId } from '../../../graphql/queries';
import LessonMaterial from '../LessonMaterial/LessonMaterial';
import LessonTable from '../LessonMaterial/LessonTable';
import { formatLesson } from './utils';

const EditLessons = ({ courseData, refetchCourse }) => {
  const { courseId } = useParams();
  const { orgId } = useSnapshot(globalStore);
  const { getValues, reset, trigger, control } = useFormContext();
  const [selectionValue, setSelectionValue] = useState(false);
  const { fields: lessons, move: moveLesson } = useFieldArray({
    control,
    name: 'lessons',
  });

  const [createCourseLessonMutation, { loading: createCourseLessonLoading }] =
    useMutation(gql(createCourseLesson));
  const [updateCourseLessonMutation, { loading: updateCourseLessonLoading }] =
    useMutation(gql(updateCourseLesson));
  const [deleteCourseLessonMutation, { loading: deleteCourseLessonLoading }] =
    useMutation(gql(deleteCourseLesson));

  // ALl lesson published by this org
  const { data: lessonData } = useQueryRecursive(gql(listLessonsByOrgId), {
    variables: {
      orgId,
      filter: { status: { eq: 'PUBLISHED' } },
    },
    skip: !orgId,
  });

  const courseLessons = courseData?.getCourse?.courseLessons?.items?.map(
    (item) => ({
      courseLessonId: item?.id,
      lessonId: item?.lessonID,
    }),
  );

  const lessonTableData = !selectionValue
    ? getValues()?.lessons
    : lessonData?.listLessonsByOrgId?.items;

  const allLessons = lessonTableData?.map(formatLesson) || [];

  const updateCourseLessonsAction = async () => {
    const { lessonIds, lessons: lessonsValues } = getValues();

    const deleteItems = courseLessons?.filter(
      (item) => !lessonIds?.includes(item?.lessonId),
    );
    const addItems = lessonIds?.filter(
      (item) => !courseLessons?.map((i) => i?.lessonId)?.includes(item),
    );

    const addedLessons = lessonsValues
      ?.map((l, idx) => ({ ...l, order: idx + 1 }))
      ?.filter((item) => addItems?.includes(item?.id));

    if (addItems?.length > 0) {
      await Promise.all(
        addedLessons?.map((l) => {
          return createCourseLessonMutation({
            variables: {
              input: {
                courseID: courseId,
                lessonID: l?.lessonId,
                orderNumber: l?.order,
              },
            },
          });
        }),
      );
    }
    if (deleteItems?.length > 0) {
      await Promise.all(
        deleteItems?.map((i) => {
          return deleteCourseLessonMutation({
            variables: {
              input: {
                id: i?.courseLessonId,
              },
            },
          });
        }),
      );
    }
    // update order if existing lesson
    await Promise.all(
      lessonsValues
        ?.filter((l) => l?.lessonDbId)
        ?.map((l, idx) => {
          return updateCourseLessonMutation({
            variables: {
              input: {
                id: l?.lessonDbId,
                orderNumber: idx + 1,
              },
            },
          });
        }),
    );
    refetchCourse();
    setSelectionValue(false);
    successToastMessage('Success! Course edits saved.');
  };

  const ActionButtons = () => (
    <Box display="flex">
      {!selectionValue ? (
        <FdButton onClick={() => setSelectionValue(true)} data-cy="edit-button">
          Edit
        </FdButton>
      ) : (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setSelectionValue(false);
              warningToastMessage('Course edits not saved.');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                const result = await trigger(['lessons']);
                if (result) {
                  updateCourseLessonsAction();
                }
              }}
              disabled={
                createCourseLessonLoading ||
                updateCourseLessonLoading ||
                deleteCourseLessonLoading
              }
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
      <LessonMaterial
        allLessons={allLessons}
        actionButtons={<ActionButtons />}
        selectionValue={selectionValue}
        editMode
      />
      {selectionValue && (
        <FdCard variant="outlined" className="h-full">
          <Box className="flex items-center gap-x-2 my-2">
            <PresentationIcon />
            <FdTypography variant="h3">Lesson Order</FdTypography>
            <FdTypography variant="body1" color="secondary" className="mt-1">
              optional
            </FdTypography>
          </Box>
          <FdTypography variant="body2" color="secondary">
            This table displays the lesson order that participants will see.
            Reorder lessons by dragging or using the arrows. Lessons will appear
            here as you select them from the Lesson Selection table.
          </FdTypography>
          <LessonTable
            lessonData={lessons?.map(formatLesson) || []}
            rowReordering
            onRowOrderChange={({ oldIndex, targetIndex }) => {
              moveLesson(oldIndex, targetIndex);
            }}
            onUpArrow={(rowIndex) => moveLesson(rowIndex, rowIndex - 1)}
            onDownArrow={(rowIndex) => moveLesson(rowIndex, rowIndex + 1)}
            showUpDown
            isOrderTable
          />
        </FdCard>
      )}
    </Box>
  );
};

EditLessons.propTypes = {
  courseData: PropTypes.shape({
    getCourse: PropTypes.shape({
      courseLessons: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  refetchCourse: PropTypes.func.isRequired,
};

export default EditLessons;
