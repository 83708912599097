import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const FdBoxButton = ({ children, ...rest }) => {
  return (
    <Box
      component="button"
      className="flex items-center justify-center w-full h-16 border-2 border-dashed rounded uppercase hover:bg-blue-50 focus:outline-none"
      sx={(theme) => ({ borderColor: theme.palette.primary.main })}
      py={2}
      {...rest}
    >
      <FdTypography
        variant="subtitle1"
        color="rgba(51, 51, 255, 1)"
        className="font-semibold tracking-wider"
      >
        {children}
      </FdTypography>
    </Box>
  );
};

FdBoxButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FdBoxButton;
