import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import {
  FdAccordion,
  FdTypography,
  FdProgress,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import ModuleParts from './ModuleParts';
import SummaryTitles from '../Create/SummaryTitles';
import { listModulePartProgressesByCourseUserId } from '../../../graphql/queries';
import { upperCaseFirstLetter } from '../../../shared/utils/stringUtils';

function ModuleItem({ module, allQuizzes, userId, courseId }) {
  const { courseUserId } = useParams();

  const { data: modulePartProgress, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
      variables: {
        courseUserId,
      },
    });

  if (modulePartProgressLoading) return <FdProgress size="small" />;

  const allUserModuleParts =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items || [];

  const filteredModuleParts = allUserModuleParts?.filter((modulePart) =>
    module?.parts?.items?.map(({ id }) => id).includes(modulePart.modulePartId),
  );

  const modulePartsComplete =
    filteredModuleParts?.filter(
      (userModulePartProgress) => userModulePartProgress.status === 'FINISHED',
    ) || [];

  const totalModuleParts = module?.parts?.items || [];
  const allModulePartsComplete =
    modulePartsComplete?.length === totalModuleParts?.length;

  return (
    <FdAccordion
      summary={() => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" justifyContent="center">
            <FdTypography variant="h3">{module.name}</FdTypography>
          </Box>
          <SummaryTitles
            parts={
              module?.parts?.items
                .filter(
                  (p) =>
                    (p.lab?.status === 'READY' && p.type === 'LAB') ||
                    p.type !== 'LAB',
                )
                .map((p) => ({
                  ...p,
                  partType:
                    p.type === 'PDF' ? p.type : upperCaseFirstLetter(p.type),
                  quiz: p.type === 'QUIZ' ? p.quiz?.id : '',
                  lab: p.type === 'LAB' ? p.name : '',
                })) || []
            }
            quizzes={allQuizzes}
            duration={module.duration}
          />
        </Box>
      )}
      content={() => (
        <Box>
          <Box mb={2}>
            <FdTypography variant="body1">{module.description}</FdTypography>
          </Box>
          <ModuleParts
            parts={module?.parts?.items}
            module={module}
            userId={userId}
            courseId={courseId}
          />
        </Box>
      )}
      startAdornment
      startAdornmentIcon={() => {
        if (filteredModuleParts.length && allModulePartsComplete) {
          return (
            <DoneOutlineIcon
              style={{ color: '#2E7D32', marginRight: '16px' }}
            />
          );
        }
        return null;
      }}
      showToggleButton={false}
    />
  );
}

ModuleItem.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    parts: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  allQuizzes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ModuleItem;
