import React from 'react';
import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTextField } from '@fifthdomain/fe-shared';
import { VM_PLATFORMS } from '../constants';
import RadioOptions from '../components/Labs/RadioOptions';

const VMDetails = () => {
  const { control } = useFormContext();

  return (
    <Box className="flex flex-col gap-y-6">
      <Controller
        control={control}
        name="vmName"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <FdTextField
            id="vmName"
            label="VM Name"
            placeholder="Enter VM Name"
            required
            fullWidth
            error={error}
            helperText={error?.message}
            {...rest}
            inputRef={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="vmNotes"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <FdTextField
            id="vmNotes"
            label="VM Notes"
            placeholder="Enter VM Notes"
            fullWidth
            multiline
            rows={1}
            error={error}
            helperText={error?.message}
            {...rest}
            inputRef={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="vmPlatform"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <RadioOptions
            id="vmPlatform"
            options={[
              { value: VM_PLATFORMS.LINUX, label: VM_PLATFORMS.LINUX },
              { value: VM_PLATFORMS.WINDOWS, label: VM_PLATFORMS.WINDOWS },
            ]}
            title="VM Platform"
            subTitle="Choose the operating system this VM will run on."
            error={error}
            {...rest}
            inputRef={ref}
          />
        )}
      />
    </Box>
  );
};

export default VMDetails;
