import Yup from '../Common';

export const labInitialValues = {
  labName: '',
  labDescription: '',
  labNetworks: [],
  labVms: [],
  labDeleteNetworks: [],
  labDeleteVMs: [],
  labDeleteVMNetworks: [],
};

export const labValidationSchema = Yup.object().shape({
  labName: Yup.string()
    .required('Please enter a Lab Name')
    .max(200, 'Lab Name must be 200 characters or less')
    .noWhitespace('Please enter a Lab Name'),
  labDescription: Yup.string()
    .max(250, 'Lab Description must be 250 characters or less')
    .test(
      'noWhitespaceNonRequired',
      'Please enter a Lab Description',
      (value) => !value || (value && value.trim().length),
    ),
  labNetworks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Please enter a Network Name')
          .noWhitespace('Please enter a Network Name')
          .max(200, 'Network Name must be 200 characters or less'),
      }),
    )
    .unique('name', 'Please enter a unique name for this network'),
  labVms: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Please enter a VM Name')
          .noWhitespace('Please enter a VM Name')
          .max(200, 'VM Name must be 200 characters or less'),
        imageId: Yup.string().required('Please select a VM image'),
        accessibilityOption: Yup.string().required('Please select an access type'),
        instanceType: Yup.string().required('Please select a VM size'),
        vdiUser: Yup.string().required('Please enter a VDI User'),
        networks: Yup.object().shape({
          items: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required('Please select a network')
                  .test(
                    'correct-network',
                    'Please select a network',
                    (value, labNetworks) =>
                      labNetworks?.from[3]?.value?.labNetworks
                        ?.map((labNetwork) => labNetwork.name)
                        ?.includes(value),
                  ),
              }),
            )
            .unique('name', 'Please select a network which is not in use')
        }),
      }),
    )
    .unique('name', 'Please enter a unique VM Name')
    .min(1),
});

export const duplicateLabValidationSchema = Yup.object().shape({
  labDuplicate: Yup.string()
    .required('Please enter a Lab Name')
    .max(200, 'Lab Name must be 200 characters or less')
    .noWhitespace('Please enter a  Lab Name'),
});
