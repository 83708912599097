import React from 'react';
import { Box, Switch, FormGroup, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTextField, FdTypography, FdCard } from '@fifthdomain/fe-shared';
import FdTextView from '../../FdTextView';
import InfoFdAccordion from '../LessonMaterial/InfoFdAccordion';

const CourseDetails = ({ isEdit, heading }) => {
  const { control, getValues, setValue } = useFormContext();
  const Wrapper = heading ? FdCard : Box;
  return (
    <Wrapper heading={heading ? heading : ''} variant="outlined">
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="name"
          render={({
            field: { value, ref, ...rest },
            fieldState: { error },
          }) => (
            <Box mt={1} mb={3}>
              {isEdit ? (
                <FdTextField
                  id="name"
                  label="Course Name"
                  value={value}
                  required
                  fullWidth
                  placeholder="Course name must be 200 characters or less"
                  error={error}
                  helperText={error && error.message}
                  data-cy="name"
                  {...rest}
                  inputRef={ref}
                />
              ) : (
                <FdTextView label="Course Name" value={value} />
              )}
            </Box>
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({
            field: { value, ref, ...rest },
            fieldState: { error },
          }) => (
            <Box mb={3}>
              {isEdit ? (
                <FdTextField
                  id="description"
                  label="Course Introduction"
                  value={value}
                  fullWidth
                  multiline
                  required
                  rows={3}
                  placeholder="Enter a course introduction"
                  error={error}
                  helperText={
                    (error && error.message) ||
                    'This introduction will be visible to participants when they take this course.'
                  }
                  data-cy="description"
                  {...rest}
                  inputRef={ref}
                />
              ) : (
                <>
                  <FdTypography variant="subtitle1">
                    Course Introduction
                  </FdTypography>
                  <Box mt={1}>
                    <InfoFdAccordion
                      title="Course Introduction"
                      content={
                        <FdTypography color="secondary" variant="body2">
                          {value}
                        </FdTypography>
                      }
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
        />
      </Box>

      {getValues('status') !== 'DRAFT' && (
        <Controller
          control={control}
          name="availability"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) => (
            <Box mb={2}>
              <FormGroup>
                <FdTextView label="Availability" />
                {isEdit ? (
                  <FormControlLabel
                    label="Make course available to invited participants"
                    control={
                      <Switch
                        id="availability"
                        checked={fieldValue}
                        value={fieldValue?.value}
                        error={error && error?.message}
                        data-cy="availability"
                        {...rest}
                        onChange={(e) => {
                          setValue('availability', e.target.checked);
                        }}
                        inputRef={ref}
                      />
                    }
                  />
                ) : (
                  <Box className="flex">
                    <Switch checked={fieldValue} />
                    <FdTypography variant="body1" color="secondary">
                      Make course available to invited participants
                    </FdTypography>
                  </Box>
                )}
              </FormGroup>
            </Box>
          )}
        />
      )}
    </Wrapper>
  );
};

CourseDetails.propTypes = {
  isEdit: PropTypes.bool,
  heading: PropTypes.string,
};

CourseDetails.defaultProps = {
  isEdit: false,
  heading: '',
};

export default CourseDetails;
