import React, { useState } from 'react';
import { Box } from '@mui/material';
import shortid from 'shortid';
import { useMutation, gql } from '@apollo/client';
import { useForm, useWatch, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdChip,
  BasePage,
  FdTable,
  FdModal,
  FdTypography,
  FdAlert,
  FdSelect,
  FdProgress,
  FdAccordion,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdExternalLink,
  warningToastMessage,
  errorToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { VM_PLATFORMS } from '../constants';
import FdFileUpload from '../components/FdFileUploadModal/FdFileUpload';
import VMDetails from '../VM/VMDetails';
import { fileUploadAWSEc2 } from '../shared/utils/fileUploadAWS';
import {
  createVMFile,
  transferVMOwnership,
  updateVMFile,
} from '../graphql/mutations';
import { listVMFiles, listVMFilesByStatus } from '../graphql/queries';
import { getVMStatusColor } from '../shared/utils/getStatusColor';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import TransferContentOwnership from '../components/Labs/TransferContentOwnership';

const ListVMs = () => {
  const globalSnap = useSnapshot(globalStore);
  const [uploadModal, setUploadModal] = useState(false);
  const [editModal, setEditModal] = useState(undefined);
  const [copyVMModal, setCopyVMModal] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(undefined);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(undefined);
  const [selectedVms, setSelectedVms] = useState([]);
  const [transferVmOwnershipModal, setTransferOwnershipModal] = useState(false);
  const [orgAlias, setOrgAlias] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { orgId } = globalSnap;

  const { data: listVMFilesInQueueData, loading: listVMFilesInQueueLoading } =
    useQueryRecursive(gql(listVMFilesByStatus), {
      variables: {
        status: 'IN_QUEUE',
        filter: { orgId: { eq: orgId } },
      },
      skip: !orgId,
    });

  const {
    data: listVMFilesData,
    loading: listVMFilesLoading,
    refetch: refetchListVMFiles,
  } = useQueryRecursive(gql(listVMFiles), {
    variables: {
      filter: { orgId: { eq: orgId } },
    },
    skip: !orgId,
  });
  const [createVMFileMutation] = useMutation(gql(createVMFile));
  const [updateVMFileMutation] = useMutation(gql(updateVMFile));
  const [transferVmOwnershipMutation, { loading: transferVmOwnershipLoading }] =
    useMutation(gql(transferVMOwnership));

  const initialValues = {
    vmName: '',
    vmNotes: '',
    vmPlatform: VM_PLATFORMS.LINUX,
    vmUploadFile: undefined,
  };
  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test({
      name: 'noWhitespace',
      message,
      test: (value) => (value ? value.trim().length : true),
    });
  });

  const validationSchema = Yup.object().shape({
    vmName: Yup.string()
      .required('Please enter a VM name')
      .max(25, 'VM Name can only have 25 characters')
      .noWhitespace('Please enter a valid VM name'),
    vmNotes: Yup.string()
      .noWhitespace('Please enter a valid note')
      .max(80, 'VM Notes can only have 80 characters'),
    vmPlatform: Yup.string().noWhitespace('Please enter a VM platform'),
    vmUploadFile: Yup.lazy(() => {
      return Yup.mixed().required();
    }),
  });

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, getValues, setValue, trigger, reset } = reactHookFormMethods;

  const watchUploadFile = useWatch({
    control,
    name: 'vmUploadFile',
  });

  if (listVMFilesLoading || listVMFilesInQueueLoading) {
    return <FdProgress />;
  }

  const totalQueue =
    listVMFilesInQueueData?.listVMFilesByStatus?.items?.length || 0;
  const vmFilesInQueue =
    listVMFilesInQueueData?.listVMFilesByStatus?.items?.map((v) => v.id) || [];

  const vmRows =
    listVMFilesData?.listVMFiles?.items?.map((vmFile) => {
      const vmFileIndex =
        vmFilesInQueue.findIndex((vmFileQueue) => vmFile.id === vmFileQueue) +
        1;
      const vmFileStatus =
        vmFileIndex > 0
          ? `queue ${vmFileIndex} of ${totalQueue}`
          : vmFile.status;
      const fileSizeGB =
        vmFile.fileSize && vmFile.fileSize !== null
          ? `${parseFloat((parseFloat(vmFile.fileSize) / 1024).toFixed(2))} GB`
          : '';
      return { ...vmFile, fileSizeGB, status: vmFileStatus };
    }) || [];

  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    { field: 'description', width: 500, headerName: 'Notes' },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => (
        <FdChip
          color={getVMStatusColor(params?.row?.status || '')}
          size="small"
          label={upperCaseFirstLetter(params?.row?.status || '')}
        />
      ),
    },
    {
      field: 'noOfLabs',
      headerName: 'No. of Labs',
      type: 'number',
      hide: true,
    },
    {
      field: 'createdAt',
      width: 150,
      headerName: 'Date Created',
      type: 'dateTime',
      valueGetter: (params) => new Date(params?.row?.createdAt),
      renderCell: (params) =>
        getDateTimeZoneFormatted(params?.row?.createdAt, true),
    },
    {
      field: 'fileSizeGB',
      headerName: 'File Size',
    },
  ];

  const actions = [
    {
      label: 'Edit',
      show: () => true,
      onClick: ({ id }) => {
        setUploadError(false);
        const editVm = vmRows.find((vm) => vm.id === id);
        reset({
          vmName: editVm.name,
          vmNotes: editVm.description,
          vmUploadFile: {},
        });
        setEditModal(id);
      },
    },
    // TODO: Hiding option to delete as the functionality to delete VMs haven't been built yet
    // {
    //   label: 'Delete',
    //   show: (row) => row.status === 'FAILED' || row.status === 'ERROR',
    //   onClick: ({ id }) => setDeleteModal(id),
    // },
    {
      show: () => false, // this is to show the drop down instead of buttons
    },
    {
      label: 'Duplicate',
      show: (row) => row.status === 'READY',
      onClick: ({ id }) => {
        setUploadError(false);
        const copyVm = vmRows.find((vm) => vm.id === id);
        reset({
          vmName: `Copy of ${copyVm.name}`,
          vmNotes: copyVm.description,
          vmUploadFile: copyVm.file,
          fileSizeMb: copyVm.fileSizeMb,
        });
        setCopyVMModal(id);
      },
    },
  ];

  const onBulkAction = (_selection) => {
    switch (_selection) {
      case 'Transfer Ownership':
        setTransferOwnershipModal(true);
        break;
      default:
        break;
    }
  };

  const onConfirmTransferVmOwnership = () => {
    transferVmOwnershipMutation({
      variables: {
        orgAlias,
        vmIds: selectedVms,
      },
      onCompleted: () => {
        setTransferOwnershipModal(false);
        setSelectedVms([]);
        setOrgAlias('');
        successToastMessage('Transfer of ownership initiated');
        refetchListVMFiles();
      },
      onError: (e) => setErrorMessage(e.message),
    });
  };

  const onDismissTransferVmOwnership = () => {
    setTransferOwnershipModal(false);
    setOrgAlias('');
    setErrorMessage('');
    warningToastMessage('Transfer of ownership cancelled');
  };

  const isOnlyAdmin = globalSnap.userType === 'ADMIN';

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'Manage VMs', type: 'VM' }} />
      <BasePage heading="Manage VMs" data-cy="mange-vms-base-page">
        <Box mt={2} mb={2} height="770px">
          <FdTable
            toolbarSettings={{
              title: 'VMs',
              headerActions: [
                {
                  label: 'UPLOAD VM',
                  onClick: () => {
                    setUploadError(false);
                    reset(initialValues);
                    setUploadPercentage(0);
                    setUploadModal(true);
                  },
                },
              ],
              filterButton: true,
              searchBox: true,
              headerCustomElements:
                selectedVms.length > 0
                  ? [
                      {
                        CustomElement: () => (
                          <FdSelect
                            label=""
                            options={['Transfer Ownership']}
                            width="250px"
                            placeholder="Actions"
                            customPlaceHolder
                            onChange={onBulkAction}
                          />
                        ),
                      },
                    ]
                  : [],
            }}
            selection={isOnlyAdmin}
            disableSelectionOnClick
            selectionModel={selectedVms}
            onSelectionModelChange={(_value) => setSelectedVms(_value)}
            actions={actions}
            rows={vmRows}
            columns={columns}
            pagination
            visibleSelection
            rowsPerPageOptions={[5, 10, 20]}
            tablePageSize={10}
            isRowSelectable={(params) =>
              params.row.status === 'READY' && params.row.vAppTemplateId
            }
            gridId="labs-list-vms-grid"
          />
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form style={{ width: '100%' }}>
            <FdModal
              size="md"
              title="Upload VM"
              confirm={uploadProgress ? 'Loading...' : 'UPLOAD'}
              disableConfirm={uploadProgress}
              dismiss="CANCEL"
              open={uploadModal}
              onConfirm={async () => {
                const result = await trigger();
                const { vmName, vmNotes, vmPlatform, vmUploadFile } =
                  getValues();
                if (result) {
                  setUploadProgress(true);
                  const { bucket, name, region, size } = await fileUploadAWSEc2(
                    {
                      file: vmUploadFile,
                      platform: vmPlatform,
                      fileType: '.ova',
                    },
                    setUploadPercentage,
                  );

                  const fileSizeMb = parseInt(size / 1048576, 10);
                  createVMFileMutation({
                    variables: {
                      input: {
                        userId: globalSnap.userId,
                        name: vmName,
                        description: vmNotes,
                        status: 'LOADING',
                        fileSize: fileSizeMb,
                        file: { bucket, key: name, region },
                        orgId,
                      },
                    },
                    onCompleted: () => {
                      setUploadProgress(false);
                      setUploadModal(false);
                      successToastMessage('Success! VM uploaded');
                      refetchListVMFiles();
                    },
                  });
                }
              }}
              onDismiss={() => {
                setUploadModal(false);
                warningToastMessage('VM not uploaded');
              }}
              data-cy="upload-vm-modal"
            >
              <Box display="flex" alignItems="flex-end" mb={2} mt={1}>
                <Box display="flex" flexDirection="column" width="100%">
                  <VMDetails />
                  <Box>
                    <Box mb={1}>
                      <FdAccordion
                        summary={() => (
                          <FdTypography variant="body1">
                            VM Image Constraints
                          </FdTypography>
                        )}
                        content={() => (
                          <Box>
                            <FdTypography variant="body2" color="secondary">
                              <ul>
                                <li>
                                  Supported Operating Systems:
                                  <ul>
                                    <li>Linux VMs:</li>
                                    <li>
                                      Ubuntu (recommended: download our
                                      pre-configured version)
                                    </li>
                                    <li>CentOS</li>
                                    <li>Debian</li>
                                    <li>Fedora</li>
                                  </ul>
                                  <ul>
                                    <li>Windows VMs:</li>
                                    <li>Windows Server 2022</li>
                                    <li>Windows Server 2019</li>
                                    <li>Windows Server 2016</li>
                                  </ul>
                                  <ul>
                                    <li>
                                      See more about supported OS&apos;s
                                      <FdExternalLink
                                        href="https://docs.aws.amazon.com/vm-import/latest/userguide/prerequisites.html"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        here
                                      </FdExternalLink>
                                    </li>
                                  </ul>
                                  <ul>Requirements:</ul>
                                  <li>
                                    - Install cloud-enabled kernel (Linux only)
                                  </li>
                                  <li>
                                    - Configure default boot to cloud kernel
                                    (Linux only)
                                  </li>
                                  <li>
                                    - Export from VMware Workstation Note: We
                                    strongly recommend using our pre-configured
                                    VM templates as your starting point.
                                  </li>
                                </li>
                              </ul>
                            </FdTypography>
                          </Box>
                        )}
                        endAdornment
                        showToggleButton={false}
                      />
                    </Box>
                    <FdFileUpload
                      inProgress={uploadProgress}
                      onDrop={(files) => {
                        if (files.length === 0) {
                          errorToastMessage('Only .ova file type accepted');
                          return;
                        }
                        if (files.length > 1) {
                          errorToastMessage('Only one file is accepted ');
                          return;
                        }
                        setValue('vmUploadFile', files[0]);
                      }}
                      fileTypes=".ova"
                      id="fileUpload"
                      fileCount={getValues('vmUploadFile') ? 1 : 0}
                      uploadPercentage={uploadPercentage}
                      onSingleFileNextAttempt={() => setUploadError(true)}
                      singleFile
                    />
                    <Box mt={2}>
                      <Box>
                        {watchUploadFile && (
                          <FdChip
                            color={uploadError ? 'error' : 'default'}
                            key={shortid.generate()}
                            label={watchUploadFile?.name}
                            onDelete={() => {
                              setValue('vmUploadFile', undefined);
                              setUploadError(false);
                            }}
                            style={{
                              marginRight: '0.5rem',
                              marginBottom: '0.5rem',
                            }}
                          />
                        )}
                      </Box>
                      <Box mt={1}>
                        {uploadError && (
                          <FdAlert
                            variant="error"
                            message="You must remove the current file before uploading another file. "
                          />
                        )}
                        <Controller
                          control={control}
                          name="vmUploadFile"
                          render={({ fieldState: { error } }) => (
                            <Box>
                              {error && !watchUploadFile && (
                                <FdAlert
                                  variant="error"
                                  message="Please upload a file"
                                />
                              )}
                            </Box>
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </FdModal>
            <FdModal
              size="md"
              title="Edit VM"
              confirm={uploadProgress ? 'Loading...' : 'Save'}
              disableConfirm={uploadProgress}
              dismiss="CANCEL"
              open={editModal}
              onConfirm={async () => {
                const result = await trigger();
                const { vmName, vmNotes } = getValues();
                if (result) {
                  setUploadProgress(true);
                  updateVMFileMutation({
                    variables: {
                      input: {
                        id: editModal, // id stored here
                        userId: globalSnap.userId,
                        name: vmName,
                        description: vmNotes,
                        orgId,
                      },
                    },
                    onCompleted: () => {
                      setUploadProgress(false);
                      setEditModal(false);
                      successToastMessage('Success! VM updated');
                      refetchListVMFiles();
                    },
                  });
                }
              }}
              onDismiss={() => {
                setEditModal(undefined);
                warningToastMessage('VM not changed');
              }}
              data-cy="edit-vm-modal"
            >
              <Box display="flex" alignItems="flex-end" mb={2} mt={1}>
                <Box display="flex" flexDirection="column" width="100%">
                  <VMDetails />
                </Box>
              </Box>
            </FdModal>
            <FdModal
              size="xs"
              title={
                <Box display="flex" alignItems="center">
                  <WarningIcon
                    style={{
                      fontSize: 38,
                      color: '#C62828',
                      paddingRight: '0.5rem',
                    }}
                  />
                  <span>Delete VM?</span>
                </Box>
              }
              description={
                <Box>
                  <FdTypography variant="subtitle1">
                    Are you sure you want to delete the VM?
                  </FdTypography>
                  <Box mt={2}>
                    <FdTypography variant="body1" color="secondary">
                      The VM will be permanently removed from the platform.
                    </FdTypography>
                  </Box>
                </Box>
              }
              dismiss="CANCEL"
              confirm="OK"
              open={deleteModal}
              onDismiss={() => {
                setDeleteModal(undefined);
                warningToastMessage('VM not deleted');
              }}
              onConfirm={() => {
                setDeleteModal(undefined);
              }}
            />

            <FdModal
              size="md"
              title="Duplicate VM"
              confirm={uploadProgress ? 'Loading...' : 'Copy'}
              disableConfirm={uploadProgress}
              dismiss="CANCEL"
              open={copyVMModal}
              onConfirm={async () => {
                const result = await trigger();
                const {
                  vmName,
                  vmNotes,
                  fileSizeMb,
                  vmUploadFile: { bucket, key, region },
                } = getValues();
                if (result) {
                  setUploadProgress(true);
                  createVMFileMutation({
                    variables: {
                      input: {
                        userId: globalSnap.userId,
                        name: vmName,
                        description: vmNotes,
                        status: 'LOADING',
                        fileSize: fileSizeMb,
                        file: {
                          bucket,
                          key,
                          region,
                        },
                        orgId,
                      },
                    },
                    onCompleted: () => {
                      setUploadProgress(false);
                      setCopyVMModal(false);
                      successToastMessage('Copy of VM created successfully.');
                      refetchListVMFiles();
                    },
                  });
                }
              }}
              onDismiss={() => {
                setCopyVMModal(undefined);
                warningToastMessage('VM not copied');
              }}
              data-cy="edit-vm-modal"
            >
              <Box display="flex" alignItems="flex-end" mb={2} mt={1}>
                <Box display="flex" flexDirection="column" width="100%">
                  <VMDetails />
                </Box>
              </Box>
            </FdModal>
            <TransferContentOwnership
              rows={vmRows.filter((row) => selectedVms.includes(row.id))}
              columns={[
                {
                  field: 'name',
                  width: 200,
                  headerName: 'VMs to be transferred',
                },
                {
                  field: 'noOfLabs',
                  width: 200,
                  headerName: 'Dependent Labs',
                  hide: true,
                },
              ]}
              onConfirm={onConfirmTransferVmOwnership}
              onDismiss={onDismissTransferVmOwnership}
              onChange={(e) => {
                setOrgAlias(e.target.value);
                setErrorMessage('');
              }}
              transferContentOwnershipModal={transferVmOwnershipModal}
              orgAlias={orgAlias}
              contentType="VMs"
              loading={transferVmOwnershipLoading}
              error={!!errorMessage}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

export default ListVMs;
