import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { Box, Badge, Card, CardContent } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  FdTable,
  FdTypography,
  useQueryRecursive,
  FdProgress,
} from '@fifthdomain/fe-shared';
import CourseChat from './CourseChat';
import NoChatMessages from '../../shared/images/no-chat-messages.svg';
import {
  getCourseUsersGroups,
  listCourseUsersForMessages,
  listModulePartProgresses,
} from '../../queries/customQueries';
import { getDateTimeZoneCustom } from '../../shared/utils/dateUtils';
import { invalidateMessageQueries } from '../../queries/invalidateQueries';
import { ChatContext } from '../../contexts';
import ChatSaveModal from './ChatSaveModal';
import { successToastMessage } from '../../shared/utils/toast';
import { getCourseProgress } from '../Courses/Create/courseUtils';

const ChatQueue = ({ courseId }) => {
  const [openChat, setOpenChat] = useState(false);
  const [chatCourseUser, setChatCourseUser] = useState({});
  const [showChatSave, setShowChatSave] = useState(false);
  const [targetRow, setTargetRow] = useState(undefined);
  const [userIdsInQueue, setUserIdsInQueue] = useState([]);
  const { isChatDirty, setIsChatDirty } = useContext(ChatContext);

  const { data: courseData, loading: courseLoading } = useQueryRecursive(
    gql(getCourseUsersGroups),
    {
      variables: {
        id: courseId,
      },
      skip: !courseId,
    },
  );
  const courseUsers = courseData?.getCourse?.courseUsers?.items || [];
  const courseUserIds = courseUsers?.map((u) => u?.user?.id) || [];

  const {
    data: messagesData,
    loading: messagesLoading,
    refetch: refetchMessages,
  } = useQueryRecursive(gql(listCourseUsersForMessages), {
    variables: {
      filter: { courseId: { eq: courseId } },
    },
    skip: !courseId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const usersInQueue = _(_data?.listCourseUsers?.items)
        .flatMap('messages.items')
        .filter((i) => courseUserIds.includes(_.get(i, 'sender.id')))
        .map('sender.id')
        .uniq()
        .value();
      setUserIdsInQueue(usersInQueue);
    },
  });

  const { data: modulePartProgressData, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgresses), {
      variables: {
        filter: {
          or: userIdsInQueue.map((userId) => ({ userId: { eq: userId } })),
        },
      },
      skip: userIdsInQueue.length === 0,
    });

  const columns = [
    {
      field: 'name',
      width: 350,
      headerName: 'Name',
    },
    {
      field: 'unReadMessageCount',
      width: 100,
      headerName: '',
      filterable: false,
      searchable: false,
      renderCell: (column) => {
        if (column.value === 0) {
          return null;
        }
        return (
          <Badge badgeContent={column.value} color="error">
            <ChatBubbleOutlineIcon />
          </Badge>
        );
      },
    },
    {
      field: 'latestMessageDate',
      width: 350,
      headerName: 'Last message sent on',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        getDateTimeZoneCustom(value, 'MMM d, yyyy h:mm a'),
    },
    {
      field: 'email',
      width: 350,
      headerName: 'Email',
    },
    {
      field: 'courseProgress',
      width: 350,
      headerName: 'Course Progress',
      searchable: false,
      filterable: false,
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdProgress
          type="linear"
          value={params?.value?.progress}
          caption={params?.value?.progressCaption}
        />
      ),
    },
    {
      field: 'courseUserId',
      flex: 1,
    },
    {
      field: 'senderId',
      flex: 1,
    },
  ];

  if (messagesLoading || modulePartProgressLoading || courseLoading)
    return <FdProgress />;

  const getUserCourseProgress = (userCourseId, course) => {
    const courseModulePartIds = course?.courseModules?.items
      .map((m) => m?.parts?.items.map((mp) => mp?.id))
      .flat();

    // all parts progress
    const partsProgress =
      modulePartProgressData?.listModulePartProgresses?.items?.filter(
        (item) =>
          userCourseId === item?.courseUserId &&
          courseModulePartIds?.includes(item?.modulePartId),
      );

    // course progress
    const { individualCourseProgress } = getCourseProgress({
      modulePartData: partsProgress,
      courseData: course?.courseModules?.items,
    });
    return individualCourseProgress;
  };

  const unreadMessageItems = messagesData?.listCourseUsers?.items
    .map((item) => {
      const messages =
        item.messages.items.filter(
          (m) => !m?.sender?.permissions?.includes('VIEW_INSIGHTS'),
        ) || [];
      const latestMessage = messages.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )?.[0];
      const progress = getUserCourseProgress(
        latestMessage?.userCourseId,
        item?.course,
      );
      return {
        id: latestMessage?.id,
        name: latestMessage?.sender?.name,
        email: latestMessage?.sender?.email,
        latestMessageDate: latestMessage?.createdAt,
        unReadMessageCount: messages.length,
        courseUserId: latestMessage?.userCourseId,
        senderId: latestMessage?.sender?.id,
        courseProgress: {
          progress,
          progressCaption: `${progress}% done`,
        },
      };
    })
    .filter((m) => m.email)
    .sort((a, b) => b.unReadMessageCount - a.unReadMessageCount);

  if (unreadMessageItems.length === 0) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box mt={3}>
              <FdTypography variant="h2" color="secondary">
                All messages answered. Nothing to action.
              </FdTypography>
            </Box>
            <Box my={4}>
              <img src={NoChatMessages} alt="no-chat-messages" />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  const onChatSelection = (_params) => {
    invalidateMessageQueries();
    setOpenChat(true);
    setChatCourseUser(_params.row);
    setTargetRow(undefined);
  };

  return (
    <Box height="715px">
      <FdTable
        toolbarSettings={{
          title: 'Users',
          filterButton: true,
          searchBox: true,
          headerActions: [],
        }}
        rows={unreadMessageItems}
        columns={columns}
        tablePageSize={10}
        actions={[]}
        onRowClick={(params) => {
          // if chat dirty then stop user from switching rows
          if (
            isChatDirty &&
            chatCourseUser?.courseUserId !== params?.row?.courseUserId
          ) {
            setTargetRow(params);
            setShowChatSave(true);
            return;
          }
          // do nothing for same row selection
          if (
            !chatCourseUser ||
            chatCourseUser?.courseUserId !== params?.row?.courseUserId
          ) {
            onChatSelection(params);
          }
        }}
        columnVisibilityModel={{
          courseUserId: false,
          senderId: false,
        }}
        gridId="labs-chat-queue"
      />
      <CourseChat
        openChat={openChat}
        onCloseChat={(refreshQueries) => {
          setOpenChat(false);
          setChatCourseUser({});
          if (refreshQueries) {
            refetchMessages();
            successToastMessage('Users(s) cleared from the Chat Queue.');
          }
        }}
        chatTitle={chatCourseUser?.name}
        userCourseId={chatCourseUser?.courseUserId}
        receiverId={chatCourseUser.senderId}
        courseId={courseId}
        isAdmin
      />
      <ChatSaveModal
        onConfirm={() => {
          setIsChatDirty(false);
          setShowChatSave(false);
          onChatSelection(targetRow);
        }}
        onCancel={() => {
          setShowChatSave(false);
        }}
        open={showChatSave}
      />
    </Box>
  );
};

ChatQueue.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default ChatQueue;
