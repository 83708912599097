import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  FdTable,
  FdModal,
  FdTextField,
  FdTypography,
  FdCheckbox,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';

const TransferContentOwnership = ({
  rows,
  columns,
  onConfirm,
  onDismiss,
  transferContentOwnershipModal,
  contentType,
  orgAlias,
  onChange,
  loading,
  error,
  errorMessage,
  setErrorMessage,
}) => {
  const [checked, setChecked] = useState(false);

  const contentTypes = {
    Labs: 'lab',
    VMs: 'VM',
  };

  return (
    <FdModal
      title="Transfer Content"
      description=""
      confirm={loading ? 'Loading...' : 'Confirm Transfer'}
      dismiss="Cancel"
      showDismiss
      open={transferContentOwnershipModal}
      onConfirm={() => {
        if (!orgAlias) {
          setErrorMessage('Org Alias required!');
        } else if (!checked) {
          warningToastMessage(
            'Please agree to the terms to transfer ownership.',
          );
        } else {
          onConfirm();
          setChecked(false);
        }
      }}
      onDismiss={onDismiss}
      maxWidth="md"
    >
      <Box>
        <Box mb={2}>
          <FdTextField
            width="276px"
            id="org_alias"
            label="Enter Org Alias"
            placeholder=""
            required
            onChange={onChange}
            error={error}
            helperText={errorMessage}
          />
        </Box>
        <FdTable
          autoHeight
          title=""
          toolbarSettings={{
            hide: true,
          }}
          rows={rows}
          columns={columns}
          tablePageSize={5}
          gridId="labs-transfer-content-ownership"
        />
        {orgAlias && (
          <Box mt={2}>
            <FdTypography variant="h3">{`Confirm Transfer of ${rows.length} ${contentType} to ${orgAlias} `}</FdTypography>
            <FdTypography variant="body2">
              When you agree to transfer content to an org, you will no longer
              be the owner of the content. After the transaction is confirmed,
              you will lose access to this content.
            </FdTypography>
            <Box mt={2}>
              <FdTypography color="secondary" variant="body2">
                {` Tip: You can duplicate a ${contentTypes[contentType]} in your
              org before the transfer, if you need the ${contentTypes[contentType]}
              in future.`}
              </FdTypography>
            </Box>
            <Box mt={2} mb={2}>
              <FdCheckbox
                label={`I agree to transfer content to ${orgAlias}`}
                checked={checked}
                onChange={(e) => setChecked(e)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </FdModal>
  );
};

TransferContentOwnership.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  transferContentOwnershipModal: PropTypes.bool.isRequired,
  contentType: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.string.isRequired,
};

export default TransferContentOwnership;
